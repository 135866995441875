import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ShareService } from '@core/services/share.service';

@Injectable({
  providedIn: 'root',
})
export class DeliveryNotesService {
  public onReadyScroll: BehaviorSubject<any>;

  constructor() {
    this.onReadyScroll = new BehaviorSubject(false);
  }

  setReadyScroll() {
    this.onReadyScroll.next(true);
  }

  setAfterScroll() {
    this.onReadyScroll.next(false);
  }

  get isReadyScroll(): any | Observable<any> {
    return this.onReadyScroll.asObservable();
  }
}

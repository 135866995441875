<p-dialog
  header="Header"
  [(visible)]="visible"
  [style]="{ width: '30vw' }"
  (onHide)="close()"
>
  <ng-template pTemplate="header">
    <h4 class="font-weight-bold font-medium-3 text-secondary-darken mb-0">
      Notes #{{ deliveryCode }}
    </h4>
  </ng-template>
  <div *ngIf="loading" class="w-100 h-100 text-center">
    <div
      class="spinner-border mx-auto my-5"
      style="width: 3rem; height: 3rem"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <app-delivery-notes
    *ngIf="!loading"
    [chats]="chats"
    [fromDialog]="true"
  ></app-delivery-notes>
  <ng-template pTemplate="footer">
    <div class="d-flex align-items-start">
      <div class="d-flex">
        <button
          type="button"
          class="btn btn-icon btn-icon rounded-circle btn-flat-secondary m-0"
          rippleEffect
          (click)="toggled = !toggled"
        >
          <span [data-feather]="'smile'"></span>
        </button>
      </div>
      <div class="d-flex w-100">
        <div class="mx-1 w-100">
          <textarea
            #inputNotes
            placeholder="Type your message"
            class="form-control message"
            rows="1"
            id="chat-message-input"
            textareaAutoresize
            [(ngModel)]="note"
            (click)="onInputChange($event)"
            (keydown.ArrowRight)="onInputChange($event)"
            (keydown.ArrowLeft)="onInputChange($event)"
            (keydown.Space)="onInputChange($event)"
            (keydown.backspace)="onInputChange($event)"
          ></textarea>
        </div>
        <div>
          <button
            type="button"
            rippleeffect
            (click)="sendNotes()"
            [disabled]="noteLoading"
            class="m-0 btn btn-icon btn-primary waves-effect waves-float waves-light"
          >
            <span *ngIf="!noteLoading">
              <i data-feather="send"></i>
            </span>
            <span *ngIf="noteLoading">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Loading...</span>
            </span>
          </button>
        </div>
      </div>
      <div *ngIf="toggled" style="position: absolute; bottom: 5.5rem">
        <emoji-mart
          [showPreview]="false"
          (emojiClick)="handleEmoji($event, inputNotes)"
        ></emoji-mart>
      </div>
    </div>
  </ng-template>
</p-dialog>

import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import * as _ from 'lodash';

@Injectable()
export class ApiService {
  constructor(private http: HttpClient) {}

  setHeader() {
    const token = JSON.parse(localStorage.getItem('alsuser')).access_token;

    return new HttpHeaders({
      Authorization: 'Bearer ' + token,
      'Access-Control-Allow-Origin': '*',
    });
  }

  find(endpoint: string, params: any): Observable<{ results: [] }> {
    return this.http.get<{ results: [] }>(environment.apiUrl + endpoint, {
      headers: this.setHeader(),
      params,
    });
  }

  get(endpoint: string, params: any) {
    return this.http.get<any>(environment.apiUrl + endpoint, {
      headers: this.setHeader(),
      params,
    });
  }

  post(endpoint: string, data: any, withHeader = true) {
    const headers = withHeader ? this.setHeader() : {};
    return this.http.post<any>(environment.apiUrl + endpoint, data, {
      headers: headers,
    });
  }

  login(endpoint: string, data: any) {
    return this.http.post<any>(environment.apiUrl + endpoint, data);
  }

  put(endpoint: string, data: any) {
    return this.http.put<any>(environment.apiUrl + endpoint, data, {
      headers: this.setHeader(),
    });
  }

  patch(endpoint: string, data: any) {
    return this.http.patch<any>(environment.apiUrl + endpoint, data, {
      headers: this.setHeader(),
    });
  }

  delete(endpoint: string, id: any) {
    return this.http.delete<any>(environment.apiUrl + endpoint + id, {
      headers: this.setHeader(),
    });
  }

  deleteBulk(endpoint: string, ids: any[]) {
    const params = { id: ids.join(',') };
    return this.http.delete<any>(environment.apiUrl + endpoint, {
      headers: this.setHeader(),
      params,
    });
  }

  async getComponentItems(component, filter = {}) {
    const params = {
      data: JSON.stringify(_.isArray(component) ? component : [component]),
      filter: JSON.stringify(filter),
    };
    return await lastValueFrom(this.get('components', params))
      .then((res) => {
        let result = res.result;
        if (!_.isArray(component)) {
          result = res.result[component];
          if (component == 'fieldman') {
            result = _.map(res.result[component], (d) => {
              d['job_title_id'] = d.job.job_title.id;
              return d;
            });
          }
        }
        return result;
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

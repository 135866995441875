export const locale = {
  lang: 'en',
  data: {
    menu: {
      dashboard: {
        collapsible: 'Dashboards',
        welcome: 'Welcome',
      },
    },
    search: {
      result: {
        haveMatch: ' have a match for <b>{{value}}</b>',
        text: '{{criteria.label}} contains <b>{{criteria.value}}</b>',
        select: '{{criteria.label}} is <b>{{criteria.valueLabel}}</b>',
        horizontalSwitch:
          '{{criteria.label}} is <b>{{criteria.valueLabel}}</b>',
        daterange:
          '{{criteria.label}} between {{criteria.value.from}} and {{criteria.value.to}}',
        single: {
          'delivery-orders':
            'Order Number, Customer Code, Customer Name, Route Code, Route Name',
          routes: 'Code, Name, Customer Code, Customer Name',
          'check-sheets':
            'Code, Template Name, Driver Name, Driver ID, Truck Number, Truck Plate',
          employees:
            'Name, Employee ID, Job Title, Employment Status, Location',
          'stocks/entry': 'Code, Type, Status',
          'settings/delivery/logistic-points': 'Code, Name',
          'settings/delivery/e-tolls': 'Number, Location',
          'settings/payroll-variables': 'Code, Name, Type, Description',
          'settings/payroll-components': 'Code, Name, Type',
          'settings/auth/permissions': 'Name, Definition',
          'settings/qualifications/skills': 'Name, Description',
          'settings/qualifications/trainings': 'Name, Description',
          'settings/attendance/work-schedules': 'Name',
          'periodic-service':
            'Vehicle Number, Vehicle License Plate Number, Mechanic Name',
          'settings/health-types': 'Name',
          'settings/healths': 'Name',
          'health-check': 'Employee Name',
          'employee-sicks': 'Employee, Job Title, Department, Location, Status',
          schedules: 'Employee',
          attendances: 'Employee, Job Title, Work Schedule',
          'attendances/timeline': 'Employee Name, Employee ID',
          'employee-birthday': 'Employee, Job Title',
          'delivery-incidents':
            'Incident Name, Driver Management, CheckSheet, Route, Incident Type',
          'delivery-driver-performances':
            'CheckSheet, Route, Truck Number, Truck Plate Number, Driver, Employee ID, Status',
          vehicles:
            'Number, Plate Number, Type, Brand, Model, Status, Vendor, Location',
          'payroll-generates': 'Payroll Type Name, Status',
          'settings/payroll-types': 'Name, Type',
        },
      },
    },
  },
};

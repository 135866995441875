<div ngbDropdown>
  <button
    ngbDropdownToggle
    class="btn btn-outline-secondary pl-1 {{ class }}"
    [style]="style"
    type="button"
    id="dropdownDate"
  >
    <i *ngIf="!hideIcon" data-feather="calendar" class="mr-2"></i>
    <span>{{ dateLabel() }}</span>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownDate">
    <a
      ngbDropdownItem
      href="javascript:void(0)"
      *ngFor="let opt of dateOptions; let i = index"
      (click)="
        opt.key === 'custom' || opt.key === 'month-year' || opt.key === 'year'
          ? opt.key === 'custom'
            ? (showDateCustom = true)
            : opt.key === 'month-year'
            ? (showMonthYearCustom = true)
            : (showYearCustom = true)
          : onSelect(opt)
      "
      >{{ opt.label }}</a
    >
  </div>
</div>

<p-dialog
  [(visible)]="showDateCustom"
  header="Custom Date"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  position="top"
  appendTo="body"
>
  <p-calendar
    [(ngModel)]="dateCustom"
    [numberOfMonths]="2"
    selectionMode="range"
    [inline]="true"
  >
  </p-calendar>
  <ng-template pTemplate="footer">
    <button
      type="button"
      class="btn btn-primary"
      rippleEffect
      (click)="onSelect(dateOptions[9]); showDateCustom = false"
    >
      Apply
    </button>
    <button
      type="button"
      class="btn btn-grey"
      (click)="showDateCustom = false"
      rippleEffect
    >
      Cancel
    </button>
  </ng-template>
</p-dialog>

<p-dialog
  [style]="{ width: '25vw' }"
  [(visible)]="showMonthYearCustom"
  header="Custom Month & Year"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  position="top"
  appendTo="body"
>
  <div class="range-month-year">
    <p-calendar
      [(ngModel)]="dateCustom"
      view="month"
      selectionMode="range"
      [inline]="true"
    >
    </p-calendar>
  </div>
  <ng-template pTemplate="footer">
    <button
      type="button"
      class="btn btn-primary"
      rippleEffect
      (click)="onSelect(dateOptions[0]); showMonthYearCustom = false"
    >
      Apply
    </button>
    <button
      type="button"
      class="btn btn-grey"
      (click)="showMonthYearCustom = false"
      rippleEffect
    >
      Cancel
    </button>
  </ng-template>
</p-dialog>

<p-dialog
  [style]="{ width: '25vw' }"
  [(visible)]="showYearCustom"
  header="Custom Year"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  position="top"
  appendTo="body"
>
  <div class="range-month-year">
    <p-calendar [(ngModel)]="dateCustom[0]" view="year" [inline]="true">
    </p-calendar>
  </div>
  <ng-template pTemplate="footer">
    <button
      type="button"
      class="btn btn-primary"
      rippleEffect
      (click)="onSelect(dateOptions[0]); showYearCustom = false"
    >
      Apply
    </button>
    <button
      type="button"
      class="btn btn-grey"
      (click)="showYearCustom = false"
      rippleEffect
    >
      Cancel
    </button>
  </ng-template>
</p-dialog>

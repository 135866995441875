<ng-container *ngFor="let item of menu">
  <!-- section -->
  <ng-container *ngIf="item.type === 'section'">
    <li
      core-menu-vertical-section
      *ngxPermissionsOnly="item.role"
      [item]="item"
      class="navigation-header"
    ></li>
  </ng-container>

  <!-- sub-menu item/collapsible of sections  -->
  <ng-container *ngIf="item.type === 'section'">
    <ng-container *ngFor="let item of item.children">
      <!-- item | if openInNewTab set routerLink & routerLinkActive blank -->
      <ng-container *ngIf="item.type === 'item'">
        <li
          core-menu-vertical-item
          *ngxPermissionsOnly="item.role"
          [item]="item"
          [ngClass]="{ disabled: item.disabled === true }"
          [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
          [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
        >
          <span
            [routerLink]="item.openInNewTab ? [] : [item.url]"
            class="d-none"
          ></span>
        </li>
      </ng-container>

      <!-- collapsible -->
      <ng-container *ngIf="item.type === 'collapsible'">
        <li
          core-menu-vertical-collapsible
          *ngxPermissionsOnly="item.role"
          [item]="item"
          class="nav-item has-sub"
        ></li>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- item -->
  <ng-container *ngIf="item.type === 'item'">
    <li
      core-menu-vertical-item
      *ngxPermissionsOnly="item.role"
      [item]="item"
      [ngClass]="{ disabled: item.disabled === true }"
      [routerLinkActive]="!item.openInNewTab ? 'active' : ''"
      [routerLinkActiveOptions]="{ exact: item.exactMatch || false }"
    >
      <span
        [routerLink]="item.openInNewTab ? [] : [item.url]"
        class="d-none"
      ></span>
    </li>
  </ng-container>

  <!-- collapsible -->
  <ng-container *ngIf="item.type === 'collapsible'">
    <li
      core-menu-vertical-collapsible
      *ngxPermissionsOnly="item.role"
      [item]="item"
      class="nav-item has-sub"
    ></li>
  </ng-container>
</ng-container>

import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import * as moment from 'moment-timezone';
import * as _ from 'lodash';
import { random_hex_color_code } from 'app/main/delivery/closing/closing.component';
import { SocketApi } from 'app/app.module';
import { DeliveryNotesService } from '../delivery-notes/delivery-notes.service';

@Component({
  selector: 'app-modal-delivery-notes',
  templateUrl: './modal-delivery-notes.component.html',
  styleUrls: ['./modal-delivery-notes.component.scss'],
  providers: [ApiService],
  encapsulation: ViewEncapsulation.None,
})
export class ModalDeliveryNotesComponent implements OnInit {
  @Input('visible') visible: boolean;
  @Input('loading') loading: boolean;
  @Input('deliveryId') deliveryId: boolean;
  @Input('deliveryCode') deliveryCode: string;

  @Output() onClose: EventEmitter<any> = new EventEmitter<any>();

  public toggled = false;
  public noteLoading = false;
  public note: any = '';
  public cursor = [0, 0];
  public chats = [];
  public user = JSON.parse(localStorage.getItem('alsuser')).employee;

  constructor(
    private socket: SocketApi,
    private _apiService: ApiService,
    private _toastrService: ToastrService,
    private _deliveryNoteService: DeliveryNotesService
  ) {}

  ngOnInit(): void {
    this.socket.connect();
    this.loading = true;
    lastValueFrom(this._apiService.get('delivery-notes/' + this.deliveryId, {}))
      .then((res) => {
        if (res.statusCode == 200) {
          this.chats = this.arrangeNotes(res.rows);
          this.loading = false;
          setTimeout(() => {
            this._deliveryNoteService.setReadyScroll();
          }, 500);
          this.listenSocket(this.deliveryId);
        }
      })
      .catch((err) => {
        this.handleError(err);
      });
  }

  handleError(err) {
    if (err?.error?.message) {
      if (_.isArray(err.error.message)) {
        for (const message of err.error.message) {
          this._toastrService.error(message);
        }
      } else {
        this._toastrService.error(err.error.message);
      }
    } else {
      this._toastrService.error(err.message);
    }
  }

  arrangeNotes(rows) {
    const notes = _.reduce(
      rows,
      (init: any, d: any) => {
        const length = init.length;
        if (!length) {
          init.push({
            senderId: d.employee.id,
            color: random_hex_color_code(),
            avatar: d.employee.photo_id,
            senderName: d.employee.fullname,
            messages: [
              {
                id: d.id,
                text: d.note,
                time: d.created_at,
              },
            ],
          });
        } else {
          const lastItem = init.slice(-1)[0];
          if (lastItem.senderId == d.employee.id) {
            init.pop();
            lastItem.messages.push({
              id: d.id,
              text: d.note,
              time: d.created_at,
            });
            init.push(lastItem);
          } else {
            const selected = _.filter(init, {
              senderId: d.employee.id,
            });

            if (!selected.length) {
              init.push({
                senderId: d.employee.id,
                color: random_hex_color_code(),
                avatar: d.employee.photo_id,
                senderName: d.employee.fullname,
                messages: [
                  {
                    id: d.id,
                    text: d.note,
                    time: d.created_at,
                  },
                ],
              });
            } else {
              const lastItem = init.slice(-1)[0];
              if (lastItem.senderId == d.employee.id) {
                init.pop();
                lastItem.messages.push({
                  id: d.id,
                  text: d.note,
                  time: d.created_at,
                });
                init.push(lastItem);
              } else {
                const color = selected[0].color;
                init.push({
                  senderId: d.employee.id,
                  color: color,
                  avatar: d.employee.photo_id,
                  senderName: d.employee.fullname,
                  messages: [
                    {
                      id: d.id,
                      text: d.note,
                      time: d.created_at,
                    },
                  ],
                });
              }
            }
          }
        }
        return init;
      },
      []
    );
    return notes;
  }

  listenSocket(deliveryId) {
    this.socket.on('delivery-' + deliveryId, (data) => {
      if (data) {
        if (data.employee.id !== this.user.id) {
          const length = this.chats.length;
          if (!length) {
            this.chats = [
              {
                senderId: data.employee.id,
                color: '#F97900',
                avatar: data.employee.photo_id,
                senderName: data.employee.fullname,
                messages: [
                  {
                    id: data.id,
                    text: data.note,
                    time: moment().tz('Asia/Jakarta').format(),
                  },
                ],
              },
            ];
          } else {
            const latest = this.chats.slice(-1)[0];
            if (latest.senderId == data.employee.id) {
              this.chats[length - 1].messages.push({
                id: data.id,
                text: data.note,
                time: moment().tz('Asia/Jakarta').format(),
              });
            } else {
              this.chats.push({
                senderId: data.employee.id,
                color: '#F97900',
                avatar: data.employee.photo_id,
                senderName: data.employee.fullname,
                messages: [
                  {
                    id: data.id,
                    text: data.note,
                    time: moment().tz('Asia/Jakarta').format(),
                  },
                ],
              });
            }
          }
        }
      }
    });
  }

  sendNotes() {
    if (!this.note) {
      this._toastrService.warning('Fill the note first!');
      return;
    }

    const payload = {
      delivery: { id: this.deliveryId },
      employee: { id: this.user.id },
      note: this.note,
    };

    this.noteLoading = true;

    lastValueFrom(this._apiService.post('delivery-notes', payload))
      .then((res) => {
        this._toastrService.success(res.message);
        const length = this.chats.length;
        if (!length) {
          this.chats = [
            {
              senderId: this.user.id,
              color: '#F97900',
              avatar: this.user.photo,
              senderName: this.user.fullname,
              messages: [
                {
                  id: 0,
                  text: payload.note,
                  time: moment().tz('Asia/Jakarta').format(),
                },
              ],
            },
          ];
        } else {
          const latest = this.chats.slice(-1)[0];
          if (latest.senderId == this.user.id) {
            this.chats[length - 1].messages.push({
              id: 0,
              text: payload.note,
              time: moment().tz('Asia/Jakarta').format(),
            });
          } else {
            this.chats.push({
              senderId: this.user.id,
              color: '#F97900',
              avatar: this.user.photo,
              senderName: this.user.fullname,
              messages: [
                {
                  id: 0,
                  text: payload.note,
                  time: moment().tz('Asia/Jakarta').format(),
                },
              ],
            });
          }
        }
        this.note = '';
        this._deliveryNoteService.setReadyScroll();
      })
      .catch((err) => {
        console.error(err);
        this.handleError(err);
      })
      .finally(() => {
        this.noteLoading = false;
      });
  }

  onInputChange($event) {
    const start = $event.target.selectionStart;
    const end = $event.target.selectionEnd;

    this.cursor = [start, end];
  }

  handleEmoji($event, textarea) {
    const emoji = $event.emoji.native;
    const currentValue = this.note;

    if (currentValue) {
      const newValue =
        currentValue.substr(0, this.cursor[0]) +
        emoji +
        currentValue.substr(this.cursor[1]);
      this.note = newValue;
    } else {
      this.note = emoji;
    }

    this.cursor = [this.cursor[0] + 2, this.cursor[1] + 2];
    textarea.focus();
    textarea.setSelectionRange(this.cursor[0], this.cursor[1]);
    this.toggled = !this.toggled;
  }

  close() {
    this.onClose.emit({
      loading: false,
      showDialog: false,
    });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ShareService {
  public onNotifChanged: BehaviorSubject<any>;

  constructor() {
    this.onNotifChanged = new BehaviorSubject({});
  }

  public setNotif(notif) {
    this.onNotifChanged.next(notif);
  }

  get notif(): any | Observable<any> {
    return this.onNotifChanged.asObservable();
  }
}

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from 'app/auth/service/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  /**
   *
   * @param {Router} _router
   * @param {AuthService} _authenticationService
   */
  constructor(private _router: Router, private _authService: AuthService) {}

  // canActivate
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const login = this._authService.currentUserValue;
    if (login) {
      return true;
    }
    console.log('auth guard');
    // not logged in so redirect to login page with the return url
    this._router.navigate(['/auth/login'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}

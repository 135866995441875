import { NgModule } from '@angular/core';

import { FilterArrayPipe } from '@core/pipes/filter-array.pipe';
import { FilterPipe } from '@core/pipes/filter.pipe';
import { InitialsPipe } from '@core/pipes/initials.pipe';
import { Nl2brPipe } from '@core/pipes/nl2br.pipe';
import { SafePipe } from '@core/pipes/safe.pipe';
import { StripHtmlPipe } from '@core/pipes/stripHtml.pipe';
import { ImgPipe } from './img.pipe';
import { ImgCldPipe } from './imgCld.pipe';
import { AgePipe } from './age.pipe';
import { CurrencyPipe } from './currency.pipe';
import { FindDataPipe } from './find.pipe';

@NgModule({
  declarations: [
    InitialsPipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    ImgCldPipe,
    AgePipe,
    Nl2brPipe,
    FilterArrayPipe,
    CurrencyPipe,
    ImgPipe,
    FindDataPipe,
  ],
  imports: [],
  exports: [
    InitialsPipe,
    FilterPipe,
    StripHtmlPipe,
    SafePipe,
    ImgCldPipe,
    AgePipe,
    Nl2brPipe,
    FilterArrayPipe,
    CurrencyPipe,
    ImgPipe,
    FindDataPipe,
  ],
})
export class CorePipesModule {}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SearchService } from '@core/services/search.service';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';

export interface SearchTerm {
  category: string;
  term?: {
    type: 'single' | 'advanced';
    criteria:
      | string
      | Array<{
          type?: 'date' | 'date-range' | 'text' | 'select';
          value?: string;
        }>;
  };
}

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
})
export class SearchResultsComponent implements OnInit, OnDestroy {
  public searchResult: SearchTerm;
  public path: string;

  private _unsubscribeAll: Subject<any>;
  constructor(private router: Router, private _searchService: SearchService) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit() {
    this.path = this.router.url.substring(1);
    console.log(this.path);
    this._searchService.search
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.searchResult = _.merge({}, this.searchResult, res);
        if (Object.keys(this.searchResult.term).length === 0) {
          this.searchResult.term = undefined;
        } else {
          if (this.searchResult.term.criteria.length === 0) {
            this.searchResult.term = undefined;
          }
        }

        if (['check-sheets', 'routes/templates'].includes(this.path)) {
          if (localStorage.getItem('search-' + this.path)) {
            this.searchResult = _.merge(
              {},
              this.searchResult,
              JSON.parse(localStorage.getItem('search-' + this.path))
            );
          }
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(void 0);
    this._unsubscribeAll.complete();
  }

  dismissSearch() {
    this.searchResult.term = undefined;
    localStorage.removeItem('search-' + this.path);
    this._searchService.setTerm({});
  }
}

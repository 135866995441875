<section
  class="chat-app-window"
  style="
    background-color: #f2f2f2 !important;
    overflow-x: hidden;
    overflow-y: hidden;
  "
  [style.height]="fromDialog ? '100%' : ''"
>
  <ng-template #content>
    <div class="user-chats" [style.height]="fromDialog ? 'auto' : ''">
      <div class="chats">
        <div *ngIf="!chats.length" class="p-3 mt-3 text-center mx-auto rounded">
          <i
            class="fa-regular fa-message-lines font-large-3 mb-1 text-secondary-darken"
          ></i>
          <h6 class="text-secondary-light font-small-5">Notes is empty</h6>
        </div>
        <ng-container *ngIf="chats.length">
          <div
            class="chat"
            *ngFor="let chat of chats"
            [ngClass]="{ 'chat-left': chat.senderId !== user.id }"
          >
            <div class="chat-avatar">
              <span class="avatar box-shadow-1 cursor-pointer">
                <img
                  *ngIf="chat.senderId !== user.id; else alternateAvatar"
                  [src]="chat.avatar | img : 50 : 50"
                  alt="avatar"
                  height="36"
                  width="36"
                />
                <ng-template #alternateAvatar>
                  <img
                    [src]="chat.avatar | img : 50 : 50"
                    alt="avatar"
                    height="36"
                    width="36"
                  />
                </ng-template>
              </span>
            </div>
            <div class="chat-body">
              <div
                class="chat-content"
                *ngFor="let message of chat.messages; let i = index"
              >
                <p
                  *ngIf="i === 0"
                  class="font-small-4 font-weight-bold"
                  [style.color]="
                    chat.senderId !== user.id ? chat.color : '#F97900'
                  "
                >
                  {{ chat.senderId !== user.id ? chat.senderName : 'You' }}
                </p>
                <p class="font-small-5 mb-1 text-secondary-darken">
                  {{ message.text }}
                </p>
                <small class="float-right text-secondary-light font-small-2"
                  >{{ message.time | date : 'dd/MM/Y | HH:mm' }}
                  <!-- <span
              *ngIf="loadingNote[chat.senderId + '-' + chats.length]"
              class="ml-50 inline-block"
            >
              <i
                class="fa-regular mb-2 text-secondary-darken fa-clock font-small-4"
              ></i>
            </span> -->
                  <!-- <span
              *ngIf="
                chat.senderId == user.id
              "
              class="ml-50 inline-block"
            >
              <i
                class="fa-solid mb-2 text-info-darken fa-circle-check font-small-4"
              ></i>
            </span> -->
                </small>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <ng-scrollbar
    #scrollbar
    *ngIf="fromDialog; else content"
    [ngStyle]="{ height: '30rem', width: '100%' }"
  >
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </ng-scrollbar>
</section>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../@core/services/api.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  //public
  public currentUser: Observable<any>;

  //private
  private currentUserSubject: BehaviorSubject<any>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private _apiService: ApiService,
    private ngxPermissionsService: NgxPermissionsService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('alsuser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  // get isAdmin() {
  //   return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  // }
  //
  // /**
  //  *  Confirms if user is client
  //  */
  // get isClient() {
  //   return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  // }

  /**
   * User login
   *
   * @param username
   * @param password
   * @returns user
   */
  async login(username: string, password: string) {
    const login = this._apiService.login('auth/login', {
      employee_id: username,
      password,
    });
    return await lastValueFrom(login)
      .then((data) => {
        if (data.statusCode == 200) {
          const alsUser = {
            employee: data.employee,
            access_token: data.access_token,
            refresh_token: data.refresh_token,
          };
          localStorage.setItem('alsuser', JSON.stringify(alsUser));
          data.employee.permissions = data.permissions;
          this.currentUserSubject.next(data.employee);
          this.ngxPermissionsService.loadPermissions(data.permissions);
          return true;
        } else {
          this._toastrService.error(data.message);
        }
      })
      .catch((err) => {
        this._toastrService.error('Employee ID or Password not correctly!');
        return false;
      });
  }

  async loadPermissions() {
    if (!this.currentUserValue) {
      return [];
    }
    const permissions = await lastValueFrom(
      this._apiService.get('auth/permissions', {})
    );
    const employee = this.currentUserValue;
    employee.permissions = permissions;
    this.currentUserSubject.next(employee);
    return permissions;
  }

  //not implemented
  refreshToken() {
    return this._apiService
      .post('auth/refresh-token', {
        refresh_token: JSON.parse(localStorage.getItem('alsuser'))
          .refresh_token,
      })
      .toPromise()
      .then((res) => {
        // localStorage.setItem('als-token', res.access_token);
        return res.access_token;
      });
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('alsuser');
    // notify
    this.currentUserSubject.next(null);
  }

  getToken() {
    return JSON.parse(localStorage.getItem('alsuser')).access_token;
  }
}

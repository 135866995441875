import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-checksheet-sidebar',
  templateUrl: './checksheet-sidebar.component.html',
  styleUrls: ['./checksheet-sidebar.component.scss'],
})
export class ChecksheetSidebarComponent implements OnInit {
  @Input() detail: any;
  public statusCollapsed = true;

  constructor() {}

  ngOnInit(): void {}
}

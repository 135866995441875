import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';

@Pipe({
  name: 'img',
})
export class ImgPipe implements PipeTransform {
  transform(imageKey: string, width: number, height: number): any {
    return `${environment.imgUrl}${imageKey}?tr=h-${height},w-${width}`;
  }
}

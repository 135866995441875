import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as _ from 'lodash';
import { SearchService } from '@core/services/search.service';
import { ShareService } from '../../../../../@core/services/share.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  public onDataChanged: BehaviorSubject<any>;
  public user = JSON.parse(localStorage.getItem('alsuser')).employee;
  public data: any = {
    unread: 0,
    notifications: [],
  };

  constructor(
    private _searchService: SearchService,
    private _shareService: ShareService
  ) {
    this.onDataChanged = new BehaviorSubject({});
    this._shareService.notif.subscribe((res) => {
      if (Object.keys(res).length > 0) {
        this.add(res);
      }
    });
  }

  add(data) {
    this.data = JSON.parse(localStorage.getItem('notifications'));
    if (this.user.id !== data.employee.id) {
      data['read'] = false;
      this.data.notifications.unshift(data);
      this.data.notifications = _.uniqBy(this.data.notifications, 'id');
      this.data.unread = _.countBy(
        this.data.notifications,
        (obj) => obj.read == false
      ).true;
      localStorage.setItem('notifications', JSON.stringify(this.data));
      this.onDataChanged.next(this.data);
    }
  }

  open(index) {
    this.data = JSON.parse(localStorage.getItem('notifications'));
    this.data.notifications[index]['read'] = true;
    this.data.notifications = _.uniqBy(this.data.notifications, 'id');
    this.data.unread = _.countBy(
      this.data.notifications,
      (obj) => obj.read == false
    ).true;
    localStorage.setItem('notifications', JSON.stringify(this.data));
    this.onDataChanged.next(this.data);
  }

  get notif(): any | Observable<any> {
    return this.onDataChanged.asObservable();
  }
}

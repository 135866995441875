<div class="d-flex align-items-center justify-content-between my-1">
  <h5 class="text-secondary-darken font-weight-bolder mb-0 font-medium-2">
    {{ detail.code }}
  </h5>
  <div class="d-flex align-items-center">
    <div *ngIf="detail.template.is_additional">
      <i class="fa-solid text-success-darken fa-circle-check font-small-5"></i>
      <span class="text-success-darken text-success-darken font-small-3">
        Additional
      </span>
    </div>
    <span
      *ngIf="detail.template.is_additional && detail.not_billable"
      class="d-block mx-1 text-secondary-light"
    >
      |
    </span>
    <div *ngIf="detail.not_billable">
      <i class="fa-solid fa-circle-check text-success-darken font-small-5"></i>
      <span class="text-success-darken font-small-3"> Not Billable </span>
    </div>
  </div>
</div>
<div class="card border shadow-none">
  <div class="media-list d-flex">
    <div class="media w-50 align-items-center">
      <div class="avatar bg-light-primary rounded mr-1">
        <div class="avatar-content">
          <i data-feather="calendar" class="avatar-icon font-medium-3"></i>
        </div>
      </div>
      <div class="media-body">
        <small class="text-muted">Date</small>
        <h6 class="mb-0">
          {{ detail.date | date: 'dd/MM/Y' }}
        </h6>
      </div>
    </div>

    <div class="media w-50 align-items-center">
      <div class="avatar bg-light-primary rounded mr-1">
        <div class="avatar-content">
          <i data-feather="user-check" class="avatar-icon font-medium-3"></i>
        </div>
      </div>
      <div class="media-body">
        <div class="d-flex justify-content-between">
          <div>
            <small class="text-muted">Driver</small>
            <h6 class="mb-0" *ngIf="!detail.driver">-</h6>
            <div
              class="d-flex align-items-center justify-content-between"
              *ngIf="detail.driver"
            >
              <h6 class="mb-0">
                {{ detail.driver?.fullname }}
              </h6>
            </div>
          </div>
          <span
            *ngIf="
              detail.driver &&
              detail.driver?.id !== 800 &&
              detail.driver?.job?.employment_status?.alias === 'Mitra'
            "
            style="width: 15px; height: 15px"
            class="rounded text-info-darken bg-info-transparent font-small-2 font-weight-bolder text-center"
            >M</span
          >
        </div>
      </div>
    </div>
  </div>
  <div class="media-list d-flex">
    <div class="media w-50 align-items-center">
      <div class="avatar bg-light-primary rounded mr-1">
        <div class="avatar-content">
          <i data-feather="layout" class="avatar-icon font-medium-3"></i>
        </div>
      </div>
      <div class="media-body">
        <small class="text-muted">Template</small>
        <h6 class="mb-0">{{ detail.template.name }}</h6>
      </div>
    </div>
    <div class="media w-50 align-items-center">
      <div class="avatar bg-light-primary rounded mr-1">
        <div class="avatar-content">
          <i data-feather="truck" class="avatar-icon font-medium-3"></i>
        </div>
      </div>
      <div class="media-body">
        <div class="d-flex justify-content-between">
          <div>
            <small class="text-muted">Truck</small>
            <h6 class="mb-0" *ngIf="!detail.vehicle">-</h6>
            <div class="d-flex font-weight-bolder" *ngIf="detail.vehicle">
              <h6 class="mb-0 text-info-darken">
                {{ detail.vehicle.number }}
              </h6>
              <h6 class="mb-0 text-secondary font-small-3">&nbsp; | &nbsp;</h6>
              <h6 class="mb-0 text-secondary">
                {{ detail.vehicle.license_plate_number }}
              </h6>
            </div>
          </div>
          <div *ngIf="detail.vehicle && detail.gps_connected">
            <div class="gps-icon rounded-circle text-center">
              <img
                src="../../../../assets/images/illustration/icon-jitra.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card border shadow-none mt-1">
  <div class="media-list">
    <div class="media align-items-center">
      <div class="avatar bg-light-primary rounded mr-1">
        <div class="avatar-content">
          <i data-feather="pocket" class="avatar-icon font-medium-3"></i>
        </div>
      </div>
      <div class="media-body">
        <div class="d-flex justify-content-between">
          <div>
            <small class="text-muted">Status</small>
            <h6
              class="mb-0 text-uppercase text-status {{
                detail.status.replace(' ', '-')
              }}"
            >
              {{ detail.status }}
            </h6>
          </div>
          <div class="collapse-icon">
            <a
              href="javascript:void(0);"
              (click)="statusCollapsed = !statusCollapsed"
              [attr.aria-expanded]="!statusCollapsed"
            ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body pt-0" [ngbCollapse]="statusCollapsed">
    <div class="card border mb-0">
      <div class="card-body">
        <ul class="timeline ml-50 mb-0">
          <li
            *ngFor="let history of detail.histories; let i = index"
            class="timeline-item"
          >
            <span
              class="timeline-point timeline-point-indicator {{
                detail.status === history.status && i == 0
                  ? 'timeline-point-' + history.status.replace(' ', '-')
                  : 'timeline-point-secondary'
              }}"
            ></span>
            <div class="timeline-event">
              <div
                class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1"
              >
                <h6
                  class="text-status {{
                    history.status === detail.status && i == 0
                      ? history.status.replace(' ', '-')
                      : ''
                  }}"
                >
                  {{ history.status }}
                </h6>
                <span class="timeline-event-time mr-1">
                  {{ history.created_at | date: 'dd/MM HH:mm' }}
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div>
  <h5 class="font-weight-bold">Departure & Arrival</h5>
  <div class="card border shadow-none mt-2">
    <span class="card-legend">
      <i
        data-feather="arrow-up-circle"
        class="font-medium-4 text-success-darken"
      ></i
      >&nbsp;
      <h5 class="ellipsis text-secondary-light">
        {{ detail.origin.name }}
      </h5>
    </span>
    <div class="card-body">
      <div class="media">
        <div class="media-body row justify-content-between">
          <div class="col-auto">
            <small class="text-muted">Plan</small>
            <h6 class="mb-0">
              {{ detail.departure_plan | date: 'HH:mm' }}
            </h6>
          </div>
          <div class="col-auto">
            <small class="text-muted">Actual</small>
            <h6 class="mb-0">
              {{ detail.departure_actual | date: 'HH:mm' }}
            </h6>
          </div>
          <div class="col-auto">
            <small class="text-muted">Odometer</small>
            <h6 class="mb-0">{{ detail.departure_odometer }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card border mt-1 shadow-none">
    <span class="card-legend">
      <i
        data-feather="arrow-down-circle"
        class="font-medium-4 text-warning-darken"
      ></i
      >&nbsp;
      <h5 class="ellipsis text-secondary-light">
        {{ detail.destination.name }}
      </h5>
    </span>
    <div class="card-body">
      <div class="media">
        <div class="media-body row justify-content-between">
          <div class="col-auto">
            <small class="text-muted">Plan</small>
            <h6 class="mb-0">
              {{ detail.arrival_plan | date: 'HH:mm' }}
            </h6>
          </div>
          <div class="col-auto">
            <small class="text-muted">Actual</small>
            <h6 class="mb-0">
              {{ detail.arrival_actual | date: 'HH:mm' }}
            </h6>
          </div>
          <div class="col-auto">
            <small class="text-muted">Odometer</small>
            <h6 class="mb-0">{{ detail.arrival_odometer }}</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div>
  <h5 class="font-weight-bold">Fuel & Cost</h5>

  <div class="list-group mt-1">
    <div class="list-group-item p-2">
      <div class="media">
        <div class="media-body row justify-content-between">
          <div class="col-6 mb-1">
            <small class="text-muted">Fuel Station</small>
            <h6 class="mb-0">
              {{ detail.fuel_station?.name ?? '-' }}
            </h6>
          </div>
          <div class="col-6 mb-1 text-right">
            <small class="text-muted">Fuel</small>
            <h6 class="mb-0">
              {{ detail.fuel_price * detail.fuel_consumption | localCurrency }}
              <span class="d-inline" *ngIf="detail.fuel_consumption">{{
                ' (' + detail.fuel_consumption + 'Ltr)'
              }}</span>
            </h6>
          </div>
          <div class="col-6">
            <small class="text-muted">Mel</small>
            <h6 class="mb-0">
              {{ detail.mel | localCurrency }}
            </h6>
          </div>
          <div class="col-6 text-right">
            <small class="text-muted">Incentive</small>
            <h6 class="mb-0">
              {{
                detail.incentive.incentive +
                  detail.incentive.incentive_overtime_delivery | localCurrency
              }}
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div class="list-group-item p-2">
      <div class="media">
        <div class="media-body row justify-content-between">
          <div class="col-auto">
            <small class="text-muted">Remark</small>
            <h6 class="mb-0">
              {{ detail.remark ?? '-' }}
            </h6>
          </div>
          <div class="col-auto">
            <small class="text-muted">Note</small>
            <h6
              class="mb-0"
              [innerHTML]="!detail.note ? '-' : (detail.note | nl2br)"
            ></h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

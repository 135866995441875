import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { IEmitPickList } from '../interfaces';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputComponent implements OnInit {
  @Input() form: any;
  @Input() type: any;
  @Input() submitted: any;
  @Input() group: any;
  @Input() groupName: string;
  @Input() controls: any;
  @Input() disabled: boolean;
  @Input() reset: boolean;

  @Output() returnData = new EventEmitter<any>();
  @Output() eventData = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<any>();
  @Output() onChangeValue = new EventEmitter<any>();
  @Output() onPickListChange = new EventEmitter<IEmitPickList>();

  public showPassword = false;

  @ViewChild('inputText') inputText: TemplateRef<any>;
  @ViewChild('inputNumber') inputNumber: TemplateRef<any>;
  @ViewChild('inputPassword') inputPassword: TemplateRef<any>;
  @ViewChild('textarea') textarea: TemplateRef<any>;
  @ViewChild('radio') radio: TemplateRef<any>;
  @ViewChild('checkbox') checkBox: TemplateRef<any>;
  @ViewChild('select') select: TemplateRef<any>;
  @ViewChild('multiselect') multiselect: TemplateRef<any>;
  @ViewChild('selectTime') selectTime: TemplateRef<any>;
  @ViewChild('bsDatepicker') bsDatepicker: TemplateRef<any>;
  @ViewChild('rating') rating: TemplateRef<any>;
  @ViewChild('pCalendar') pCalendar: TemplateRef<any>;
  @ViewChild('pRangeDatepicker') pRangeDatepicker: TemplateRef<any>;
  @ViewChild('horizontalSwitch') horizontalSwitch: TemplateRef<any>;
  @ViewChild('currency') currency: TemplateRef<any>;
  @ViewChild('listBox') listBox: TemplateRef<any>;
  @ViewChild('multiCheckAll') multiCheckAll: TemplateRef<any>;
  @ViewChild('pickList') pickList: TemplateRef<any>;
  @ViewChild('colorPallete') colorPallete: TemplateRef<any>;

  public timeValue: Array<any> = [];
  public timeTypes = [
    { id: 'p', name: 'P' },
    { id: 'm', name: 'M' },
    { id: 'n', name: 'N' },
  ];

  searchResult: any;
  checkedAll: boolean = false;
  disableCheck: boolean = false;

  @Input() customTemplate: TemplateRef<any>;

  constructor(
    private cdref: ChangeDetectorRef,
    private _apiService: ApiService
  ) {}

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    if (this.type === 'selectTime') {
      this._generateTimeValue();
    }
  }

  setReturn() {
    this.returnData.emit(this.form.rate);
  }

  async setChange($event, input) {
    this.onChange.emit($event);
    this.onChangeValue.emit({ event: $event, input });
    if (input?.with_param) {
      const filter = {};
      filter[input?.params_component?.prop] = input?.params_component?.value;

      input.items = await this._apiService.getComponentItems(
        input?.component_param ?? input.name,
        filter
      );
    } else {
      if (input.items.length < 1) {
        input.items = await this._apiService.getComponentItems(
          input?.component_param ?? input.name
        );
        input.loading = false;
        input['default'] = false;
      }
    }
  }

  get selectedInput() {
    const input = {
      text: this.inputText,
      number: this.inputNumber,
      password: this.inputPassword,
      textarea: this.textarea,
      radio: this.radio,
      checkbox: this.checkBox,
      select: this.select,
      multiselect: this.multiselect,
      selectTime: this.selectTime,
      bsdatepicker: this.bsDatepicker,
      rating: this.rating,
      pcalendar: this.pCalendar,
      horizontalSwitch: this.horizontalSwitch,
      currency: this.currency,
      daterange: this.pRangeDatepicker,
      listbox: this.listBox,
      multiselectall: this.multiCheckAll,
      pickList: this.pickList,
      colorPallete: this.colorPallete,
    };

    return input[this.type];
  }

  onOpenCalendar(container) {
    if (this.form.isCustom && this.form.customEvent == 'year_only') {
      container.yearSelectHandler = (event: any): void => {
        container._store.dispatch(container._actions.select(event.date));
      };
      container.setViewMode(this.form.config.startView);
    }

    if (this.form.isCustom && this.form.customEvent == 'month_and_year') {
      container.monthSelectHandler = (event: any): void => {
        container._store.dispatch(container._actions.select(event.date));
      };
      container.setViewMode(this.form.config.startView);
    }
  }

  async getItems(input) {
    if (input.items.length < 1 || input['default']) {
      input.loading = true;
      if (input?.filter) {
        this.form.items = await this._apiService.getComponentItems(
          input?.component_param ?? input.name,
          input.filter
        );
      } else {
        this.form.items = await this._apiService.getComponentItems(
          input?.component_param ?? input.name
        );
      }
      if (input.withReturn) {
        this.returnData.emit({ type: this.form.name, value: input.items });
      }
      input.loading = false;
      input['default'] = false;
    }
  }

  private _generateTimeValue() {
    let hour;
    let minute;
    for (let i = 0; i <= 23; i++) {
      hour = i.toString();
      hour = hour.length == 1 ? '0' + hour : hour;

      for (let i = 0; i < 60; i++) {
        minute = i.toString();
        if (minute.length == 1) {
          minute = '0' + minute;
        }

        const value = hour + minute;
        this.timeValue.push({ id: this.formatTime(value), name: value });
      }
    }
  }

  selectAll(event?) {
    if (event?.target?.checked) {
      let selected: any;
      if (this.searchResult) {
        selected = this.searchResult.map((item) => item.id);
      } else {
        selected = this.form.items.map((item) => item.id);
      }
      this.form.model = selected;
      this.checkedAll = true;
      this.disableCheck = false;
    } else {
      this.form.model = [];
      this.checkedAll = false;
    }
  }

  onSelect(type) {
    this.eventData.emit({ key: type, value: this.form.model });
  }

  onSearch(result) {
    this.searchResult = result.items;

    if (this.form.model.length !== this.searchResult.length) {
      this.checkedAll = false;
    } else {
      if (this.form.model.length) {
        this.checkedAll = true;
      }
    }

    if (!this.searchResult.length) {
      this.disableCheck = true;
    } else {
      this.disableCheck = false;
    }
  }

  formatTime(value) {
    return [value.slice(0, 2), ':', value.slice(2)].join('');
  }

  onPickListMove() {
    this.onPickListChange.emit({
      id: this.form.id,
      sources: this.form.sources,
      target: this.form.selected,
    });
  }
}

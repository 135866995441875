import { Injectable } from '@angular/core';
import { Workbook, BorderStyle, Worksheet } from 'exceljs';

import * as _ from 'lodash';
import * as fs from 'file-saver';
import * as moment from 'moment-timezone';
import { split } from 'lodash';

// import { Cloudinary } from '@cloudinary/url-gen';
// import { cldConfig } from 'app/config/cloudinary.config';
import { environment } from 'environments/environment';
import {
  EXCEL_TYPE,
  EXCEL_ACCOUNTING_FORMAT,
  EXCEL_EXTENSION,
} from '@core/const';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor() {}

  public exportAllocationTruck(
    reportHeading: string,
    headersArray: any,
    json: any,
    excelFileName: string,
    sheetName: string,
    date: any
  ) {
    const header = headersArray;
    const data = json;

    const user = JSON.parse(localStorage.getItem('alsuser'));

    //init workbook & worksheet
    const workbook = new Workbook();
    (workbook.creator = user.employee.fullname),
      (workbook.lastModifiedBy = user.employee.fullname),
      (workbook.created = new Date());
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(sheetName);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' + moment(date).format('DD/MM/YYYY');

    worksheet.addRow([]);

    //header & style
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        header[index - 1].length < 20 ? 20 : header[index - 1].length;
    });

    worksheet.getRow(1).height = 30;

    for (let i = 1; i <= 13; i++) {
      const cell = this.numToAlpha(i + 1) + '4';
      worksheet.getCell(cell).alignment = {
        horizontal: 'center',
        vertical: 'middle',
      };
    }

    _.forEach(data, (obj) => {
      const eachRow = [];
      eachRow.push(obj.customer.name);
      eachRow.push(obj.customer.route.name);
      _.forEach(obj.customer.route.vehicles, (vehicle) => {
        eachRow.push(vehicle?.number ? vehicle.number.toString() : '');
      });

      for (let i = 1; i <= 13 - obj.customer.route.vehicles.length; i++) {
        eachRow.push('');
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index !== 1 && index !== 2) {
          cell.alignment = { horizontal: 'center' };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    worksheet.getColumn(2).width = 40;
    worksheet.getRow(4).height = 25;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportDetailOperation(
    reportHeading: string,
    json: any,
    excelFileName: string,
    sheetName: string,
    date: any
  ) {
    const header1 = [
      'Date',
      'Customer',
      'Route',
      'Cycle',
      'Status',
      'Truck No',
      'No Polisi',
      'Driver',
      'Departure',
      '',
      '',
      'Arrival',
      '',
      '',
      'Lead Time',
      '',
      'Distance',
      'Cost',
      '',
      '',
      '',
      '',
      'SPBU',
      'Delivery Type',
      'Note',
    ];
    const header2 = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Plan',
      'Actual',
      'Odometer',
      'Plan',
      'Actual',
      'Odometer',
      'Plan',
      'Actual',
      '',
      'Incentive',
      'Toll',
      'Mell',
      'Fuel (Liter)',
      'Fuel (Rp)',
      '',
      '',
      '',
    ];
    const data = json;

    const user = JSON.parse(localStorage.getItem('alsuser'));

    //init workbook & worksheet
    const workbook = new Workbook();
    (workbook.creator = user.employee.fullname),
      (workbook.lastModifiedBy = user.employee.fullname),
      (workbook.created = new Date());
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(sheetName);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    //header & style
    const headerRow1 = worksheet.addRow(header1);
    const headerRow2 = worksheet.addRow(header2);

    const getLeadTime = (arrival, departure) => {
      let result = '-';
      if (arrival && departure) {
        const arrivalTime = moment(arrival).tz('Asia/Jakarta');
        const departureTime = moment(departure).tz('Asia/Jakarta');

        const duration = moment.duration(arrivalTime.diff(departureTime));
        const hour = Math.round(duration.asHours());
        const minute = Math.round(duration.asMinutes() % 60);

        result =
          // (hour < 10 ? '0' + hour.toString() : hour.toString()) +
          hour +
          ':' +
          (minute < 10 ? '0' + minute.toString() : minute.toString());
      }

      return result;
    };

    worksheet.mergeCells('A4:A5');
    worksheet.mergeCells('B4:B5');
    worksheet.mergeCells('C4:C5');
    worksheet.mergeCells('D4:D5');
    worksheet.mergeCells('E4:E5');
    worksheet.mergeCells('F4:F5');
    worksheet.mergeCells('G4:G5');
    worksheet.mergeCells('H4:H5');
    worksheet.mergeCells('I4:K4');
    worksheet.mergeCells('L4:N4');
    worksheet.mergeCells('O4:P4');
    worksheet.mergeCells('Q4:Q5');
    worksheet.mergeCells('R4:V4');
    worksheet.mergeCells('W4:W5');
    worksheet.mergeCells('X4:X5');
    worksheet.mergeCells('Y4:Y5');

    headerRow1.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        header1[index - 1].length < 20 ? 20 : header1[index - 1].length;
    });

    headerRow2.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };

      worksheet.getColumn(index).width =
        header2[index - 1].length < 20 ? 20 : header2[index - 1].length;
    });

    _.forEach(data, (obj) => {
      const eachRow = [];
      eachRow.push(moment(obj.date).tz('Asia/Jakarta').format('DD/MM/YYYY'));
      eachRow.push(obj.routes.length ? obj.routes[0].route.customer.name : '');
      eachRow.push(obj.routes.length ? obj.routes[0].route.code : '');
      eachRow.push(obj.routes.length ? obj.routes[0].cycle : '');
      eachRow.push(obj.status.toUpperCase());
      eachRow.push(obj.vehicle?.number ?? '');
      eachRow.push(obj.vehicle?.license_plate_number ?? '');
      eachRow.push(obj.driver?.fullname ?? '');
      eachRow.push(
        moment(obj.departure_plan).tz('Asia/Jakarta').format('HH:mm')
      );
      eachRow.push(
        obj.departure_actual
          ? moment(obj.departure_actual).tz('Asia/Jakarta').format('HH:mm')
          : ''
      );
      eachRow.push(obj.departure_odometer ?? '');
      eachRow.push(moment(obj.arrival_plan).tz('Asia/Jakarta').format('HH:mm'));
      eachRow.push(
        obj.arrival_actual
          ? moment(obj.arrival_actual).tz('Asia/Jakarta').format('HH:mm')
          : ''
      );
      eachRow.push(obj.arrival_odometer ?? '');
      eachRow.push(getLeadTime(obj.arrival_plan, obj.departure_plan));
      eachRow.push(getLeadTime(obj.arrival_actual, obj.departure_actual));
      eachRow.push(
        obj.arrival_odometer
          ? obj.arrival_odometer - obj.departure_odometer
          : ''
      );
      eachRow.push(
        (obj.incentive_overtime_delivery ?? 0) + (obj.incentive?.incentive ?? 0)
      );
      eachRow.push(obj.toll ?? 0);
      eachRow.push(obj.mel ?? 0);
      eachRow.push(obj.fuel_consumption ?? '');
      eachRow.push(obj.fuel_consumption * obj.fuel_price);
      eachRow.push(obj.fuel_station?.name ?? '');
      eachRow.push(obj.attendance ? 'Additional' : 'Reguler');
      eachRow.push(obj.note ?? '');

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (
          index !== 2 &&
          index !== 3 &&
          index !== 7 &&
          index !== 17 &&
          index !== 18 &&
          index !== 19 &&
          index !== 21 &&
          index !== 22 &&
          index !== 23 &&
          index !== 24
        ) {
          cell.alignment = { horizontal: 'center' };
        }
        if (index == 18 || index == 19 || index == 20 || index == 22) {
          cell.alignment = { horizontal: 'right' };
          // cell.numFmt = 'Rp #,##0'
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getColumn(index).width =
          eachRow[index - 1].length < 20 ? 20 : eachRow[index - 1].length + 10;
      });
    });
    worksheet.getColumn('D').width = 8;
    worksheet.getColumn('Q').width = 15;
    worksheet.getColumn('R').width = 15;
    worksheet.getColumn('S').width = 15;
    worksheet.getColumn('T').width = 15;
    worksheet.getColumn('U').width = 15;
    worksheet.getColumn('V').width = 15;
    worksheet.getColumn('W').width = 20;
    worksheet.getColumn('X').width = 15;
    worksheet.getColumn('Y').width = 50;
    worksheet.getRow(1).height = 25;
    worksheet.getRow(4).height = 25;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportFuelConsumption(
    reportHeading: string,
    headers: any,
    data: any,
    excelFileName: string,
    sheetName: string,
    date: any
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(sheetName);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(headers.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    //header & style
    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        headers[index - 1].length < 20 ? 20 : headers[index - 1].length;
    });

    _.forEach(data, (obj) => {
      const eachRow = [];
      eachRow.push(moment(obj.date).tz('Asia/Jakarta').format('DD/MM/YYYY'));
      eachRow.push(obj.template?.name);
      eachRow.push(obj.vehicle?.number ?? '');
      eachRow.push(obj.vehicle?.license_plate_number ?? '');
      eachRow.push(obj.driver?.fullname ?? '');
      eachRow.push(obj.code);
      eachRow.push(obj.fuel_station?.name ?? '');
      eachRow.push(obj.fuel_consumption ?? 0);
      eachRow.push(
        obj.fuel_price && obj.fuel_consumption
          ? obj.fuel_price * obj.fuel_consumption
          : 0
      );

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1 || index == 3 || index == 4 || index == 8) {
          cell.alignment = { horizontal: 'center' };
        }
        if (index == 9) {
          cell.alignment = { horizontal: 'right' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        if (index !== 8) {
          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        }
      });
    });
    worksheet.getColumn('C').width = 15;
    worksheet.getColumn('I').width = 15;
    worksheet.getColumn('J').width = 30;
    worksheet.getRow(1).height = 25;
    worksheet.getRow(4).height = 25;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportGPSNotification(
    reportHeading: string,
    headers: any,
    data: any,
    excelFileName: string,
    date: any
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    //init workbook & worksheet
    const workbook = new Workbook();
    (workbook.creator = user.employee.fullname),
      (workbook.lastModifiedBy = user.employee.fullname),
      (workbook.created = new Date());
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(headers.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    //header & style
    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        headers[index - 1].length < 20 ? 20 : headers[index - 1].length;
    });

    _.forEach(data, (obj) => {
      const eachRow = [];
      eachRow.push(moment(obj.time).tz('Asia/Jakarta').format('DD/MM/YYYY'));
      eachRow.push(obj.truck_number);
      eachRow.push(obj?.code ?? '');
      eachRow.push(obj.type);
      eachRow.push(moment(obj.time).tz('Asia/Jakarta').format('HH:mm'));
      eachRow.push(obj.odometer);
      eachRow.push(obj.delivery?.code ?? '');
      let routes = '';

      if (obj.delivery?.routes) {
        _.forEach(obj.delivery.routes, (data, index) => {
          routes += data.route.code;
          if (
            obj.delivery.routes.length > 1 &&
            parseInt(index) !== obj.delivery.routes.length - 1
          ) {
            routes += ', ';
          }
        });
        eachRow.push(routes);

        let customers = '';
        _.forEach(obj.delivery.routes, (data, index) => {
          customers += data.route.customer?.name;
          if (
            obj.delivery.routes.length > 1 &&
            parseInt(index) !== obj.delivery.routes.length - 1
          ) {
            customers += ', ';
          }
        });

        eachRow.push(customers);
      } else {
        eachRow.push('');
        eachRow.push('');
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (
          index == 1 ||
          index == 2 ||
          index == 4 ||
          index == 5 ||
          index == 6
        ) {
          cell.alignment = { horizontal: 'center' };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getColumn(index).width =
          eachRow[index - 1].length < 20 ? 20 : eachRow[index - 1].length + 10;
      });
    });
    worksheet.getColumn('C').width = 25;
    worksheet.getColumn('F').width = 25;
    worksheet.getRow(1).height = 25;
    worksheet.getRow(4).height = 25;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportEtollTransaction(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const headers = ['No', 'Date', ''];

    //init workbook & worksheet
    const workbook = new Workbook();
    (workbook.creator = user.employee.fullname),
      (workbook.lastModifiedBy = user.employee.fullname),
      (workbook.created = new Date());
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(headers.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    //header & style
    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '8EA9DB' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: 'ffffff' } };
      worksheet.getColumn(index).width =
        headers[index - 1].length < 20 ? 20 : headers[index - 1].length;
    });
    worksheet.mergeCells('C4:I4');
    worksheet.getRow(4).height = 20;

    let no = 1;
    let start = 5;
    let end = start;
    for (const key in data) {
      const firstRow = [];
      firstRow.push(no++);
      firstRow.push(moment(key).tz('Asia/Jakarta').format('DD/MM/YYYY'));
      firstRow.push('E-Toll Number');
      firstRow.push('Delivery');
      firstRow.push('Route');
      firstRow.push('Truck Number');
      firstRow.push('Driver');
      firstRow.push('Usage');
      firstRow.push("Other's Transaction");
      firstRow.push('Note');

      const subheader = worksheet.addRow(firstRow);
      subheader.eachCell((cell, index) => {
        if (index !== 1 && index !== 2) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DDEBF7' },
          };
          cell.alignment = { horizontal: 'center', vertical: 'middle' };
          cell.font = { bold: true };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });

      worksheet.getRow(start).height = 15;

      _.forEach(data[key], (obj) => {
        end++;
        const eachRow = [];
        eachRow.push('');
        eachRow.push('');
        eachRow.push(obj.etoll?.number ?? '');

        let routes = [];
        if (obj.delivery?.routes) {
          if (obj.delivery.routes.length) {
            routes = _.map(obj.delivery.routes, (obj) => {
              return obj.route.name;
            });
          }
        }
        eachRow.push(routes.length ? routes.join(', ') : '');
        eachRow.push(obj.delivery?.code ?? '');
        eachRow.push(obj.delivery?.vehicle?.number ?? '');
        eachRow.push(obj.delivery?.driver?.fullname ?? '');
        eachRow.push(obj.credit ?? 0);
        eachRow.push(obj.credit_other ?? 0);
        eachRow.push(obj.note ?? '');

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          if (index == 1 || index == 2 || index == 4 || index == 5) {
            cell.alignment = { horizontal: 'center' };
          }

          if (index == 7 || index == 8) {
            cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
          }

          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        });
      });

      worksheet.mergeCells('A' + start.toString() + ':A' + end.toString());
      worksheet.mergeCells('B' + start.toString() + ':B' + end.toString());
      worksheet.getCell('A' + start.toString()).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
      worksheet.getCell('B' + start.toString()).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
      end++;
      start = end;
    }

    worksheet.getColumn('A').width = 5;
    worksheet.getColumn('G').width = 20;
    worksheet.getColumn('H').width = 25;
    worksheet.getColumn('I').width = 20;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportReportAttendances(
    reportHeading: string,
    json: any,
    excelFileName: string,
    sheetName: string,
    date: any,
    type: string
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(sheetName);
    const data = json;

    if (type == 'daily') {
      const header1 = [
        'Date',
        'Employee ID',
        'Fullname',
        'Job Title',
        'Shift',
        'Plan',
        '',
        'Actual',
        '',
        '',
        '',
        'Status',
      ];
      const header2 = ['', '', '', '', '', '', '', 'In', '', 'Out', '', ''];
      const header3 = [
        '',
        '',
        '',
        '',
        '',
        'In',
        'Out',
        'Time',
        'Diff',
        'Time',
        'Diff',
        '',
      ];

      //heading title
      worksheet.addRow([]);
      worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
      worksheet.getCell('A1').value = reportHeading;
      worksheet.getCell('A1').alignment = { vertical: 'middle' };
      worksheet.getCell('A1').font = { size: 15, bold: true };
      worksheet.getCell('A2').value =
        'Tanggal : ' +
        (date[0] == date[1]
          ? moment(date[0]).format('DD/MM/YYYY')
          : moment(date[0]).format('DD/MM/YYYY') +
            ' - ' +
            moment(date[1]).format('DD/MM/YYYY'));

      worksheet.addRow([]);

      //header & style
      worksheet.addRow(header1);
      worksheet.addRow(header2);
      worksheet.addRow(header3);

      worksheet.mergeCells('A4:A6');
      worksheet.mergeCells('B4:B6');
      worksheet.mergeCells('C4:C6');
      worksheet.mergeCells('D4:D6');
      worksheet.mergeCells('E4:E6');
      worksheet.mergeCells('F4:G5');
      worksheet.mergeCells('H4:K4');
      worksheet.mergeCells('H5:I5');
      worksheet.mergeCells('J5:K5');
      worksheet.mergeCells('L4:L6');

      for (let i = 4; i <= 6; i++) {
        for (const [index] of header1.entries()) {
          worksheet.getRow(i).getCell(index + 1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DDEBF7' },
          };
          worksheet.getRow(i).getCell(index + 1).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getRow(i).getCell(index + 1).alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
          worksheet.getRow(i).getCell(index + 1).font = {
            size: 12,
            bold: true,
          };
          worksheet.getColumn(index + 1).width =
            header1[index].length < 20 ? 20 : header1[index].length;
        }
      }

      _.forEach(data, (obj) => {
        const eachRow = [];

        eachRow.push(moment(obj.date).tz('Asia/Jakarta').format('DD/MM/YYYY'));
        eachRow.push(obj.employee.employee_id);
        eachRow.push(obj.employee.fullname);
        eachRow.push(obj.employee.job.job_title.name);
        eachRow.push(obj.work_schedule?.name ?? obj.work_schedule_name);
        eachRow.push(
          obj.in_plan
            ? moment.tz(obj.in_plan, 'Asia/Jakarta').format('HH:mm')
            : '-'
        );
        eachRow.push(
          obj.out_plan
            ? moment.tz(obj.out_plan, 'Asia/Jakarta').format('HH:mm')
            : '-'
        );
        eachRow.push(
          obj.in_actual
            ? moment.tz(obj.in_actual, 'Asia/Jakarta').format('HH:mm')
            : '-'
        );
        eachRow.push(this.diffTime(obj.in_actual, obj.in_plan));
        eachRow.push(
          obj.out_actual
            ? moment.tz(obj.out_actual, 'Asia/Jakarta').format('HH:mm')
            : '-'
        );
        eachRow.push(this.diffTime(obj.out_actual, obj.out_plan));
        eachRow.push(
          !obj.in_actual
            ? obj.leave?.request_type?.name
              ? ['NOT PRESENT', obj.leave.request_type.name.toUpperCase()].join(
                  ', '
                )
              : 'NOT PRESENT'
            : !obj.status
            ? 'PRESENT'
            : ['PRESENT', obj.status?.toUpperCase()].join(', ')
        );

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          if (
            index !== 2 &&
            index !== 3 &&
            index !== 4 &&
            index !== 5 &&
            index !== 12
          ) {
            cell.alignment = { horizontal: 'center' };
          }

          const split = eachRow[0].split('/');
          const day = moment([split[2], split[1], split[0]].join('-')).format(
            'dddd'
          );
          if (['Saturday', 'Sunday'].includes(day)) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FFD12F' },
            };
          }

          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        });
      });
    } else if (type == 'monthly') {
      const header1 = ['Employee ID', 'Fullname'];
      const header2 = ['', ''];

      for (const month of this.getMonths(date)) {
        header1.push(month.name);
        header1.push('');
        header1.push('');
        header2.push('Present');
        header2.push('Late');
        header2.push('Not Present');
      }

      //heading title
      worksheet.addRow([]);
      worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
      worksheet.getCell('A1').value = reportHeading;
      worksheet.getCell('A1').alignment = { vertical: 'middle' };
      worksheet.getCell('A1').font = { size: 15, bold: true };
      worksheet.getCell('A2').value =
        'Tanggal : ' +
        (date[0] == date[1]
          ? moment(date[0]).format('DD/MM/YYYY')
          : moment(date[0]).format('DD/MM/YYYY') +
            ' - ' +
            moment(date[1]).format('DD/MM/YYYY'));

      worksheet.addRow([]);

      //header & style
      worksheet.addRow(header1);
      worksheet.addRow(header2);

      worksheet.mergeCells('A4:A5');
      worksheet.mergeCells('B4:B5');

      let start = 2;
      this.getMonths(date).forEach(() => {
        worksheet.mergeCells(
          this.numToAlpha(start) + '4:' + this.numToAlpha(start + 2) + '4'
        );
        start += 3;
      });

      for (let i = 4; i <= 5; i++) {
        for (const [index] of header1.entries()) {
          worksheet.getRow(i).getCell(index + 1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DDEBF7' },
          };
          worksheet.getRow(i).getCell(index + 1).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getRow(i).getCell(index + 1).alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
          worksheet.getRow(i).getCell(index + 1).font = {
            size: 12,
            bold: true,
          };
          worksheet.getColumn(index + 1).width =
            header1[index].length < 20 ? 20 : header1[index].length;
        }
      }

      _.forEach(data, (obj) => {
        const eachRow = [];

        eachRow.push(obj.employee_id);
        eachRow.push(obj.fullname);

        for (const attendance of obj.attendances) {
          eachRow.push(attendance.present ?? '');
          eachRow.push(attendance.late ?? '');
          eachRow.push(attendance.absent ?? '');
        }

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          if (index !== 1 && index !== 2) {
            cell.alignment = { horizontal: 'center' };
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        });
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportFingerprintActivity(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const headers = [
      'Date',
      'Employee ID',
      'Fullname',
      'Job Title',
      'Time',
      'Type',
      'Machine Name',
      'Location',
      'Photo',
    ];

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(headers.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    //header & style
    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        headers[index - 1].length < 20 ? 20 : headers[index - 1].length;
    });

    worksheet.getRow(4).height = 20;

    _.forEach(data, (obj) => {
      const eachRow = [];
      let linkImage: any;
      if (obj.photo_id) {
        const url = environment.imgUrl + obj.photo_id;
        // const url = new Cloudinary(cldConfig).image(obj.photo_id).toURL();
        linkImage = {
          text: 'Click Here',
          hyperlink: url,
          tooltip: url,
        };
      }

      eachRow.push(
        moment(obj.attendance.date).tz('Asia/Jakarta').format('DD/MM/YYYY')
      );
      eachRow.push(obj.attendance.employee.employee_id);
      eachRow.push(obj.attendance.employee.fullname);
      eachRow.push(obj.attendance.employee.job.job_title.name);
      eachRow.push(moment(obj.time).tz('Asia/Jakarta').format('HH:mm'));
      eachRow.push(obj.type);
      eachRow.push(obj.fingerprint ? obj.fingerprint.name : 'MyArmas');
      eachRow.push(obj.location?.name ?? '');
      eachRow.push(linkImage ?? '');

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1 || index == 5 || index == 6) {
          cell.alignment = { horizontal: 'center' };
        }
        if (index == 9) {
          cell.font = { underline: true, color: { argb: '0000EE' } };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getColumn(index).width =
          eachRow[index - 1].length < 20 ? 20 : eachRow[index - 1].length + 10;
      });
    });

    worksheet.getColumn('I').width = 20;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportEmployeeDetail(
    reportHeading: string,
    data: any,
    excelFileName: string
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const headers = [
      'Employee ID',
      'Fullname',
      'E-KTP Number',
      'NPWP',
      'Birthday',
      'Religion',
      'Marital Status',
      'Children',
      'Address',
      'Phone',
      'School',
      'Job Title',
      'Level',
      'Employment Status',
      'Grade',
      'Contract From',
      'Contract To',
      'BPJS KS',
      'BPJS TK',
    ];

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(headers.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };

    worksheet.addRow([]);

    //header & style
    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        headers[index - 1].length < 20 ? 20 : headers[index - 1].length;
    });

    worksheet.getRow(3).height = 20;

    _.forEach(data, (obj) => {
      const eachRow = [];

      let children = obj.family_child_1_name ?? '';
      children += obj.family_child_2_name ? ', ' + obj.family_child_2_name : '';
      children += obj.family_child_3_name ? ', ' + obj.family_child_3_name : '';

      let phone = obj.phone_1 ?? '';
      phone += obj.phone_2 ? ', ' + obj.phone_2 : '';

      let education = '';
      _.forEach(obj.educations, function (data) {
        education += data.school_name;
        education += data.major ? ' ( ' + data.major + ' )' : '';
        education += obj.educations.length ? ', ' : '';
      });

      eachRow.push(obj.employee_id);
      eachRow.push(obj.fullname);
      eachRow.push(obj.id_number);
      eachRow.push(obj.id_tax ?? '');
      eachRow.push(
        moment(obj.birthday).tz('Asia/Jakarta').format('DD/MM/YYYY')
      );
      eachRow.push(obj.religion ?? '');
      eachRow.push(obj.marital_status ?? '');
      eachRow.push(children);
      eachRow.push(obj.id_address ?? '');
      eachRow.push(phone);
      eachRow.push(education);
      eachRow.push(obj.job?.job_title?.name ?? '');
      eachRow.push(obj.job?.level ?? '');
      eachRow.push(obj.job?.employment_status?.name ?? '');
      eachRow.push(obj.job?.grade?.name ?? '');
      eachRow.push(
        obj.job?.date_effective_from
          ? moment(obj.job?.date_effective_from)
              .tz('Asia/Jakarta')
              .format('DD MMM YYYY')
          : ''
      );
      eachRow.push(
        obj.job?.date_effective_to
          ? moment(obj.job?.date_effective_to)
              .tz('Asia/Jakarta')
              .format('DD MMM YYYY')
          : ''
      );
      eachRow.push(obj.bpjs_kesehatan ?? '-');
      eachRow.push(obj.bpjs_ketenagakerjaan ?? '-');

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (
          index == 1 ||
          index == 5 ||
          index == 6 ||
          index == 7 ||
          index == 15 ||
          index == 16 ||
          index == 17
        ) {
          cell.alignment = { horizontal: 'center' };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getColumn(index).width =
          eachRow[index - 1].length < 20 ? 20 : eachRow[index - 1].length + 10;
      });
    });

    const fullnameCharLength = _.max(
      _.map(data, (item) => _.size(item.fullname))
    );
    const childrenCharLength = _.max(
      _.map(data, (item) =>
        _.size(
          item.family_child_1_name +
            ' ' +
            item.family_child_2_name +
            ' ' +
            item.family_child_3_name
        )
      )
    );
    const addressCharLength = _.max(
      _.map(data, (item) => _.size(item.id_address))
    );
    const phoneCharLength = _.max(
      _.map(data, (item) => _.size(item.phone_1 + ' ' + item.phone_2))
    );

    worksheet.getColumn('B').width = fullnameCharLength;
    worksheet.getColumn('H').width = childrenCharLength;
    worksheet.getColumn('I').width = addressCharLength;
    worksheet.getColumn('J').width = phoneCharLength;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportDeliveryOrder(
    reportHeading: string,
    data: any,
    date: any,
    excelFileName: string
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const headers = [
      'Date',
      'Customer',
      'Route',
      'Cycle',
      'Truck No',
      'Driver',
      'Order Number',
      'Outgoing',
      'Incoming',
      'Status',
    ];

    const workbook = new Workbook();
    (workbook.creator = user.employee.fullname),
      (workbook.lastModifiedBy = user.employee.fullname),
      (workbook.created = new Date());
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(headers.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));
    worksheet.addRow([]);

    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        headers[index - 1].length < 20 ? 20 : headers[index - 1].length;
    });

    worksheet.getRow(4).height = 20;

    _.forEach(data, (obj) => {
      const eachRow = [];

      eachRow.push(moment(obj.date).tz('Asia/Jakarta').format('DD/MM/YYYY'));
      eachRow.push(obj.customer_name);
      eachRow.push(obj.route_code);
      eachRow.push(obj.cycle);
      eachRow.push(obj.truck_number ?? '');
      eachRow.push(obj.driver_name ?? '');
      eachRow.push(obj.order_number);
      eachRow.push(
        obj.outgoing
          ? moment(obj.outgoing).tz('Asia/Jakarta').format('DD/MM/YYYY')
          : ''
      );
      eachRow.push(
        obj.incoming
          ? moment(obj.incoming).tz('Asia/Jakarta').format('DD/MM/YYYY')
          : ''
      );
      eachRow.push(obj.status ?? '');

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index !== 2 && index !== 6) {
          cell.alignment = { horizontal: 'center' };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getColumn(index).width =
          eachRow[index - 1].length < 20 ? 20 : eachRow[index - 1].length + 10;
      });
    });
    worksheet.getColumn('D').width = 10;

    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportMonitoringDelivery(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any
  ) {
    const header1 = [
      'Date',
      'Route',
      'Cycle',
      'Truck No',
      'Driver',
      'Logistic Point',
      'Arrival',
      '',
      '',
      '',
      'Departure',
      '',
      '',
      '',
      'Problem',
      'Remark',
    ];
    const header2 = [
      '',
      '',
      '',
      '',
      '',
      '',
      'Plan',
      'Actual',
      'GAP',
      'EVA',
      'Plan',
      'Actual',
      'GAP',
      'EVA',
      '',
      '',
    ];

    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    (workbook.creator = user.employee.fullname),
      (workbook.lastModifiedBy = user.employee.fullname),
      (workbook.created = new Date());
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    worksheet.addRow(header1);
    worksheet.addRow(header2);

    worksheet.mergeCells('A4:A5');
    worksheet.mergeCells('B4:B5');
    worksheet.mergeCells('C4:C5');
    worksheet.mergeCells('D4:D5');
    worksheet.mergeCells('E4:E5');
    worksheet.mergeCells('F4:F5');

    worksheet.mergeCells('G4:J4');
    worksheet.mergeCells('K4:N4');

    worksheet.mergeCells('O4:O5');
    worksheet.mergeCells('P4:P5');

    for (let i = 4; i <= 5; i++) {
      for (const [index] of header1.entries()) {
        worksheet.getRow(i).getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getRow(i).getCell(index + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getRow(i).getCell(index + 1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(i).getCell(index + 1).font = { size: 12, bold: true };
        worksheet.getColumn(index + 1).width =
          header1[index].length < 20 ? 20 : header1[index].length;
      }
    }
    worksheet.getRow(4).height = 25;

    let start = 6;
    let end = start;
    _.forEach(data, (items) => {
      _.forEach(items, (obj, index) => {
        const eachRow = [];
        if (index == '0') {
          eachRow.push(
            moment(obj.delivery_date).tz('Asia/Jakarta').format('DD/MM/YYYY')
          );
          eachRow.push(obj.route_code);
          eachRow.push(obj.cycle ?? '');
          eachRow.push(obj.truck_number ?? '');
          eachRow.push(obj.driver_name ?? '');
        } else {
          eachRow.push('');
          eachRow.push('');
          eachRow.push('');
          eachRow.push('');
          eachRow.push('');
        }

        eachRow.push(obj.location_name);
        eachRow.push(
          obj.arrival_plan
            ? moment(obj.arrival_plan).tz('Asia/Jakarta').format('HH:mm')
            : ''
        );
        eachRow.push(
          obj.arrival_actual
            ? moment(obj.arrival_actual).tz('Asia/Jakarta').format('HH:mm')
            : ''
        );
        eachRow.push(obj.arrival_gap ?? '');
        eachRow.push(obj.arrival_eva ?? '');
        eachRow.push(
          obj.departure_plan
            ? moment(obj.departure_plan).tz('Asia/Jakarta').format('HH:mm')
            : ''
        );
        eachRow.push(
          obj.departure_actual
            ? moment(obj.departure_actual).tz('Asia/Jakarta').format('HH:mm')
            : ''
        );
        eachRow.push(obj.departure_gap ?? '');
        eachRow.push(obj.departure_eva ?? '');
        eachRow.push(obj.problem ?? '');
        eachRow.push(obj.remark ?? '');

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          if (index !== 6 && index !== 15 && index !== 16) {
            cell.alignment = { horizontal: 'center' };
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };

          if (index !== 3) {
            worksheet.getColumn(index).width =
              eachRow[index - 1].length < 20
                ? 20
                : eachRow[index - 1].length + 10;
          }
        });
        end++;
      });
      end--;

      for (const val of ['A', 'B', 'C', 'D', 'E']) {
        worksheet.mergeCells(val + start + `:${val}` + end);
        worksheet.getRow(start).getCell(val).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
      }

      end++;
      start = end;
    });

    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportSummaryDelivery(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any,
    view: string
  ) {
    let summary = [];
    const summaryRoute = {};

    if (view == 'customer' || view == 'status') {
      const array = [];

      _.forEach(data, (val) => {
        _.forEach(val.data, (obj) => {
          array.push(obj);
        });
      });

      const grouping = array.reduce((group, arr) => {
        const { date } = arr;
        group[date] = group[date] ?? [];
        group[date].push(arr);

        return group;
      }, {});

      const calc = _.map(grouping, (val) => {
        if (view == 'customer') {
          return {
            plan: _.sumBy(val, 'plan'),
            actual: _.sumBy(val, 'actual'),
            additional: _.sumBy(val, 'additional'),
            difference: _.sumBy(val, 'difference'),
            not_billable: _.sumBy(val, 'not_billable'),
          };
        } else if (view == 'status') {
          return {
            delivered: _.sumBy(val, 'delivered'),
            outstanding: _.sumBy(val, 'outstanding'),
            completed: _.sumBy(val, 'completed'),
          };
        }
      });

      summary = calc;
    } else if (view == 'route') {
      for (const d of data) {
        const array = [];
        _.forEach(d.routes, (val) => {
          _.forEach(val.data, (obj) => {
            array.push(obj);
          });
        });

        const grouping = array.reduce((group, arr) => {
          const { date } = arr;
          group[date] = group[date] ?? [];
          group[date].push(arr);

          return group;
        }, {});

        const calc = _.map(grouping, (val) => {
          return {
            plan: _.sumBy(val, 'plan'),
            actual: _.sumBy(val, 'actual'),
            additional: _.sumBy(val, 'additional'),
            not_billable: _.sumBy(val, 'not_billable'),
          };
        });

        summaryRoute['id-' + d.customer_id] = calc;
      }

      const arrayGrandTotal = [];

      _.forEach(data, (val) => {
        _.forEach(val.routes, (d) => {
          _.forEach(d.data, (obj) => {
            arrayGrandTotal.push(obj);
          });
        });
      });

      const groupSummary = arrayGrandTotal.reduce((group, arr) => {
        const { date } = arr;
        group[date] = group[date] ?? [];
        group[date].push(arr);

        return group;
      }, {});

      const calcSummary = _.map(groupSummary, (val) => {
        return {
          plan: _.sumBy(val, 'plan'),
          actual: _.sumBy(val, 'actual'),
          additional: _.sumBy(val, 'additional'),
          not_billable: _.sumBy(val, 'not_billable'),
        };
      });

      summary = calcSummary;
    }

    const headerParam = {
      customer: [
        'Plan Reguler',
        'Actual Reguler',
        'Actual Additional',
        'Diff',
        'Not Billable',
      ],
      route: ['Plan', 'Actual', 'Additional', 'Not Billable'],
      status: ['Delivered', 'Completed', 'Outstanding'],
    };

    const header1 = view !== 'route' ? ['Customer'] : ['Route'];
    const header2 = [''];

    if (data.length) {
      const headers =
        view !== 'route'
          ? _.first(data)['data']
          : _.first(data)['routes'][0]['data'];
      for (const header of headers) {
        if (header.date !== 'total') {
          header1.push(
            moment(header.date).tz('Asia/Jakarta').format('dddd - DD/MM/YYYY')
          );
        } else {
          header1.push('Total');
        }

        for (let i = 0; i < headerParam[view].length - 1; i++) {
          header1.push('');
        }

        for (const val of headerParam[view]) {
          header2.push(val);
        }
      }
    }

    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    (workbook.creator = user.employee.fullname),
      (workbook.lastModifiedBy = user.employee.fullname),
      (workbook.created = new Date());
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    worksheet.addRow(header1);
    worksheet.addRow(header2);

    worksheet.mergeCells('A4:A5');
    const mergeParam = [];
    for (let i = 4; i <= 5; i++) {
      for (const [index, data] of header1.entries()) {
        if (data && data !== 'Customer' && data !== 'Route') {
          const start = this.numToAlpha(index) + '4';
          const end =
            this.numToAlpha(index + headerParam[view].length - 1) + '4';
          mergeParam.push(start + ':' + end);
        }
        worksheet.getRow(i).getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getRow(i).getCell(index + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getRow(i).getCell(index + 1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(i).getCell(index + 1).font = { size: 12, bold: true };
        worksheet.getColumn(index + 1).width =
          header1[index].length < 20 ? 20 : header1[index].length;
      }
    }

    for (const val of _.uniq(mergeParam)) {
      worksheet.mergeCells(val);
    }

    worksheet.getRow(4).height = 25;
    let start = 6;
    _.forEach(data, (obj) => {
      let eachRow = [];
      eachRow.push(obj.customer_name);

      if (view !== 'route') {
        _.forEach(obj.data, (item) => {
          if (view == 'customer') {
            eachRow.push(item.plan);
            eachRow.push(item.actual);
            eachRow.push(item.additional);
            eachRow.push(
              item.plan > item.difference
                ? '-' + item.difference
                : item.difference
            );
            eachRow.push(item.not_billable);
          } else if (view == 'status') {
            eachRow.push(item.delivered);
            eachRow.push(item.completed);
            eachRow.push(item.outstanding);
          }
        });

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          if (index !== 1) {
            cell.alignment = { horizontal: 'center' };
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          if (index == 1) {
            worksheet.getColumn(index).width =
              eachRow[index - 1].length < 20
                ? 20
                : eachRow[index - 1].length + 10;
          }
        });
      } else {
        worksheet.addRow(eachRow);
        worksheet.mergeCells(
          'A' +
            start.toString() +
            ':' +
            this.numToAlpha(mergeParam.length * 2) +
            start.toString()
        );
        worksheet.getCell('A' + start.toString()).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getCell('A' + start.toString()).font = {
          size: 13,
          bold: true,
        };
        worksheet.getCell('A' + start.toString()).alignment = {
          vertical: 'middle',
        };
        worksheet.getCell('A' + start.toString()).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'C5D9F1' },
        };
        worksheet.getRow(start).height = 30;

        _.forEach(obj.routes, (item) => {
          eachRow = [];
          eachRow.push(item.route_name);

          _.forEach(item.data, (data) => {
            eachRow.push(data.plan);
            eachRow.push(data.actual);
            eachRow.push(data.additional);
            eachRow.push(data.not_billable);
          });

          const row = worksheet.addRow(eachRow);
          row.eachCell((cell, index) => {
            if (index !== 1) {
              cell.alignment = { horizontal: 'center' };
            }
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
            if (index == 1) {
              worksheet.getColumn(index).width =
                eachRow[index - 1].length < 20
                  ? 20
                  : eachRow[index - 1].length + 10;
            }
          });
          start++;
        });

        const summaryRow = [];
        summaryRow.push('Total');
        _.forEach(summaryRoute['id-' + obj.customer_id], (item) => {
          summaryRow.push(item.plan);
          summaryRow.push(item.actual);
          summaryRow.push(item.additional);
          summaryRow.push(item.not_billable);
        });

        const row = worksheet.addRow(summaryRow);
        row.eachCell((cell, index) => {
          cell.font = { size: 13, bold: true };
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'f6f8f9' },
          };
          cell.alignment = { horizontal: 'center' };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          if (index == 1) {
            worksheet.getColumn(index).width =
              eachRow[index - 1].length < 20
                ? 20
                : eachRow[index - 1].length + 10;
          }
          // worksheet.getRow(start).height = 30;
        });
        start += 2;
      }
    });

    const eachRow = [];
    eachRow.push(view !== 'route' ? 'Total' : 'Grand Total');
    _.forEach(summary, (item) => {
      start++;
      if (view == 'customer') {
        eachRow.push(item.plan);
        eachRow.push(item.actual);
        eachRow.push(item.additional);
        eachRow.push(
          item.plan > item.difference ? '-' + item.difference : item.difference
        );
        eachRow.push(item.not_billable);
      } else if (view == 'status') {
        eachRow.push(item.delivered);
        eachRow.push(item.completed);
        eachRow.push(item.outstanding);
      } else if (view == 'route') {
        eachRow.push(item.plan);
        eachRow.push(item.actual);
        eachRow.push(item.additional);
        eachRow.push(item.not_billable);
      }
    });
    const row = worksheet.addRow(eachRow);
    row.eachCell((cell, index) => {
      cell.font = { size: 13, bold: true };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: view !== 'route' ? 'f6f8f9' : 'dbe8f1' },
      };
      cell.alignment = { horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      if (index == 1) {
        worksheet.getColumn(index).width =
          eachRow[index - 1].length < 20 ? 20 : eachRow[index - 1].length + 10;
      }
      worksheet.getRow(start).height = 30;
    });

    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportMonitoringOntimeDelivery(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any
  ) {
    const header1 = ['Route', 'Status'];

    if (data.length) {
      const headers = _.first(data)['advance'];
      for (const header of headers) {
        if (header.date !== 'total') {
          header1.push(moment(header.date).tz('Asia/Jakarta').format('DD'));
        } else {
          header1.push('Total');
        }
      }
    }

    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    (workbook.creator = user.employee.fullname),
      (workbook.lastModifiedBy = user.employee.fullname),
      (workbook.created = new Date());
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Bulan : ' + moment(date).format('MMMM YYYY');

    worksheet.addRow([]);
    worksheet.addRow(header1);

    for (const [index] of header1.entries()) {
      worksheet.getRow(4).getCell(index + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      worksheet.getRow(4).getCell(index + 1).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      worksheet.getRow(4).getCell(index + 1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
      worksheet.getRow(4).getCell(index + 1).font = { size: 12, bold: true };
      worksheet.getColumn(index + 1).width =
        header1[index].length < 20 ? 20 : header1[index].length;
    }

    worksheet.getRow(4).height = 25;
    let start = 5;
    let end = 0;
    _.forEach(data, (obj) => {
      end = start;
      for (const key of ['advance', 'ontime', 'delay']) {
        const eachRow = [];

        if (key !== 'ontime' && key !== 'delay') {
          eachRow.push(obj.code);
        } else {
          end++;
          eachRow.push('');
        }
        eachRow.push(key.toUpperCase());

        _.forEach(obj[key], (item) => {
          eachRow.push(item.value);
        });

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          if (index !== 1 && index !== 2) {
            cell.alignment = { horizontal: 'center' };
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          if (index == 1) {
            worksheet.getColumn(index).width =
              eachRow[index - 1].length < 20
                ? 20
                : eachRow[index - 1].length + 10;
          }
        });
      }

      worksheet.mergeCells('A' + start.toString() + ':A' + end.toString());
      worksheet.getCell('A' + start.toString()).alignment = {
        vertical: 'middle',
      };

      start = end + 1;
    });

    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  private numToAlpha(num: number) {
    let alpha = '';
    for (; num >= 0; num = parseInt((num / 26).toString(), 10) - 1) {
      alpha = String.fromCharCode((num % 26) + 0x41) + alpha;
    }
    return alpha;
  }

  public exportMonitoringOnTimeDeparture(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any
  ) {
    const header = [
      'Date',
      'Route',
      'Location',
      'Trip',
      'Plan ETA',
      'Actual ETA',
      'Plan ETD',
      'Actual ETD',
      'Truck',
      'Status Delivery',
    ];

    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    (workbook.creator = user.employee.fullname),
      (workbook.lastModifiedBy = user.employee.fullname),
      (workbook.created = new Date());
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index + 1).width =
        header[index - 1].length < 20 ? 20 : header[index - 1].length;
    });
    worksheet.getRow(4).height = 25;

    _.forEach(data, (obj) => {
      const eachRow = [];
      eachRow.push(
        moment(obj.delivery_date).tz('Asia/Jakarta').format('DD/MM/YYYY')
      );
      eachRow.push(obj.route_name);
      eachRow.push(obj.location_name);
      eachRow.push(obj.cycle);
      eachRow.push(
        obj.arrival_plan
          ? moment(obj.arrival_plan).tz('Asia/Jakarta').format('HH:mm')
          : ''
      );
      eachRow.push(
        obj.arrival_actual
          ? moment(obj.arrival_actual).tz('Asia/Jakarta').format('HH:mm')
          : ''
      );
      eachRow.push(
        obj.departure_plan
          ? moment(obj.departure_plan).tz('Asia/Jakarta').format('HH:mm')
          : ''
      );
      eachRow.push(
        obj.departure_actual
          ? moment(obj.departure_actual).tz('Asia/Jakarta').format('HH:mm')
          : ''
      );
      eachRow.push(obj.truck_number ?? '');
      eachRow.push(obj.delivery_status);

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index !== 2 && index !== 3) {
          cell.alignment = { horizontal: 'center' };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        if (index !== 4) {
          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        }
      });
    });

    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportHealthCheck(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any
  ) {
    const header1 = [
      'Date',
      'Employee ID',
      'Fullname',
      'Job Title',
      'Employment Status',
      'Birthday',
      'Age',
      'Fatigue',
      'SpO2',
      'Alcohol Level',
      'Body Temperature',
      'Pulse',
      'Blood Pressure',
      '',
      'Total Rest',
      'Taking Medication',
      'Note',
      'Conclution',
      'Attachment',
    ];
    const header2 = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Celcius',
      'times per minute',
      'mmHg',
      '',
      'Hours',
      '',
      '',
      '',
      '',
    ];

    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    worksheet.addRow([]);

    worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);
    worksheet.addRow(header1);
    worksheet.addRow(header2);

    worksheet.mergeCells('A4:A5');
    worksheet.mergeCells('B4:B5');
    worksheet.mergeCells('C4:C5');
    worksheet.mergeCells('D4:D5');
    worksheet.mergeCells('E4:E5');
    worksheet.mergeCells('F4:F5');
    worksheet.mergeCells('G4:G5');
    worksheet.mergeCells('H4:H5');
    worksheet.mergeCells('I4:I5');
    worksheet.mergeCells('J4:J5');

    worksheet.mergeCells('M4:N4');
    worksheet.mergeCells('M5:N5');
    worksheet.mergeCells('P4:P5');
    worksheet.mergeCells('Q4:Q5');
    worksheet.mergeCells('R4:R5');
    worksheet.mergeCells('S4:S5');

    for (let i = 4; i <= 5; i++) {
      for (const [index] of header1.entries()) {
        worksheet.getRow(i).getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getRow(i).getCell(index + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getRow(i).getCell(index + 1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(i).getCell(index + 1).font = { size: 12, bold: true };
        worksheet.getColumn(index + 1).width =
          header1[index].length < 20 ? 20 : header1[index].length;
      }
    }

    worksheet.getRow(4).height = 25;

    _.forEach(data, (obj) => {
      const eachRow = [];
      let linkAttachment: any;
      if (obj.attachment) {
        const url = environment.imgUrl + obj.attachment;
        // const url = new Cloudinary(cldConfig).image(obj.attachment).toURL();
        linkAttachment = {
          text: 'Click Here',
          hyperlink: url,
          tooltip: url,
        };
      }

      eachRow.push(moment(obj.date).tz('Asia/Jakarta').format('DD/MM/YYYY'));
      eachRow.push(obj.employee.fullname);
      eachRow.push(obj.employee.employee_id);
      eachRow.push(obj.employee.job?.job_title?.name ?? '');
      eachRow.push(obj.employee.job?.employment_status?.name ?? '');
      eachRow.push(
        moment(obj.employee.birthday).tz('Asia/Jakarta').format('DD MMM, YYYY')
      );
      eachRow.push(this.calAge(obj.employee.birthday));
      eachRow.push(!obj.fatigue ? 'No' : 'Yes');
      eachRow.push((obj.oxygen_saturation ?? 0) + '%');
      eachRow.push(obj.alcohol_level ?? 0);
      eachRow.push(obj.body_temperature);
      eachRow.push(obj.pulse);
      eachRow.push(obj.sistolik);
      eachRow.push(obj.diastolik);
      eachRow.push(obj.total_rest);
      eachRow.push(obj.taking_medication ?? '');
      eachRow.push(obj.note ?? '');
      eachRow.push(obj.conclution ?? '');
      eachRow.push(linkAttachment ?? '');

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index !== 2 && index !== 3 && index !== 4 && index !== 5) {
          cell.alignment = { horizontal: 'center' };
        }
        if (index == 19) {
          cell.font = { underline: true, color: { argb: '0000EE' } };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        // if (
        //   index !== 4 &&
        //   index !== 7 &&
        //   index !== 8 &&
        //   index !== 9 &&
        //   index !== 10 &&
        //   index !== 11 &&
        //   index !== 12 &&
        //   index !== 16
        // ) {
        //   worksheet.getColumn(index).width =
        //     eachRow[index - 1].length < 20
        //       ? 20
        //       : eachRow[index - 1].length + 10;
        // }
      });
    });

    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  diffTime(actual, plan) {
    let time = '-';
    if (actual && plan) {
      const end = moment(actual).tz('Asia/Jakarta');
      const start = moment(plan).tz('Asia/Jakarta');

      time = Math.round(
        moment.duration(end.diff(start)).asMinutes()
      ).toString();
    }
    return time;
  }

  getMonths(date) {
    const longMonths = moment.months();
    const months = [];

    const startMonth = parseInt(split(date[0], '-')[1]);
    const endMonth = parseInt(split(date[1], '-')[1]);

    for (let i = startMonth; i <= endMonth; i++) {
      months.push({ name: longMonths[i - 1] });
    }
    return months;
  }

  calAge(birthday) {
    const date = birthday;
    const diff = moment()
      .tz('Asia/Jakarta')
      .diff(moment(date).tz('Asia/Jakarta'));
    const duration = moment.duration(diff);
    return Math.floor(duration.asYears());
  }

  formatLeaveBalance(data: any, types: any) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    (workbook.creator = user.employee.fullname),
      (workbook.lastModifiedBy = user.employee.fullname),
      (workbook.created = new Date());
    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet('Format Upload Leave Balance');

    const header1 = ['', 'Leave Balance Format'];
    const header2 = [
      '',
      '*If balance is unlimited, fill with 999. Just Ignore red section',
    ];
    const header3 = ['', '', ''];
    const header4 = ['ID', 'Employee ID', 'Fullname'];

    worksheet.addRow(header1);
    worksheet.addRow(header2);
    worksheet.addRow([]);

    for (const type of types) {
      header3.push(type.id.toString());
      header4.push(type.code);
      header4.push('');
    }

    worksheet.getCell('B1').font = { size: 15, bold: true };
    worksheet.getCell('B2').font = { italic: true };

    worksheet.addRow(header3);
    worksheet.addRow(header4);

    for (const [index] of header4.entries()) {
      worksheet.getRow(5).getCell(index + 1).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      worksheet.getRow(5).getCell(index + 1).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      if (index !== 0 && index !== 1 && index !== 2) {
        if (index % 2 !== 0) {
          worksheet.mergeCells(
            this.numToAlpha(index) + '5:' + this.numToAlpha(index + 1) + '5'
          );
        }
      }
      worksheet.getRow(5).getCell(index + 1).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
      worksheet.getRow(5).getCell(index + 1).font = { size: 12, bold: true };
    }

    _.forEach(data, (obj) => {
      const row1 = [];
      row1.push(obj.id);
      row1.push(obj.employee_id);
      row1.push(obj.fullname);

      _.forEach(types, (data) => {
        const val = _.find(obj.leave_balances, { request_type_id: data.id });
        row1.push(!val ? 'null' : val?.id.toString());
        row1.push(!val ? '0' : val?.balance);
      });

      const row = worksheet.addRow(row1);

      row.eachCell((cell, index) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        if (index !== 3) {
          cell.alignment = { horizontal: 'center' };
          worksheet.getColumn(index).width =
            row1[index - 1].length < 20 ? 20 : row1[index - 1].length + 10;
        }

        if (index !== 1 && index !== 2 && index !== 3) {
          worksheet.getColumn(index).width = 10;
          if (index % 2 == 0) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'FF0000' },
            };
            cell.font = {
              color: {
                argb: 'FF0000',
              },
            };
          }
        }
      });
    });

    worksheet.getRow(4).hidden = true;
    worksheet.getColumn(1).hidden = true;

    worksheet.getRow(5).height = 30;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 30;

    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'format-upload-leave-balance' + EXCEL_EXTENSION);
    });
  }

  formatUploadChecksheets(data: any) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    (workbook.creator = user.employee.fullname),
      (workbook.lastModifiedBy = user.employee.fullname),
      (workbook.created = new Date());
    workbook.modified = new Date();

    for (const row of data) {
      const worksheet = workbook.addWorksheet(row.name);
      worksheet.addRow([row.name, '', 'template_id_' + row.id.toString()]);
      worksheet.mergeCells('A1:B1');
      worksheet.getCell('A1').font = { size: 18, bold: true };
      worksheet.getCell('C1').font = { color: { argb: 'ffffff' } };

      worksheet.addRow([]);

      worksheet.addRow([
        'Date',
        '',
        '*Use comma for multiple date, with format date/month/year',
      ]);
      worksheet.addRow(['Not Billable', '', '*Fill 1 if true, or 0 if false']);

      worksheet.getCell('A3').font = { bold: true };
      worksheet.getCell('A4').font = { bold: true };
      worksheet.getCell('B3').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      worksheet.getCell('B4').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };

      worksheet.getCell('C3').font = {
        italic: true,
        color: { argb: '757171' },
      };
      worksheet.getCell('C4').font = {
        italic: true,
        color: { argb: '757171' },
      };

      worksheet.addRow([]);
      worksheet.addRow([]);

      worksheet.addRow([
        'Origin',
        ':  ' + row.origin.name,
        'origin_id_' + row.origin.id,
        'Start Time',
        row.origin_time_type,
        row.start_time,
        '*Fill time type with m, n or p',
      ]);
      worksheet.addRow([
        'Destination',
        ':  ' + row.destination.name,
        'destination_id_' + row.destination.id,
        'End Time',
        row.destination_time_type,
        row.end_time,
        '*Fill time with format hour:minute',
      ]);

      worksheet.getCell('A7').font = { bold: true };
      worksheet.getCell('A8').font = { bold: true };
      worksheet.getCell('B7').font = { bold: true };
      worksheet.getCell('B8').font = { bold: true };
      worksheet.getCell('C7').font = { color: { argb: 'ffffff' } };
      worksheet.getCell('C8').font = { color: { argb: 'ffffff' } };
      worksheet.getCell('D7').font = { bold: true };
      worksheet.getCell('D8').font = { bold: true };

      worksheet.getCell('E7').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      worksheet.getCell('E8').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      worksheet.getCell('F7').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      worksheet.getCell('F8').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };

      worksheet.getCell('E7').alignment = {
        horizontal: 'center',
      };
      worksheet.getCell('E8').alignment = {
        horizontal: 'center',
      };
      worksheet.getCell('F7').alignment = {
        horizontal: 'right',
      };
      worksheet.getCell('F8').alignment = {
        horizontal: 'right',
      };

      worksheet.getCell('G7').font = {
        italic: true,
        color: { argb: '757171' },
      };
      worksheet.getCell('G8').font = {
        italic: true,
        color: { argb: '757171' },
      };

      worksheet.addRow([]);
      worksheet.mergeCells('A9:I9');
      worksheet.getCell('A9').border = {
        bottom: { style: 'thin' },
      };

      worksheet.addRow([]);
      worksheet.addRow(['Routes']);
      worksheet.getCell('A11').font = { size: 18, bold: true };
      worksheet.addRow([]);

      let lastIndex = 12;

      _.forEach(row.routes, (route) => {
        lastIndex++;

        worksheet.addRow([
          'Name',
          ': ' + route.route.name,
          'id__' + route.id,
          'cycle_' + route.cycle.toString(),
          'route_id_' + route.route.id.toString(),
          'type_' + route.type,
          'route_price_' + route.route.price,
          'route_incentive_price_pickup_' + route.route.incentive_price_pickup,
          'route_incentive_price_drop_' + route.route.incentive_price_drop,
          'sort_order_' + route.sort_order,
        ]);
        worksheet.addRow([
          'Type',
          ': ' + route.type == 'add'
            ? ': ADDITIONAL'
            : ': ' + route.type.toUpperCase(),
        ]);
        worksheet.getCell('A' + lastIndex).font = { bold: true };
        worksheet.getCell('B' + lastIndex).font = { bold: true };
        worksheet.getCell('C' + lastIndex).font = { color: { argb: 'ffffff' } };
        worksheet.getCell('D' + lastIndex).font = { color: { argb: 'ffffff' } };
        worksheet.getCell('E' + lastIndex).font = { color: { argb: 'ffffff' } };
        worksheet.getCell('F' + lastIndex).font = { color: { argb: 'ffffff' } };
        worksheet.getCell('G' + lastIndex).font = { color: { argb: 'ffffff' } };
        worksheet.getCell('H' + lastIndex).font = { color: { argb: 'ffffff' } };
        worksheet.getCell('I' + lastIndex).font = { color: { argb: 'ffffff' } };
        worksheet.getCell('J' + lastIndex).font = { color: { argb: 'ffffff' } };
        lastIndex++;
        worksheet.getCell('A' + lastIndex).font = { bold: true };
        worksheet.getCell('B' + lastIndex).font = { bold: true };

        worksheet.addRow([]);
        const header = worksheet.addRow([
          '',
          'Logistik Point',
          'Arrival',
          '',
          'Departure',
          '',
        ]);
        worksheet.getRow(lastIndex).height = 15;

        lastIndex += 2;
        worksheet.mergeCells('C' + lastIndex + ':D' + lastIndex);
        worksheet.mergeCells('E' + lastIndex + ':F' + lastIndex);

        header.eachCell((cell, cellIndex) => {
          if (cellIndex !== 1) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'B4C6E7' },
            };
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
          }
        });

        _.forEach(route.locations, (location) => {
          lastIndex++;
          const newRow = [
            '',
            location.route_location.location.name,
            location.arrival_type,
            location.arrival_time ?? '',
            location.departure_type,
            location.departure_time ?? '',
            'id__' + location.id.toString(),
            'route_location_id_' + location.route_location.id.toString(),
            'route_location_type_' + location.route_location.type,
            'route_location_sort_order_' +
              location.route_location.sort_order.toString(),
            'route_location_location_id_' +
              location.route_location.location.id.toString(),
          ];
          const rowLocations = worksheet.addRow(newRow);
          rowLocations.eachCell((locationCell, locationCellIndex) => {
            if (locationCellIndex == 2) {
              locationCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'F2F2F2' },
              };
            } else if ([2, 3, 4, 5, 6].includes(locationCellIndex)) {
              locationCell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              };
              locationCell.alignment = {
                horizontal: 'center',
              };
            }

            if ([7, 8, 9, 10, 11].includes(locationCellIndex)) {
              locationCell.font = { color: { argb: 'ffffff' } };
            }
          });
        });
        worksheet.addRow([]);
        // worksheet.mergeCells('A' + lastIndex + ':I' + lastIndex);
        // worksheet.getCell('A' + lastIndex).border = {
        //   bottom: { style: 'thin' },
        // };
        lastIndex++;
      });

      worksheet.getColumn('A').width = 15;
      worksheet.getColumn('B').width = 35;
      worksheet.getColumn('C').width = 10;
      worksheet.getColumn('D').width = 20;
      worksheet.getColumn('E').width = 10;
      worksheet.getColumn('F').width = 20;
    }

    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'format-upload-chechksheets' + EXCEL_EXTENSION);
    });
  }

  public exportReportOvertime(
    reportHeading: string,
    json: any,
    excelFileName: string,
    sheetName: string,
    date: any
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(sheetName);
    const data = json;

    const header1 = [
      'Date',
      'Employee ID',
      'Fullname',
      'Job Title',
      'Shift',
      'Plan',
      '',
      'Actual',
      '',
      '',
      '',
      'Pengajuan',
      '',
      'Status Overtime',
    ];
    const header2 = [
      '',
      '',
      '',
      '',
      '',
      'In',
      'Out',
      'In',
      '',
      'Out',
      '',
      'Start',
      'End',
      '',
    ];
    const header3 = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Time',
      'Diff',
      'Time',
      'Diff',
      '',
    ];

    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    //header & style
    worksheet.addRow(header1);
    worksheet.addRow(header2);
    worksheet.addRow(header3);

    worksheet.mergeCells('A4:A6');
    worksheet.mergeCells('B4:B6');
    worksheet.mergeCells('C4:C6');
    worksheet.mergeCells('D4:D6');
    worksheet.mergeCells('E4:E6');
    worksheet.mergeCells('F4:G4');
    worksheet.mergeCells('H4:K4');
    worksheet.mergeCells('L4:M4');
    worksheet.mergeCells('F5:F6');
    worksheet.mergeCells('G5:G6');
    worksheet.mergeCells('H5:I5');
    worksheet.mergeCells('J5:K5');
    worksheet.mergeCells('L5:L6');
    worksheet.mergeCells('M5:M6');
    worksheet.mergeCells('N4:N6');

    for (let i = 4; i <= 6; i++) {
      for (const [index] of header1.entries()) {
        worksheet.getRow(i).getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getRow(i).getCell(index + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getRow(i).getCell(index + 1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(i).getCell(index + 1).font = {
          size: 12,
          bold: true,
        };
        worksheet.getColumn(index + 1).width =
          header1[index].length < 20 ? 20 : header1[index].length;
      }
    }

    _.forEach(data, (obj) => {
      const eachRow = [];

      eachRow.push(moment(obj.date).tz('Asia/Jakarta').format('DD/MM/YYYY'));
      eachRow.push(obj.employee.employee_id);
      eachRow.push(obj.employee.fullname);
      eachRow.push(obj.employee.job.job_title.name);
      eachRow.push(obj.work_schedule?.name ?? '');
      eachRow.push(
        obj.in_plan
          ? moment.tz(obj.in_plan, 'Asia/Jakarta').format('HH:mm')
          : '-'
      );
      eachRow.push(
        obj.out_plan
          ? moment.tz(obj.out_plan, 'Asia/Jakarta').format('HH:mm')
          : '-'
      );
      eachRow.push(
        obj.in_actual
          ? moment.tz(obj.in_actual, 'Asia/Jakarta').format('HH:mm')
          : '-'
      );
      eachRow.push(this.diffTime(obj.in_actual, obj.in_plan));
      eachRow.push(
        obj.out_actual
          ? moment.tz(obj.out_actual, 'Asia/Jakarta').format('HH:mm')
          : '-'
      );
      eachRow.push(this.diffTime(obj.out_actual, obj.out_plan));

      eachRow.push(
        obj.requests.length
          ? obj.requests[0].request.request_data.start_time
          : '-'
      );

      eachRow.push(
        obj.requests.length
          ? obj.requests[0].request.request_data.end_time
          : '-'
      );

      eachRow.push(
        obj.requests.length ? obj.requests[0].request.status.toUpperCase() : '-'
      );

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        const split = eachRow[0].split('/');
        const day = moment([split[2], split[1], split[0]].join('-')).format(
          'dddd'
        );
        if (['Saturday', 'Sunday'].includes(day)) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFD12F' },
          };
        }

        if (index !== 2 && index !== 3 && index !== 4 && index !== 5) {
          cell.alignment = { horizontal: 'center' };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getColumn(index).width =
          eachRow[index - 1].length < 20 ? 20 : eachRow[index - 1].length + 10;
      });
    });

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportReportIncentive(
    reportHeading: string,
    excelFileName: string,
    data: any,
    date: any,
    view: string
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    if (view == 'daily') {
      // const header1 = [
      //   'Date',
      //   'Employee ID',
      //   'Employee Name',
      //   'Job Title',
      //   'Employment Status',
      //   'Route',
      //   'Incentive Route',
      //   '',
      //   'Incentive Overtime',
      //   'Total Incentive',
      // ];
      // const header2 = ['', '', '', '', '', '', 'Plan', 'Actual', '', ''];
      // worksheet.addRow([]);
      // worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
      // worksheet.getCell('A1').value = reportHeading;
      // worksheet.getCell('A1').alignment = { vertical: 'middle' };
      // worksheet.getCell('A1').font = { size: 15, bold: true };
      // worksheet.getCell('A2').value =
      //   'Tanggal : ' +
      //   (date[0] == date[1]
      //     ? moment(date[0]).format('DD/MM/YYYY')
      //     : moment(date[0]).format('DD/MM/YYYY') +
      //       ' - ' +
      //       moment(date[1]).format('DD/MM/YYYY'));
      // worksheet.addRow([]);
      // worksheet.addRow(header1);
      // worksheet.addRow(header2);
      // worksheet.mergeCells('A4:A5');
      // worksheet.mergeCells('B4:B5');
      // worksheet.mergeCells('C4:C5');
      // worksheet.mergeCells('D4:D5');
      // worksheet.mergeCells('E4:E5');
      // worksheet.mergeCells('F4:F5');
      // worksheet.mergeCells('G4:H4');
      // worksheet.mergeCells('I4:I5');
      // worksheet.mergeCells('J4:J5');
      // for (let i = 4; i <= 5; i++) {
      //   for (const [index, data] of header1.entries()) {
      //     worksheet.getRow(i).getCell(index + 1).fill = {
      //       type: 'pattern',
      //       pattern: 'solid',
      //       fgColor: { argb: 'DDEBF7' },
      //     };
      //     worksheet.getRow(i).getCell(index + 1).border = {
      //       top: { style: 'thin' },
      //       left: { style: 'thin' },
      //       bottom: { style: 'thin' },
      //       right: { style: 'thin' },
      //     };
      //     worksheet.getRow(i).getCell(index + 1).alignment = {
      //       vertical: 'middle',
      //       horizontal: 'center',
      //     };
      //     worksheet.getRow(i).getCell(index + 1).font = {
      //       size: 12,
      //       bold: true,
      //     };
      //     worksheet.getColumn(index + 1).width =
      //       header1[index].length < 20 ? 20 : header1[index].length;
      //   }
      // }
      // _.forEach(data, (obj, index) => {
      //   const eachRow = [];
      //   eachRow.push(
      //     moment(obj.delivery_date).tz('Asia/Jakarta').format('DD/MM/YYYY')
      //   );
      //   eachRow.push(obj.driver_employee_id);
      //   eachRow.push(obj.driver_fullname);
      //   eachRow.push(obj.job_title_name ?? '');
      //   eachRow.push(obj.employment_status_name ?? '');
      //   eachRow.push(obj.template_name ?? '');
      //   eachRow.push(obj.incentive_plan);
      //   eachRow.push(obj.incentive_actual);
      //   eachRow.push(obj.incentive_overtime);
      //   eachRow.push(obj.incentive_total);
      //   const row = worksheet.addRow(eachRow);
      //   row.eachCell((cell, index) => {
      //     if (index == 1) {
      //       cell.alignment = { horizontal: 'center' };
      //     } else if (index == 7 || index == 8 || index == 9 || index == 10) {
      //       cell.alignment = { horizontal: 'right' };
      //       cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
      //     }
      //     cell.border = {
      //       top: { style: 'thin' },
      //       left: { style: 'thin' },
      //       bottom: { style: 'thin' },
      //       right: { style: 'thin' },
      //     };
      //     if (index !== 7 && index !== 8 && index !== 9 && index !== 10) {
      //       worksheet.getColumn(index).width =
      //         eachRow[index - 1].length < 20
      //           ? 20
      //           : eachRow[index - 1].length + 10;
      //     }
      //   });
      // });

      const header1 = [
        'Date',
        'Driver',
        'Route',
        'Incentive Plan',
        'Incentive Route',
        'Incentive Overtime',
        'Total Incentive',
      ];

      worksheet.addRow([]);
      worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
      worksheet.getCell('A1').value = reportHeading;
      worksheet.getCell('A1').alignment = { vertical: 'middle' };
      worksheet.getCell('A1').font = { size: 15, bold: true };
      worksheet.getCell('A2').value =
        'Tanggal : ' +
        (date[0] == date[1]
          ? moment(date[0]).format('DD/MM/YYYY')
          : moment(date[0]).format('DD/MM/YYYY') +
            ' - ' +
            moment(date[1]).format('DD/MM/YYYY'));
      worksheet.addRow([]);

      let startIndex = 4;
      _.forEach(data, (obj) => {
        const employeeLine = worksheet.addRow([
          `${obj.fullname} - [ ${obj.employee_id} ][ ${obj.job?.job_title?.name} ]`,
          '',
          '',
          '',
          '',
          '',
          '',
        ]);

        worksheet.mergeCells(
          `A${startIndex}:${this.numToAlpha(header1.length - 1)}${startIndex}`
        );

        employeeLine.eachCell((cell) => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'D9D9D9' },
          };
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          cell.alignment = { vertical: 'middle', horizontal: 'left' };
          cell.font = { size: 12, bold: true };
          worksheet.getRow(startIndex).height = 20;
          // worksheet.getColumn(index).width =
          //   header1[index - 1].length < 20 ? 20 : header1[index - 1].length;
        });
        startIndex++;

        const header = worksheet.addRow(header1);
        header.eachCell((cell, index) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
          cell.font = { size: 12, bold: true };
          worksheet.getColumn(index).width =
            header1[index - 1].length < 20 ? 20 : header1[index - 1].length;
          worksheet.getRow(startIndex).height = 30;
        });

        startIndex++;

        for (const [index] of obj.incentives.entries()) {
          let startMerge = startIndex;
          let endMerge = null;
          for (const [i, d] of obj.incentives[index].data.entries()) {
            startIndex++;
            const eachRow = [];
            eachRow.push(
              moment(d.date).tz('Asia/Jakarta').format('DD-MM-YYYY')
            );
            eachRow.push(d.driver.fullname);
            eachRow.push(d.template?.name);
            eachRow.push(d.incentive.incentive_plan);
            eachRow.push(d.incentive.incentive);
            eachRow.push(d.incentive.incentive_overtime_delivery);
            if (i == 0) {
              eachRow.push(
                obj.incentives[index].sum !== 0 &&
                  obj.incentives[index].sum < 55000
                  ? 55000
                  : obj.incentives[index].sum
              );
            } else {
              eachRow.push('');
              endMerge = startIndex - 1;
            }
            const row = worksheet.addRow(eachRow);

            row.eachCell((cell, index) => {
              cell.border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
              };

              cell.alignment = { vertical: 'middle' };

              if (index == 2 || index == 3) {
                worksheet.getColumn(index).width =
                  header1[index - 1].length < 20
                    ? 40
                    : header1[index - 1].length;
              }
              // worksheet.getRow(startIndex).height = 20;

              if (index !== 1 && index !== 2 && index !== 3) {
                cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
              }
            });
          }
          if (endMerge) {
            worksheet.mergeCells(`F${startMerge}:F${endMerge}`);
            startMerge = endMerge + 1;
            endMerge = null;
          }
        }
      });
    } else if (view == 'summary') {
      const header1 = [
        'Employee ID',
        'Employee Name',
        'Job Title',
        'Employment Status',
        'Incentive Overtime',
        'Incentive Route',
        'Total Incentive',
      ];

      worksheet.addRow([]);
      worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
      worksheet.getCell('A1').value = reportHeading;
      worksheet.getCell('A1').alignment = { vertical: 'middle' };
      worksheet.getCell('A1').font = { size: 15, bold: true };
      worksheet.getCell('A2').value =
        'Bulan : ' +
        moment(date).tz('Asia/Jakarta').format('MMMM') +
        ', ' +
        moment(date).tz('Asia/Jakarta').format('YYYY');

      worksheet.addRow([]);
      worksheet.addRow(header1);

      for (const [index] of header1.entries()) {
        worksheet.getRow(4).getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getRow(4).getCell(index + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getRow(4).getCell(index + 1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(4).getCell(index + 1).font = { size: 12, bold: true };
        worksheet.getColumn(index + 1).width =
          header1[index].length < 20 ? 20 : header1[index].length;
      }

      _.forEach(data, (obj) => {
        const eachRow = [];

        eachRow.push(obj.driver.employee_id);
        eachRow.push(obj.driver.fullname);
        eachRow.push(obj.driver.job?.job_title?.name ?? '');
        eachRow.push(obj.driver.job?.employment_status?.name ?? '');
        eachRow.push(obj.incentive.incentive_overtime_delivery);
        eachRow.push(obj.incentive.incentive);
        eachRow.push(obj.incentive.incentive_total);

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          if (index == 5 || index == 6 || index == 7) {
            cell.alignment = { horizontal: 'right' };
            cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };

          if (index !== 5 && index !== 6 && index !== 7) {
            worksheet.getColumn(index).width =
              eachRow[index - 1].length < 20
                ? 20
                : eachRow[index - 1].length + 10;
          }
        });
      });
    } else if (view == 'detail') {
      const header1 = [
        'Employee ID',
        'Employee Name',
        'Job Title',
        'Employment Status',
      ];

      const num = moment(date).tz('Asia/Jakarta').daysInMonth();
      const firstDay = moment(date).tz('Asia/Jakarta').startOf('month');

      _.times(num, function () {
        header1.push([firstDay.format('DD'), firstDay.format('ddd')].join(' '));

        firstDay.add(1, 'day');
      });

      worksheet.addRow([]);
      worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
      worksheet.getCell('A1').value = reportHeading;
      worksheet.getCell('A1').alignment = { vertical: 'middle' };
      worksheet.getCell('A1').font = { size: 15, bold: true };
      worksheet.getCell('A2').value =
        'Bulan : ' +
        moment(date).tz('Asia/Jakarta').format('MMMM') +
        ', ' +
        moment(date).tz('Asia/Jakarta').format('YYYY');

      worksheet.addRow([]);
      worksheet.addRow(header1);

      for (const [index] of header1.entries()) {
        worksheet.getRow(4).getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getRow(4).getCell(index + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getRow(4).getCell(index + 1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(4).getCell(index + 1).font = { size: 12, bold: true };
        worksheet.getColumn(index + 1).width =
          header1[index].length < 20 ? 20 : header1[index].length;
      }

      _.forEach(data, (obj) => {
        const eachRow = [];

        eachRow.push(obj.driver_fullname);
        eachRow.push(obj.driver_employee_id);
        eachRow.push(obj.job_title_name);
        eachRow.push(obj.employment_status_name);
        for (const incentive of obj.incentive) {
          eachRow.push(incentive.is_exist == '1' ? 'H' : '');
        }

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          if (index !== 1 && index !== 2 && index !== 3 && index !== 4) {
            cell.alignment = { horizontal: 'center' };
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        });
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  formatUploadPayrollData(cols, data) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    (workbook.creator = user.employee.fullname),
      (workbook.lastModifiedBy = user.employee.fullname),
      (workbook.created = new Date());
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Payroll Data');

    const header1 = ['No', 'Employee ID', 'Fullname'];
    const header2 = ['', '', ''];

    for (const col of cols) {
      header1.push(col.code);
      header2.push(col.name);
    }

    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
    worksheet.getCell('A1').value = 'Format Upload Payroll Data';
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };

    worksheet.addRow([]);

    worksheet.addRow(header1);
    worksheet.addRow(header2);

    worksheet.mergeCells('A3:A4');
    worksheet.mergeCells('B3:B4');
    worksheet.mergeCells('C3:C4');

    for (let i = 3; i <= 4; i++) {
      for (const [index] of header1.entries()) {
        worksheet.getRow(i).getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getRow(i).getCell(index + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getRow(i).getCell(index + 1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(i).getCell(index + 1).font = {
          size: 12,
          bold: true,
        };
        worksheet.getColumn(index + 1).width =
          header1[index].length < 20 ? 20 : header1[index].length;
      }
    }

    _.forEach(data, (obj, index) => {
      const eachRow = [];

      eachRow.push(index + 1);
      eachRow.push(obj.employee_id);
      eachRow.push(obj.fullname);

      for (const payment of obj.payroll_data) {
        eachRow.push(payment.value);
      }

      eachRow.push('');
      eachRow.push('');
      eachRow.push('');
      eachRow.push('employee_id_' + obj.id);

      for (const component of obj.payroll_data) {
        eachRow.push(
          `payroll_data_id_${
            component.id ? component.id : 'null'
          }, component_id_${component.payroll_component.id}`
        );
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) {
          cell.alignment = { horizontal: 'center' };
        }
        if (index !== 1 && index !== 2 && index !== 3) {
          if (index <= cols.length + 3) {
            cell.alignment = { horizontal: 'right' };
            cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
          } else if (index > cols.length + 3) {
            cell.font = { color: { argb: 'ffffff' } };
          }
        }

        if (index <= cols.length + 3) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        }

        if (index == 2 || index == 3) {
          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        }
      });
    });

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'format-upload-payroll-data' + EXCEL_EXTENSION);
    });
  }

  public exportEtollBalance(
    reportHeading: string,
    json: any,
    excelFileName: string
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(excelFileName);
    const data = json;
    const header = ['E-Toll Number', 'Balance'];

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };

    worksheet.addRow([]);

    //header & style
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width = 25;
    });

    worksheet.getRow(3).height = 30;

    _.forEach(data, (obj) => {
      const eachRow = [];

      eachRow.push(obj.etoll_number);
      eachRow.push(obj.balance);

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 2) {
          cell.alignment = { horizontal: 'right' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  // public exportPayslip(data: any) {
  //   const user = JSON.parse(localStorage.getItem('alsuser'));
  //   const heading = 'Payslip - ' + data.payroll_type.name;

  //   //init workbook & worksheet
  //   const workbook = new Workbook();
  //   workbook.creator = user.employee.fullname;
  //   workbook.lastModifiedBy = user.employee.fullname;
  //   workbook.created = new Date();
  //   workbook.modified = new Date();
  //   const worksheet = workbook.addWorksheet(heading);

  //   const header = data.payroll_slips.length
  //     ? _.map(data.payroll_slips[0].payroll_slip_components, (obj) => {
  //         return obj.name;
  //       })
  //     : [];

  //   if (header.length) {
  //     header.unshift(
  //       'Employee ID',
  //       'Nama Lengkap',
  //       'Job Title',
  //       'Employment Status'
  //     );
  //     header.push('Take Home Pay');
  //     header.push('Bank Account 1');
  //     header.push('Bank Number 1');
  //     header.push('Split Payment 1');
  //     header.push('Bank Account 2');
  //     header.push('Bank Number 2');
  //     header.push('Split Payment 2');
  //   }

  //   //heading title
  //   worksheet.addRow([]);
  //   worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');
  //   worksheet.getCell('A1').value = heading;
  //   worksheet.getCell('A1').alignment = { vertical: 'middle' };
  //   worksheet.getCell('A1').font = { size: 15, bold: true };

  //   let months = moment.months();

  //   if (!data?.payment_date) {
  //     worksheet.addRow([
  //       'Month : ' + [months[data.month - 1], data.year].join(' '),
  //     ]);
  //   } else {
  //     worksheet.addRow([
  //       'Month : ' +
  //         moment(data.payment_date).tz('Asia/Jakarta').format('MMMM YYYY'),
  //     ]);
  //   }
  //   worksheet.mergeCells('A2:' + this.numToAlpha(header.length - 1) + '2');
  //   worksheet.addRow(['Status : ' + data.status.toUpperCase()]);
  //   worksheet.mergeCells('A3:' + this.numToAlpha(header.length - 1) + '3');

  //   worksheet.addRow([]);

  //   if (header.length) {
  //     const headerRow = worksheet.addRow(header);
  //     headerRow.eachCell((cell, index) => {
  //       cell.fill = {
  //         type: 'pattern',
  //         pattern: 'solid',
  //         fgColor: { argb: 'DDEBF7' },
  //       };
  //       cell.border = {
  //         top: { style: 'thin' },
  //         left: { style: 'thin' },
  //         bottom: { style: 'thin' },
  //         right: { style: 'thin' },
  //       };
  //       cell.alignment = { vertical: 'middle', horizontal: 'center' };
  //       cell.font = { size: 12, bold: true };
  //       worksheet.getColumn(index).width = 25;
  //     });

  //     worksheet.getRow(5).height = 30;

  //     _.forEach(data.payroll_slips, (obj, i: number) => {
  //       const eachRow = [];

  //       const allowances = _.filter(obj.payroll_slip_components, (d, i) => {
  //         return d.payroll_component.type == 'allowance';
  //       });
  //       const deductions = _.filter(obj.payroll_slip_components, (d, i) => {
  //         return d.payroll_component.type == 'deduction';
  //       });

  //       const totalAllowance = _.sumBy(allowances, 'value');
  //       const totalDeduction = _.sumBy(deductions, 'value');

  //       eachRow.push(obj.employee.employee_id);
  //       eachRow.push(obj.employee.fullname);
  //       eachRow.push(obj.employee.job?.job_title?.name);
  //       eachRow.push(obj.employee.job?.employment_status?.name);

  //       for (const component of obj.payroll_slip_components) {
  //         eachRow.push(component.value);
  //       }
  //       const takeHomePay = totalAllowance - totalDeduction;

  //       eachRow.push(takeHomePay);
  //       eachRow.push(
  //         obj.employee.bank_account_1_name
  //           ? obj.employee.bank_account_1_name.toUpperCase()
  //           : '-'
  //       );
  //       eachRow.push(
  //         obj.employee.bank_account_1_number
  //           ? obj.employee.bank_account_1_number
  //           : '-'
  //       );

  //       let splitPayment1 = 0;
  //       if (obj.employee.bank_account_1_value) {
  //         if (takeHomePay < obj.employee.bank_account_1_value) {
  //           splitPayment1 = takeHomePay;
  //         } else {
  //           splitPayment1 = obj.employee.bank_account_1_value;
  //         }
  //       }

  //       eachRow.push(splitPayment1);
  //       eachRow.push(
  //         obj.employee.bank_account_2_name
  //           ? obj.employee.bank_account_2_name.toUpperCase()
  //           : '-'
  //       );
  //       eachRow.push(
  //         obj.employee.bank_account_2_number
  //           ? obj.employee.bank_account_2_number
  //           : '-'
  //       );

  //       let splitPayment2 = 0;
  //       if (obj.employee.bank_account_1_value) {
  //         if (takeHomePay > obj.employee.bank_account_1_value) {
  //           splitPayment2 = takeHomePay - obj.employee.bank_account_1_value;
  //         }
  //       }
  //       eachRow.push(splitPayment2);

  //       const row = worksheet.addRow(eachRow);
  //       row.eachCell((cell, index) => {
  //         if ([1, 2, 3, 4].includes(index)) {
  //           cell.alignment = { horizontal: 'left' };
  //         } else {
  //           cell.alignment = { horizontal: 'right' };
  //           cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
  //         }

  //         cell.border = {
  //           top: { style: 'thin' },
  //           left: { style: 'thin' },
  //           bottom: { style: 'thin' },
  //           right: { style: 'thin' },
  //         };
  //       });
  //     });
  //   }

  //   //export
  //   workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
  //     const blob = new Blob([data], { type: EXCEL_TYPE });
  //     fs.saveAs(blob, heading + EXCEL_EXTENSION);
  //   });
  // }

  public exportPayslip(data: any) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const heading = (
      !data.param.payment_2 ? data.param.payment_1 : data.param.payment_2
    ).replaceAll(' ', '_');

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(heading);

    const header1 = ['No', 'Employee ID', 'Fieldman', 'Employee'];
    const header2 = ['', '', '', ''];

    _.forEach(data.headers, (d) => {
      if (d.attendance_status.length) {
        header1.push(
          'Attendance Status',
          ...Array(d.attendance_status.length - 1).fill('')
        );
      }

      header1.push(...d.allowances);
      header2.push(
        ...d.attendance_status,
        ...Array(d.allowances.length).fill('')
      );

      header1.push(
        'Total Allowance',
        '',
        ...d.deductions,
        'Total Deduction',
        '',
        'THP',
        '',
        ''
      );

      header2.push(
        '',
        '',
        ...Array(d.deductions.length).fill(''),
        '',
        '',
        'Transfer',
        'Cash',
        'Loss'
      );

      if (data.param.have_reference) {
        header1.push('');
        header2.push('');
      }
    });

    if (data.param.have_reference) {
      header1.push('Total Bruto');
      header2.push('');
    }

    //heading title
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);

    let indexHeading = 0;
    indexHeading += 3; //tambah kolom employee & dll

    let startColumn = 0;
    _.forEach(data.headers, (d, i) => {
      indexHeading += d.attendance_status.length;
      indexHeading += d.allowances.length;
      indexHeading += 2;
      indexHeading += d.deductions.length;
      indexHeading += data.param.have_reference && !d.is_reference ? 7 : 5;

      worksheet.getCell(`${this.numToAlpha(startColumn)}1`).value =
        data.param['payment_' + (i + 1)] +
        ' ( ' +
        moment(data.param['payment_date_' + (i + 1)])
          .locale('id')
          .format('DD MMMM YYYY') +
        ' )';

      worksheet.mergeCells(
        `${this.numToAlpha(startColumn)}1:${this.numToAlpha(indexHeading)}3`
      );

      worksheet.getCell(`${this.numToAlpha(startColumn)}1`).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
      if (data.param['color_' + (i + 1)]) {
        worksheet.getCell(`${this.numToAlpha(startColumn)}1`).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: data.param['color_' + (i + 1)].replace('#', '') },
        };
      }

      worksheet.getCell(`${this.numToAlpha(startColumn)}1`).font = {
        size: 15,
        bold: true,
      };

      if (data.param.have_reference) {
        indexHeading += 1;
      }
      startColumn = indexHeading + 1;
    });

    worksheet.addRow(header1);
    worksheet.addRow(header2);

    //start 5 - 6 (header)
    for (let i = 5; i <= 6; i++) {
      for (const [index] of header1.entries()) {
        worksheet.getRow(i).getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getRow(i).getCell(index + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getRow(i).getCell(index + 1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(i).getCell(index + 1).font = {
          size: 12,
          bold: true,
        };
        worksheet.getColumn(index + 1).width =
          header1[index].length < 20 ? 20 : header1[index].length;
      }
    }

    worksheet.mergeCells('A5:A6');
    worksheet.mergeCells('B5:B6');
    worksheet.mergeCells('C5:C6');
    worksheet.mergeCells('D5:D6');

    const indexCenter = [1, 2];
    const indexCurrency = [];
    const indexGrey = [];

    //merge column & row
    //posisi kolom employee
    let colIndex = 3;
    _.forEach(data.headers, (d) => {
      let colIndexTo = colIndex;

      d.attendance_status.forEach(() => {
        colIndexTo++;
        worksheet.getColumn(colIndexTo + 1).width = 7;
        indexCenter.push(colIndexTo + 1);
      });

      worksheet.mergeCells(
        `${this.numToAlpha(colIndex + 1)}5:${this.numToAlpha(colIndexTo)}5`
      );
      _.forEach(d.allowances, () => {
        worksheet.mergeCells(
          `${this.numToAlpha(colIndexTo + 1)}5:${this.numToAlpha(
            colIndexTo + 1
          )}6`
        );
        colIndexTo++;
        indexCurrency.push(colIndexTo + 1);
      }); //merge kolom allowance ke bawah
      colIndexTo += 1; //ditambah kolom total
      worksheet.mergeCells(
        `${this.numToAlpha(colIndexTo)}5:${this.numToAlpha(colIndexTo)}6`
      );
      indexCurrency.push(colIndexTo + 1);
      colIndexTo += 1; // longkap 1 kolom
      indexGrey.push(colIndexTo + 1);

      _.forEach(d.deductions, () => {
        worksheet.mergeCells(
          `${this.numToAlpha(colIndexTo + 1)}5:${this.numToAlpha(
            colIndexTo + 1
          )}6`
        );
        colIndexTo++;
        indexCurrency.push(colIndexTo + 1);
      }); //merge kolom deduction ke bawah
      colIndexTo += 1; //ditambah kolom total
      worksheet.mergeCells(
        `${this.numToAlpha(colIndexTo)}5:${this.numToAlpha(colIndexTo)}6`
      );
      indexCurrency.push(colIndexTo + 1);
      indexGrey.push(colIndexTo + 2);
      colIndexTo += 2; // tambah kolom kosong & 1 kolom THP

      worksheet.mergeCells(
        `${this.numToAlpha(colIndexTo)}5:${this.numToAlpha(colIndexTo + 2)}5`
      ); //merge THP ke pinggir
      indexCurrency.push(colIndexTo + 1, colIndexTo + 2, colIndexTo + 3);

      if (data.param.have_reference) {
        colIndexTo += 3; //ngelewat 3 kolom kalo ada reference
        indexGrey.push(colIndexTo + 1);
      }
      colIndex = colIndexTo;
    });

    if (data.param.have_reference) {
      worksheet.mergeCells(
        `${this.numToAlpha(colIndex + 1)}5:${this.numToAlpha(colIndex + 1)}6`
      ); //merge kolom total bruto
      indexCurrency.push(colIndex + 2);
    }

    worksheet.getColumn(1).width = 7; // kolom no

    _.forEach(data.rows, (d) => {
      const eachRow = [d.no, d.employee_id, d.fieldman, d.fullname];
      for (const o of d.data) {
        eachRow.push(
          ...o.attendance_status,
          ...o.allowances,
          o.total_allowance,
          '',
          ...o.deductions,
          o.total_deduction,
          '',
          o.transfer,
          o.cash,
          o.loss
        );

        if (data.param.have_reference) eachRow.push('');
      }
      if (data.param.have_reference) eachRow.push(d.bruto);

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (indexCenter.includes(index)) {
          cell.alignment = { horizontal: 'center' };
        }

        if (indexCurrency.includes(index)) {
          cell.alignment = { horizontal: 'right' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        if ([2, 3, 4].includes(index)) {
          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        }
      });
    });

    const eachRow = ['Total', '', '', ''];

    _.forEach(data.sum.data, (sum) => {
      eachRow.push(
        ...sum.attendance_status,
        ...sum.allowances,
        sum.total_allowance,
        '',
        ...sum.deductions,
        sum.total_deduction,
        '',
        sum.transfer,
        sum.cash,
        sum.loss
      );

      if (data.param.have_reference) eachRow.push('');
    });
    if (data.param.have_reference) eachRow.push(data.sum.total_bruto);

    const row = worksheet.addRow(eachRow);
    row.eachCell((cell, index) => {
      if (indexCenter.includes(index)) {
        cell.alignment = { horizontal: 'center' };
      }

      if (indexCurrency.includes(index)) {
        cell.alignment = { horizontal: 'right' };
        cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
      }

      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };

      cell.alignment = { ...cell.alignment, vertical: 'middle' };
      cell.font = {
        size: 13,
        bold: true,
      };

      if ([2, 3, 4].includes(index)) {
        worksheet.getColumn(index).width =
          eachRow[index - 1].length < 20 ? 20 : eachRow[index - 1].length + 10;
      }
    });

    const lastIndex = 6 + data.rows.length + 1;
    worksheet.mergeCells(`A${lastIndex}:D${lastIndex}`);
    worksheet.getRow(lastIndex).height = 25;

    _.forEach(header1, (h, i) => {
      if (indexGrey.includes(i + 1)) {
        worksheet.mergeCells(
          `${this.numToAlpha(i)}5:${this.numToAlpha(i)}${lastIndex}`
        );
        worksheet.getColumn(i + 1).width = 5;
        worksheet.getCell(`${this.numToAlpha(i)}5`).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D3D3D3' },
        };
      }
    });

    // worksheet.getColumn(indexGreyColor).width = 5;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, heading + EXCEL_EXTENSION);
    });
  }

  public exportPayslip2(data: any) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const heading =
      'Pembayaran Insentif ' +
      moment(data.param.payment_date_1).format('DD MMM YYYY');

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(heading);

    worksheet.addRow([heading]);
    worksheet.addRow([data.param.payment_1]);

    worksheet.mergeCells('A1:C1');
    worksheet.mergeCells('A2:C2');

    worksheet.getCell('A1').font = { size: 13, bold: true };
    worksheet.getCell('A2').font = { size: 13, bold: true };

    worksheet.addRow([]);

    const header1 = ['No', 'Employee ID', 'Employee'];
    const header2 = ['', '', ''];

    _.forEach(data.headers, (d) => {
      if (d.attendance_status.length) {
        header1.push(
          'Working Days',
          ...Array(d.attendance_status.length - 1).fill('')
        );

        header2.push(...d.attendance_status);
      }

      header1.push(
        'Allowances',
        ...Array(d.allowances.length - 1).fill(''),
        'Total Incentive',
        '',
        'Deductions',
        ...Array(d.deductions.length - 1).fill(''),
        'Total Received Incentive',
        '',
        'BCA',
        'BRI',
        'CASH'
      );
      header2.push(
        ...d.allowances,
        '',
        '',
        ...d.deductions,
        '',
        '',
        '',
        '',
        ''
      );
    });

    worksheet.addRow(header1);
    worksheet.addRow(header2);

    //start 4 - 5 (header)
    let rowIdx = 4;
    for (let i = rowIdx; i <= 5; i++) {
      for (const [index] of header1.entries()) {
        worksheet.getRow(i).getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getRow(i).getCell(index + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getRow(i).getCell(index + 1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(i).getCell(index + 1).font = {
          size: 12,
          bold: true,
        };
        worksheet.getColumn(index + 1).width =
          header1[index].length < 20 ? 30 : header1[index].length;
      }
      worksheet.getRow(rowIdx).height = 20;
      rowIdx++;
    }

    worksheet.mergeCells('A4:A5');
    worksheet.mergeCells('B4:B5');
    worksheet.mergeCells('C4:C5');

    const indexCenter = [1];
    const indexCurrency = [];
    const indexEmpty = [];
    const indexDate = [];

    _.forEach(data.headers, (h) => {
      let nthColumn = 3;
      _.forEach(h.attendance_status, () => {
        nthColumn++;
        indexCenter.push(nthColumn);
        worksheet.getColumn(nthColumn).width = 20;
      });
      worksheet.mergeCells(
        `${this.numToAlpha(
          nthColumn - h.attendance_status.length
        )}4:${this.numToAlpha(nthColumn - 1)}4`
      ); //merge working days

      _.forEach(h.allowances, () => {
        nthColumn++;
        indexCurrency.push(nthColumn);
      });

      worksheet.mergeCells(
        `${this.numToAlpha(nthColumn - h.allowances.length)}4:${this.numToAlpha(
          nthColumn - 1
        )}4`
      );

      nthColumn++;
      indexCurrency.push(nthColumn);
      worksheet.mergeCells(
        `${this.numToAlpha(nthColumn - 1)}4:${this.numToAlpha(nthColumn - 1)}5`
      );

      nthColumn++;
      indexEmpty.push(nthColumn);

      _.forEach(h.deductions, () => {
        nthColumn++;
        indexCurrency.push(nthColumn);
      });

      worksheet.mergeCells(
        `${this.numToAlpha(nthColumn - h.deductions.length)}4:${this.numToAlpha(
          nthColumn - 1
        )}4`
      );

      for (let i = 0; i < 5; i++) {
        nthColumn++;
        if (i == 1) {
          indexEmpty.push(nthColumn);
          continue;
        }
        worksheet.mergeCells(
          `${this.numToAlpha(nthColumn - 1)}4:${this.numToAlpha(
            nthColumn - 1
          )}5`
        );
        indexCurrency.push(nthColumn);
      }
    });

    _.forEach(
      data.rows,
      ({
        no,
        employee_id,
        fullname,
        attendance_status,
        allowances,
        total_received,
        deductions,
        total_insentif,
        bri,
        bca,
        cash,
      }) => {
        const eachRow = [no, employee_id, fullname];

        eachRow.push(
          ...attendance_status,
          ..._.map(allowances, (o) => o.value),
          total_insentif,
          '',
          ..._.map(deductions, (o) => o.value),
          total_received,
          '',
          bca,
          bri,
          cash
        );

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          if (indexCenter.includes(index))
            cell.alignment = { horizontal: 'center' };
          if (indexCurrency.includes(index)) {
            cell.alignment = { horizontal: 'right' };
            cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
          }
          cell.alignment = { ...cell.alignment, vertical: 'middle' };

          if (indexDate.includes(index)) cell.numFmt = 'dd/mm/yyyy';

          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });

        worksheet.getRow(rowIdx).height = 20;
        rowIdx++;
      }
    );

    const eachRow = ['Total', '', ''];

    _.forEach(data.sum.data, (sum) => {
      eachRow.push(
        ...sum.attendance_status,
        ...sum.allowances,
        sum.total_incentive,
        '',
        ...sum.deductions,
        sum.total_received_incentive,
        '',
        sum.total_bca,
        sum.total_bri,
        sum.total_cash
      );
    });
    const footer = worksheet.addRow(eachRow);
    footer.eachCell((cell, index) => {
      if (indexCenter.includes(index)) {
        cell.alignment = { horizontal: 'center' };
      }

      if (indexCurrency.includes(index)) {
        cell.alignment = { horizontal: 'right' };
        cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
      }

      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };

      cell.alignment = { ...cell.alignment, vertical: 'middle' };
      cell.font = {
        size: 13,
        bold: true,
      };
    });

    const lastRowIndex = 5 + data.rows.length + 1;
    for (const i of indexEmpty) {
      worksheet.mergeCells(
        `${this.numToAlpha(i - 1)}4:${this.numToAlpha(i - 1)}${lastRowIndex}`
      );
      worksheet.getColumn(i).width = 5;
      worksheet.getCell(`${this.numToAlpha(i - 1)}4`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
      };
    }
    worksheet.getColumn(1).width = 5;
    worksheet.mergeCells(`A${lastRowIndex}:C${lastRowIndex}`);
    worksheet.getRow(lastRowIndex).height = 20;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, heading + EXCEL_EXTENSION);
    });
  }

  public exportPayslip3(data: any, ref_payment_name: string) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const heading =
      'Pembayaran Insentif ' +
      moment(data.param.payment_date_1).format('DD MMM YYYY');

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(heading);

    worksheet.addRow([heading]);
    worksheet.addRow([data.param.payment_1]);

    worksheet.mergeCells('A1:C1');
    worksheet.mergeCells('A2:C2');

    worksheet.getCell('A1').font = { size: 13, bold: true };
    worksheet.getCell('A2').font = { size: 13, bold: true };

    worksheet.addRow([]);

    const header1 = ['No', 'Employee ID', 'Employee'];
    const header2 = ['', '', ''];

    _.forEach(data.headers, (d) => {
      if (d.attendance_status.length) {
        header1.push(
          'Attendance Status',
          ...Array(d.attendance_status.length - 1).fill('')
        );
        header2.push(...d.attendance_status);
      }

      if (d.ref_allowances.length) {
        header1.push(
          ref_payment_name,
          ...Array(d.ref_allowances.length - 1).fill('')
        );
        header2.push(...d.ref_allowances);
      }

      header1.push(
        'Allowances',
        ...Array(d.allowances.length - 1).fill(''),
        'Total Allowance',
        '',
        'Deductions',
        ...Array(d.deductions.length - 1).fill(''),
        'Total Deduction',
        'Net Salary',
        ''
      );
      header2.push(...d.allowances, '', '', ...d.deductions, '', '', '');

      if (d.other_allowances.length) {
        header1.push('Other Allowances');
        header1.push(...Array(d.other_allowances.length - 1).fill(''));
        header2.push(...d.other_allowances);

        header1.push('');
        header2.push('');
      }

      header1.push('Take Home Pay');
      header2.push('');
    });

    worksheet.addRow(header1);
    worksheet.addRow(header2);

    //start 4 - 5 (header)
    let rowIdx = 4;
    for (let i = rowIdx; i <= 5; i++) {
      for (const [index] of header1.entries()) {
        worksheet.getRow(i).getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getRow(i).getCell(index + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getRow(i).getCell(index + 1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(i).getCell(index + 1).font = {
          size: 12,
          bold: true,
        };
        worksheet.getColumn(index + 1).width =
          header1[index].length < 20 ? 30 : header1[index].length;
      }
      worksheet.getRow(rowIdx).height = 20;
      rowIdx++;
    }

    worksheet.mergeCells('A4:A5');
    worksheet.mergeCells('B4:B5');
    worksheet.mergeCells('C4:C5');

    const indexCenter = [1];
    const indexCurrency = [];
    const indexEmpty = [];

    _.forEach(data.headers, (h) => {
      let nthColumn = 3;
      _.forEach(h.attendance_status, () => {
        nthColumn++;
        indexCenter.push(nthColumn);
        worksheet.getColumn(nthColumn).width = 5;
      });
      worksheet.mergeCells(
        `${this.numToAlpha(
          nthColumn - h.attendance_status.length
        )}4:${this.numToAlpha(nthColumn - 1)}4`
      ); //merge attendance status

      _.forEach(h.ref_allowances, () => {
        nthColumn++;
        indexCurrency.push(nthColumn);
      });
      worksheet.mergeCells(
        `${this.numToAlpha(
          nthColumn - h.ref_allowances.length
        )}4:${this.numToAlpha(nthColumn - 1)}4`
      ); //merge judul reference payment

      _.forEach(h.allowances, () => {
        nthColumn++;
        indexCurrency.push(nthColumn);
      });
      worksheet.mergeCells(
        `${this.numToAlpha(nthColumn - h.allowances.length)}4:${this.numToAlpha(
          nthColumn - 1
        )}4`
      ); //merge judul allowances
      nthColumn++;
      indexCurrency.push(nthColumn);
      worksheet.mergeCells(
        `${this.numToAlpha(nthColumn - 1)}4:${this.numToAlpha(nthColumn - 1)}5`
      );

      nthColumn++; //kolom kosong
      indexEmpty.push(nthColumn);

      _.forEach(h.deductions, () => {
        nthColumn++;
        indexCurrency.push(nthColumn);
      });
      worksheet.mergeCells(
        `${this.numToAlpha(nthColumn - h.deductions.length)}4:${this.numToAlpha(
          nthColumn - 1
        )}4`
      ); //merge judul deductions

      for (let i = 0; i < 2; i++) {
        nthColumn++;
        indexCurrency.push(nthColumn);
        worksheet.mergeCells(
          `${this.numToAlpha(nthColumn - 1)}4:${this.numToAlpha(
            nthColumn - 1
          )}5`
        );
      }
      nthColumn++; //kolom kosong
      indexEmpty.push(nthColumn);

      _.forEach(h.other_allowances, () => {
        nthColumn++;
        indexCurrency.push(nthColumn);
      });

      if (h.other_allowances.length) {
        worksheet.mergeCells(
          `${this.numToAlpha(
            nthColumn - h.other_allowances.length
          )}4:${this.numToAlpha(nthColumn - 1)}4`
        ); //merge judul other allowance

        nthColumn++; //loncat 1 kolom
        indexEmpty.push(nthColumn);
      }

      nthColumn++; //loncat 1 kolom
      indexCurrency.push(nthColumn);

      worksheet.mergeCells(
        `${this.numToAlpha(nthColumn - 1)}4:${this.numToAlpha(nthColumn - 1)}5`
      ); //merge kolom Take Home Pay
    });

    _.forEach(data.rows, (d) => {
      const eachRow = [
        d.no,
        d.employee_id,
        d.fullname,
        ...d.attendance_status,
        ..._.map(d.ref_allowances, (o) => o.value),
        ..._.map(d.allowances, (o) => o.value),
        d.total_allowance,
        '',
        ..._.map(d.deductions, (o) => o.value),
        d.total_deduction,
        d.net_salary,
        '',
        ..._.map(d.other_allowances, (o) => o.value),
      ];
      if (d.other_allowances.length) eachRow.push('');
      eachRow.push(d.thp);

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (indexCenter.includes(index))
          cell.alignment = { horizontal: 'center' };
        if (indexCurrency.includes(index)) {
          cell.alignment = { horizontal: 'right' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        }
        cell.alignment = { ...cell.alignment, vertical: 'middle' };

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      worksheet.getRow(rowIdx).height = 20;
      rowIdx++;
    });
    const eachRow = ['Total', '', ''];

    _.forEach(data.sum.data, (sum) => {
      eachRow.push(
        ...sum.attendance_status,
        ...sum.ref_allowances,
        ...sum.allowances,
        sum.total_allowance,
        '',
        ...sum.deductions,
        sum.total_deduction,
        sum.total_net_salary,
        '',
        ...sum.other_allowances
      );

      if (sum.other_allowances.length) eachRow.push('');
      eachRow.push(sum.total_thp);
    });

    const footer = worksheet.addRow(eachRow);
    footer.eachCell((cell, index) => {
      if (indexCenter.includes(index)) {
        cell.alignment = { horizontal: 'center' };
      }

      if (indexCurrency.includes(index)) {
        cell.alignment = { horizontal: 'right' };
        cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
      }

      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };

      cell.alignment = { ...cell.alignment, vertical: 'middle' };
      cell.font = {
        size: 13,
        bold: true,
      };
    });

    const lastRowIndex = 5 + data.rows.length + 1;

    for (const i of indexEmpty) {
      worksheet.mergeCells(
        `${this.numToAlpha(i - 1)}4:${this.numToAlpha(i - 1)}${lastRowIndex}`
      );
      worksheet.getColumn(i).width = 5;
      worksheet.getCell(`${this.numToAlpha(i - 1)}4`).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'D3D3D3' },
      };
    }
    worksheet.getColumn(1).width = 5;
    worksheet.mergeCells(`A${lastRowIndex}:C${lastRowIndex}`);
    worksheet.getRow(lastRowIndex).height = 20;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, heading + EXCEL_EXTENSION);
    });
  }

  public exportPayslip4(data: any) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const heading =
      'Pembayaran Insentif ' +
      moment(data.param.payment_date_1).format('DD MMM YYYY');

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(heading);

    worksheet.addRow([heading]);
    worksheet.addRow([data.param.payment_1]);

    worksheet.mergeCells('A1:C1');
    worksheet.mergeCells('A2:C2');

    worksheet.getCell('A1').font = { size: 13, bold: true };
    worksheet.getCell('A2').font = { size: 13, bold: true };

    worksheet.addRow([]);

    let rowIdx = 4;
    for (const [i, row] of data.data.entries()) {
      worksheet.addRow(['Grade ' + data.params[i]]);
      worksheet.mergeCells(`A${rowIdx}:C${rowIdx}`);
      worksheet.getCell('A' + rowIdx).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '01377D' },
      };
      worksheet.getCell('A' + rowIdx).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
      worksheet.getCell('A' + rowIdx).font = {
        size: 15,
        bold: true,
        color: { argb: 'ffffff' },
      };
      worksheet.getRow(rowIdx).height = 30;

      const header1 = ['No', 'Employee ID', 'Employee'];
      const header2 = ['', '', ''];

      _.forEach(row.headers, (d) => {
        if (d.attendance_status.length)
          header1.push(
            'Working Days',
            ...Array(d.attendance_status.length - 1).fill('')
          );

        header1.push(
          'Allowances',
          ...Array(d.allowances.length - 1).fill(''),
          'Total Incentive',
          '',
          'Deductions',
          ...Array(d.deductions.length - 1).fill(''),
          'Total Received Incentive',
          '',
          'BCA',
          'BRI',
          'CASH'
        );
        header2.push(
          ...d.attendance_status,
          ...d.allowances,
          '',
          '',
          ...d.deductions,
          '',
          '',
          '',
          '',
          ''
        );
      });

      worksheet.addRow(header1);
      worksheet.addRow(header2);

      rowIdx++;
      const start = rowIdx;
      const end = rowIdx + 1;
      for (let i = rowIdx; i <= end; i++) {
        for (const [index] of header1.entries()) {
          worksheet.getRow(i).getCell(index + 1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DDEBF7' },
          };
          worksheet.getRow(i).getCell(index + 1).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getRow(i).getCell(index + 1).alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
          worksheet.getRow(i).getCell(index + 1).font = {
            size: 12,
            bold: true,
          };
          worksheet.getColumn(index + 1).width =
            header1[index].length < 20 ? 30 : header1[index].length;
        }
        worksheet.getRow(rowIdx).height = 20;
        rowIdx++;
      }
      worksheet.mergeCells(`A${start}:A${end}`);
      worksheet.mergeCells(`B${start}:B${end}`);
      worksheet.mergeCells(`C${start}:C${end}`);

      const indexCenter = [1];
      const indexCurrency = [];
      const indexEmpty = [];

      _.forEach(row.headers, (h) => {
        let nthColumn = 3;
        _.forEach(h.attendance_status, () => {
          nthColumn++;
          indexCenter.push(nthColumn);
          worksheet.getColumn(nthColumn).width = 20;
        });
        worksheet.mergeCells(
          `${this.numToAlpha(
            nthColumn - h.attendance_status.length
          )}${start}:${this.numToAlpha(nthColumn - 1)}${start}`
        ); //merge working days

        _.forEach(h.allowances, () => {
          nthColumn++;
          indexCurrency.push(nthColumn);
        });

        worksheet.mergeCells(
          `${this.numToAlpha(
            nthColumn - h.allowances.length
          )}${start}:${this.numToAlpha(nthColumn - 1)}${start}`
        );

        nthColumn++;
        indexCurrency.push(nthColumn);

        worksheet.mergeCells(
          `${this.numToAlpha(nthColumn - 1)}${start}:${this.numToAlpha(
            nthColumn - 1
          )}${end}`
        );

        nthColumn++;
        indexEmpty.push(nthColumn);

        _.forEach(h.deductions, () => {
          nthColumn++;
          indexCurrency.push(nthColumn);
        });

        worksheet.mergeCells(
          `${this.numToAlpha(
            nthColumn - h.deductions.length
          )}${start}:${this.numToAlpha(nthColumn - 1)}${start}`
        );

        for (let i = 0; i < 5; i++) {
          nthColumn++;
          if (i == 1) {
            indexEmpty.push(nthColumn);
            continue;
          }
          worksheet.mergeCells(
            `${this.numToAlpha(nthColumn - 1)}${start}:${this.numToAlpha(
              nthColumn - 1
            )}${end}`
          );
          indexCurrency.push(nthColumn);
        }
      });

      _.forEach(
        row.rows,
        ({
          no,
          employee_id,
          fullname,
          attendance_status,
          allowances,
          total_received,
          deductions,
          total_insentif,
          bri,
          bca,
          cash,
        }) => {
          const eachRow = [no, employee_id, fullname];
          eachRow.push(
            ...attendance_status,
            ..._.map(allowances, (o) => o.value),
            total_insentif,
            '',
            ..._.map(deductions, (o) => o.value),
            total_received,
            '',
            bca,
            bri,
            cash
          );

          const row = worksheet.addRow(eachRow);
          row.eachCell((cell, index) => {
            if (indexCenter.includes(index))
              cell.alignment = { horizontal: 'center' };
            if (indexCurrency.includes(index)) {
              cell.alignment = { horizontal: 'right' };
              cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
            }
            cell.alignment = { ...cell.alignment, vertical: 'middle' };

            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };
          });

          worksheet.getRow(rowIdx).height = 20;
          rowIdx++;
        }
      );

      const eachRow = ['Total', '', ''];

      _.forEach(row.sum.data, (sum) => {
        eachRow.push(
          ...sum.attendance_status,
          ...sum.allowances,
          sum.total_incentive,
          '',
          ...sum.deductions,
          sum.total_received_incentive,
          '',
          sum.total_bca,
          sum.total_bri,
          sum.total_cash
        );
      });
      const footer = worksheet.addRow(eachRow);
      footer.eachCell((cell, index) => {
        if (indexCenter.includes(index)) {
          cell.alignment = { horizontal: 'center' };
        }

        if (indexCurrency.includes(index)) {
          cell.alignment = { horizontal: 'right' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        cell.alignment = { ...cell.alignment, vertical: 'middle' };
        cell.font = {
          size: 13,
          bold: true,
        };
      });

      const lastRowIndex = end + row.rows.length + 1;
      for (const i of indexEmpty) {
        worksheet.mergeCells(
          `${this.numToAlpha(i - 1)}${start}:${this.numToAlpha(
            i - 1
          )}${lastRowIndex}`
        );
        worksheet.getColumn(i).width = 5;
        worksheet.getCell(`${this.numToAlpha(i - 1)}${start}`).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D3D3D3' },
        };
      }
      worksheet.getColumn(1).width = 5;
      worksheet.mergeCells(`A${lastRowIndex}:C${lastRowIndex}`);
      worksheet.getRow(lastRowIndex).height = 20;

      rowIdx += 4;
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.addRow([]);
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, heading + EXCEL_EXTENSION);
    });
  }

  public exportPayslip5(data: any, ref_payment_name: string) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const heading =
      'Pembayaran Insentif ' +
      moment(data.param.payment_date_1).format('DD MMM YYYY');

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(heading);

    worksheet.addRow([heading]);
    worksheet.addRow([data.param.payment_1]);

    worksheet.mergeCells('A1:C1');
    worksheet.mergeCells('A2:C2');

    worksheet.getCell('A1').font = { size: 13, bold: true };
    worksheet.getCell('A2').font = { size: 13, bold: true };

    worksheet.addRow([]);
    let rowIdx = 4;
    for (const [i, row] of data.data.entries()) {
      worksheet.addRow(['Grade ' + data.params[i]]);
      worksheet.mergeCells(`A${rowIdx}:C${rowIdx}`);
      worksheet.getCell('A' + rowIdx).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '01377D' },
      };
      worksheet.getCell('A' + rowIdx).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
      worksheet.getCell('A' + rowIdx).font = {
        size: 15,
        bold: true,
        color: { argb: 'ffffff' },
      };
      worksheet.getRow(rowIdx).height = 30;

      const header1 = ['No', 'Employee ID', 'Employee'];
      const header2 = ['', '', ''];

      _.forEach(row.headers, (d) => {
        if (d.attendance_status.length) {
          header1.push(
            'Attendance Status',
            ...Array(d.attendance_status.length - 1).fill('')
          );
          header2.push(...d.attendance_status);
        }

        if (d.ref_allowances.length) {
          header1.push(
            ref_payment_name,
            ...Array(d.ref_allowances.length - 1).fill('')
          );
          header2.push(...d.ref_allowances);
        }

        header1.push(
          'Allowances',
          ...Array(d.allowances.length - 1).fill(''),
          'Total Allowance',
          '',
          'Deductions',
          ...Array(d.deductions.length - 1).fill(''),
          'Total Deduction',
          'Net Salary',
          ''
        );
        header2.push(...d.allowances, '', '', ...d.deductions, '', '', '');

        if (d.other_allowances.length) {
          header1.push(
            'Other Allowances',
            ...Array(d.other_allowances.length - 1).fill(''),
            ''
          );
          header2.push(...d.other_allowances, '');
        }
        header1.push('Take Home Pay');
      });

      worksheet.addRow(header1);
      worksheet.addRow(header2);

      rowIdx++;
      const start = rowIdx;
      const end = rowIdx + 1;

      for (let i = rowIdx; i <= end; i++) {
        for (const [index] of header1.entries()) {
          worksheet.getRow(i).getCell(index + 1).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DDEBF7' },
          };
          worksheet.getRow(i).getCell(index + 1).border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          worksheet.getRow(i).getCell(index + 1).alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
          worksheet.getRow(i).getCell(index + 1).font = {
            size: 12,
            bold: true,
          };
          worksheet.getColumn(index + 1).width =
            header1[index].length < 20 ? 30 : header1[index].length;
        }
        worksheet.getRow(rowIdx).height = 20;
        rowIdx++;
      }

      worksheet.mergeCells(`A${start}:A${end}`);
      worksheet.mergeCells(`B${start}:B${end}`);
      worksheet.mergeCells(`C${start}:C${end}`);

      const indexCenter = [1];
      const indexCurrency = [];
      const indexEmpty = [];

      _.forEach(row.headers, (h) => {
        let nthColumn = 3;
        _.forEach(h.attendance_status, () => {
          nthColumn++;
          indexCenter.push(nthColumn);
          worksheet.getColumn(nthColumn).width = 5;
        });
        worksheet.mergeCells(
          `${this.numToAlpha(
            nthColumn - h.attendance_status.length
          )}${start}:${this.numToAlpha(nthColumn - 1)}${start}`
        ); //merge attendance status

        _.forEach(h.ref_allowances, () => {
          nthColumn++;
          indexCurrency.push(nthColumn);
        });
        worksheet.mergeCells(
          `${this.numToAlpha(
            nthColumn - h.ref_allowances.length
          )}${start}:${this.numToAlpha(nthColumn - 1)}${start}`
        ); //merge judul reference payment

        _.forEach(h.allowances, () => {
          nthColumn++;
          indexCurrency.push(nthColumn);
        });
        worksheet.mergeCells(
          `${this.numToAlpha(
            nthColumn - h.allowances.length
          )}${start}:${this.numToAlpha(nthColumn - 1)}${start}`
        ); //merge judul allowances

        nthColumn++;
        indexCurrency.push(nthColumn);
        worksheet.mergeCells(
          `${this.numToAlpha(nthColumn - 1)}${start}:${this.numToAlpha(
            nthColumn - 1
          )}${end}`
        );

        nthColumn++; //kolom kosong
        indexEmpty.push(nthColumn);

        _.forEach(h.deductions, () => {
          nthColumn++;
          indexCurrency.push(nthColumn);
        });
        worksheet.mergeCells(
          `${this.numToAlpha(
            nthColumn - h.deductions.length
          )}${start}:${this.numToAlpha(nthColumn - 1)}${start}`
        ); //merge judul deductions

        for (let i = 0; i < 2; i++) {
          nthColumn++;
          indexCurrency.push(nthColumn);
          worksheet.mergeCells(
            `${this.numToAlpha(nthColumn - 1)}${start}:${this.numToAlpha(
              nthColumn - 1
            )}${end}`
          );
        }
        nthColumn++; //kolom kosong
        indexEmpty.push(nthColumn);

        _.forEach(h.other_allowances, () => {
          nthColumn++;
          indexCurrency.push(nthColumn);
        });

        if (h.other_allowances.length) {
          worksheet.mergeCells(
            `${this.numToAlpha(
              nthColumn - h.other_allowances.length
            )}${start}:${this.numToAlpha(nthColumn - 1)}${start}`
          ); //merge judul other allowance

          nthColumn++; //loncat 1 kolom
          indexEmpty.push(nthColumn);
        }

        nthColumn++; //loncat 1 kolom
        indexCurrency.push(nthColumn);

        worksheet.mergeCells(
          `${this.numToAlpha(nthColumn - 1)}${start}:${this.numToAlpha(
            nthColumn - 1
          )}${end}`
        ); //merge kolom Take Home Pay
      });

      _.forEach(row.rows, (d) => {
        const eachRow = [
          d.no,
          d.employee_id,
          d.fullname,
          ...d.attendance_status,
          ..._.map(d.ref_allowances, (o) => o.value),
          ..._.map(d.allowances, (o) => o.value),
          d.total_allowance,
          '',
          ..._.map(d.deductions, (o) => o.value),
          d.total_deduction,
          d.net_salary,
          '',
        ];
        if (d.other_allowances.length)
          eachRow.push(..._.map(d.other_allowances, (o) => o.value), '');
        eachRow.push(d.thp);

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          if (indexCenter.includes(index))
            cell.alignment = { horizontal: 'center' };
          if (indexCurrency.includes(index)) {
            cell.alignment = { horizontal: 'right' };
            cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
          }
          cell.alignment = { ...cell.alignment, vertical: 'middle' };

          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
        worksheet.getRow(rowIdx).height = 20;
        rowIdx++;
      });

      const eachRow = ['Total', '', ''];
      _.forEach(row.sum.data, (sum) => {
        eachRow.push(
          ...sum.attendance_status,
          ...sum.ref_allowances,
          ...sum.allowances,
          sum.total_allowance,
          '',
          ...sum.deductions,
          sum.total_deduction,
          sum.total_net_salary,
          ''
        );
        if (sum.other_allowances.length)
          eachRow.push(...sum.other_allowances, '');
        eachRow.push(sum.total_thp);
      });

      const footer = worksheet.addRow(eachRow);
      footer.eachCell((cell, index) => {
        if (indexCenter.includes(index)) {
          cell.alignment = { horizontal: 'center' };
        }

        if (indexCurrency.includes(index)) {
          cell.alignment = { horizontal: 'right' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        cell.alignment = { ...cell.alignment, vertical: 'middle' };
        cell.font = {
          size: 13,
          bold: true,
        };
      });

      const lastRowIndex = end + row.rows.length + 1;
      for (const i of indexEmpty) {
        worksheet.mergeCells(
          `${this.numToAlpha(i - 1)}${start}:${this.numToAlpha(
            i - 1
          )}${lastRowIndex}`
        );
        worksheet.getColumn(i).width = 5;
        worksheet.getCell(`${this.numToAlpha(i - 1)}${start}`).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'D3D3D3' },
        };
      }
      worksheet.getColumn(1).width = 5;
      worksheet.mergeCells(`A${lastRowIndex}:C${lastRowIndex}`);
      worksheet.getRow(lastRowIndex).height = 20;

      rowIdx += 4;
      worksheet.addRow([]);
      worksheet.addRow([]);
      worksheet.addRow([]);
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, heading + EXCEL_EXTENSION);
    });
  }

  public exportJournalPayslip(data: any) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const heading =
      'Jurnal_' +
      data.param.payment_1 +
      '_(' +
      moment(data.param.payment_date_1).format('DD-MM-YYYY') +
      ')';

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(heading);

    const createOuterBorder = (
      worksheet: Worksheet,
      [startCol, startRow]: [number, number],
      [endCol, endRow]: [number, number],
      style: BorderStyle = 'medium'
    ) => {
      for (let i = startRow; i <= endRow; i++) {
        const leftBorderCell = worksheet.getCell(i, startCol);
        const rightBorderCell = worksheet.getCell(i, endCol);

        leftBorderCell.border = {
          ...leftBorderCell.border,
          left: {
            style,
          },
        };

        rightBorderCell.border = {
          ...rightBorderCell.border,
          right: {
            style,
          },
        };
      }

      for (let i = startCol; i <= endCol; i++) {
        const topBorderCell = worksheet.getCell(startRow, i);
        const bottomBorderCell = worksheet.getCell(endRow, i);

        topBorderCell.border = {
          ...topBorderCell.border,
          top: {
            style,
          },
        };

        bottomBorderCell.border = {
          ...bottomBorderCell.border,
          bottom: {
            style,
          },
        };
      }
    };

    const start = 1;
    // _.forEach(data.headers[data.param.have_reference ? 1 : 0], (h, i) => {

    const allowances = data.headers[0].allowances;
    const deductions = data.headers[0].deductions;

    const sum_allowances = data.sum.data[0].allowances;
    const sum_deductions = data.sum.data[0].deductions;

    let nthRow = start;
    const row = worksheet.addRow([
      data.param['payment_1'] +
        ' (' +
        moment(data.param['payment_date_1']).format('DD-MM-YYYY') +
        ')',
    ]);
    worksheet.getCell('A' + nthRow).font = { size: 15, bold: true };
    worksheet.mergeCells(`A${nthRow}:H${nthRow}`);

    row.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFFFFF' },
      };
    });

    _.forEach(allowances, (allowance, i2) => {
      const row = worksheet.addRow([
        allowance,
        '',
        '',
        '',
        '',
        '',
        sum_allowances[i2],
        '',
      ]);

      row.eachCell((cell, index) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF' },
        };

        if (index == 7) {
          cell.alignment = { horizontal: 'right' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        }
      });
      nthRow++;
      worksheet.mergeCells(`A${nthRow}:F${nthRow}`);
    });
    _.forEach(deductions, (deduction, i2) => {
      const row = worksheet.addRow([
        '',
        deduction,
        '',
        '',
        '',
        '',
        '',
        sum_deductions[i2],
      ]);

      row.eachCell((cell, index) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFF' },
        };

        if (index == 8) {
          cell.alignment = { horizontal: 'right' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        }
      });
      nthRow++;
      worksheet.mergeCells(`B${nthRow}:F${nthRow}`);
    });

    createOuterBorder(
      worksheet,
      [start, start],
      [8, start + allowances.length + deductions.length]
    );

    // if (data.param.have_reference) {
    //   worksheet.addRow([]);
    //   worksheet.addRow([]);
    //   worksheet.addRow([]);

    //   nthRow += 3;
    // }
    // });

    worksheet.getColumn(7).width = 20; // kolom nominal
    worksheet.getColumn(8).width = 20; // kolom nominal
    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, heading + EXCEL_EXTENSION);
    });
  }

  public exportJournalPayslip2(
    data: any,
    end_date: string,
    payment_schedule: string
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const heading =
      'Journal ' +
      data.headers[0].replace(
        '(' + moment(data.dates[0]).format('DD/MM/YYYY') + ')',
        ''
      );

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Rekap Journal');

    worksheet.addRow(['PT. ARMAS LOGISTIC SERVICE']);
    worksheet.addRow(['GAJI DAN TUNJANGAN - TUNJANGAN']);
    worksheet.addRow([
      'BULAN ' +
        (end_date
          ? moment(end_date).locale('id-ID').format('MMMM YYYY').toUpperCase()
          : ''),
    ]);

    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').font = { size: 15, bold: true };
    worksheet.getCell('A3').font = { size: 15, bold: true };

    worksheet.addRow(['']);

    const header = ['Keterangan', ...data.headers];
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width = 30;
    });

    worksheet.getRow(5).height = 40;
    worksheet.addRow(['PENDAPATAN :']);

    let indexRow = 6;

    worksheet.mergeCells(
      `A${indexRow}:${this.numToAlpha(data.headers.length) + indexRow}`
    );
    worksheet.getCell(`A${indexRow}`).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell(`A${indexRow}`).alignment = { vertical: 'middle' };
    worksheet.getRow(indexRow).height = 25;

    _.forEach(data.allowances, (allowance) => {
      const row = worksheet.addRow([
        allowance.alias ? allowance.alias : allowance.name,
        ...allowance.values,
      ]);
      row.eachCell((cell, index) => {
        if (index !== 1) {
          cell.alignment = { horizontal: 'right', vertical: 'middle' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        } else {
          cell.alignment = { horizontal: 'left', vertical: 'middle' };
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      indexRow++;
      worksheet.getRow(indexRow).height = 30;
    });

    indexRow += 1;
    const totalAllowanceRow = worksheet.addRow(['TOTAL PENDAPATAN :']);
    _.forEach(data.headers, (h, i) => {
      const alphaCol = this.numToAlpha(parseInt(i) + 1);
      worksheet.getCell(alphaCol + indexRow).value = {
        formula: `SUM(${alphaCol}${
          indexRow - data.allowances.length
        }:${alphaCol}${indexRow - 1})`,
      };
    });

    totalAllowanceRow.eachCell((cell, index) => {
      if (index !== 1) {
        cell.alignment = { horizontal: 'right', vertical: 'middle' };
        cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
      } else {
        cell.alignment = { horizontal: 'left', vertical: 'middle' };
      }

      cell.font = { size: 11, bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    worksheet.getRow(indexRow).height = 25;

    const emptyRow = worksheet.addRow([
      ...Array(data.headers.length + 1).fill(''),
    ]);
    emptyRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    worksheet.getRow(indexRow + 1).height = 25;
    indexRow += 2;

    worksheet.addRow(['POTONGAN :']);

    worksheet.mergeCells(
      `A${indexRow}:${this.numToAlpha(data.headers.length) + indexRow}`
    );
    worksheet.getCell(`A${indexRow}`).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell(`A${indexRow}`).alignment = { vertical: 'middle' };
    worksheet.getRow(indexRow).height = 25;

    _.forEach(data.deductions, (deduction) => {
      const row = worksheet.addRow([
        deduction.alias ? deduction.alias : deduction.name,
        ...deduction.values,
      ]);
      row.eachCell((cell, index) => {
        if (index !== 1) {
          cell.alignment = { horizontal: 'right', vertical: 'middle' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        } else {
          cell.alignment = { horizontal: 'left', vertical: 'middle' };
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      indexRow++;
      worksheet.getRow(indexRow).height = 30;
    });

    indexRow += 1;
    const totalDeductionRow = worksheet.addRow(['TOTAL POTONGAN :']);
    _.forEach(data.headers, (h, i) => {
      const alphaCol = this.numToAlpha(parseInt(i) + 1);
      worksheet.getCell(alphaCol + indexRow).value = {
        formula: `SUM(${alphaCol}${
          indexRow - data.deductions.length
        }:${alphaCol}${indexRow - 1})`,
      };
    });
    totalDeductionRow.eachCell((cell, index) => {
      if (index !== 1) {
        cell.alignment = { horizontal: 'right', vertical: 'middle' };
        cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
      } else {
        cell.alignment = { horizontal: 'left', vertical: 'middle' };
      }

      cell.font = { size: 11, bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    worksheet.getRow(indexRow).height = 25;

    const rest = [
      {
        isFormula: false,
        content: [...Array(data.headers.length + 1).fill('')],
      },
      {
        isFormula: true,
        content: ['JUMLAH PENERIMAAN'],
      },
      {
        isFormula: false,
        content: ['KOREKSI', ...Array(data.headers.length).fill(0)],
      },
      {
        isFormula: true,
        content: ['PENERIMAAN BERSIH'],
      },
    ];
    const scheduleDate = moment(payment_schedule).format('DD');
    if (scheduleDate == '28') {
      rest.push({
        isFormula: false,
        content: [
          'KOREKSI PPH 21 DITANGGUNG PEMERINTAH',
          ...Array(data.headers.length).fill(0),
        ],
      });
    }
    rest.push(
      {
        isFormula: true,
        content: ['GRAND TOTAL'],
      },
      {
        isFormula: false,
        content: ['GRAND TOTAL REKAPAN', ...Array(data.headers.length).fill(0)],
      },
      {
        isFormula: true,
        content: ['NILAI PEMBULATAN'],
      }
    );
    _.forEach(rest, (r, i) => {
      indexRow++;

      const rowContent = r.content;
      const eachRow = worksheet.addRow(rowContent);

      if (r.isFormula) {
        _.forEach(data.headers, (h, index) => {
          const alphaCol = this.numToAlpha(parseInt(index) + 1);
          if (i == 1) {
            worksheet.getCell(alphaCol + indexRow).value = {
              formula: `${alphaCol}${
                indexRow - 2 - data.deductions.length - 3
              }-${alphaCol}${indexRow - 2}`,
            };
          }

          if (i == 3) {
            worksheet.getCell(alphaCol + indexRow).value = {
              formula: `${alphaCol}${indexRow + 12}`,
            };
          }

          if (i == 4 && scheduleDate == '15') {
            worksheet.getCell(alphaCol + indexRow).value = {
              formula: `${alphaCol}${indexRow - 1}`,
            };
          }

          if (i == 5 && scheduleDate == '28') {
            worksheet.getCell(alphaCol + indexRow).value = {
              formula: `${alphaCol}${indexRow - 1}+${alphaCol}${indexRow - 2}`,
            };
          }

          if (i == 6 && scheduleDate == '15') {
            worksheet.getCell(alphaCol + indexRow).value = {
              formula: `${alphaCol}${indexRow - 3}-${alphaCol}${indexRow - 5}`,
            };
          }
          if (i == 7 && scheduleDate == '28') {
            worksheet.getCell(alphaCol + indexRow).value = {
              formula: `${alphaCol}${indexRow - 2}-${alphaCol}${indexRow - 6}`,
            };
          }
        });
      }

      eachRow.eachCell((cell, index) => {
        if (index !== 1) {
          cell.alignment = { horizontal: 'right', vertical: 'middle' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        } else {
          cell.alignment = { horizontal: 'left', vertical: 'middle' };
        }

        cell.font = { size: 11, bold: true };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      worksheet.getRow(indexRow).height = 25;
    });

    worksheet.addRow(['']);
    indexRow++;

    const rest2 = [
      ['PEMBAYARAN', ...Array(data.headers.length).fill('')],
      [...Array(data.headers.length + 1).fill('')],
      ['BCA', ...data.bca],
      ['BRI', ...data.bri],
      ['TUNAI', ...data.cash],
      [...Array(data.headers.length + 1).fill('')],
      ['TOTAL'],
    ];

    _.forEach(rest2, (val, i) => {
      indexRow++;
      const eachRow = worksheet.addRow(val);
      if ([2, 3, 4, 6].includes(i)) {
        _.forEach(data.headers, (h, index) => {
          const alphaCol = this.numToAlpha(parseInt(index) + 1);

          if (i == 6) {
            //TOTAL
            worksheet.getCell(alphaCol + indexRow).value = {
              formula: `SUM(${alphaCol}${indexRow - 4}:${alphaCol}${
                indexRow - 2
              })`,
            };
          }
        });
      }
      eachRow.eachCell((cell, index) => {
        if (index !== 1) {
          cell.alignment = { horizontal: 'right', vertical: 'middle' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        } else {
          cell.alignment = { horizontal: 'left', vertical: 'middle' };
        }
        cell.font = { size: 11, bold: true };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      worksheet.getRow(indexRow).height = 30;
    });

    worksheet.addRow(['']);
    worksheet.addRow(['']);
    worksheet.addRow(['']);
    indexRow += 3;

    indexRow += 1;
    worksheet.addRow(['Bekasi, ' + moment().format('DD MMM YYYY')]);

    const footer = [
      ['Dibuat', 'Mengetahui', 'Menyetujui', 'Proses'],
      [
        user.employee.fullname,
        'Bpk. Teguh H',
        'Bpk. Widodo Eko Rijanto',
        'Ibu Sadkitri',
      ],
    ];
    _.forEach(footer, (val, i) => {
      const footerRow = worksheet.addRow(val);
      footerRow.eachCell((cell) => {
        cell.font = { size: 11, bold: true };
        if (i == 1) {
          cell.alignment = { horizontal: 'center' };
        } else {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    worksheet.getRow(indexRow + 1).height = 20;
    worksheet.getRow(indexRow + 2).height = 50;
    worksheet.getRow(indexRow + 3).height = 20;

    worksheet.getColumn('A').width = 50;
    worksheet.getColumn('B').width = 25;
    worksheet.getColumn('C').width = 25;
    worksheet.getColumn('D').width = 25;

    worksheet.addRow(['']);
    worksheet.addRow(['']);
    worksheet.addRow(['']);

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, heading + EXCEL_EXTENSION);
    });
  }

  public exportMergeJournal(data: any, end_date: string, isMitra: boolean) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const heading = 'Rekap Journal';

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(heading);

    worksheet.addRow(['PT. ARMAS LOGISTIC SERVICE']);
    worksheet.addRow([
      'GAJI DAN TUNJANGAN - TUNJANGAN ' + (isMitra ? 'Mitra' : ''),
    ]);
    worksheet.addRow([
      'BULAN ' +
        (end_date
          ? moment(end_date).locale('id-ID').format('MMMM YYYY').toUpperCase()
          : ''),
    ]);

    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').font = { size: 15, bold: true };
    worksheet.getCell('A3').font = { size: 15, bold: true };

    worksheet.addRow(['']);

    const header = ['Keterangan', ...data.headers];
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width = 30;
    });

    worksheet.getRow(5).height = 40;
    worksheet.addRow(['PENDAPATAN :']);

    let indexRow = 6;

    worksheet.mergeCells(
      `A${indexRow}:${this.numToAlpha(data.headers.length) + indexRow}`
    );
    worksheet.getCell(`A${indexRow}`).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell(`A${indexRow}`).alignment = { vertical: 'middle' };
    worksheet.getRow(indexRow).height = 25;

    _.forEach(data.allowances, (allowance) => {
      const row = worksheet.addRow([
        allowance.alias ? allowance.alias : allowance.name,
        ...allowance.values,
      ]);
      row.eachCell((cell, index) => {
        if (index !== 1) {
          cell.alignment = { horizontal: 'right', vertical: 'middle' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        } else {
          cell.alignment = { horizontal: 'left', vertical: 'middle' };
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      indexRow++;
      worksheet.getRow(indexRow).height = 30;
    });

    indexRow += 1;
    const totalAllowanceRow = worksheet.addRow(['TOTAL PENDAPATAN :']);
    _.forEach(data.headers, (h, i) => {
      const alphaCol = this.numToAlpha(parseInt(i) + 1);
      worksheet.getCell(alphaCol + indexRow).value = {
        formula: `SUM(${alphaCol}${
          indexRow - data.allowances.length
        }:${alphaCol}${indexRow - 1})`,
      };
    });

    totalAllowanceRow.eachCell((cell, index) => {
      if (index !== 1) {
        cell.alignment = { horizontal: 'right', vertical: 'middle' };
        cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
      } else {
        cell.alignment = { horizontal: 'left', vertical: 'middle' };
      }

      cell.font = { size: 11, bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    worksheet.getRow(indexRow).height = 25;

    const emptyRow = worksheet.addRow([
      ...Array(data.headers.length + 1).fill(''),
    ]);
    emptyRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    worksheet.getRow(indexRow + 1).height = 25;
    indexRow += 2;

    worksheet.addRow(['POTONGAN :']);

    worksheet.mergeCells(
      `A${indexRow}:${this.numToAlpha(data.headers.length) + indexRow}`
    );
    worksheet.getCell(`A${indexRow}`).border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };
    worksheet.getCell(`A${indexRow}`).alignment = { vertical: 'middle' };
    worksheet.getRow(indexRow).height = 25;

    _.forEach(data.deductions, (deduction) => {
      const row = worksheet.addRow([
        deduction.alias ? deduction.alias : deduction.name,
        ...deduction.values,
      ]);
      row.eachCell((cell, index) => {
        if (index !== 1) {
          cell.alignment = { horizontal: 'right', vertical: 'middle' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        } else {
          cell.alignment = { horizontal: 'left', vertical: 'middle' };
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      indexRow++;
      worksheet.getRow(indexRow).height = 30;
    });

    indexRow += 1;
    const totalDeductionRow = worksheet.addRow(['TOTAL POTONGAN :']);
    _.forEach(data.headers, (h, i) => {
      const alphaCol = this.numToAlpha(parseInt(i) + 1);
      worksheet.getCell(alphaCol + indexRow).value = {
        formula: `SUM(${alphaCol}${
          indexRow - data.deductions.length
        }:${alphaCol}${indexRow - 1})`,
      };
    });
    totalDeductionRow.eachCell((cell, index) => {
      if (index !== 1) {
        cell.alignment = { horizontal: 'right', vertical: 'middle' };
        cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
      } else {
        cell.alignment = { horizontal: 'left', vertical: 'middle' };
      }

      cell.font = { size: 11, bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    worksheet.getRow(indexRow).height = 25;

    const rest = [
      {
        isFormula: false,
        content: [...Array(data.headers.length + 1).fill('')],
      },
      {
        isFormula: true,
        content: ['JUMLAH PENERIMAAN'],
      },
      {
        isFormula: false,
        content: ['KOREKSI', ...Array(data.headers.length).fill(0)],
      },
      {
        isFormula: true,
        content: ['PENERIMAAN BERSIH'],
      },
      {
        isFormula: false,
        content: [
          'KOREKSI PPH 21 DITANGGUNG PEMERINTAH',
          ...Array(data.headers.length).fill(0),
        ],
      },
      {
        isFormula: true,
        content: ['GRAND TOTAL'],
      },
      {
        isFormula: false,
        content: ['GRAND TOTAL REKAPAN', ...Array(data.headers.length).fill(0)],
      },
      {
        isFormula: true,
        content: [isMitra ? 'SELISIH' : 'NILAI PEMBULATAN'],
      },
    ];
    _.forEach(rest, (r, i) => {
      indexRow++;

      const rowContent = r.content;
      const eachRow = worksheet.addRow(rowContent);

      if (r.isFormula) {
        _.forEach(data.headers, (h, index) => {
          const alphaCol = this.numToAlpha(parseInt(index) + 1);
          if (i == 1) {
            worksheet.getCell(alphaCol + indexRow).value = {
              formula: `${alphaCol}${
                indexRow - 2 - data.deductions.length - 3
              }-${alphaCol}${indexRow - 2}`,
            };
          }

          if (i == 3) {
            worksheet.getCell(alphaCol + indexRow).value = {
              formula: `${alphaCol}${indexRow + 12}`,
            };
          }

          if (i == 5) {
            worksheet.getCell(alphaCol + indexRow).value = {
              formula: `${alphaCol}${indexRow - 1}+${alphaCol}${indexRow - 2}`,
            };
          }

          if (i == 7) {
            if (isMitra) {
              worksheet.getCell(alphaCol + indexRow).value = {
                formula: `${alphaCol}${indexRow - 2}-${alphaCol}${
                  indexRow - 6
                }`,
              };
            } else {
              worksheet.getCell(alphaCol + indexRow).value = {
                formula: `ROUND(${alphaCol}${indexRow - 1},0)`,
              };
            }
          }
        });
      }

      eachRow.eachCell((cell, index) => {
        if (index !== 1) {
          cell.alignment = { horizontal: 'right', vertical: 'middle' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        } else {
          cell.alignment = { horizontal: 'left', vertical: 'middle' };
        }

        cell.font = { size: 11, bold: true };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      worksheet.getRow(indexRow).height = 25;
    });

    worksheet.addRow(['']);
    indexRow++;

    const rest2 = [
      ['PEMBAYARAN', ...Array(data.headers.length).fill('')],
      [...Array(data.headers.length + 1).fill('')],
      [
        'BCA ' +
          (isMitra
            ? 'tgl ' +
              _.map(data.dates, (date) =>
                moment(date).format('DD MMM YYYY')
              ).join(' | ')
            : ''),
        ...data.bca,
      ],
      [
        'BRI ' +
          (isMitra
            ? 'tgl ' +
              _.map(data.dates, (date) =>
                moment(date).format('DD MMM YYYY')
              ).join(' | ')
            : ''),
        ...data.bri,
      ],
      [
        'TUNAI ' +
          (isMitra
            ? 'tgl ' +
              _.map(data.dates, (date) =>
                moment(date).format('DD MMM YYYY')
              ).join(' | ')
            : ''),
        ...data.cash,
      ],
      [...Array(data.headers.length + 1).fill('')],
      ['TOTAL'],
    ];

    _.forEach(rest2, (val, i) => {
      indexRow++;
      const eachRow = worksheet.addRow(val);
      if ([2, 3, 4, 6].includes(i)) {
        _.forEach(data.headers, (h, index) => {
          const alphaCol = this.numToAlpha(parseInt(index) + 1);

          if (i == 6) {
            //TOTAL
            worksheet.getCell(alphaCol + indexRow).value = {
              formula: `SUM(${alphaCol}${indexRow - 4}:${alphaCol}${
                indexRow - 2
              })`,
            };
          }
        });
      }
      eachRow.eachCell((cell, index) => {
        if (index !== 1) {
          cell.alignment = { horizontal: 'right', vertical: 'middle' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        } else {
          cell.alignment = { horizontal: 'left', vertical: 'middle' };
        }
        cell.font = { size: 11, bold: true };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      worksheet.getRow(indexRow).height = 30;
    });

    worksheet.addRow(['']);
    worksheet.addRow(['']);
    worksheet.addRow(['']);
    indexRow += 3;

    indexRow += 1;
    worksheet.addRow(['Bekasi, ' + moment().format('DD MMM YYYY')]);

    const footer = isMitra
      ? [
          ['Dibuat', 'Diketahui', '', 'Menyetujui'],
          [
            user.employee.fullname,
            'Bpk. Teguh Hendarto',
            'Ibu Sadkitri',
            'Bpk. Widodo Eko Rijanto',
          ],
          [user.employee.job.job_title.name, 'F & A', 'HRD', 'Direktur'],
        ]
      : [
          ['Mengetahui', 'Menyetujui', 'Proses'],
          ['Ibu Titi A', 'Bpk. Widodo Eko Rijanto', 'Ibu Sadkitri'],
        ];
    _.forEach(footer, (val, i) => {
      const footerRow = worksheet.addRow(val);
      footerRow.eachCell((cell) => {
        cell.font = { size: 11, bold: true };
        if (i == 1) {
          cell.alignment = { horizontal: 'center' };
        } else {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    worksheet.getRow(indexRow + 1).height = 20;
    worksheet.getRow(indexRow + 2).height = 50;
    worksheet.getRow(indexRow + 3).height = 20;

    worksheet.getColumn('A').width = 50;
    worksheet.getColumn('B').width = 25;
    worksheet.getColumn('C').width = 25;
    worksheet.getColumn('D').width = 25;

    if (isMitra) worksheet.mergeCells(`B${indexRow + 1}:C${indexRow + 1}`);

    worksheet.addRow(['']);
    worksheet.addRow(['']);
    worksheet.addRow(['']);

    //set TOTAL Kolom

    if (data.headers.length) {
      const totalCol = this.numToAlpha(data.headers.length + 1);

      for (const index of [5, 6]) {
        const cell = worksheet.getCell(totalCol + index);
        cell.value = index == 5 ? 'TOTAL' : '';
        if (index == 5) {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'DDEBF7' },
          };
          cell.font = { size: 12, bold: true };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
          worksheet.getColumn(index).width = 30;
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }

      let idx = 7;
      data.allowances.forEach(() => {
        const cell = worksheet.getCell(totalCol + idx);
        cell.value = {
          formula: `SUM(B${idx}:${this.numToAlpha(data.headers.length)}${idx})`,
        };
        cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        cell.alignment = { vertical: 'middle' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        idx++;
      });

      for (const index of [idx, idx + 1, idx + 2]) {
        const cell = worksheet.getCell(totalCol + index);
        if (index == idx) {
          cell.value = {
            formula: `SUM(B${idx}:${this.numToAlpha(
              data.headers.length
            )}${idx})`,
          };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        } else {
          cell.value = '';
        }

        cell.alignment = { vertical: 'middle' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }

      idx += 3;
      data.deductions.forEach(() => {
        const cell = worksheet.getCell(totalCol + idx);
        cell.value = {
          formula: `SUM(B${idx}:${this.numToAlpha(data.headers.length)}${idx})`,
        };
        cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        cell.alignment = { vertical: 'middle' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        idx++;
      });

      for (const index of [
        idx,
        idx + 1,
        idx + 2,
        idx + 3,
        idx + 4,
        idx + 5,
        idx + 6,
        idx + 7,
        idx + 8,
      ]) {
        const cell = worksheet.getCell(totalCol + index);
        if (index !== idx + 1) {
          cell.value = {
            formula: `SUM(B${index}:${this.numToAlpha(
              data.headers.length
            )}${index})`,
          };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        } else {
          cell.value = '';
        }

        cell.alignment = { vertical: 'middle' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }

      idx += 10;
      for (const index of [
        idx,
        idx + 1,
        idx + 2,
        idx + 3,
        idx + 4,
        idx + 5,
        idx + 6,
      ]) {
        const cell = worksheet.getCell(totalCol + index);
        if ([idx + 2, idx + 3, idx + 4, idx + 6].includes(index)) {
          cell.value = {
            formula: `SUM(B${index}:${this.numToAlpha(
              data.headers.length
            )}${index})`,
          };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        } else {
          cell.value = '';
        }
        cell.alignment = { vertical: 'middle' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, heading + EXCEL_EXTENSION);
    });
  }

  public exportMergeTax(rows: any) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const heading = 'Tax';

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(heading);

    worksheet.addRow([...Array(17).fill('')]);
    worksheet.getRow(1).eachCell({ includeEmpty: true }, function (cell) {
      worksheet.getCell(cell.address).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '449e48' },
      };
    });
    const headerRow = worksheet.addRow([
      'No',
      'Tanggal Pemotongan',
      'Penerima Penghasilan? (NPWP/NIK)',
      'NPWP (tanpa format/tanda baca)',
      'NIK (tanpa format/tanda baca)',
      'Nama Penerima Penghasilan Sesuai NIK',
      'Alamat Penerima Penghasilan Sesuai NIK',
      'Kode Objek Pajak',
      'Penandatangan Menggunakan? (NPWP/NIK)',
      'NPWP Penandatangan (tanpa format/tanda baca)',
      'NIK Penandatangan (tanpa format/tanda baca)',
      'Kode PTKP',
      'Pegawai Harian? (Ya/Tidak)',
      'Menggunakan Gross Up? (Ya/Tidak)',
      'Penghasilan Bruto',
      'Mendapatkan Fasilitas? (N/SKB/DTP)',
      'Nomor SKB/Nomor DTP',
    ]);
    headerRow.eachCell((cell, index) => {
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
      if (index == 17) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: '0060a1' },
        };
        cell.font = { size: 12, bold: true, color: { argb: 'ffffff' } };
      } else {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        cell.font = { size: 12, bold: true };
      }

      worksheet.getColumn(index).width = 25;
    });
    worksheet.getRow(2).height = 40;

    _.forEach(rows, (d, i) => {
      const eachRow = [];
      eachRow.push(i + 1);
      eachRow.push(moment(d.end_date).format('DD/MM/YYYY'));
      eachRow.push(d.id_tax ? d.id_tax : d.id_number);
      eachRow.push(d.id_tax ? d.id_tax.replace(/[\[\]&]+/g, '') : '');
      eachRow.push(d.id_number ? d.id_number.replace(/[\[\]&]+/g, '') : '');
      eachRow.push(d.fullname || '');
      eachRow.push(d.id_address || '');
      eachRow.push('21-100-03');
      eachRow.push('NPWP');
      eachRow.push('092500883432000');
      eachRow.push('3275052306520001');
      eachRow.push(d.ptkp_code);
      eachRow.push('Tidak');
      eachRow.push('Tidak');
      eachRow.push(d.bruto);
      eachRow.push('N');
      eachRow.push('');

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if ([1, 2, 8, 9, 12, 13, 14, 16].includes(index)) {
          cell.alignment = { horizontal: 'center', vertical: 'middle' };
        } else {
          cell.alignment = { horizontal: 'left', vertical: 'middle' };
        }

        if (index == 15) {
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    worksheet.getColumn(1).width = 5;
    worksheet.getColumn(7).width = 100;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, heading + EXCEL_EXTENSION);
    });
  }

  public exportBankTransfer(
    rows: any,
    paymentDate: string,
    paymentSchedule: string,
    paymentType: string,
    grades: Array<string>,
    period: Array<string>
  ) {
    rows = _.filter(rows, (row) => row.nominal > 0);

    const scheduleDate = moment(paymentSchedule).format('DD');
    const banks = ['BRI', 'BCA', 'TUNAI'];

    const exclusives_emp = _.filter(rows, (d) =>
      ['President Director', 'Commissioner'].includes(d.job_title)
    );
    const regulars_emp = _.filter(
      rows,
      (d) => !['President Director', 'Commissioner'].includes(d.job_title)
    );

    if (exclusives_emp.length) {
      const title = 'PAYROLL BRI ' + paymentType;
      this._generateBankFormat(
        title,
        'BRI',
        grades,
        exclusives_emp,
        scheduleDate,
        paymentDate,
        period
      );
    }

    for (const bank of banks) {
      const title = 'PAYROLL ' + bank + ' ' + paymentType;
      if (bank == 'TUNAI') {
        const selected = _.filter(regulars_emp, { payment_type: 'cash' });
        if (selected.length) {
          this._generateBankFormat(
            title,
            bank,
            grades,
            selected,
            scheduleDate,
            paymentDate,
            period
          );
        }
      } else {
        const regex = new RegExp(bank, 'i');
        const selected_1 = _.filter(
          regulars_emp,
          (d) =>
            d.payment_type == 'transfer' &&
            regex.test((d.bank_name_1 || '').toUpperCase())
        );
        const selected_2 = _.filter(
          regulars_emp,
          (d) =>
            d.payment_type == 'transfer' &&
            regex.test((d.bank_name_2 || '').toUpperCase())
        );

        const reformat_1 = selected_1.map((d) => ({
          ...d,
          bankNth: '1',
        }));
        const reformat_2 = selected_2.map((d) => ({
          ...d,
          bankNth: '2',
        }));

        const selected = [...reformat_1, ...reformat_2];
        if (selected.length) {
          this._generateBankFormat(
            title,
            bank,
            grades,
            selected,
            scheduleDate,
            paymentDate,
            period
          );
        }
      }
    }
  }

  _generateBankFormat(
    title: string,
    bank: string,
    grades: Array<any>,
    data: Array<any>,
    scheduleDate: string,
    paymentDate: string,
    period: Array<any>
  ) {
    //init workbook & worksheet
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet(bank);
    const emptyArr = Array(bank == 'BCA' ? 5 : bank == 'BRI' ? 3 : 2);
    const mergeHeadingRows = (indexes) => {
      for (const val of indexes) {
        worksheet.mergeCells(
          `A${val}:${this.numToAlpha(emptyArr.length)}${val}`
        );
        worksheet.getCell('A' + val).font = { size: 15, bold: true };
        worksheet.getCell('A' + val).alignment = { horizontal: 'center' };
      }
    };

    let headers = [];
    let indexCurrency = 0;
    let endMerge = '';
    let nominalCol = '';

    switch (bank) {
      case 'BRI':
        headers = ['No', 'Nama', 'Nomor Rekening', 'Nominal'];
        indexCurrency = 4;
        endMerge = 'B';
        nominalCol = 'D';
        break;
      case 'BCA':
        headers = [
          'No',
          'Nomor Rekening',
          'Nominal',
          'NIK',
          'Nama',
          'Department',
        ];
        indexCurrency = 3;
        endMerge = 'B';
        nominalCol = 'C';
        break;
      case 'TUNAI':
        headers = ['No', 'Nama', 'Nominal'];
        indexCurrency = 3;
        endMerge = 'B';
        nominalCol = 'C';
        break;
    }

    if (
      bank == 'BRI' ||
      (bank == 'BCA' && scheduleDate == '28') ||
      bank == 'TUNAI'
    ) {
      worksheet.addRow(['']);
      worksheet.addRow(['PT. ARMAS LOGISTIC SERVICE', ...emptyArr.fill('')]);
      worksheet.addRow([
        'PAYROLL ' + (bank !== 'TUNAI' ? 'TRANSFER ' + bank : bank),
        ...emptyArr.fill(''),
      ]);
      worksheet.addRow([
        moment(paymentDate).locale('id-ID').format('DD-MMM-YY'),
        ...emptyArr.fill(''),
      ]);
      worksheet.addRow(['']);

      mergeHeadingRows([2, 3, 4]);
    } else {
      const emptyArr = Array(5);

      worksheet.addRow(['']);
      worksheet.addRow(['Daftar Payroll', ...emptyArr.fill('')]);
      worksheet.addRow(['Insentif Kelebihan Jam Kerja', ...emptyArr.fill('')]);
      worksheet.addRow([
        `Period ${_.map(period, (date) =>
          moment(date).format('DD MMM YYYY')
        ).join(' - ')}`,
        ...emptyArr.fill(''),
      ]);
      worksheet.addRow([bank]);
      worksheet.addRow(['']);

      mergeHeadingRows([2, 3, 4, 5]);
    }
    const headerRow = worksheet.addRow(headers);
    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true,
      };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width = 30;
    });

    let idxRow = bank == 'BCA' && scheduleDate == '15' ? 8 : 7;
    for (const [i, d] of data.entries()) {
      const eachRow = [];
      let bank_number = d.bank_number_1;
      let nominal = d.nominal;

      //cek split payment
      if (d?.bankNth) {
        if (d.bankNth == '1' && d.bank_value_1) {
          nominal = d.bank_value_1;
        }
        if (d.bankNth == '2') {
          if (!d.bank_value_1) {
            continue;
          }
          nominal = d.nominal - d.bank_value_1;
          bank_number = d.bank_number_2;
        }
      }

      switch (bank) {
        case 'BRI':
          eachRow.push(i + 1, d.fullname, bank_number, nominal);
          break;
        case 'BCA':
          eachRow.push(
            i + 1,
            bank_number,
            nominal,
            d.employee_id,
            d.fullname,
            d.department ?? ''
          );
          break;
        case 'TUNAI':
          eachRow.push(i + 1, d.fullname, nominal);
          break;
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) {
          cell.alignment = { horizontal: 'center' };
        }

        if (index == indexCurrency) {
          cell.alignment = { horizontal: 'right' };
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        }
        cell.alignment = { ...cell.alignment, vertical: 'middle' };

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      worksheet.getRow(idxRow).height = 20;
      idxRow++;
    }

    const start = bank == 'BCA' && scheduleDate == '15' ? 8 : 7;
    const headerIndex = bank == 'BCA' && scheduleDate == '15' ? 7 : 6;
    const lastIndex = headerIndex + data.length + 1;

    const summary = ['Total', ...Array(headers.length - 1).fill('')];
    const summaryRow = worksheet.addRow(summary);

    worksheet.getCell(nominalCol + lastIndex).value = {
      formula: `SUM(${nominalCol}${start}:${nominalCol}${
        headerIndex + data.length
      })`,
    };
    worksheet.mergeCells(`A${lastIndex}:${endMerge}${lastIndex}`);

    summaryRow.eachCell((cell, index) => {
      if (index == 1) {
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
      }

      if (index == indexCurrency) {
        cell.alignment = { horizontal: 'right' };
        cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
      }

      cell.alignment = { ...cell.alignment, vertical: 'middle' };
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    worksheet.getRow(headerIndex).height = 30;
    worksheet.getRow(lastIndex).height = 20;
    worksheet.getColumn('A').width = 5;

    if (
      bank == 'BCA' &&
      (grades.includes('I') || grades.includes('II') || grades.includes('III'))
    ) {
      const worksheet2 = workbook.addWorksheet('System Payroll BCA');
      worksheet2.addRow([
        'Acc. No.',
        'Trans. Amount',
        'emp.Number',
        'emp.Name',
        'Dept',
        'Trans. Date',
      ]);
      worksheet2
        .getRow(1)
        .eachCell({ includeEmpty: true }, function (cell, colNumber) {
          worksheet2.getCell(cell.address).font = { size: 13, bold: true };
          worksheet2.getCell(cell.address).alignment = {
            horizontal: 'center',
            vertical: 'middle',
          };
          worksheet2.getColumn(colNumber).width = 25;
        });
      worksheet2.getRow(1).height = 20;

      let i = 1;
      for (const d of data) {
        let bank_number = d.bank_number_1;
        let nominal = d.nominal;

        //cek split payment
        if (d?.bankNth) {
          if (d.bankNth == '1' && d.bank_value_1) {
            nominal = d.bank_value_1;
          }
          if (d.bankNth == '2') {
            if (!d.bank_value_1) {
              continue;
            }
            nominal = d.nominal - d.bank_value_1;
            bank_number = d.bank_number_2;
          }
        }

        const row = worksheet2.addRow([
          bank_number,
          nominal,
          d.employee_id,
          d.fullname,
          d.department,
          moment(paymentDate).format('DD/MM/YYYY'),
        ]);

        row.eachCell((cell, index) => {
          if (index == 2) {
            cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
          }

          cell.alignment = { horizontal: 'center', vertical: 'middle' };

          if (index == 6) cell.numFmt = 'dd/mm/yyyy';
          worksheet2.getRow(i).height = 18;
        });
        i++;
      }
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, title + EXCEL_EXTENSION);
    });
  }

  public templateClosing() {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const heading = 'Closing Template';

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(heading);

    const header = ['Order Number', 'Note'];

    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');
    worksheet.getCell('A1').value = heading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.addRow(['Fill data below, and export it on closing menu']);
    worksheet.getCell('A2').font = { size: 10, italic: true };
    worksheet.addRow([]);

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width = 25;
    });
    worksheet.getRow(5).height = 20;
    worksheet.getRow(6).height = 20;
    worksheet.getRow(7).height = 20;
    for (const cell of ['A5', 'B5', 'A6', 'B6', 'A7', 'B7']) {
      worksheet.getCell(cell).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    }

    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, heading + EXCEL_EXTENSION);
    });
  }

  public templateDeliveryOrder() {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const heading = 'Delivery Order Template Upload';

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(heading);

    const header = [
      'Delivery Number',
      'Customer',
      'Date',
      'Route Code - Cycle',
      'Remark',
      'Cycle',
    ];

    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');
    worksheet.getCell('A1').value = heading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.addRow([
      '**Fill data below with exact default format. First row is example.',
    ]);
    worksheet.getCell('A2').font = { size: 10, italic: true };
    worksheet.addRow([]);

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width = 25;
    });

    worksheet.getRow(4).height = 30;

    const rows = [
      [
        'R202204231449',
        'TMMIS1',
        moment().format('DD/MM/YYYY'),
        'M5JP - 01',
        'Write Remark Here',
        'Write Note Here',
      ],
      ['', '', '', '', '', ''],
      ['', '', '', '', '', ''],
    ];
    _.forEach(rows, (data) => {
      const row = worksheet.addRow(data);
      row.eachCell((cell, index) => {
        if (index == 3) {
          cell.numFmt = 'dd/mm/yyyy';
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, heading + EXCEL_EXTENSION);
    });
  }

  formatUploadOvertime(dates, data) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Overtime');

    const header1 = ['Employee'];
    const header2 = [''];
    for (const date of dates) {
      header1.push(moment(date).tz('Asia/Jakarta').format('DD/MM/YYYY'), '');
      header2.push('Start Time', 'End Time');
    }

    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
    worksheet.getCell('A1').value = 'Format Upload Overtime';
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.addRow(['*Format Time hour:minute (Example 20:00)']);
    worksheet.getCell('A2').alignment = { vertical: 'middle' };
    worksheet.getCell('A2').font = { size: 11, italic: true };
    worksheet.addRow([]);

    const firstHeader = worksheet.addRow(header1);
    const secondHeader = worksheet.addRow(header2);

    firstHeader.eachCell((cell, index) => {
      if (index !== 1 && index % 2 !== 0) {
        worksheet.mergeCells(
          `${this.numToAlpha(index - 2)}4:${this.numToAlpha(index - 1)}4`
        );
      }

      if (index !== 1) cell.numFmt = 'dd/mm/yyyy';
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'BDD7EE' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.font = { size: 12, bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      worksheet.getColumn(index).width = 25;
    });
    secondHeader.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'BDD7EE' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width = 25;
    });

    worksheet.mergeCells('A4:A5');

    _.forEach(data, (obj) => {
      const eachRow = [obj.employee.fullname];
      for (const detail of obj.details) {
        eachRow.push(detail.overtime.start ?? '');
        eachRow.push(detail.overtime.end ?? '');
      }

      eachRow.push('', '', '', obj.employee.id);
      console.log(obj.employee.id, eachRow);
      const row = worksheet.addRow(eachRow);

      row.eachCell((cell, index) => {
        if (index !== 1) {
          cell.alignment = { horizontal: 'center' };
        } else {
          cell.alignment = { horizontal: 'left' };
        }
        if (index > obj.details.length * 2 + 4) {
          cell.font = { color: { argb: 'ffffff' } };
        }

        if (index <= obj.details.length * 2 + 1) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        }

        worksheet.getColumn(index).width =
          eachRow[index - 1].length < 20 ? 20 : eachRow[index - 1].length + 10;
      });
    });

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'format-upload-overtime' + EXCEL_EXTENSION);
    });
  }

  templatePurchaseRequest() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet('Purchase Request');

    const header1 = ['Date', 'Req. Date', 'Note'];
    const header2 = ['Item', 'Quantity'];

    const firstHeader = worksheet.addRow(header1);
    firstHeader.eachCell((cell, index) => {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { horizontal: 'center' };
      worksheet.getColumn(index).width = 25;
    });

    const row1 = [
      moment().tz('Asia/Jakarta').toDate(),
      moment().tz('Asia/Jakarta').toDate(),
      'Note Test',
    ];
    const firstRow = worksheet.addRow(row1);

    firstRow.eachCell((cell, index) => {
      if (index !== 3) cell.numFmt = 'dd/mm/yyyy';
      cell.alignment = { horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    worksheet.addRow([]);
    const secondHeader = worksheet.addRow(header2);
    secondHeader.eachCell((cell, index) => {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { horizontal: 'center' };
      worksheet.getColumn(index).width = 25;
    });

    const dataTest = [
      { item: 'Test 11', qty: 5 },
      { item: 'Test 22', qty: 3 },
      { item: 'Test 33', qty: 12 },
      { item: 'Test 44', qty: 7 },
      { item: 'Test 55', qty: 4 },
    ];
    _.forEach(dataTest, (obj) => {
      const eachRow = [];
      eachRow.push(obj.item, obj.qty);
      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getColumn(index).width = 25;
      });
    });

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'format-upload-PR' + EXCEL_EXTENSION);
    });
  }

  public templatePurchaseOrder() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Purchase Orders');

    const header1 = ['Date', 'Vendor', 'Address'];
    const header2 = ['Item', 'Quantity'];

    const firstHeader = worksheet.addRow(header1);
    firstHeader.eachCell((cell, index) => {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { horizontal: 'center' };
      worksheet.getColumn(index).width = 25;
    });

    const row1 = [
      moment().tz('Asia/Jakarta').toDate(),
      'Jiwalu',
      'Telukjambe Timur, Karawang',
    ];
    const firstRow = worksheet.addRow(row1);

    firstRow.eachCell((cell, index) => {
      if (index !== 3) cell.numFmt = 'dd/mm/yyyy';
      cell.alignment = { horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    worksheet.addRow([]);
    const secondHeader = worksheet.addRow(header2);
    secondHeader.eachCell((cell, index) => {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { horizontal: 'center' };
      worksheet.getColumn(index).width = 30;
    });

    const dataTest = [
      { item: 'Test 11', qty: 5 },
      { item: 'Test 22', qty: 3 },
      { item: 'Test 33', qty: 12 },
      { item: 'Test 44', qty: 7 },
      { item: 'Test 55', qty: 4 },
    ];
    _.forEach(dataTest, (obj) => {
      const eachRow = [];
      eachRow.push(obj.item, obj.qty);

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        worksheet.getColumn(index).width = 30;
      });
    });

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'format-upload-PO' + EXCEL_EXTENSION);
    });
  }

  public templateReceiveItems() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Receive Items');

    const header1 = ['Date', 'Vendor', '# Invoice'];
    const header2 = ['Item', 'Quantity', 'Warehouse'];

    const firstHeader = worksheet.addRow(header1);
    firstHeader.eachCell((cell, index) => {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { horizontal: 'center' };
      worksheet.getColumn(index).width = 30;
    });

    const row1 = [moment().tz('Asia/Jakarta').toDate(), 'Jiwalu', 'INV.0001'];
    const firstRow = worksheet.addRow(row1);

    firstRow.eachCell((cell, index) => {
      if (index !== 3) cell.numFmt = 'dd/mm/yyyy';
      cell.alignment = { horizontal: 'center' };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    worksheet.addRow([]);
    const secondHeader = worksheet.addRow(header2);
    secondHeader.eachCell((cell, index) => {
      cell.font = { size: 12, bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { horizontal: 'center' };
      worksheet.getColumn(index).width = 25;
    });

    const dataTest = [
      { item: 'Test 11', qty: 5, warehouse: 'Gudang Cikarang' },
      { item: 'Test 22', qty: 3, warehouse: 'Gudang Cikarang' },
      { item: 'Test 33', qty: 12, warehouse: 'Utama' },
      { item: 'Test 44', qty: 7, warehouse: 'Utama' },
      { item: 'Test 55', qty: 4, warehouse: 'Utama' },
    ];
    _.forEach(dataTest, (obj) => {
      const eachRow = [];
      eachRow.push(obj.item, obj.qty, obj.warehouse);

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        worksheet.getColumn(index).width = 30;
      });
    });

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'format-upload-receive-items' + EXCEL_EXTENSION);
    });
  }

  public exportInspections(
    reportHeading: string,
    excelFileName: string,
    data: any,
    date: any,
    type: string
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    let headers = [];
    if (type == 'man_power_abnormal') {
      headers = [
        'No',
        'Date',
        'Time',
        'Truck Number',
        'Driver',
        'Employee ID',
        'Coordinator',
        'Coord. Job Title',
        'Route',
        'Session',
        'Component',
        'Status',
        'Closed',
        'Note',
      ];
    } else if (type == 'inspector_abnormal') {
      headers = [
        'No',
        'Date',
        'Time',
        'Truck Number',
        'Inspector',
        'Driver',
        'Employee ID',
        'Coordinator',
        'Coord. Job Title',
        'Route',
        'Session',
        'Component',
        'Man Power Status',
        'Inspector Status',
        'Closed',
        'Note',
      ];
    } else if (type == 'license_expired') {
      headers = [
        'No',
        'Truck Number',
        'Brand',
        'Model',
        'License Plate Number',
        'License Status',
        'License Expired',
        'KIR Status',
        'KIR Expired',
      ];
    } else {
      headers = [
        'No',
        'Truck Number',
        'Delivery Date',
        'Departure Plan',
        'Arrival Plan',
        'Driver',
        'Employee ID',
      ];
    }
    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(headers.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    //header & style
    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        headers[index - 1].length < 20 ? 20 : headers[index - 1].length;
    });

    _.forEach(data, (obj, index) => {
      const eachRow = [];
      eachRow.push((index + 1).toString());

      if (type == 'man_power_abnormal') {
        eachRow.push(
          moment(obj.activity.date).tz('Asia/Jakarta').format('DD/MM/YYYY')
        );
        eachRow.push(
          moment(obj.activity.created_at).tz('Asia/Jakarta').format('HH:mm')
        );
        eachRow.push(obj.activity.vehicle.number);
        eachRow.push(obj.activity.employee.fullname);
        eachRow.push(obj.activity.employee.employee_id);
        eachRow.push(
          obj.activity.employee.fieldman?.coordinator?.fullname || '-'
        );
        eachRow.push(
          obj.activity.employee.fieldman?.coordinator?.job?.job_title?.name ||
            '-'
        );
        eachRow.push(obj.activity.delivery?.template?.name || '-');

        eachRow.push(obj.activity.type.type);
        eachRow.push(obj.name);
        eachRow.push(obj.value);
        eachRow.push(
          obj.activity.reference?.status &&
            obj.activity.reference?.status == 'closed'
            ? moment(obj.activity.reference.updated_at)
                .tz('Asia/Jakarta')
                .format('DD/MM/YYYY')
            : '-'
        );
        eachRow.push('');
      } else if (type == 'inspector_abnormal') {
        eachRow.push(moment(obj.date).tz('Asia/Jakarta').format('DD/MM/YYYY'));
        eachRow.push(moment(obj.created_at).tz('Asia/Jakarta').format('HH:mm'));
        eachRow.push(obj.vehicle_number);
        eachRow.push(obj.inspector);
        eachRow.push(obj.driver);
        eachRow.push(obj.driver_id);
        eachRow.push(obj.fieldman?.coordinator?.fullname || '');
        eachRow.push(obj.fieldman?.coordinator?.job?.job_title?.name || '');
        eachRow.push(obj.template_name || '');
        eachRow.push(obj.session);
        eachRow.push(obj.component);
        eachRow.push(obj.driver_status);
        eachRow.push(obj.inspector_status);
        eachRow.push(
          obj.close
            ? moment(obj.close).tz('Asia/Jakarta').format('DD/MM/YYYY')
            : '-'
        );
        eachRow.push('');
      } else if (type == 'license_expired') {
        eachRow.push(obj.number ?? '-');
        eachRow.push(obj.brand ?? '-');
        eachRow.push(obj.brand_model ?? '-');
        eachRow.push(obj.license_plate_number ?? '-');
        eachRow.push(obj.license_status);
        eachRow.push(
          moment(obj.license_expired).tz('Asia/Jakarta').format('DD/MM/YYYY')
        );
        eachRow.push(obj.kir_status);
        eachRow.push(
          moment(obj.kir_expired).tz('Asia/Jakarta').format('DD/MM/YYYY')
        );
      } else {
        eachRow.push(obj.vehicle.number);
        eachRow.push(
          moment(obj.delivery.date).tz('Asia/Jakarta').format('DD MMM YYYY')
        );
        eachRow.push(
          moment(obj.delivery.departure_plan)
            .tz('Asia/Jakarta')
            .format('DD MMM YYYY, HH:mm')
        );
        eachRow.push(
          moment(obj.delivery.arrival_plan)
            .tz('Asia/Jakarta')
            .format('DD MMM YYYY, HH:mm')
        );
        eachRow.push(obj.employee.fullname);
        eachRow.push(obj.employee.employee_id);
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (
          (type == 'man_power_abnormal' && [3, 12].includes(index)) ||
          (type == 'inspector_abnormal' && [3, 13, 14].includes(index))
        ) {
          cell.alignment = { horizontal: 'center' };
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        if (index !== 1) {
          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        } else {
          cell.alignment = { horizontal: 'center' };
        }
      });
    });
    worksheet.getColumn('A').width = 5;
    worksheet.getRow(4).height = 30;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public formatPurchaseRequest() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Purchase Request');

    const header = [
      'Request Date',
      'Required Date',
      'Request No.',
      'Item No.',
      'Item Description',
      'Quantity',
    ];

    worksheet.addRow(['*First row is example.']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });

    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 20; //B
    worksheet.getColumn(3).width = 20; //C
    worksheet.getColumn(4).width = 15; //D
    worksheet.getColumn(5).width = 60; //E
    worksheet.getColumn(6).width = 15; //F

    worksheet.getRow(2).height = 25;

    for (let i = 0; i < 100; i++) {
      const eachRow = [];
      if (i == 0) {
        eachRow.push(
          moment().format('DD/MM/YYYY'),
          moment().format('DD/MM/YYYY'),
          'RO/VII/2023/0379',
          'PRT.000008',
          'BAN DALAM 9.00-20 # GAJAH TUNGGAL # FG235/210/PK-215/FUSO',
          '15'
        );
      } else {
        eachRow.push('', '', '', '', '', '');
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1 || index == 2) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-purchase-request' + EXCEL_EXTENSION);
    });
  }

  public formatPurchaseOrder() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Purchase Order');

    const header = [
      'PO Date',
      'PO No.',
      'Vendor No.',
      'Request No.',
      'Item No.',
      'Quantity',
      'Unit',
      'Price',
      'Discount Cash',
      'Freigth Account No.',
      'Freight',
      'Tax 1 Base Currency',
      'Tax 2 Base Currency',
    ];

    worksheet.addRow(['*First row is example.']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });

    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 25; //B
    worksheet.getColumn(3).width = 25; //C
    worksheet.getColumn(4).width = 25; //D
    worksheet.getColumn(5).width = 25; //E
    worksheet.getColumn(6).width = 15; //F
    worksheet.getColumn(7).width = 15; //G
    worksheet.getColumn(8).width = 25; //H
    worksheet.getColumn(9).width = 25; //I
    worksheet.getColumn(10).width = 35; //J
    worksheet.getColumn(11).width = 25; //J
    worksheet.getColumn(12).width = 35; //J
    worksheet.getColumn(13).width = 35; //J

    worksheet.getRow(2).height = 25;

    for (let i = 0; i < 100; i++) {
      const eachRow = [];
      if (i == 0) {
        eachRow.push(
          moment().format('DD/MM/YYYY'),
          'PO/VII/2023/1000',
          'V.00005',
          'RO/VII/2023/3000',
          'PRT.000008',
          '10',
          'PCS',
          '',
          '',
          '',
          '',
          '',
          ''
        );
      } else {
        eachRow.push('', '', '', '', '', '', '', '', '', '', '', '', '');
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-purchase-order' + EXCEL_EXTENSION);
    });
  }

  public formatPurchaseInvoice() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Purchase Invoice');

    const header = [
      'Invoice Date',
      'Invoice No.',
      'Bill No.',
      'Vendor No.',
      'Request No.',
      'PO No.',
      'Receive No.',
      'Item No.',
      'Quantity',
      'Unit',
      'Price',
      'Warehouse Name',
      'Down Payment',
      'Tax Date',
      'Tax Number',
      'Desc',
      'Inv Disc',
      'Inv Payment Amount',
    ];

    worksheet.addRow(['*First row is example.']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });

    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 20; //B
    worksheet.getColumn(3).width = 20; //C
    worksheet.getColumn(4).width = 20; //C
    worksheet.getColumn(5).width = 20; //D
    worksheet.getColumn(6).width = 20; //E
    worksheet.getColumn(7).width = 20; //F
    worksheet.getColumn(8).width = 15; //G
    worksheet.getColumn(9).width = 20; //H
    worksheet.getColumn(10).width = 15; //I
    worksheet.getColumn(11).width = 15; //J
    worksheet.getColumn(12).width = 25; //K
    worksheet.getColumn(13).width = 25; //L
    worksheet.getColumn(14).width = 30; //M
    worksheet.getColumn(15).width = 30; //N
    worksheet.getColumn(16).width = 30; //O
    worksheet.getColumn(17).width = 30; //P
    worksheet.getColumn(18).width = 30; //Q

    worksheet.getRow(2).height = 25;

    for (let i = 0; i < 100; i++) {
      const eachRow = [];
      if (i == 0) {
        eachRow.push(
          moment().format('DD/MM/YYYY'),
          'PI/VI/2023/1000',
          '#001',
          'V.00005',
          '',
          'PO/VII/2023/3000',
          '',
          'PRT.000008',
          '100',
          'PCS',
          '500',
          'WH A',
          0,
          moment().format('DD/MM/YYYY'),
          '',
          '',
          '',
          ''
        );
      } else {
        eachRow.push(
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          ''
        );
      }
      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1 || index == 14) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-purchase-invoice' + EXCEL_EXTENSION);
    });
  }

  public formatReceiveItem() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Receive Items');

    const header = [
      'Receive Date',
      'Receive No.',
      'Vendor No.',
      'PO No.',
      'Request No.',
      'Invoice No.',
      'Item No.',
      'Quantity',
      'Warehouse Name',
    ];

    worksheet.addRow(['*First row is example.']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });

    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 20; //B
    worksheet.getColumn(3).width = 20; //C
    worksheet.getColumn(4).width = 20; //C
    worksheet.getColumn(5).width = 20; //D
    worksheet.getColumn(6).width = 25; //E
    worksheet.getColumn(7).width = 20; //F
    worksheet.getColumn(8).width = 15; //G
    worksheet.getColumn(9).width = 25; //H

    worksheet.getRow(2).height = 25;

    for (let i = 0; i < 100; i++) {
      const eachRow = [];
      if (i == 0) {
        eachRow.push(
          moment().format('DD/MM/YYYY'),
          'RI/VII/2023/0450',
          'V.00005',
          'PO/VII/2023/1000',
          'RO/VII/2023/3000',
          '',
          'PRT.000008',
          '15',
          'WH A'
        );
      } else {
        eachRow.push('', '', '', '', '', '', '', '', '');
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-receive-item' + EXCEL_EXTENSION);
    });
  }

  public formatSalesInvoice() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Sales Invoice');

    const header = [
      'Invoice Date',
      'Invoice No.',
      'Customer No.',
      'Item No.',
      'Unit Price',
      'Unit Name',
      'Quantity',
      'Warehouse Name',
      'Tax Date',
      'Tax Number',
      'Desc',
    ];

    worksheet.addRow(['*First row is example.']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });

    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 20; //B
    worksheet.getColumn(3).width = 20; //C
    worksheet.getColumn(4).width = 20; //D
    worksheet.getColumn(5).width = 25; //E
    worksheet.getColumn(6).width = 20; //F
    worksheet.getColumn(7).width = 15; //G
    worksheet.getColumn(8).width = 25; //H
    worksheet.getColumn(9).width = 25; //I
    worksheet.getColumn(10).width = 25; //J
    worksheet.getColumn(11).width = 30; //K

    worksheet.getRow(2).height = 25;

    for (let i = 0; i < 100; i++) {
      const eachRow = [];
      if (i == 0) {
        eachRow.push(
          moment().format('DD/MM/YYYY'),
          'ALS/INV/2023/001',
          'C.00001',
          'PRT.000008',
          '1500',
          'PCS',
          '15',
          'WH A',
          moment().format('DD/MM/YYYY'),
          '112233444',
          ''
        );
      } else {
        eachRow.push('', '', '', '', '', '', '', '', '', '', '');
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1 || index == 9) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-sales-invoice' + EXCEL_EXTENSION);
    });
  }

  public formatSalesReceipt() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Sales Receipt');

    const header = [
      'Receipt Date',
      'Receipt No.',
      'Bank No.',
      'Customer No.',
      'Amount',
      'Invoice No.',
      'Payment',
    ];

    worksheet.addRow(['*First row is example.']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });

    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 20; //B
    worksheet.getColumn(3).width = 20; //C
    worksheet.getColumn(4).width = 20; //D
    worksheet.getColumn(5).width = 20; //E
    worksheet.getColumn(6).width = 20; //F
    worksheet.getColumn(7).width = 20; //G

    worksheet.getRow(2).height = 25;

    for (let i = 0; i < 100; i++) {
      const eachRow = [];
      if (i == 0) {
        eachRow.push(
          moment().format('DD/MM/YYYY'),
          'SR/ALS/2023/001',
          '123456789',
          'C.00001',
          '22500',
          'ALS/INV/2023/001',
          '22500'
        );
      } else {
        eachRow.push('', '', '', '', '', '', '');
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-sales-receipt' + EXCEL_EXTENSION);
    });
  }

  public formatOtherPayment() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Other Payment');

    const header = [
      'Payment Date',
      'Payment No.',
      'Bank No.',
      'Account No.',
      'Amount',
      'Expense Name',
      'Notes',
    ];

    worksheet.addRow(['*First row is example.']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });

    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 20; //B
    worksheet.getColumn(3).width = 20; //C
    worksheet.getColumn(4).width = 20; //D
    worksheet.getColumn(5).width = 20; //E
    worksheet.getColumn(6).width = 20; //F
    worksheet.getColumn(7).width = 20; //G

    worksheet.getRow(2).height = 25;

    for (let i = 0; i < 100; i++) {
      const eachRow = [];
      if (i == 0) {
        eachRow.push(
          moment().format('DD/MM/YYYY'),
          'OP/ALS/2023/001',
          '123456789',
          '1104',
          '50000',
          'Pembelian',
          'untuk stok barang'
        );
      } else {
        eachRow.push('', '', '', '', '', '', '');
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-other-payment' + EXCEL_EXTENSION);
    });
  }

  public formatOtherDeposit() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Other Deposit');

    const header = [
      'Deposit Date',
      'Deposit No.',
      'Bank No.',
      'Account No.',
      'Amount',
      'Expense Name',
      'Notes',
    ];

    worksheet.addRow(['*First row is example.']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });

    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 20; //B
    worksheet.getColumn(3).width = 20; //C
    worksheet.getColumn(4).width = 20; //D
    worksheet.getColumn(5).width = 20; //E
    worksheet.getColumn(6).width = 20; //F
    worksheet.getColumn(7).width = 25; //G

    worksheet.getRow(2).height = 25;

    for (let i = 0; i < 100; i++) {
      const eachRow = [];
      if (i == 0) {
        eachRow.push(
          moment().format('DD/MM/YYYY'),
          'OD/ALS/2023/001',
          '123456789',
          '1104',
          '50000',
          'Deposit',
          'untuk deposit berjangka'
        );
      } else {
        eachRow.push('', '', '', '', '', '', '');
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-other-deposit' + EXCEL_EXTENSION);
    });
  }

  public formatExpenseAccrual() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Expense Accrual');

    const header = [
      'Expense Date',
      'Expense No.',
      'Due Date',
      'Expense Account No.',
      'Account No.',
      'Amount',
      'Expense Name',
      'Notes',
    ];

    worksheet.addRow(['*First row is example.']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });

    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 20; //B
    worksheet.getColumn(3).width = 20; //C
    worksheet.getColumn(4).width = 30; //D
    worksheet.getColumn(5).width = 20; //E
    worksheet.getColumn(6).width = 20; //F
    worksheet.getColumn(7).width = 20; //G
    worksheet.getColumn(8).width = 20; //G

    worksheet.getRow(2).height = 25;

    for (let i = 0; i < 100; i++) {
      const eachRow = [];
      if (i == 0) {
        eachRow.push(
          moment().format('DD/MM/YYYY'),
          'EA/ALS/2023/001',
          moment().format('DD/MM/YYYY'),
          '1117',
          '6999',
          '1200000',
          'Pembelian',
          'pembelian lainnya'
        );
      } else {
        eachRow.push('', '', '', '', '', '', '', '');
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1 || index == 3) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-expense-accrual' + EXCEL_EXTENSION);
    });
  }

  public formatJournalVoucher() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Journal Voucher');

    const header = [
      'Journal Date',
      'Journal No.',
      'Account No.',
      'Amount',
      'Type',
      'Customer No.',
      'Vendor No.',
      'Notes',
    ];

    worksheet.addRow(['*Example :']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });

    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 20; //B
    worksheet.getColumn(3).width = 20; //C
    worksheet.getColumn(4).width = 30; //D
    worksheet.getColumn(5).width = 20; //E
    worksheet.getColumn(6).width = 20; //F
    worksheet.getColumn(7).width = 20; //G
    worksheet.getColumn(8).width = 30; //G

    worksheet.getRow(2).height = 25;

    for (let i = 0; i < 100; i++) {
      const eachRow = [];

      switch (i) {
        case 0:
          eachRow.push(
            moment().format('DD/MM/YYYY'),
            'JV/ALS/2023/001',
            '110301',
            '50000',
            'DEBIT',
            'C.00001',
            '',
            'catatan journal debit'
          );
          break;
        case 1:
          eachRow.push(
            moment().format('DD/MM/YYYY'),
            'JV/ALS/2023/001',
            '210102',
            '50000',
            'CREDIT',
            '',
            'V.00005',
            'catatan journal credit'
          );
          break;
        default:
          eachRow.push('', '', '', '', '', '', '', '');
          break;
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-journal-voucher' + EXCEL_EXTENSION);
    });
  }

  public formatPurchasePayment() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Purchase Payment');

    const header = [
      'Payment Date',
      'Payment No.',
      'Payment Amount',
      'Vendor No.',
      'Bank No.',
      'Invoice No.',
      'Invoice Payment',
      'Discount Amount',
      'Discount Account',
      'Desc',
    ];

    worksheet.addRow(['*Example :']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });

    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 20; //B
    worksheet.getColumn(3).width = 20; //C
    worksheet.getColumn(4).width = 30; //D
    worksheet.getColumn(5).width = 20; //E
    worksheet.getColumn(6).width = 20; //F
    worksheet.getColumn(7).width = 20; //G
    worksheet.getColumn(8).width = 30; //H
    worksheet.getColumn(9).width = 25; //I
    worksheet.getColumn(10).width = 25; //J

    worksheet.getRow(2).height = 25;

    for (let i = 0; i < 100; i++) {
      const eachRow = [];

      if (i == 0) {
        eachRow.push(
          moment().format('DD/MM/YYYY'),
          'PO.123',
          1000,
          'V.00001',
          '110102',
          '11223344',
          1000,
          '',
          '',
          ''
        );
      } else {
        eachRow.push('', '', '', '', '', '', '', '', '', '');
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-purchase-payment' + EXCEL_EXTENSION);
    });
  }
  // public templateUploadPI() {
  //   const user = JSON.parse(localStorage.getItem('alsuser'));

  //   const workbook = new Workbook();
  //   workbook.creator = user.employee.fullname;
  //   workbook.lastModifiedBy = user.employee.fullname;
  //   workbook.created = new Date();
  //   workbook.modified = new Date();
  //   const worksheet = workbook.addWorksheet('Upload RI');

  //   const header = [
  //     'Invoice No.',
  //     'Invoice.',
  //     'Receive',
  //     'Receipt No.',
  //     'Purchase Order PO',
  //     'Purchase Order PO Date',
  //     'Item No.',
  //     'Qty. Inv.',
  //     'Quantity',
  //     'Warehouse Name',
  //   ];

  //   worksheet.addRow(['*First row is example.']);
  //   worksheet.getCell('A1').font = { size: 10, italic: true };
  //   worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

  //   const headerRow = worksheet.addRow(header);
  //   headerRow.eachCell((cell, index) => {
  //     cell.border = {
  //       top: { style: 'thin' },
  //       bottom: { style: 'thin' },
  //       left: { style: 'thin' },
  //       right: { style: 'thin' },
  //     };
  //     cell.alignment = { vertical: 'middle', horizontal: 'center' };
  //     cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
  //   });

  //   worksheet.getColumn(1).width = 20; //A
  //   worksheet.getColumn(2).width = 20; //B
  //   worksheet.getColumn(3).width = 20; //C
  //   worksheet.getColumn(4).width = 20; //D
  //   worksheet.getColumn(5).width = 25; //E
  //   worksheet.getColumn(6).width = 30; //F
  //   worksheet.getColumn(7).width = 20; //G;
  //   worksheet.getColumn(8).width = 15; //H;
  //   worksheet.getColumn(9).width = 15; //I
  //   worksheet.getColumn(10).width = 30; //J

  //   worksheet.getRow(2).height = 25;

  //   for (let i = 6; i < 200; i++) {
  //     let eachRow = [];
  //     if (i == 6) {
  //       eachRow = [
  //         '',
  //         '',
  //         '07 Jul 2023',
  //         'SJB-23-000401',
  //         'PO/VII/2023/946',
  //         '05 Jul 2023',
  //         'ELC.000012',
  //         '0',
  //         '20',
  //         'WH A',
  //       ];
  //     } else {
  //       eachRow = ['', '', '', '', '', '', '', '', '', ''];
  //     }

  //     const row = worksheet.addRow(eachRow);
  //     row.eachCell((cell, index) => {
  //       if (index == 8 || index == 9) cell.alignment = { horizontal: 'center' };

  //       cell.border = {
  //         top: { style: 'thin' },
  //         left: { style: 'thin' },
  //         bottom: { style: 'thin' },
  //         right: { style: 'thin' },
  //       };
  //     });
  //   }

  //   //export
  //   workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
  //     const blob = new Blob([data], { type: EXCEL_TYPE });
  //     fs.saveAs(blob, 'format-upload-PI' + EXCEL_EXTENSION);
  //   });
  // }

  public async exportEmployeeHealth(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any
  ) {
    const header = [
      'Date',
      'Notes',
      'Taking Medication',
      'Fatigue',
      'Rest Time',
      'Tension',
      'Pulse',
      'Body Temperature',
      'SpO2',
      'Alcohol Level',
      'Conclution',
    ];

    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    worksheet.addRow([]);

    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };

    worksheet.addRow([]);
    worksheet.addRow(['Fullname', ': ' + data.fullname]);
    worksheet.addRow(['Employee ID', ': ' + data.employee_id]);
    worksheet.addRow([
      'Birth Date',
      ': ' +
        moment(data.birthday).tz('Asia/Jakarta').format('DD MMM YYYY') +
        ' (' +
        this.calAge(data.birthday) +
        ' Years Old)',
    ]);
    worksheet.addRow(['Job Title', ': ' + data.job.job_title.name]);
    worksheet.addRow([
      'Date',
      ': ' +
        (date[0] == date[1]
          ? moment(date[0]).format('DD/MM/YYYY')
          : moment(date[0]).format('DD/MM/YYYY') +
            ' - ' +
            moment(date[1]).format('DD/MM/YYYY')),
    ]);

    worksheet.addRow([]);

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        header[index - 1].length < 20 ? 20 : header[index - 1].length;
    });

    worksheet.getRow(9).height = 25;

    _.forEach(data.healths, (obj) => {
      const eachRow = [];

      eachRow.push(moment(obj.date).tz('Asia/Jakarta').format('DD/MM/YYYY'));
      eachRow.push(obj.note ?? '-');
      eachRow.push(obj.taking_medication ?? '-');
      eachRow.push(!obj.fatigue ? 'No' : 'Yes');
      eachRow.push(obj.total_rest + ' hour');
      eachRow.push(obj.sistolik + '/' + obj.diastolik);
      eachRow.push(obj.pulse + ' bpm');
      eachRow.push(obj.body_temperature + 'ᵒC');
      eachRow.push((obj.oxygen_saturation ?? 0) + '%');
      eachRow.push(obj.alcohol_level ?? 0);
      eachRow.push(obj.conclution ?? '-');

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });

    return true;
  }

  deliveryMonpowerWeekly(date: Date, data: any, weekParam: string) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Delivery Manpower Weekly');

    const IS_PROD = environment.production;
    const STAGING = 'https://staging.armas.id/';
    const PROD = 'https://erp.armas.id/';
    const URL_TRAINING = 'driver-performance/detail/training/';
    const URL_TANOKO = 'tanoko/filterDriver/';

    const urlTraining =
      (IS_PROD ? PROD : STAGING) + URL_TRAINING + data.driver.id;
    const linkTraining = {
      text: 'Link',
      hyperlink: urlTraining,
      tooltip: urlTraining,
    };
    const urlTanoko = (IS_PROD ? PROD : STAGING) + URL_TANOKO + data.driver.id;
    const linkTanoko = {
      text: 'Link',
      hyperlink: urlTanoko,
      tooltip: urlTanoko,
    };

    const week = _.map(data.week, (d) => moment(d).format('DD'));
    week.unshift('');
    if (data.week.length < 7) {
      const limit = 7 - data.week.length;
      for (let i = 1; i <= limit; i++) {
        week.push('-');
      }
    }
    const days = _.map(data.week, (d) => moment(d).format('ddd'));
    days.unshift('');
    if (data.week.length < 7) {
      const limit = 7 - data.week.length;
      for (let i = 1; i <= limit; i++) {
        days.push('-');
      }
    }

    const header1 = [
      'PT. Armas Logistic Service',
      weekParam == 'all'
        ? 'Laporan Kinerja Bulan ' + moment(date).format('MM')
        : 'Laporan Kinerja Minggu ' + weekParam,
      ...Array(3).fill(''),
      'Departemen Operasional',
      '',
      '',
    ];

    const header2 = [
      '',
      '',
      '',
      '',
      '',
      'Periode',
      moment(date).format('MMMM'),
      moment(date).format('YYYY'),
    ];
    const header3 = [
      'Nama',
      data.driver.fullname,
      '',
      'Riwayat Training',
      '',
      '',
      linkTraining,
      '',
    ];
    const header4 = [
      'NIK',
      data.driver.employee_id,
      '',
      'Penguasaan Rute',
      '',
      '',
      linkTanoko,
      '',
    ];

    const header6 = week;

    let header5 = ['Keterangan', 'Tanggal'];
    header5 = [
      ...header5,
      ...(weekParam == 'all'
        ? Array(days.length - 2).fill('')
        : Array(6).fill('')),
    ];

    const header7 = days;

    const sectionTop = [
      {
        row: header1,
        customs: [
          {
            column: 'A1',
            styles: {
              fontSize: 12,
              fontBold: true,
              bgColor: 'EBF1DE',
              alignment: 'center',
            },
          },
          {
            column: 'B1',
            styles: {
              fontSize: 14,
              fontBold: true,
              bgColor: 'D8E4BC',
              alignment: 'center',
            },
          },
          {
            column: 'F1',
            styles: {
              fontSize: 12,
              fontBold: true,
              bgColor: 'EBF1DE',
              alignment: 'center',
            },
          },
        ],
      },
      {
        row: header2,
        customs: [
          {
            column: 'F2',
            styles: {
              fontSize: 11,
              fontBold: true,
              bgColor: 'D8E4BC',
              alignment: 'center',
            },
          },
          {
            column: 'G2',
            styles: {
              fontSize: 11,
              fontBold: true,
              bgColor: 'D8E4BC',
              alignment: 'center',
            },
          },
          {
            column: 'H2',
            styles: {
              fontSize: 11,
              fontBold: true,
              bgColor: 'D8E4BC',
              alignment: 'center',
            },
          },
        ],
      },
      {
        row: header3,
        customs: [
          {
            column: 'A3',
            styles: {
              fontSize: 12,
              fontBold: true,
              alignment: 'center',
            },
          },
          {
            column: 'B3',
            styles: {
              fontSize: 12,
              fontBold: true,
              alignment: 'center',
            },
          },
          {
            column: 'D3',
            styles: {
              fontSize: 12,
              fontBold: true,
              alignment: 'center',
            },
          },
          {
            column: 'G3',
            styles: {
              fontSize: 12,
              fontBold: true,
              bgColor: '8DB4E2',
              alignment: 'center',
            },
          },
        ],
      },
      {
        row: header4,
        customs: [
          {
            column: 'A4',
            styles: {
              fontSize: 12,
              fontBold: true,
              alignment: 'center',
            },
          },
          {
            column: 'B4',
            styles: {
              fontSize: 12,
              fontBold: true,
              alignment: 'center',
            },
          },
          {
            column: 'D4',
            styles: {
              fontSize: 12,
              fontBold: true,
              alignment: 'center',
            },
          },
          {
            column: 'G4',
            styles: {
              fontSize: 12,
              fontBold: true,
              bgColor: '8DB4E2',
              alignment: 'center',
            },
          },
        ],
      },
      {
        row: header5,
        customs: [
          {
            column: 'A5',
            styles: {
              fontSize: 12,
              fontBold: true,
              alignment: 'center',
            },
          },
          {
            column: 'B5',
            styles: {
              fontSize: 11,
              fontBold: true,
              bgColor: 'D8E4BC',
              alignment: 'center',
            },
          },
        ],
      },
      {
        row: header6,
        customAll: {
          styles: {
            fontSize: 11,
            fontBold: true,
            alignment: 'center',
          },
        },
      },
      {
        row: header7,
        customAll: {
          styles: {
            fontSize: 11,
            alignment: 'center',
          },
        },
      },
    ];

    for (const section of sectionTop) {
      const headerRow = worksheet.addRow(section.row);
      if (section?.customs) {
        for (const custom of section.customs) {
          const style = custom.styles;

          if (style?.fontSize) {
            worksheet.getCell(custom.column).font = {
              size: style.fontSize,
              bold: style?.fontBold ? true : false,
            };
          }
          if (style?.bgColor) {
            worksheet.getCell(custom.column).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: style.bgColor },
            };
          }
          if (style?.alignment) {
            if (style.alignment == 'center') {
              worksheet.getCell(custom.column).alignment = {
                vertical: 'middle',
                horizontal: 'center',
              };
            }
          }
        }
      }

      headerRow.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        };

        if (section.customAll) {
          const style = section.customAll.styles;
          if (style?.fontSize) {
            cell.font = {
              size: style.fontSize,
              bold: style?.fontBold ? true : false,
            };
          }

          if (style?.alignment) {
            if (style.alignment == 'center') {
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'center',
              };
            }
          }
        }
      });
    }

    const mergeCols = [
      'F1:H1',
      'A1:A2',
      'B1:E2',
      'B3:C3',
      'D3:F3',
      'G3:H3',
      'B4:C4',
      'D4:F4',
      'G4:H4',
      'A5:A7',
      `B5:${this.numToAlpha(days.length - 1)}5`, //label tanggal
    ];
    for (const mergeCol of mergeCols) {
      worksheet.mergeCells(mergeCol);
    }

    const range = _.range(1, 7, 1);
    for (const i of range) {
      worksheet.getRow(i).height = 20;
    }

    const alpha = Array.from(Array(8)).map((e, i) => i + 65);
    const alphabets = alpha.map((x) => String.fromCharCode(x));

    for (const alphabet of alphabets) {
      worksheet.getColumn(alphabet).width = alphabet == 'A' ? 40 : 20;
    }

    if (weekParam == 'all') {
      worksheet.mergeCells(`I1:${this.numToAlpha(days.length - 1)}4`);
      worksheet.getCell('I1').fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'EBF1DE' },
      };
    }

    let indexRow = 8;
    _.forEach(data.performance_types, (row) => {
      indexRow++;
      const eachRow = [];

      eachRow.push(row.name);
      if (!row.performance_components.length) {
        for (const val of row.values) {
          eachRow.push(val);
        }
      } else {
        row.values.forEach(() => {
          eachRow.push('');
        });
      }

      if (data.week.length < 7) {
        const limit = 7 - data.week.length;
        for (let i = 1; i <= limit; i++) {
          eachRow.push('');
        }
      }

      const typeRow = worksheet.addRow(eachRow);
      typeRow.eachCell((cell, index) => {
        if (index !== 1) {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'EBF1DE' },
        };
        cell.font = { bold: true };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });

      for (const d of row.performance_components) {
        indexRow++;
        const eachRow = [];
        eachRow.push(d.performance_component.name);

        for (const value of d.performance_component.values) {
          eachRow.push(value);
        }

        if (data.week.length < 7) {
          const limit = 7 - data.week.length;
          for (let i = 1; i <= limit; i++) {
            eachRow.push('');
          }
        }

        const componentRow = worksheet.addRow(eachRow);
        componentRow.eachCell((cell, index) => {
          if (index !== 1) {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          }
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
        });
      }
    });

    const abnormalHeading = _.map(data.week, () => {
      return '';
    });
    abnormalHeading.unshift('Abnormal');
    if (data.week.length < 7) {
      const limit = 7 - data.week.length;
      for (let i = 1; i <= limit; i++) {
        abnormalHeading.push('');
      }
    }
    const newRow1 = abnormalHeading;
    const abnormalRow = worksheet.addRow(newRow1);
    indexRow++;
    abnormalRow.eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'EBF1DE' },
      };
      cell.font = { bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    const eachRow1 = [];
    eachRow1.push('Point');

    for (const deduction of data.accumulated_deductions) {
      eachRow1.push(deduction);
    }
    if (data.week.length < 7) {
      const limit = 7 - data.week.length;
      for (let i = 1; i <= limit; i++) {
        eachRow1.push('');
      }
    }

    const row1 = worksheet.addRow(eachRow1);
    indexRow++;
    row1.eachCell((cell, index) => {
      if (index !== 1) {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      }
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    const accumulationHeading = _.map(data.week, () => {
      return '';
    });
    accumulationHeading.unshift('Accumulated Points');
    if (data.week.length < 7) {
      const limit = 7 - data.week.length;
      for (let i = 1; i <= limit; i++) {
        accumulationHeading.push('');
      }
    }
    const newRow2 = accumulationHeading;
    const accumulatedRow = worksheet.addRow(newRow2);
    indexRow++;
    accumulatedRow.eachCell((cell) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'EBF1DE' },
      };
      cell.font = { bold: true };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    const eachRow2 = [];
    eachRow2.push('Point');

    for (const point of data.accumulated_points) {
      eachRow2.push(point);
    }

    if (data.week.length < 7) {
      const limit = 7 - data.week.length;
      for (let i = 1; i <= limit; i++) {
        eachRow2.push('');
      }
    }

    const row2 = worksheet.addRow(eachRow2);
    indexRow++;
    row2.eachCell((cell, index) => {
      if (index !== 1) {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      }
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    const text = {
      null: '-',
      A: 'Ini adalah pencapaian yang luar biasa. Pertahankan untuk yang terbaik!',
      B: 'Ini adalah pencapaian yang baik. Selangkah lagi untuk menjadi yang terbaik!',
      C: 'Pertahankan semangat untuk belajar, kamu pasti bisa!',
      D: 'Jangan berhenti belajar, pasti kamu bisa!',
    };

    console.log(indexRow);
    const bottom1 = worksheet.addRow([
      'Grade Minggu Ini',
      data.grade || '-',
      '',
      'Riwayat Grade',
      '',
      '',
      'Grade Nilai',
      '',
    ]);
    const bottom2 = worksheet.addRow([
      '',
      '',
      '',
      'Minggu 1',
      data.performance.weekly_grade?.[0] || '-',
      '',
      'Nilai',
      'Grade',
    ]);
    const bottom3 = worksheet.addRow([
      '',
      '',
      '',
      'Minggu 2',
      data.performance.weekly_grade?.[1] || '-',
      '',
      '10',
      'A',
    ]);
    const bottom4 = worksheet.addRow([
      text[data.grade || 'null'],
      '',
      '',
      'Minggu 3',
      data.performance.weekly_grade?.[2] || '-',
      '',
      '9-8',
      'B',
    ]);
    const bottom5 = worksheet.addRow([
      '',
      '',
      '',
      'Minggu 4',
      data.performance.weekly_grade?.[3] || '-',
      '',
      '7-6',
      'C',
    ]);
    const bottom6 = worksheet.addRow([
      '',
      '',
      '',
      'Minggu 5',
      data.performance.weekly_grade?.[4] || '-',
      '',
      '5-0',
      'D',
    ]);

    bottom1.eachCell((cell) => {
      cell.alignment = {
        horizontal: 'center',
        vertical: 'middle',
      };
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    bottom2.eachCell((cell) => {
      cell.alignment = {
        horizontal: 'center',
        vertical: 'middle',
      };
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    bottom3.eachCell((cell) => {
      cell.alignment = {
        horizontal: 'center',
        vertical: 'middle',
      };
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    bottom4.eachCell((cell) => {
      cell.alignment = {
        horizontal: 'center',
        vertical: 'middle',
      };
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    bottom5.eachCell((cell) => {
      cell.alignment = {
        horizontal: 'center',
        vertical: 'middle',
      };
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
    });
    bottom6.eachCell((cell) => {
      cell.alignment = {
        horizontal: 'center',
        vertical: 'middle',
      };
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    console.log(
      'A:' + indexRow + ':A' + (indexRow + 2),
      'B:' + indexRow + ':B' + (indexRow + 2)
    );
    worksheet.mergeCells('A' + indexRow + ':A' + (indexRow + 2));
    worksheet.mergeCells('A' + (indexRow + 3) + ':B' + (indexRow + 5));
    worksheet.getCell('A:' + indexRow).font = { size: 12, bold: true };
    worksheet.getCell('A:' + (indexRow + 3)).font = { size: 12, bold: true };
    worksheet.getCell('A:' + (indexRow + 3)).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'BCD3EE' },
    };

    worksheet.mergeCells('B' + indexRow + ':B' + (indexRow + 2));
    worksheet.getCell('B:' + indexRow).font = { size: 20, bold: true };

    console.log('C:' + indexRow + ':C' + (indexRow + 5));
    console.log('F:' + indexRow + ':F' + (indexRow + 5));
    console.log('D:' + indexRow + ':E' + indexRow);
    console.log('G:' + indexRow + ':H' + indexRow);

    worksheet.mergeCells('C' + indexRow + ':C' + (indexRow + 5));
    worksheet.mergeCells('F' + indexRow + ':F' + (indexRow + 5));
    worksheet.mergeCells('D' + indexRow + ':E' + indexRow);
    worksheet.mergeCells('G' + indexRow + ':H' + indexRow);

    worksheet.getCell('D:' + indexRow).font = { size: 12, bold: true };
    worksheet.getCell('D:' + indexRow).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'EBF1DE' },
    };
    worksheet.getCell('G:' + indexRow).font = { size: 12, bold: true };
    worksheet.getCell('G:' + indexRow).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'EBF1DE' },
    };

    worksheet.getCell('H:' + (indexRow + 2)).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '92D050' },
    };
    worksheet.getCell('H:' + (indexRow + 3)).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFFF00' },
    };
    worksheet.getCell('H:' + (indexRow + 4)).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFC000' },
    };
    worksheet.getCell('H:' + (indexRow + 5)).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF0000' },
    };

    if (weekParam == 'all') {
      worksheet.mergeCells(
        `I${indexRow}:${this.numToAlpha(days.length - 1)}${indexRow + 5}`
      );
      worksheet.getCell(`I${indexRow}`).border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'delivery-manpower-weekly' + EXCEL_EXTENSION);
    });
  }

  deliveryMonpowerMonthly(date: Date, rows: Array<any>) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Delivery Manpower Monthly');

    const header1 = [
      'PT. Armas Logistic Service',
      '',
      '',
      'Laporan Kinerja',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
    ];
    const header2 = [
      'Departemen Operasional',
      '',
      '',
      moment(date).format('MMMM'),
      moment(date).format('YYYY'),
      '',
      '',
      '',
      '',
      '',
    ];
    const header3 = [
      'No',
      'Nama',
      'NIK',
      'Fieldman',
      'Grade Mingguan',
      '',
      '',
      '',
      '',
      'Grade Bulanan',
      'Keterangan Incident',
    ];
    const header4 = [
      '',
      '',
      '',
      '',
      'Minggu 1',
      'Minggu 2',
      'Minggu 3',
      'Minggu 4',
      'Minggu 5',
      '',
      '',
    ];

    const sectionTop = [
      {
        row: header1,
        customs: [
          {
            column: 'A1',
            styles: {
              fontSize: 12,
              fontBold: true,
              bgColor: 'EBF1DE',
              alignment: 'center',
            },
          },
          {
            column: 'D1',
            styles: {
              fontSize: 14,
              fontBold: true,
              bgColor: 'D8E4BC',
              alignment: 'center',
            },
          },
        ],
      },
      {
        row: header2,
        customs: [
          {
            column: 'A2',
            styles: {
              fontSize: 11,
              fontBold: true,
              bgColor: 'D8E4BC',
              alignment: 'center',
            },
          },
          {
            column: 'D2',
            styles: {
              fontSize: 11,
              fontBold: true,
              bgColor: 'D8E4BC',
              alignment: 'center',
            },
          },
          {
            column: 'E2',
            styles: {
              fontSize: 11,
              fontBold: true,
              bgColor: 'D8E4BC',
              alignment: 'center',
            },
          },
          {
            column: 'F2',
            styles: {
              fontSize: 11,
              fontBold: true,
              bgColor: 'D8E4BC',
              alignment: 'center',
            },
          },
        ],
      },
      {
        row: header3,
        customAll: {
          styles: {
            fontSize: 11,
            fontBold: true,
            alignment: 'center',
          },
        },
      },
      {
        row: header4,
        customAll: {
          styles: {
            fontSize: 11,
            fontBold: true,
            alignment: 'center',
          },
        },
      },
    ];

    for (const section of sectionTop) {
      const headerRow = worksheet.addRow(section.row);
      if (section?.customs) {
        for (const custom of section.customs) {
          const style = custom.styles;

          if (style?.fontSize) {
            worksheet.getCell(custom.column).font = {
              size: style.fontSize,
              bold: style?.fontBold ? true : false,
            };
          }
          if (style?.bgColor) {
            worksheet.getCell(custom.column).fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: style.bgColor },
            };
          }
          if (style?.alignment) {
            if (style.alignment == 'center') {
              worksheet.getCell(custom.column).alignment = {
                vertical: 'middle',
                horizontal: 'center',
              };
            }
          }
        }
      }

      headerRow.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        };

        if (section.customAll) {
          const style = section.customAll.styles;
          if (style?.fontSize) {
            cell.font = {
              size: style.fontSize,
              bold: style?.fontBold ? true : false,
            };
          }

          if (style?.alignment) {
            if (style.alignment == 'center') {
              cell.alignment = {
                vertical: 'middle',
                horizontal: 'center',
              };
            }
          }
        }
      });
    }

    _.forEach(rows, (row, i) => {
      const eachRow = [];

      eachRow.push(i + 1);
      eachRow.push(row.fullname);
      eachRow.push(row.employee_id.toString());
      eachRow.push(row.fieldman.fullname);

      for (const grade of row.performance.weekly_grade) {
        eachRow.push(grade || '-');
      }
      eachRow.push(row.performance.monthly_grade);
      eachRow.push(row.performance.incident ? 'Ya' : 'Tidak');

      const newRow = worksheet.addRow(eachRow);
      newRow.eachCell((cell, index) => {
        if (![2, 3, 4].includes(index)) {
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    });

    const mergeCols = [
      'A1:C1',
      'D1:K1',
      'A2:C2',
      'F2:K2',
      'A3:A4',
      'B3:B4',
      'C3:C4',
      'D3:D4',
      'E3:I3',
      'J3:J4',
      'K3:K4',
    ];
    for (const mergeCol of mergeCols) {
      worksheet.mergeCells(mergeCol);
    }

    worksheet.getRow(1).height = 30;

    const alpha = Array.from(Array(11)).map((e, i) => i + 65);
    const alphabets = alpha.map((x) => String.fromCharCode(x));

    for (const alphabet of alphabets) {
      if (alphabet == 'A') {
        worksheet.getColumn(alphabet).width = 8;
        continue;
      }
      if (['B', 'D'].includes(alphabet)) {
        worksheet.getColumn(alphabet).width = 40;
        continue;
      } else {
        worksheet.getColumn(alphabet).width = 20;
      }
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'delivery-manpower-monthly' + EXCEL_EXTENSION);
    });
  }

  public exportIncident(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const headers = [
      'Date',
      'Incident Name',
      'Type',
      'Reason',
      'Delivery',
      'Route',
      'Driver Management',
      'Total Cost',
      'Driver Cost',
      'Company Cost',
      'Attachment',
    ];

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(headers.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    //header & style
    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        headers[index - 1].length < 20 ? 20 : headers[index - 1].length;
    });

    worksheet.getRow(4).height = 20;

    _.forEach(data, (obj) => {
      const eachRow = [];
      let linkImage: any;
      if (obj.file_id) {
        const url = environment.imgUrl + obj.file_id;
        // const url = new Cloudinary(cldConfig).image(obj.file_id).toURL();
        linkImage = {
          text: 'Click Here',
          hyperlink: url,
          tooltip: url,
        };
      }

      eachRow.push(moment(obj.date).tz('Asia/Jakarta').format('DD/MM/YYYY'));
      eachRow.push(obj.name ?? '-');
      eachRow.push(obj.type ?? '-');
      eachRow.push(obj.incident_type?.name ?? '-');
      eachRow.push(obj.delivery.code ?? '-');
      eachRow.push(obj.delivery.template?.name ?? '-');
      eachRow.push(obj.employee ? obj.employee.fullname : '-');
      eachRow.push(obj.total_cost ?? 0);
      eachRow.push(obj.driver_cost ?? 0);
      eachRow.push(obj.company_cost ?? 0);
      eachRow.push(linkImage ?? '');

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 8 || index == 9 || index == 10) {
          cell.numFmt = EXCEL_ACCOUNTING_FORMAT;
        }
        if (index == 12) {
          cell.font = { underline: true, color: { argb: '0000EE' } };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        // worksheet.getColumn(index).width =
        //   eachRow[index - 1].length < 20 ? 20 : eachRow[index - 1].length + 10;
      });
    });

    worksheet.getColumn('I').width = 20;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportTanoko(reportHeading: string, data: any, excelFileName: string) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const headers = [
      'Employee Name',
      'Employee ID',
      'Job Title',
      'Tanoko Route',
    ];

    const array = _.map(data, (d) => d.tanoko_routes.length);
    const loop = Array.from(Array(_.max(array)).keys());

    loop.forEach(() => {
      headers.push('', '');
    });
    headers.pop();

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(headers.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Exported at ' + moment().format('DD/MM/YYYY HH:mm');
    // worksheet.getCell('A2').value =
    //   'Tanggal : ' +
    //   (date[0] == date[1]
    //     ? moment(date[0]).format('DD/MM/YYYY')
    //     : moment(date[0]).format('DD/MM/YYYY') +
    //       ' - ' +
    //       moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    //header & style
    const headerRow = worksheet.addRow(headers);
    worksheet.mergeCells(
      'D4:' + this.numToAlpha((loop.length - 1) * 2 + 3) + '4'
    );

    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        headers[index - 1].length < 20 ? 20 : headers[index - 1].length;
    });

    worksheet.getRow(4).height = 20;

    _.forEach(data, (obj) => {
      const eachRow = [];

      eachRow.push(obj.fullname ?? '-');
      eachRow.push(obj.employee_id ?? '-');
      eachRow.push(obj.job?.job_title?.name ?? '-');

      for (const val of loop) {
        if (obj.tanoko_routes?.[val]) {
          eachRow.push(
            obj.tanoko_routes?.[val]?.route?.name,
            obj.tanoko_routes?.[val]?.value
          );
        } else {
          eachRow.push('', '');
        }
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };

        if (index % 2 !== 0) {
          if (![1, 3].includes(index)) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'C4D79B' },
            };
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center' };
            worksheet.getColumn(index).width = 5;
          }
        } else {
          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        }
      });
    });

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportDriverTraining(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any,
    type: string
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const headersTraining = [
      'Employee Name',
      'Job Title',
      'Date',
      (type == 'training' ? 'Training' : 'Assessment') + ' Name',
      'Batch',
      type == 'training' ? 'Trainer' : 'Assessor',
      (type == 'training' ? 'Trainer' : 'Assessor') + ' Job Title',
      'Training Start',
      'Training End',
      'Location',
      'Pre Score',
      'Post Score',
      'Pass Score',
      'Status',
      'Refreshment Date',
    ];

    const headersAssessment = [
      'Employee Name',
      'Job Title',
      'Date',
      (type == 'training' ? 'Training' : 'Assessment') + ' Name',
      type == 'training' ? 'Trainer' : 'Assessor',
      (type == 'training' ? 'Trainer' : 'Assessor') + ' Job Title',
      'Status',
      'Refreshment Date',
    ];
    const headers = type == 'training' ? headersTraining : headersAssessment;

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(headers.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    //header & style
    const headerRow = worksheet.addRow(headers);

    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        headers[index - 1].length < 20 ? 20 : headers[index - 1].length;
    });

    worksheet.getRow(4).height = 20;

    _.forEach(data, (obj) => {
      const eachRow = [];

      if (type == 'training') {
        eachRow.push(obj.employee.fullname ?? '-');
        eachRow.push(obj.employee.job.job_title.name ?? '-');
        eachRow.push(moment(obj.date).tz('Asia/Jakarta').format('DD/MM/YYYY'));
        eachRow.push(obj.driver_training_type?.name ?? '-');
        eachRow.push(obj.batch ?? '-');
        eachRow.push(obj.trainer.fullname ?? '-');
        eachRow.push(obj.trainer.job?.job_title?.name ?? '-');
        eachRow.push(moment(obj.end).tz('Asia/Jakarta').format('DD/MM/YYYY'));
        eachRow.push(moment(obj.start).tz('Asia/Jakarta').format('DD/MM/YYYY'));
        eachRow.push(obj.location?.name ?? '-');
        eachRow.push(
          obj.pre_score
            ? Number.isInteger(obj.pre_score)
              ? obj.pre_score
              : obj.pre_score.toFixed(1)
            : 0
        );
        eachRow.push(
          obj.post_score
            ? Number.isInteger(obj.post_score)
              ? obj.post_score
              : obj.post_score.toFixed(1)
            : 0
        );
        eachRow.push(
          obj.pass_score
            ? Number.isInteger(obj.pass_score)
              ? obj.pass_score
              : obj.pass_score.toFixed(1)
            : 0
        );
        eachRow.push(obj.status ? obj.status.toUpperCase() : '-');
        eachRow.push(
          obj.refreshment
            ? moment(obj.refreshment).tz('Asia/Jakarta').format('DD/MM/YYYY')
            : '-'
        );
      } else {
        eachRow.push(obj.employee.fullname ?? '-');
        eachRow.push(obj.employee.job.job_title.name ?? '-');
        eachRow.push(moment(obj.date).tz('Asia/Jakarta').format('DD/MM/YYYY'));
        eachRow.push(obj.driver_training_type?.name ?? '-');
        eachRow.push(obj.trainer.fullname ?? '-');
        eachRow.push(obj.trainer.job?.job_title?.name ?? '-');
        eachRow.push(obj.status ? obj.status.toUpperCase() : '-');
        eachRow.push(
          obj.refreshment
            ? moment(obj.refreshment).tz('Asia/Jakarta').format('DD/MM/YYYY')
            : '-'
        );
      }

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        // worksheet.getColumn(index).width =
        //   eachRow[index - 1].length < 20 ? 20 : eachRow[index - 1].length + 10;
      });
    });

    worksheet.getColumn('I').width = 20;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportMaintenance(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const header1 = [
      'No',
      'No Truck',
      'Plate Number',
      'Brand',
      'Model',
      'Vendor',
      'Creator Order',
      '',
      '',
      '',
      'PIC Maintenance',
      '',
      '',
      'Maintenance',
      '',
      'Abnormal',
      '',
      'Status Order',
      'Note',
    ];
    const header2 = [
      '',
      '',
      '',
      '',
      '',
      '',
      'Name',
      'Employee ID',
      'Coordinator',
      'Customer',
      'Name',
      'Job Title',
      'Location',
      'Plan',
      'Actual',
      '',
      '',
      '',
      '',
    ];

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    //header & style
    worksheet.addRow(header1);
    worksheet.addRow(header2);

    console.log(this.numToAlpha(0), this.numToAlpha(1));
    for (let i = 4; i <= 5; i++) {
      for (const [index] of header1.entries()) {
        worksheet.getRow(i).getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getRow(i).getCell(index + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getRow(i).getCell(index + 1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
        };
        worksheet.getRow(i).getCell(index + 1).font = {
          size: 12,
          bold: true,
        };
        worksheet.getColumn(index + 1).width =
          header1[index].length < 20 ? 20 : header1[index].length;
      }
    }

    for (let i = 0; i < 6; i++) {
      worksheet.mergeCells(
        this.numToAlpha(i) + '4:' + this.numToAlpha(i) + '5'
      );
    }
    worksheet.mergeCells('G4:J4');
    worksheet.mergeCells('K4:M4');
    worksheet.mergeCells('N4:O4');
    worksheet.mergeCells('P4:Q5');
    worksheet.mergeCells('R4:R5');
    worksheet.mergeCells('S4:S5');

    worksheet.getRow(4).height = 20;
    worksheet.getRow(5).height = 20;

    const ids = _.uniq(_.map(data, (d) => d.maintenance_order.id));
    const rows = _.map(ids, (id, index) => {
      const filtered = _.filter(data, (d) => d.maintenance_order.id == id);
      const objectData = {
        number: index + 1,
        data: filtered,
      };

      return objectData;
    });
    console.log(rows);

    let startLine = 6;
    let eachLine = 6;
    _.forEach(rows, (obj) => {
      _.forEach(obj.data, (d, i) => {
        const eachRow = [];

        eachRow.push(i == 0 ? obj.number : '');
        eachRow.push(d.maintenance_order.vehicle.number);
        eachRow.push(d.maintenance_order.vehicle.license_plate_number);
        eachRow.push(d.maintenance_order.vehicle.brand);
        eachRow.push(d.maintenance_order.vehicle.brand_model);
        eachRow.push(d.maintenance_order.vehicle.vendor?.name || '-');
        eachRow.push(d.maintenance_order.employee.fullname);
        eachRow.push(d.maintenance_order.employee.employee_id);
        eachRow.push(d.maintenance_order.employee.coordinator?.fullname || '-');

        if (
          d.maintenance_order?.maintenance_request?.inspection?.delivery?.routes
            ?.length
        ) {
          const customers = _.map(
            d.maintenance_order.maintenance_request.inspection.delivery.routes,
            (o) => o.route?.customer?.name
          );
          eachRow.push(customers.join(', '));
        } else {
          eachRow.push('-');
        }
        eachRow.push(d.maintenance_order.mechanic.fullname || '-');
        eachRow.push(d.maintenance_order.mechanic.job?.job_title?.name || '-');
        eachRow.push(d.maintenance_order.mechanic.job?.office?.name || '-');
        eachRow.push(
          `${moment(d.maintenance_order.plan).format('DD/MM/YYYY')}   ${
            d.maintenance_order.plan_start_time || '--:--'
          } to ${d.maintenance_order.plan_end_time || '--:--'}`
        );
        eachRow.push(
          `${
            d.maintenance_order.actual
              ? moment(d.maintenance_order.actual).format('DD/MM/YYYY')
              : '-'
          }   ${d.maintenance_order.actual_time || ''}`
        );
        eachRow.push(d.inspection_component?.name || '-');
        eachRow.push(d.value.replace('_', ' ').toUpperCase());
        eachRow.push(d.maintenance_order.status.toUpperCase());
        eachRow.push(d.maintenance_order.note || '-');

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };

          const center = [1, 2, 3, 4, 5, 25, 17, 18];
          if (center.includes(index)) {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          } else {
            cell.alignment = { vertical: 'middle' };
          }

          if (index == 17) {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: {
                argb:
                  cell.value == 'NOT WORKING'
                    ? 'FFFF00'
                    : cell.value == 'NOT EXIST'
                    ? 'FF0000'
                    : 'F2F2F2',
              },
            };
            cell.font = { bold: true };
          }

          worksheet.getRow(eachLine).height = 15;
          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        });
        eachLine += 1;
      });

      if (obj.data.length > 1)
        worksheet.mergeCells(
          `A${startLine}:A${startLine + (obj.data.length - 1)}`
        );
      startLine += obj.data.length;
    });
    worksheet.getColumn(1).width = 5;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportDepartureArrival(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(11) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    let i = 4;
    for (const key in data) {
      const type = key.slice(0, -1);
      if (type == 'arrival') {
        i += 3;
      }
      worksheet.addRow([]);
      const headers = [
        'Delivery Date',
        'Customer Name',
        'Route',
        'Cycle',
        'Truck Number',
        'Driver Name',
        'Employee ID',
        type == 'departure' ? 'Depart From' : 'Arrival At',
        'Plan',
        'Gap',
        'Status',
        'Actual',
      ];

      worksheet.mergeCells(
        'A' + i + ':' + this.numToAlpha(headers.length - 1) + i
      );
      worksheet.getCell('A' + i).value = type.toUpperCase();
      worksheet.getCell('A' + i).alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
      worksheet.getCell('A' + i).font = { size: 14, bold: true };
      worksheet.getCell('A' + i).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      worksheet.getCell('A' + i).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'C5D9F1' },
      };

      //header & style
      const headerRow = worksheet.addRow(headers);
      headerRow.eachCell((cell, index) => {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.font = { size: 12, bold: true };
        worksheet.getColumn(index).width =
          headers[index - 1].length < 20 ? 20 : headers[index - 1].length;
      });
      worksheet.getRow(i).height = 20;

      _.forEach(data[key], (d) => {
        i++;
        const eachRow = [];

        eachRow.push(moment(d.date).tz('Asia/Jakarta').format('DD/MM/YYYY'));
        eachRow.push(
          d.routes?.[type == 'departure' ? 0 : d.routes.length - 1]?.route
            ?.customer?.name
        );
        eachRow.push(
          d.routes?.[type == 'departure' ? 0 : d.routes.length - 1]?.route?.name
        );
        eachRow.push(
          d.routes?.[type == 'departure' ? 0 : d.routes.length - 1]?.cycle
        );
        eachRow.push(d.vehicle.number);
        eachRow.push(d.driver.fullname);
        eachRow.push(d.driver.employee_id);
        eachRow.push(type == 'departure' ? d.origin.name : d.destination.name);
        eachRow.push(
          moment(d[type + '_plan'])
            .tz('Asia/Jakarta')
            .format('HH:mm')
        );
        eachRow.push(d[type + '_gap']);
        eachRow.push(
          d[type + '_status'] ? d[type + '_status'].toUpperCase() : '-'
        );
        eachRow.push(
          d[type + '_actual']
            ? moment(d[type + '_actual'])
                .tz('Asia/Jakarta')
                .format('HH:mm')
            : '-'
        );

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };

          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        });
      });
      i++;
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportPeriodicService(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any
  ) {
    const header1 = [
      'No',
      'Truck Number',
      'Plate Number',
      'Brand',
      'Model',
      'Vendor',
      'Section Head',
      'Customer',
      'Service Date',
      '',
      '',
      '',
      'Odometer',
      '',
      '',
      'Service Time',
      'Service Type',
      'PIC',
      'Note',
      'Last Service Status',
    ];
    const header2 = [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'Plan',
      'Plan Time',
      'Actual',
      'Actual Time',
      'Plan',
      'Actual',
      'Gap',
      '',
      '',
      '',
      '',
      '',
    ];

    const user = JSON.parse(localStorage.getItem('alsuser'));

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header1.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    //header & style
    const headerRow1 = worksheet.addRow(header1);
    const headerRow2 = worksheet.addRow(header2);

    worksheet.mergeCells('A4:A5');
    worksheet.mergeCells('B4:B5');
    worksheet.mergeCells('C4:C5');
    worksheet.mergeCells('D4:D5');
    worksheet.mergeCells('E4:E5');
    worksheet.mergeCells('F4:F5');
    worksheet.mergeCells('G4:G5');
    worksheet.mergeCells('H4:H5');
    worksheet.mergeCells('I4:L4');
    worksheet.mergeCells('M4:O4');
    worksheet.mergeCells('P4:P5');
    worksheet.mergeCells('Q4:Q5');
    worksheet.mergeCells('R4:R5');
    worksheet.mergeCells('S4:S5');
    worksheet.mergeCells('T4:T5');

    headerRow1.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      worksheet.getColumn(index).width =
        header1[index - 1].length < 20 ? 20 : header1[index - 1].length;
    });

    headerRow2.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };

      worksheet.getColumn(index).width =
        header2[index - 1].length < 20 ? 20 : header2[index - 1].length;
    });

    const showRangeTime = (nextDate: string) => {
      const current = moment();
      const next = moment(nextDate).tz('Asia/Jakarta');
      const duration = Math.round(moment.duration(next.diff(current)).asDays());

      let text = 'H-' + duration;
      if (duration < 0) {
        text = 'H+' + Math.abs(duration);
      }
      let status = '';
      if (duration <= 0) {
        status = 'Delay';
      } else if (duration <= 3) {
        status = 'Plan';
      } else if (duration <= 7) {
        status = 'Plan';
      } else if (duration <= 14) {
        status = 'Plan';
      } else if (duration <= 30) {
        status = 'Plan';
      } else {
        text = duration + ' Days Left';
      }

      return {
        text,
        status,
      };
    };

    _.forEach(data, (d, index) => {
      const eachRow = [];
      eachRow.push(index + 1);
      eachRow.push(d.vehicle.number);
      eachRow.push(d.vehicle.license_plate_number);
      eachRow.push(d.vehicle.brand || '-');
      eachRow.push(d.vehicle.brand_model || '-');
      eachRow.push(d.vehicle?.vendor?.name || '-');
      eachRow.push(
        d.delivery?.driver?.fieldman?.section_head
          ? `${d.delivery?.driver?.fieldman?.section_head?.fullname} (${d.delivery?.driver?.fieldman?.section_head?.job?.job_title?.name})`
          : ''
      );
      if (d.delivery?.routes?.length) {
        const customers = _.map(
          d.delivery.routes,
          (d) => d?.route?.customer?.name || '-'
        );
        eachRow.push(customers.join(', '));
      } else {
        eachRow.push('-');
      }
      eachRow.push(moment(d.plan).tz('Asia/Jakarta').format('DD/MM/YYYY'));

      let plan_time = '-';
      if (d.plan_start_time || d.plan_end_time) {
        plan_time =
          (d.plan_start_time || '--:--') + ' - ' + (d.plan_end_time || '--:--');
      }
      eachRow.push(plan_time);
      eachRow.push(
        d.actual
          ? moment(d.actual).tz('Asia/Jakarta').format('DD/MM/YYYY')
          : '-'
      );
      eachRow.push(d.actual_time || '-');
      eachRow.push(d.odometer_plan || 0);
      eachRow.push(d.odometer_actual || 0);
      eachRow.push((d.odometer_plan || 0) - (d.odometer_actual || 0));

      eachRow.push(
        `${d.remain_date ? showRangeTime(d.remain_date).status : '-'} (${
          showRangeTime(d.remain_date).text
        })`
      );
      eachRow.push(
        `${d.type.toUpperCase()} (${
          d.type == 'periodic' ? '10.000' : '50.000'
        } KM)`
      );
      if (d.mechanic) {
        const mechanics = [
          `${d.mechanic?.fullname} ( ${
            d.mechanic?.job?.job_title?.name || '-'
          } )`,
        ];
        for (const child of d.childs) {
          mechanics.push(
            `${child.mechanic?.fullname} ( ${child.mechanic?.job?.job_title?.name} )`
          );
        }
        eachRow.push(mechanics.join(', '));
      } else {
        eachRow.push('-');
      }
      eachRow.push(d.note || '-');
      eachRow.push(d.status.toUpperCase());

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        cell.alignment = { vertical: 'middle' };

        if ([1, 2, 9, 10, 11, 12, 13, 14, 15, 16, 19, 21].includes(index)) {
          cell.alignment = { horizontal: 'center', vertical: 'middle' };
        }

        if (index == 17) {
          cell.alignment = {
            horizontal: 'left',
            vertical: 'middle',
            wrapText: true,
          };
        }

        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        if ([6, 7, 17, 18].includes(index)) {
          worksheet.getColumn(index).width =
            eachRow[index - 1].length < 20
              ? 20
              : eachRow[index - 1].length + 10;
        }
      });
    });

    worksheet.getColumn('A').width = 5;

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportCycleDelivery(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: any,
    year: string,
    headers: any,
    type: string
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    const header = ['Cutomer', 'Route', 'Note'];

    for (const val of headers) {
      if (type == 'daily') {
        header.push(moment(val).format('DD MMM'));
      } else {
        header.push(val);
      }
    }
    header.push('Total');

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };

    if (type == 'daily') {
      worksheet.getCell('A2').value =
        'Tanggal : ' +
        (date[0] == date[1]
          ? moment(date[0]).format('DD/MM/YYYY')
          : moment(date[0]).format('DD/MM/YYYY') +
            ' - ' +
            moment(date[1]).format('DD/MM/YYYY'));
    } else {
      worksheet.getCell('A2').value = 'Tahun : ' + year;
    }

    worksheet.addRow([]);

    //header & style
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };

      if ([1, 2, 3].includes(index)) {
        worksheet.getColumn(index).width =
          header[index - 1].length < 20 ? 20 : header[index - 1].length;
      } else {
        worksheet.getColumn(index).width = type == 'daily' ? 10 : 15;
      }
    });

    worksheet.getRow(4).height = 20;

    let currentIndex = 4;
    let start = 5;
    _.forEach(data, (d) => {
      _.forEach(['plan', 'actual', 'difference', 'additional'], (val, i) => {
        currentIndex++;

        if (i == 0) {
          start = currentIndex;
        }

        const eachRow = [];
        if (i == 0) {
          eachRow.push(d.customer.name);
          eachRow.push(d.name);
        } else {
          eachRow.push('', '');
        }
        eachRow.push(val.charAt(0).toUpperCase() + val.slice(1));

        for (const [idx] of headers.entries()) {
          eachRow.push(d[val][idx].value);
        }
        eachRow.push(d['total_' + val]);

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };
          if (index !== 3) {
            cell.alignment = { horizontal: 'center', vertical: 'middle' };
          }
        });
        if (i == 3) {
          worksheet.mergeCells('A' + start + ':A' + currentIndex);
          worksheet.mergeCells('B' + start + ':B' + currentIndex);
        }
      });
    });

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public formatJobCosting() {
    const user = JSON.parse(localStorage.getItem('alsuser'));
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Job Costing');
    const header = [
      'No.',
      'Item Code',
      'Item Name',
      'Qty',
      'Total',
      'Job Account',
      'Difference Account',
      'Note',
      'Closed Job',
    ];
    worksheet.addRow(['*Example :']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');
    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });
    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 20; //B
    worksheet.getColumn(3).width = 20; //C
    worksheet.getColumn(4).width = 30; //D
    worksheet.getColumn(5).width = 20; //E
    worksheet.getColumn(6).width = 20; //F
    worksheet.getColumn(7).width = 20; //G
    worksheet.getColumn(8).width = 30; //H
    worksheet.getColumn(9).width = 25; //I
    worksheet.getColumn(10).width = 25; //J
    worksheet.getRow(2).height = 25;
    for (let i = 0; i < 100; i++) {
      const eachRow = [];
      // if (i == 0) {
      //   eachRow.push(
      //     moment().format('DD/MM/YYYY'),
      //     'PO.123',
      //     1000,
      //     'V.00001',
      //     '110102',
      //     '11223344',
      //     1000,
      //     '',
      //     '',
      //     ''
      //   );
      // } else {
      //   eachRow.push('', '', '', '', '', '', '', '', '', '');
      // }
      eachRow.push('', '', '', '', '', '', '', '', '', '');
      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }
    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-job-costing' + EXCEL_EXTENSION);
    });
  }
  public formatMultiWarehouse() {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Multiple Warehouse');

    const header = [
      'Transfer No.',
      'Date',
      'Description',
      'Warehouse From',
      'Warehouse To',
      'Item No.',
      'Item Description',
      'Quantity',
    ];

    worksheet.addRow(['*Example :']);
    worksheet.getCell('A1').font = { size: 10, italic: true };
    worksheet.mergeCells('A1:' + this.numToAlpha(header.length - 1) + '1');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true, color: { argb: '1F4E78' } };
    });

    worksheet.getColumn(1).width = 20; //A
    worksheet.getColumn(2).width = 20; //B
    worksheet.getColumn(3).width = 20; //C
    worksheet.getColumn(4).width = 30; //D
    worksheet.getColumn(5).width = 20; //E
    worksheet.getColumn(6).width = 20; //F
    worksheet.getColumn(7).width = 20; //G
    worksheet.getColumn(8).width = 20; //H

    worksheet.getRow(2).height = 25;

    for (let i = 0; i < 100; i++) {
      const eachRow = [];

      // if (i == 0) {
      //   eachRow.push(
      //     moment().format('DD/MM/YYYY'),
      //     'PO.123',
      //     1000,
      //     'V.00001',
      //     '110102',
      //     '11223344',
      //     1000,
      //     '',
      //     '',
      //     ''
      //   );
      // } else {
      //   eachRow.push('', '', '', '', '', '', '', '', '', '');
      // }
      eachRow.push('', '', '', '', '', '', '', '');

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) cell.numFmt = 'dd/mm/yyyy';
        cell.alignment = { horizontal: 'center' };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, 'upload-multi-warehouse' + EXCEL_EXTENSION);
    });
  }

  public exportDriverTruckInformations(
    reportHeading: string,
    data: any,
    excelFileName: string,
    date: Array<string>,
    headers: Array<Date>,
    type: string
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(headers.length + 2) + '1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    const header = [];

    switch (type) {
      case 'driver':
        header.push('No', 'Driver Name', 'Employee ID');
        break;
      case 'truck':
        header.push('No', 'Truck Number', 'License Plate Number');
        break;
      case 'driver_date':
      case 'truck_date':
        header.push('Note');
        break;
    }

    for (const val of headers) {
      header.push(moment(val).format('DD MMM'));
    }

    if (['driver_date', 'truck_date'].includes(type)) header.push('Total');

    const headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell, index) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };

      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = { size: 12, bold: true };
      if (type == 'driver' || type == 'truck') {
        if (index == 2 || index == 3) {
          worksheet.getColumn(index).width =
            header[index - 1].length < 20 ? 20 : header[index - 1].length;
        } else {
          worksheet.getColumn(index).width = 10;
        }
      } else {
        if (index == 1 || index == headers.length + 2) {
          worksheet.getColumn(index).width =
            header[index - 1].length < 20 ? 20 : header[index - 1].length;
        } else {
          worksheet.getColumn(index).width = 10;
        }
      }
    });

    if (['driver_date', 'truck_date'].includes(type)) {
      let array = [];
      if (type == 'driver_date') {
        array = [
          'total_driver',
          'driver_spare',
          'driver_absence',
          'driver_delivery',
          'master_driver_delivery',
        ];
      } else {
        array = [
          'total_truck',
          'truck_delivery',
          'truck_repair',
          'truck_spare',
        ];
      }

      for (const key of array) {
        _.forEach(data, (obj) => {
          const eachRow = [];

          eachRow.push(this.capitalize(key.replaceAll('_', ' ')));

          for (const [i] of headers.entries()) {
            eachRow.push(obj[key + '_rows'][i].value);
          }
          eachRow.push(
            obj[
              key == 'total_driver' || key == 'total_truck'
                ? key
                : 'total_' + key
            ]
          );

          const row = worksheet.addRow(eachRow);
          row.eachCell((cell, index) => {
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };

            if (index !== 1) {
              cell.alignment = { vertical: 'middle', horizontal: 'center' };
            }
          });
        });
      }
    } else {
      _.forEach(data, (obj, index) => {
        const eachRow = [];

        eachRow.push(index + 1);
        if (type == 'driver') {
          eachRow.push(obj.driver.fullname, obj.driver.employee_id);
        } else {
          eachRow.push(obj.vehicle.number, obj.vehicle.license_plate_number);
        }

        for (const [i] of headers.entries()) {
          eachRow.push(obj.values[i].value);
        }

        const row = worksheet.addRow(eachRow);
        row.eachCell((cell, index) => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };

          if (index !== 2 && index !== 3) {
            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          }
        });
      });
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  public exportMedicalRecord(
    data: any,
    reportHeading: string,
    excelFileName: string,
    date: Array<string>
  ) {
    const user = JSON.parse(localStorage.getItem('alsuser'));

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    // //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:' + this.numToAlpha(11) + '1');
    worksheet.getCell('A1').value = 'REKAM MEDIS';
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };

    worksheet.addRow([]);
    worksheet.addRow(['Fullname', data.employee.fullname]);
    worksheet.addRow(['Employee ID', data.employee.employee_id]);
    worksheet.addRow([
      'Birth Date',
      moment(data.employee.birthday).format('DD/MM/YYYY'),
    ]);
    worksheet.addRow(['Job Title', data.employee.job.job_title.name]);
    worksheet.addRow(['Shift', data.employee.job.work_schedule.name]);
    worksheet.addRow([
      'Employee Status',
      data.employee.job.employment_status.name,
    ]);
    worksheet.addRow([
      'Date',
      date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'),
    ]);

    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.mergeCells('A12:' + this.numToAlpha(11) + '12');
    worksheet.getCell('A12').alignment = { vertical: 'middle' };
    worksheet.getCell('A12').font = { size: 15, bold: true };
    worksheet.getCell('A12').value = 'MEDICAL CHECKUP DATA';

    const mcuHeaders = [];
    _.forEach(data.documents, (d) => {
      const file_id = d.file_id.split('.');
      mcuHeaders.push('Tahun ' + file_id[0].substring(file_id[0].length - 4));
      mcuHeaders.push('');
    });

    const mcuHeaderRow = worksheet.addRow(mcuHeaders);
    mcuHeaderRow.eachCell((cell) => {
      if (cell.value) {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.font = { size: 13, bold: true };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      }
    });

    const mcuContent = [];
    _.forEach(data.documents, (d) => {
      // let cld = new Cloudinary(cldConfig);
      // const img = cld.image(d.file_id);
      // let url = img.toURL().replace('upload', 'upload/fl_attachment');
      // let id = d.file_id.replace(' ', '%20');
      // let format = d.file_id.split('/')[1].split('').reverse().join('');
      // format = format
      //   .substring(0, format.indexOf('.') + 1)
      //   .split('')
      //   .reverse()
      //   .join('');

      // const completeURL =
      //   url.substring(0, url.indexOf('documents')) + id + format;
      // const anchor = document.createElement('a');
      // anchor.download = format;
      // anchor.href = completeURL;

      const completeURL = environment.imgUrl + d.file_id;

      const link = {
        text: 'FILE',
        hyperlink: completeURL,
        tooltip: completeURL,
      };

      mcuContent.push(link);
      mcuContent.push('');
    });

    const mcuContentRow = worksheet.addRow(mcuContent);
    mcuContentRow.eachCell((cell) => {
      if (cell.value) {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
        cell.font = { size: 25, bold: true };
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell.font = { underline: true, color: { argb: '0000EE' } };
      }
    });

    worksheet.getRow(14).height = 40;

    worksheet.addRow([]);
    worksheet.mergeCells('A16:' + this.numToAlpha(11) + '16');
    worksheet.getCell('A16').alignment = { vertical: 'middle' };
    worksheet.getCell('A16').font = { size: 15, bold: true };
    worksheet.getCell('A16').value = 'HEALTH CHECK';

    const header1 = ['Date'];
    for (const d of data.components) {
      header1.push(d.name);
      const length = d.healths.length ? d.healths.length - 1 : 0;
      for (let i = 0; length > i; i++) {
        header1.push('');
      }
    }
    header1.push('Conclution');

    const header2 = [''];
    for (const d of data.components) {
      for (const row of d.healths) {
        header2.push(row.name);
      }
    }
    header2.push('');
    worksheet.addRow(header1);
    worksheet.addRow(header2);
    for (let i = 17; i <= 18; i++) {
      for (const [index] of header1.entries()) {
        worksheet.getRow(i).getCell(index + 1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getRow(i).getCell(index + 1).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getRow(i).getCell(index + 1).alignment = {
          vertical: 'middle',
          horizontal: 'center',
          wrapText: true,
        };
        worksheet.getRow(i).getCell(index + 1).font = {
          size: 12,
          bold: true,
        };
        worksheet.getColumn(index + 1).width =
          header2[index].length < 20 ? 20 : header2[index].length;
      }
    }

    worksheet.mergeCells('A17:A18');
    let letterIndex = 1; // column A
    for (const d of data.components) {
      const length = d.healths.length;
      if (length) {
        worksheet.mergeCells(
          `${this.numToAlpha(letterIndex)}17:${this.numToAlpha(
            letterIndex + (length - 1)
          )}17`
        );
      }
      letterIndex += length;
    }

    worksheet.mergeCells(
      `${this.numToAlpha(letterIndex)}17:${this.numToAlpha(letterIndex)}18`
    );

    worksheet.getRow(17).height = 30;
    worksheet.getRow(18).height = 35;

    let index = 19;
    _.forEach(data.healths, (d) => {
      const eachRow = [];
      eachRow.push(moment(d.date).format('DD/MM/YYYY'));

      for (const row of data.components) {
        const healths = _.filter(d.components, {
          health: { health_type: { id: row.id } },
        });
        for (const val of healths) {
          let suffix = '';
          switch (val.health.name.toLowerCase()) {
            case 'body temperature':
              suffix = '°C';
              break;
            case 'oxygen saturation':
              suffix = '%';
              break;
            case 'nadi':
              suffix = ' bpm';
              break;
            case 'rest time':
              suffix = ' Hour';
              break;
          }
          eachRow.push((val.value || '-') + suffix);
        }
      }
      // eachRow.push((d.sistolik || 0) + '/' + (d.diastolik || 0));
      // eachRow.push((d.pulse || 0) + 'x / minute');
      // eachRow.push((d.body_temperature || 0) + '°C');
      // eachRow.push(d.alcohol_level || 0);
      // eachRow.push((d.oxygen_saturation || 0) + '%');
      // eachRow.push(d.taking_medication ?? '-');
      // eachRow.push(d.eyes_condition ?? '-');
      // eachRow.push(d.feel ?? '-');
      // eachRow.push(d.social ?? '-');
      // eachRow.push(d.emotional ?? '-');
      // eachRow.push(d.concentration ?? '-');
      // eachRow.push((d.rest_time || 0) + ' Hour');
      // eachRow.push(d.walk_balance ?? '-');

      eachRow.push(
        d.conclution ? this.capitalize(d.conclution).replace('_', ' ') : '-'
      );

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) {
          cell.numFmt = 'dd/mm/yyyy';
          cell.alignment = { horizontal: 'center' };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      index++;
    });

    worksheet.addRow([]);
    worksheet.addRow([]);

    index += 2;
    worksheet.mergeCells('A' + index + ':' + this.numToAlpha(11) + index);
    worksheet.getCell('A' + index).alignment = { vertical: 'middle' };
    worksheet.getCell('A' + index).font = { size: 15, bold: true };
    worksheet.getCell('A' + index).value = 'REPORT UNHEALTHY';

    const unhealthy = _.filter(
      data.healths,
      (d) => d.conclution == 'unhealthy'
    );
    const notFit = _.filter(data.healths, (d) => d.conclution == 'not_fit');

    const header3 = [
      'Tanggal',
      'Nama',
      'Employee ID',
      'Route',
      'Employment Status',
      'Usia',
      'Note',
    ];
    const headerRowUnhealthy = worksheet.addRow(header3);
    headerRowUnhealthy.eachCell((cell, index) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = {
        size: 12,
        bold: true,
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };

      worksheet.getColumn(index + 1).width =
        header3[index - 1].length < 20 ? 20 : header3[index - 1].length;
    });

    index += 1;
    worksheet.getRow(index).height = 35;

    const calcAge = (value: string): string => {
      const today = moment();
      const birthdate = moment(value);
      const years = today.diff(birthdate, 'years');
      const text: string = years + ' year old';
      return text;
    };

    _.forEach(unhealthy, (d) => {
      const eachRow = [];
      eachRow.push(moment(d.date).format('DD/MM/YYYY'));
      eachRow.push(d.employee.fullname);
      eachRow.push(d.employee.employee_id);
      eachRow.push(d.delivery?.template?.name || '-');
      eachRow.push(d.employee.job.employment_status.name);
      eachRow.push(calcAge(data.employee.birthday));
      eachRow.push(d.note || '-');

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) {
          cell.numFmt = 'dd/mm/yyyy';
          cell.alignment = { horizontal: 'center' };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      index++;
    });

    worksheet.addRow([]);
    worksheet.addRow([]);
    worksheet.addRow([]);

    index += 3;
    worksheet.mergeCells('A' + index + ':' + this.numToAlpha(11) + index);
    worksheet.getCell('A' + index).alignment = { vertical: 'middle' };
    worksheet.getCell('A' + index).font = { size: 15, bold: true };
    worksheet.getCell('A' + index).value = 'REPORT NOT FIT';

    const headerRowNotFit = worksheet.addRow(header3);
    headerRowNotFit.eachCell((cell, index) => {
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
      cell.font = {
        size: 12,
        bold: true,
      };
      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      };
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'DDEBF7' },
      };

      worksheet.getColumn(index + 1).width =
        header3[index - 1].length < 20 ? 20 : header3[index - 1].length;
    });

    index += 1;
    worksheet.getRow(index).height = 35;

    _.forEach(notFit, (d) => {
      const eachRow = [];
      eachRow.push(moment(d.date).format('DD/MM/YYYY'));
      eachRow.push(d.employee.fullname);
      eachRow.push(d.employee.employee_id);
      eachRow.push(d.delivery?.template?.name || '-');
      eachRow.push(d.employee.job.employment_status.name);
      eachRow.push(calcAge(data.employee.birthday));
      eachRow.push(d.note || '-');

      const row = worksheet.addRow(eachRow);
      row.eachCell((cell, index) => {
        if (index == 1) {
          cell.numFmt = 'dd/mm/yyyy';
          cell.alignment = { horizontal: 'center' };
        }
        cell.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
      index++;
    });

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }

  capitalize(value: string) {
    let words = value.split(' ');
    words = _.map(words, (val) => val.charAt(0).toUpperCase() + val.slice(1));

    return words.join(' ');
  }

  public exportDetailAbnormal(params: any) {
    const { reportHeading, data, excelFileName, date } = params;
    const user = JSON.parse(localStorage.getItem('alsuser'));

    //init workbook & worksheet
    const workbook = new Workbook();
    workbook.creator = user.employee.fullname;
    workbook.lastModifiedBy = user.employee.fullname;
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet(reportHeading);

    //heading title
    worksheet.addRow([]);
    worksheet.mergeCells('A1:H1');
    worksheet.getCell('A1').value = reportHeading;
    worksheet.getCell('A1').alignment = { vertical: 'middle' };
    worksheet.getCell('A1').font = { size: 15, bold: true };
    worksheet.getCell('A2').value =
      'Tanggal : ' +
      (date[0] == date[1]
        ? moment(date[0]).format('DD/MM/YYYY')
        : moment(date[0]).format('DD/MM/YYYY') +
          ' - ' +
          moment(date[1]).format('DD/MM/YYYY'));

    worksheet.addRow([]);

    const abnormals = [data];
    const additionals = [
      'status keberangkatan',
      'overspeed',
      'berhenti di red zone',
      'surat izin mengemudi (sim)',
      'surat izin operator (sio) forklift',
    ];

    let startIndex = 4;
    let numer = 1;
    for (const [i, abnormal] of abnormals.entries()) {
      //each row
      const names: any = [''];
      const values: any = [''];
      const headers: any = [''];
      const rows: any = [];

      //function to convert number to alphabet like A,B,...,Z, AA, BB, CC
      const numAbbr = (num) =>
        num <= 0
          ? ''
          : numAbbr(Math.floor((num - 1) / 26)) +
            String.fromCharCode(((num - 1) % 26) + 65);

      let letterNum = 2;
      let letter = 'B';
      const params1 = [];

      for (const d of abnormal) {
        names.push(`${numer++}. ${d.name}`);
        names.push(...Array(d.headers.length).fill(''));

        values.push(d.value);
        values.push(...Array(d.headers.length).fill(''));

        headers.push(..._.map(d.headers, (e) => e.title));
        headers.push('');

        const startLetter = numAbbr(letterNum);
        for (let v = 0; v < d.headers.length - 1; v++) {
          letter = numAbbr(++letterNum);
        }

        params1.push(startLetter + startIndex + ':' + letter + startIndex);
        letter = numAbbr((letterNum += 2));
      }
      startIndex += 2;

      const maxLengths = [];
      for (let idx = 0; idx < abnormal.length; idx++) {
        const rows = abnormals[i]?.[idx]?.rows || [];
        maxLengths.push(rows.length);
      }

      const max = _.max(maxLengths);
      for (let nth = 0; nth < max; nth++) {
        startIndex++;

        const eachRow = [];
        for (let idx = 0; idx < abnormal.length; idx++) {
          eachRow.push('');
          const name = abnormal[idx]?.name || '';
          const e = abnormal[idx]?.rows?.[nth] || {};

          if (Object.keys(e).length) {
            eachRow.push(nth + 1);
            eachRow.push(moment(e.date).format('DD/MM/YYYY'));
            eachRow.push(e.code || '-');
            eachRow.push(
              e.driver ? `${e.driver.fullname} (${e.driver.employee_id})` : '-'
            );
          } else {
            eachRow.push('', '', '', '');
          }

          if (additionals.includes(name.toLowerCase())) {
            switch (name.toLowerCase()) {
              case 'status keberangkatan':
                if (Object.keys(e).length) {
                  eachRow.push(
                    e.departure_plan
                      ? moment(e.departure_plan).format('HH:mm')
                      : '-'
                  );
                  eachRow.push(
                    e.departure_actual
                      ? moment(e.departure_actual).format('HH:mm')
                      : '-'
                  );
                  eachRow.push(e.status ? e.status.toUpperCase() : '-');
                } else {
                  eachRow.push('', '', '');
                }

                break;
              case 'overspeed':
                if (e?.gps_events) {
                  const dataOverspeed = _.filter(e.gps_events, {
                    type: 'overspeed',
                  });
                  const speeds = _.map(
                    dataOverspeed,
                    (p) => `${p.data.speed} KM/Hour `
                  );
                  eachRow.push(speeds.join('\r\n'));

                  const coordinateSpeed = _.map(
                    dataOverspeed,
                    (p) => `${p.data.lat}, ${p.data.lng}`
                  );
                  eachRow.push(coordinateSpeed.join('\r\n'));
                } else {
                  eachRow.push('');
                }

                break;
              case 'berhenti di red zone':
                if (e?.gps_events) {
                  const dataRedzone = _.filter(e.gps_events, {
                    type: 'stopped',
                  });
                  const zones = _.map(
                    dataRedzone,
                    (p) => `${p.data.zone_name} ( ${p.data.desc} ) `
                  );
                  eachRow.push(zones.join('\r\n'));

                  const coordinateZone = _.map(
                    dataRedzone,
                    (p) => `${p.data.lat}, ${p.data.lng}`
                  );
                  eachRow.push(coordinateZone.join('\r\n'));
                } else {
                  eachRow.push('');
                }

                break;
              case 'surat izin mengemudi (sim)':
                if (Object.keys(e).length) {
                  eachRow.push(`${e.sim.licenseText} \r\n ${e.sim.activeTime}`);
                } else {
                  eachRow.push('');
                }
                break;
              case 'surat izin operator (sio) forklift':
                if (Object.keys(e).length) {
                  eachRow.push(`${e.sio.licenseText} \r\n ${e.sio.activeTime}`);
                } else {
                  eachRow.push('');
                }
                break;
            }
          }
        }

        rows.push(eachRow);
      }

      worksheet.addRow(names);
      worksheet.addRow(values);
      const headerRow = worksheet.addRow(headers);

      headerRow.eachCell((cell, index) => {
        if (cell.value) {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' },
          };

          cell.alignment = { vertical: 'middle', horizontal: 'center' };
          cell.font = { size: 12, bold: true };

          if (i == 0) {
            switch (cell.value) {
              case 'No':
                worksheet.getColumn(index).width = 5;
                break;
              case 'Date':
                worksheet.getColumn(index).width = 15;
                break;
              case 'Delivery':
                worksheet.getColumn(index).width = 15;
                break;
              case 'Driver':
                worksheet.getColumn(index).width = 25;
                break;
              case 'Status':
                worksheet.getColumn(index).width = 25;
                break;
              case 'Speed':
                worksheet.getColumn(index).width = 15;
                break;
              case 'Coordinate':
                worksheet.getColumn(index).width = 25;
                break;
              case 'Validity Period':
                worksheet.getColumn(index).width = 30;
                break;
              case 'Zone':
                worksheet.getColumn(index).width = 30;
                break;
            }
          }
        }
      });

      //looping rows here
      for (const row of rows) {
        const rowCell = worksheet.addRow(row);
        rowCell.eachCell((cell) => {
          if (cell.value) {
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' },
            };

            cell.alignment = { vertical: 'middle', horizontal: 'center' };
          }
        });
      }

      worksheet.addRow([]);
      worksheet.addRow([]);

      for (const param of params1) {
        worksheet.getCell(param).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        worksheet.getCell(param).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'DDEBF7' },
        };
        worksheet.getCell(param).alignment = { vertical: 'middle' };

        const params = param.split(':');

        const letter1 = params[0].replace(/\d+/g, '');
        const letter2 = params[1].replace(/\d+/g, '');
        const index1 = params[0].match(/\d/g).join('');
        const index2 = params[1].match(/\d/g).join('');

        worksheet.mergeCells(param); //name
        worksheet.mergeCells(
          letter1 + (Number(index1) + 1) + ':' + letter2 + (Number(index2) + 1)
        ); //values

        worksheet.getRow(Number(index1)).height = 20;
        worksheet.getRow(Number(index1) + 1).height = 50;

        const cell1 = worksheet.getCell(letter1 + Number(index1));
        const cell2 = worksheet.getCell(letter1 + (Number(index1) + 1));

        cell1.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell2.border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        };
        cell1.alignment = { vertical: 'middle' };
        cell2.alignment = { vertical: 'middle', horizontal: 'center' };
        cell2.font = { size: 20, bold: true };
      }

      startIndex += 3;
    }

    //export
    workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
      const blob = new Blob([data], { type: EXCEL_TYPE });
      fs.saveAs(blob, excelFileName + EXCEL_EXTENSION);
    });
  }
}

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { ExcelService } from '@core/services/excel.service';
import { FileUploader } from 'ng2-file-upload';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import * as moment from 'moment-timezone';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { CoreConfigService } from '@core/services/config.service';
import { SocketApi } from 'app/app.module';
import { DeliveryNotesService } from 'app/components/delivery-notes/delivery-notes.service';

export const random_hex_color_code = () => {
  const n = (Math.random() * 0xfffff * 1000000).toString(16);
  return '#' + n.slice(0, 6);
};

@Component({
  selector: 'app-closing',
  templateUrl: './closing.component.html',
  styleUrls: ['./closing.component.scss'],
  providers: [ApiService],
  encapsulation: ViewEncapsulation.None,
})
export class ClosingComponent implements OnInit, OnDestroy {
  public closingBy = 'plan';
  public rangeDates = [new Date(), new Date()];
  public showed = false;
  public types = [
    {
      value: 'plan',
      label: 'Plan',
    },
    {
      value: 'actual',
      label: 'Actual',
    },
  ];
  public customer = {
    id: null,
    items: [],
    loading: false,
  };
  public fileLabel: string;
  public showAlert = false;
  public statusCollapsed = true;
  public uploader: FileUploader = new FileUploader(null);

  public detail: any;

  public sidebarOpen = false;
  public loading = false;
  public orderNumbers = [];

  public checkedAll = false;
  public checkedRows: any[] = [];

  public open: any = 0;
  public closed: any = 0;
  public partiallyClosed: any = 0;

  public data = [];
  public dataTemp = [];

  public formLoading = false;
  public noteLoading = false;
  @ViewChild('inputNotes') inputNotes: ElementRef;

  chats = [];
  note = '';
  curRow: any;
  curIndex: any;
  public user = JSON.parse(localStorage.getItem('alsuser')).employee;

  public visibleNotes = false;
  public isInit = true;
  public proceed = false;
  public loadingRow = {};
  public toggled = false;
  private cursor: any[] = [0, 0];
  public chatLoading = false;

  constructor(
    private socket: SocketApi,
    private _apiService: ApiService,
    private _excelService: ExcelService,
    private _toastrService: ToastrService,
    private _coreConfigService: CoreConfigService,
    private _coreSidebarService: CoreSidebarService,
    private _deliveryNotesService: DeliveryNotesService
  ) {
    this._coreConfigService.setConfig(
      { layout: { menu: { collapsed: false } } },
      { emitEvent: true }
    );
  }

  ngOnInit(): void {
    this.socket.connect();
  }

  handleEmoji($event, textarea) {
    const emoji = $event.emoji.native;
    const currentValue = this.data[this.curIndex].note;

    if (currentValue) {
      const newValue =
        currentValue.substr(0, this.cursor[0]) +
        emoji +
        currentValue.substr(this.cursor[1]);
      this.data[this.curIndex].note = newValue;
    } else {
      this.data[this.curIndex].note = emoji;
    }

    this.cursor = [this.cursor[0] + 2, this.cursor[1] + 2];
    textarea.focus();
    textarea.setSelectionRange(this.cursor[0], this.cursor[1]);

    this.toggled = !this.toggled;
  }

  onInputChange($event) {
    const start = $event.target.selectionStart;
    const end = $event.target.selectionEnd;

    this.cursor = [start, end];
  }

  ngOnDestroy(): void {
    this._coreConfigService.setConfig(
      { layout: { menu: { collapsed: false } } },
      { emitEvent: true }
    );
    this.socket.removeAllListeners();
  }

  onHideDialog() {
    this.socket.removeAllListeners();
  }

  async getCustomer() {
    if (!this.customer.items.length) {
      this.customer.loading = true;
      this.customer.items = await this._apiService.getComponentItems(
        'customer'
      );
      this.customer.loading = false;
    }
  }

  async getNotes(deliveryId) {
    this.chatLoading = true;
    lastValueFrom(this._apiService.get('delivery-notes/' + deliveryId, {}))
      .then((res) => {
        if (res.statusCode == 200) {
          this.chats = this.arrangeNotes(res.rows);

          this.chatLoading = false;
          setTimeout(() => {
            this._deliveryNotesService.setReadyScroll();
          }, 500);
          this.listenSocket(deliveryId);
        }
      })
      .catch((err) => {
        this.handleError(err);
      });
  }

  arrangeNotes(rows) {
    const notes = _.reduce(
      rows,
      (init: any, d: any) => {
        const length = init.length;
        if (!length) {
          init.push({
            senderId: d.employee.id,
            color: random_hex_color_code(),
            avatar: d.employee.photo_id,
            senderName: d.employee.fullname,
            messages: [
              {
                id: d.id,
                text: d.note,
                time: d.created_at,
              },
            ],
          });
        } else {
          const lastItem = init.slice(-1)[0];
          if (lastItem.senderId == d.employee.id) {
            init.pop();
            lastItem.messages.push({
              id: d.id,
              text: d.note,
              time: d.created_at,
            });
            init.push(lastItem);
          } else {
            const selected = _.filter(init, {
              senderId: d.employee.id,
            });

            if (!selected.length) {
              init.push({
                senderId: d.employee.id,
                color: random_hex_color_code(),
                avatar: d.employee.photo_id,
                senderName: d.employee.fullname,
                messages: [
                  {
                    id: d.id,
                    text: d.note,
                    time: d.created_at,
                  },
                ],
              });
            } else {
              const lastItem = init.slice(-1)[0];
              if (lastItem.senderId == d.employee.id) {
                init.pop();
                lastItem.messages.push({
                  id: d.id,
                  text: d.note,
                  time: d.created_at,
                });
                init.push(lastItem);
              } else {
                const color = selected[0].color;
                init.push({
                  senderId: d.employee.id,
                  color: color,
                  avatar: d.employee.photo_id,
                  senderName: d.employee.fullname,
                  messages: [
                    {
                      id: d.id,
                      text: d.note,
                      time: d.created_at,
                    },
                  ],
                });
              }
            }
          }
        }
        return init;
      },
      []
    );
    return notes;
  }

  listenSocket(deliveryId) {
    this.socket.on('delivery-' + deliveryId, (data) => {
      if (data) {
        if (data.employee.id !== this.user.id) {
          const length = this.chats.length;
          if (!length) {
            this.chats = [
              {
                senderId: data.employee.id,
                color: '#F97900',
                avatar: data.employee.photo_id,
                senderName: data.employee.fullname,
                messages: [
                  {
                    id: data.id,
                    text: data.note,
                    time: moment().tz('Asia/Jakarta').format(),
                  },
                ],
              },
            ];
          } else {
            const latest = this.chats.slice(-1)[0];
            if (latest.senderId == data.employee.id) {
              this.chats[length - 1].messages.push({
                id: data.id,
                text: data.note,
                time: moment().tz('Asia/Jakarta').format(),
              });
            } else {
              this.chats.push({
                senderId: data.employee.id,
                color: '#F97900',
                avatar: data.employee.photo_id,
                senderName: data.employee.fullname,
                messages: [
                  {
                    id: data.id,
                    text: data.note,
                    time: moment().tz('Asia/Jakarta').format(),
                  },
                ],
              });
            }
          }
        }
        console.log(data, this.chats);
      }
    });
  }

  selectFile(event) {
    const file = event.target.files[0];
    this.fileLabel = file.name;
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);
    fileReader.onload = () => {
      this.extractResult(fileReader.result);
    };
  }

  extractResult(res) {
    const workBook = XLSX.read(res, {
      type: 'binary',
      cellText: false,
      cellDates: true,
    });
    const sheetNames = workBook.SheetNames;
    const dataExcel = XLSX.utils.sheet_to_json(workBook.Sheets[sheetNames[0]], {
      header: 1,
      raw: false,
      dateNF: 'yyyy-mm-dd',
    });
    dataExcel.splice(0, 4);
    this.orderNumbers = dataExcel;
  }

  selectDate() {
    if (this.rangeDates[1]) {
      const start = moment(this.rangeDates[0]);
      const end = moment(this.rangeDates[1]);
      const diff = end.diff(start, 'days');

      if (diff > 6) {
        const maxDate = moment(start).add(6, 'days');
        this.rangeDates = [start.toDate(), maxDate.toDate()];
        this.showAlert = true;
      }
    }
  }

  downloadTemplate() {
    this._excelService.templateClosing();
  }

  showData() {
    if (!this.customer.id) {
      this._toastrService.info('Please select customer!');
      return;
    }

    const params = {
      type: 'order',
    };

    if (this.closingBy == 'plan') {
      if (!this.orderNumbers.length) {
        this._toastrService.info('Please upload excel data!');
        return;
      }

      const orderNumbers = _.map(this.orderNumbers, (d) => d[0]);

      params['customer'] = this.customer.id;
      params['order_numbers'] = JSON.stringify(orderNumbers);
    } else {
      const periode = [
        moment(this.rangeDates[0]).tz('Asia/Jakarta').format('YYYY-MM-DD'),
        moment(this.rangeDates[1]).tz('Asia/Jakarta').format('YYYY-MM-DD') ??
          moment(this.rangeDates[0]).tz('Asia/Jakarta').format('YYYY-MM-DD'),
      ];
      params['customer'] = this.customer.id;
      params['date'] = JSON.stringify(periode);
    }

    this._coreConfigService.setConfig(
      { layout: { menu: { collapsed: true } } },
      { emitEvent: true }
    );

    if (this.isInit) this.isInit = false;
    this.proceed = false;
    this.loading = true;

    lastValueFrom(this._apiService.get('closing-delivery', params))
      .then((res) => {
        if (res.statusCode == 200) {
          if (res.rows.length) {
            this.dataTemp = res.rows;
            this.data = res.rows;

            const data = [];
            if (this.closingBy == 'plan') {
              _.forEach(this.orderNumbers, (d) => {
                const selected = _.filter(res.rows, (obj) => {
                  return obj.order.order_number == d[0];
                });
                if (selected) {
                  selected[0]['note'] = d[1];
                  data.push(selected[0]);
                }
              });
              this.data = data;
            }
            // this.data = _.map(this.data, (obj) => {
            //   obj.delivery_route.delivery.notes = _.reduce(
            //     obj.delivery_route.delivery.notes,
            //     (init: any, d: any, i: number) => {
            //       const length = init.length;
            //       if (!length) {
            //         init.push({
            //           senderId: d.employee.id,
            //           color: random_hex_color_code(),
            //           avatar: d.employee.photo_id,
            //           senderName: d.employee.fullname,
            //           messages: [
            //             {
            //               id: d.id,
            //               text: d.note,
            //               time: d.created_at,
            //             },
            //           ],
            //         });
            //       } else {
            //         const lastItem = init.slice(-1)[0];
            //         if (lastItem.senderId == d.employee.id) {
            //           init.pop();
            //           lastItem.messages.push({
            //             id: d.id,
            //             text: d.note,
            //             time: d.created_at,
            //           });
            //           init.push(lastItem);
            //         } else {
            //           const selected = _.filter(init, {
            //             senderId: d.employee.id,
            //           });

            //           if (!selected.length) {
            //             init.push({
            //               senderId: d.employee.id,
            //               color: random_hex_color_code(),
            //               avatar: d.employee.photo_id,
            //               senderName: d.employee.fullname,
            //               messages: [
            //                 {
            //                   id: d.id,
            //                   text: d.note,
            //                   time: d.created_at,
            //                 },
            //               ],
            //             });
            //           } else {
            //             const lastItem = init.slice(-1)[0];
            //             if (lastItem.senderId == d.employee.id) {
            //               init.pop();
            //               lastItem.messages.push({
            //                 id: d.id,
            //                 text: d.note,
            //                 time: d.created_at,
            //               });
            //               init.push(lastItem);
            //             } else {
            //               const color = selected[0].color;
            //               init.push({
            //                 senderId: d.employee.id,
            //                 color: color,
            //                 avatar: d.employee.photo_id,
            //                 senderName: d.employee.fullname,
            //                 messages: [
            //                   {
            //                     id: d.id,
            //                     text: d.note,
            //                     time: d.created_at,
            //                   },
            //                 ],
            //               });
            //             }
            //           }
            //         }
            //       }
            //       return init;
            //     },
            //     []
            //   );
            //   return obj;
            // });

            const filtered = _.filter(
              this.data,
              (obj) => obj.is_closed == true
            );
            this.checkedRows = filtered;
            this.countSelected();
          } else {
            this.data = [];
            this.checkedRows = [];
          }
        }
      })
      .catch((err) => {
        console.error(err);
        this.handleError(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  onCheckedRow() {
    this.countSelected();

    if (this.data.length === this.checkedRows.length) {
      this.checkedAll = true;
    } else {
      this.checkedAll = false;
    }
  }

  onCheckedAll(event: any) {
    this.checkedAll = event.checked;
    if (this.checkedAll) {
      this.checkedRows = this.data.slice();
    } else {
      const data = _.filter(this.data, (obj) => obj.is_closed == true);

      this.checkedRows = _.isArray(data) ? data : data ? [data] : [];
    }
    this.countSelected();
  }

  countSelected() {
    this.open = this.checkedRows.reduce((init, data) => {
      if (data.is_closed == false) init.push(data);
      return init;
    }, []).length;
    this.closed = this.checkedRows.reduce((init, data) => {
      if (data.is_closed == true) init.push(data);
      return init;
    }, []).length;
  }

  sendNotes(fromTable = false) {
    if (!this.curRow.note) {
      if (fromTable) {
        this._toastrService.warning('Fill the note first!');
        this.loadingRow[this.curRow.order.order_number] = false;
      }
      return;
    }

    const payload = {
      delivery: { id: this.curRow.delivery.id },
      employee: { id: this.user.id },
      note: this.data[this.curIndex].note,
    };

    if (fromTable) {
      this.loadingRow[this.curRow.order.order_number] = true;
    } else {
      this.noteLoading = true;
    }

    lastValueFrom(this._apiService.post('delivery-notes', payload))
      .then((res) => {
        if (res.statusCode == 201) {
          this.data[this.curIndex].last_note = payload.note;
          this._toastrService.success(res.message);
          if (!fromTable) {
            const length = this.chats.length;
            if (!length) {
              this.chats = [
                {
                  senderId: this.user.id,
                  color: '#F97900',
                  avatar: this.user.photo_id,
                  senderName: this.user.fullname,
                  messages: [
                    {
                      id: 0,
                      text: payload.note,
                      time: moment().tz('Asia/Jakarta').format(),
                    },
                  ],
                },
              ];
            } else {
              const latest = this.chats.slice(-1)[0];
              if (latest.senderId == this.user.id) {
                this.chats[length - 1].messages.push({
                  id: 0,
                  text: payload.note,
                  time: moment().tz('Asia/Jakarta').format(),
                });
              } else {
                this.chats.push({
                  senderId: this.user.id,
                  color: '#F97900',
                  avatar: this.user.photo_id,
                  senderName: this.user.fullname,
                  messages: [
                    {
                      id: 0,
                      text: payload.note,
                      time: moment().tz('Asia/Jakarta').format(),
                    },
                  ],
                });
              }
            }
          }
          this.data[this.curIndex].note = '';
          this._deliveryNotesService.setReadyScroll();
        }
      })
      .catch((err) => {
        console.error(err);
        this.handleError(err);
      })
      .finally(() => {
        if (fromTable) {
          this.loadingRow[this.curRow.order.order_number] = false;
        } else {
          this.noteLoading = false;
        }
      });
  }

  handleError(err) {
    if (err?.error?.message) {
      if (_.isArray(err.error.message)) {
        for (const message of err.error.message) {
          this._toastrService.error(message);
        }
      } else {
        this._toastrService.error(err.error.message);
      }
    } else {
      this._toastrService.error(err.message);
    }
  }

  onProcess() {
    this.formLoading = true;
    const ids = _.map(this.checkedRows, (obj) => obj.id);
    lastValueFrom(this._apiService.post('closing-delivery?type=order', ids))
      .then((res) => {
        if (res.statusCode == 200) {
          this._toastrService.success(res.message);
          this.isInit = true;
          this.proceed = true;
          this.checkedRows = [];
          this.checkedAll = false;
          this.countSelected();
        }
      })
      .catch((err) => {
        console.error(err);
        this.handleError(err);
      })
      .finally(() => {
        this.formLoading = false;
      });
  }

  openSidebar(delivery) {
    this.getNotes(delivery.id);
    this.detail = _.merge({}, this.detail, delivery);
    this._coreSidebarService
      .getSidebarRegistry('detail-delivery-sidebar')
      .toggleOpen();
    this.sidebarOpen = !this.sidebarOpen;
  }

  closeSidebar() {
    this.socket.removeAllListeners();
    this._coreSidebarService
      .getSidebarRegistry('detail-delivery-sidebar')
      .toggleOpen();
  }
}

export const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const EXCEL_EXTENSION = '.xlsx';
export const EXCEL_ACCOUNTING_FORMAT =
  '_("Rp"* #,##0_);_("Rp"* (#,##0);_("Rp"* "-"??_);_(@_)';

export const ATTENDANCE_STATUS_PAYSLIP = {
  present: 'H',
  sick: 'S',
  rest: 'IS',
  other: 'IK',
  others: 'CK',
  deduction: 'IPU',
  weekend_training: 'TRA',
  weekday_training: 'TRAI',
  holiday_overtime: 'HOT',
  delivery: 'N',
  genba: 'G',
  annual: 'CT',
  holiday: 'CB',
};

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import moment from 'moment-timezone';
import * as _ from 'lodash';

@Component({
  selector: 'app-filter-date',
  templateUrl: './filter-date.component.html',
})
export class FilterDateComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selected: any;
  @Input() param: any;
  @Input() dateCustom = [];
  @Input() hideIcon = false;
  @Input() class: string;
  @Input() style: string;
  @Output() onFilter = new EventEmitter<any>();
  @Output() onLoad = new EventEmitter<any>();

  public showDateCustom = false;
  public showMonthYearCustom = false;
  public showYearCustom = false;

  public dateOptions = [
    {
      key: 'today',
      label: 'Today',
    },
    {
      key: 'week',
      label: 'This Week',
    },
    {
      key: 'month',
      label: 'This Month',
    },
    {
      key: 'yesterday',
      label: 'Yesterday',
    },
    {
      key: 'prev-week',
      label: 'Previous Week',
    },
    {
      key: 'prev-month',
      label: 'Previous Month',
    },
    {
      key: 'tomorrow',
      label: 'Tomorrow',
    },
    {
      key: 'next-week',
      label: 'Next Week',
    },
    {
      key: 'next-month',
      label: 'Next Month',
    },
    {
      key: 'custom',
      label: 'Custom',
    },
  ];
  public dates = [];
  public optionsTemp = this.dateOptions;
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.onSelect(_.find(this.dateOptions, { key: this.selected }), true);
  }

  ngOnChanges(): void {
    if (this.param && this.param == 'monthly') {
      this.dateOptions = [
        {
          key: 'month-year',
          label: 'Month Year',
        },
      ];
      this.selected = 'month-year';
    } else if (this.param && this.param == 'year') {
      this.dateOptions = [
        {
          key: 'year',
          label: 'Year',
        },
      ];
      this.selected = 'year';
    } else {
      this.dateOptions = this.optionsTemp;
      this.selected = this.selected ?? 'today';
    }
    this.onSelect(_.find(this.dateOptions, { key: this.selected }), true);
  }

  dateLabel() {
    if (this.selected === 'custom') {
      return (
        moment(this.dateCustom[0]).format('DD/MM/YYYY') +
        ' - ' +
        moment(this.dateCustom[1]).format('DD/MM/YYYY')
      );
    } else if (this.selected === 'month-year') {
      let label = moment(this.dateCustom[0]).format('MMM YYYY');
      if (this.dateCustom[1]) {
        label += ' - ' + moment(this.dateCustom[1]).format('MMM YYYY');
      }
      return label;
    } else if (this.selected === 'year') {
      let label = moment(this.dateCustom[0]).format('YYYY');
      return label;
    } else {
      this.selected = this.selected ?? 'today';
      return _.find(this.dateOptions, { key: this.selected }).label;
    }
  }

  onSelect(option, init = false) {
    this.selected = option?.key;
    let date1 = moment.tz('Asia/Jakarta');
    let date2 = moment.tz('Asia/Jakarta');
    switch (this.selected) {
      case 'today':
        break;
      case 'week':
        date1 = date1.startOf('isoWeek');
        date2 = date2.endOf('isoWeek');
        break;
      case 'month':
        date1 = date1.startOf('month');
        date2 = date2.endOf('month');
        break;
      case 'yesterday':
        date1 = date1.subtract('1', 'day');
        date2 = moment(date1);
        break;
      case 'prev-week':
        date1 = date1.subtract('1', 'week').startOf('isoWeek');
        date2 = moment(date1).endOf('isoWeek');
        break;
      case 'prev-month':
        date1 = date1.subtract('1', 'month').startOf('month');
        date2 = moment(date1).endOf('month');
        break;
      case 'tomorrow':
        date1 = date1.add('1', 'day');
        date2 = moment(date1);
        break;
      case 'next-week':
        date1 = date1.add('1', 'week').startOf('isoWeek');
        date2 = moment(date1).endOf('isoWeek');
        break;
      case 'next-month':
        date1 = date1.add('1', 'month').startOf('month');
        date2 = moment(date1).endOf('month');
        break;
      case 'custom':
        date1 = moment(this.dateCustom[0]);
        date2 = this.dateCustom[1]
          ? moment(this.dateCustom[1])
          : moment(this.dateCustom[0]);
        break;
      case 'month-year':
        date1 = moment(this.dateCustom[0]);
        date2 = moment(this.dateCustom[1] ?? this.dateCustom[0]).endOf('month');
        break;
      case 'year':
        date1 = moment(this.dateCustom[0]);
        date2 = moment(this.dateCustom[0]);
        break;
    }
    this.dateCustom = [date1.toDate(), date2.toDate()];
    if (this.param == 'year') {
      this.dates = [date1.format('YYYY'), date2.format('YYYY')];
    } else {
      this.dates = [date1.format('YYYY-MM-DD'), date2.format('YYYY-MM-DD')];
    }

    const output = {
      key: this.selected,
      label: _.find(this.dateOptions, { key: this.selected }),
      dates: this.dates,
    };
    if (init) {
      this.onLoad.emit(output);
    } else {
      this.onFilter.emit(output);
    }
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';

import { CoreCommonModule } from '@core/common.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { ListboxModule } from 'primeng/listbox';
import { NgxMaskModule } from 'ngx-mask';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

import { InputComponent } from './input/input.component';
import { FilterDateComponent } from 'app/components/filter-date/filter-date.component';
import { LoaderComponent } from 'app/components/loader/loader.component';
import { SearchResultsComponent } from 'app/components/search-results/search-results.component';
import { ModalComponent } from './modal/modal.component';
import { ChecksheetSidebarComponent } from './checksheet-sidebar/checksheet-sidebar.component';
import { DeliveryNotesComponent } from './delivery-notes/delivery-notes.component';
import { ModalDeliveryNotesComponent } from './modal-delivery-notes/modal-delivery-notes.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { SocketIoModule } from 'ngx-socket-io';
import { PickListModule } from 'primeng/picklist';
import { NgxColorsModule } from 'ngx-colors';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

@NgModule({
  declarations: [
    InputComponent,
    ModalComponent,
    SearchResultsComponent,
    LoaderComponent,
    FilterDateComponent,
    ChecksheetSidebarComponent,
    DeliveryNotesComponent,
    ModalDeliveryNotesComponent,
  ],
  exports: [
    InputComponent,
    ModalComponent,
    SearchResultsComponent,
    LoaderComponent,
    FilterDateComponent,
    ChecksheetSidebarComponent,
    DeliveryNotesComponent,
    ModalDeliveryNotesComponent,
  ],
  imports: [
    CommonModule,
    CalendarModule,
    CoreCommonModule,
    BsDatepickerModule,
    NgSelectModule,
    NgbModule,
    NgxMaskModule.forRoot(),
    TranslateModule,
    ListboxModule,
    DialogModule,
    PickListModule,
    NgxColorsModule,
    NgScrollbarModule,
    SocketIoModule,
    PickerModule,
  ],
})
export class ComponentsModule {}

import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiService } from '@core/services/api.service';

import { searchCategories, SearchService } from '@core/services/search.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { SocketApi } from 'app/app.module';
import { ShareService } from '../../../../../@core/services/share.service';

@Component({
  selector: 'app-navbar-search',
  templateUrl: './navbar-search.component.html',
})
export class NavbarSearchComponent implements OnInit, OnDestroy {
  public searchCategories = _.cloneDeep(searchCategories);
  public search = {
    category: 'check-sheets',
    term: {},
  };
  @ViewChild('inputSearch') inputSearchEl: ElementRef;

  @ViewChild('modalAdvancedSearch') modalAdvancedSearch;
  public formTermGroups = {
    'check-sheets': this.fb.group({
      code: [''],
      // date: [''],
      status: [null],
      template: [null],
      route: [null],
      customer: [null],
      vehicle: [null],
      driver: [null],
    }),
    'delivery-orders': this.fb.group({
      order_number: [''],
      date: [''],
      route: [null],
      customer: [null],
      cycle: [''],
      status: [null],
    }),
    routes: this.fb.group({
      code: [''],
      name: [''],
      logistic_point: [null],
    }),
    templates: this.fb.group({
      code: [''],
      name: [''],
      origin: [null],
      destination: [null],
      route: [null],
    }),
    vehicles: this.fb.group({
      number: [''],
      brand: [''],
      brand_model: [''],
      year: [''],
      vendor: [null],
      license_plate_number: [''],
      license_expired: [''],
      license_plate_expired: [''],
      kir: [''],
      kir_expired: [''],
      sipa_expired: [''],
      ibm_expired: [''],
    }),
    employees: this.fb.group({
      employee_id: [''],
      fullname: [''],
      id_number: [''],
      gender: [null],
      religion: [null],
      driving_license: [''],
      driving_license_expired: [''],
      address: [''],
      phone: [''],
      email: [''],
      bpjs_kesehatan: [''],
      bpjs_ketenegakerjaan: [''],
      id_tax: [''],
      job_title: [null],
      employment_status: [null],
      grade: [null],
      location: [null],
      department: [null],
      division: [null],
      is_graded: [false],
    }),
    customers: this.fb.group({
      name: [''],
      phone: [''],
      email: [''],
      address: [''],
      customer: [null],
    }),
    vendors: this.fb.group({
      name: [''],
      phone: [''],
      email: [''],
      address: [''],
      vendor: [null],
    }),
    'stocks/entry': this.fb.group({
      code: [''],
      date: [''],
      type: [null],
      status: [null],
    }),
    'purchase-requests': this.fb.group({
      date: [''],
      employee: [null],
      requirement_date: [''],
      status: [null],
    }),
  };
  public items = {
    customer: [],
    vendor: [],
    template: [],
    route: [],
    driver: [],
    employee: [],
    vehicle: [],
    logistic_point: [],
    job_title: [],
    employment_status: [],
    grade: [],
    location: [],
    department: [],
    division: [],
    pool: [],
  };
  private inputs = {
    code: {
      id: 'a.code',
      label: 'Code',
      name: 'code',
      type: 'text',
      colPosition: 'left',
    },
    name: {
      id: 'a.name',
      label: 'Name',
      name: 'name',
      type: 'text',
      colPosition: 'left',
    },
    dateRange: {
      id: 'a.date',
      label: 'Date Range',
      name: 'date',
      type: 'daterange',
      colPosition: 'left',
    },
    route: {
      id: 'route.id',
      label: 'Route',
      name: 'route',
      type: 'select',
      placeholder: 'Select Route',
      items: this.items.route,
      loading: false,
      colPosition: 'left',
    },
    customer: {
      id: 'customer.id',
      label: 'Customer',
      name: 'customer',
      type: 'select',
      placeholder: 'Select Customer',
      items: this.items.customer,
      loading: false,
      colPosition: 'left',
    },
  };
  public formTermsInputs = {
    'check-sheets': [
      _.clone(this.inputs.code),
      // _.clone(this.inputs.dateRange),
      {
        id: 'a.status',
        label: 'Status',
        name: 'status',
        type: 'select',
        items: [
          { id: 'planning', name: 'Planning' },
          { id: 'pending', name: 'Pending' },
          { id: 'on progress', name: 'On Progress' },
          { id: 'delivered', name: 'Delivered' },
          { id: 'completed', name: 'Completed' },
        ],
        colPosition: 'left',
      },
      {
        id: 'template.id',
        label: 'Template',
        name: 'template',
        type: 'select',
        bindLabel: 'name',
        bindValue: 'id',
        items: this.items.template,
        colPosition: 'left',
      },
      _.merge({}, this.inputs.route, { colPosition: 'right' }),
      _.merge({}, this.inputs.customer, {
        colPosition: 'right',
      }),
      {
        id: 'driver.id',
        label: 'Driver',
        name: 'driver',
        type: 'select',
        placeholder: 'Select Driver',
        bindLabel: 'fullname',
        items: this.items.driver,
        loading: false,
        colPosition: 'right',
      },
      {
        id: 'vehicle.id',
        label: 'Truck',
        name: 'vehicle',
        type: 'select',
        placeholder: 'Select Truck',
        items: this.items.vehicle,
        bindLabel: 'number',
        loading: false,
        colPosition: 'right',
      },
    ],
    'delivery-orders': [
      {
        id: 'a.order_number',
        label: 'Order Number',
        name: 'order_number',
        type: 'text',
        colPosition: 'left',
      },
      _.clone(this.inputs.dateRange),
      {
        id: 'a.status',
        label: 'Status',
        name: 'status',
        type: 'select',
        items: [
          { id: 'available', name: 'Available' },
          { id: 'used', name: 'Used' },
          { id: 'done', name: 'Done' },
        ],
        colPosition: 'left',
      },
      {
        id: 'a.cycle',
        label: 'Cycle',
        name: 'cycle',
        type: 'text',
        colPosition: 'right',
      },
      _.merge({}, this.inputs.route, { colPosition: 'right' }),
      _.merge({}, this.inputs.customer, { colPosition: 'right' }),
    ],
    routes: [
      _.clone(this.inputs.code),
      _.clone(this.inputs.name),
      {
        id: 'logistic_point',
        label: 'Logistic Point',
        name: 'logistic_point',
        type: 'select',
        items: this.items.logistic_point,
        loading: false,
        colPosition: 'right',
      },
    ],
    templates: [
      _.clone(this.inputs.code),
      _.clone(this.inputs.name),
      _.clone(this.inputs.route),
      {
        id: 'origin.id',
        label: 'Origin',
        name: 'origin',
        type: 'select',
        items: this.items.pool,
        loading: false,
        colPosition: 'right',
      },
      {
        id: 'destination.id',
        label: 'Destination',
        name: 'destination',
        type: 'select',
        items: this.items.pool,
        loading: false,
        colPosition: 'right',
      },
    ],
    vehicles: [
      {
        id: 'a.number',
        label: 'Number',
        name: 'number',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.license_plate_number',
        label: 'Plate Number',
        name: 'license_plate_number',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.brand',
        label: 'Brand',
        name: 'brand',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.brand_model',
        label: 'Model',
        name: 'brand_model',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.year',
        label: 'Year Range',
        name: 'year',
        type: 'daterange',
        view: 'year',
        colPosition: 'left',
      },
      {
        id: 'a.vendor',
        label: 'Vendor',
        name: 'vendor',
        type: 'select',
        items: this.items.vendor,
        loading: false,
        colPosition: 'left',
      },
      {
        id: 'a.license_plate_expired',
        label: 'STNK Expired Range',
        name: 'license_plate_expired',
        type: 'daterange',
        colPosition: 'right',
      },
      {
        id: 'a.license_expired',
        label: 'Plate Expired Range',
        name: 'license_expired',
        type: 'daterange',
        value: 'year',
        colPosition: 'right',
      },
      {
        id: 'a.kir',
        label: 'KIR',
        name: 'kir',
        type: 'text',
        colPosition: 'right',
      },
      {
        id: 'a.kir_expired',
        label: 'KIR Expired Range',
        name: 'kir_expired',
        type: 'daterange',
        colPosition: 'right',
      },
      {
        id: 'a.sipa_expired',
        label: 'SIPA Expired Range',
        name: 'sipa_expired',
        type: 'daterange',
        colPosition: 'right',
      },
      {
        id: 'a.ibm_expired',
        label: 'IBM Expired Range',
        name: 'ibm_expired',
        type: 'daterange',
        colPosition: 'right',
      },
    ],
    employees: [
      {
        id: 'a.employee_id',
        label: 'Employee ID',
        name: 'employee_id',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.fullname',
        label: 'Full Name',
        name: 'fullname',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.id_number',
        label: 'ID Number',
        name: 'id_number',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.id_tax',
        label: 'ID Tax',
        name: 'id_tax',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.gender',
        label: 'Gender',
        name: 'gender',
        type: 'select',
        items: [
          { id: 'male', name: 'Male' },
          { id: 'female', name: 'Female' },
        ],
        colPosition: 'left',
      },
      {
        id: 'a.religion',
        label: 'Religion',
        name: 'religion',
        type: 'select',
        items: [
          { id: 'Islam', name: 'Islam' },
          { id: 'Katolik', name: 'Katolik' },
          { id: 'Protestan', name: 'Protestan' },
          { id: 'Hindu', name: 'Hindu' },
          { id: 'Budha', name: 'Budha' },
          { id: 'Konghucu', name: 'Konghucu' },
        ],
        colPosition: 'left',
      },
      {
        id: 'a.address',
        label: 'Address',
        name: 'address',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.phone',
        label: 'Phone',
        name: 'phone',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.email',
        label: 'Email',
        name: 'email',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.bpjs_kesehatan',
        label: 'BPJS Kesehatan',
        name: 'bpjs_kesehatan',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.bpjs_ketenegakerjaan',
        label: 'BPJS Ketenagakerjaan',
        name: 'bpjs_ketenegakerjaan',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'job_title.id',
        label: 'Job Title',
        name: 'job_title',
        type: 'select',
        items: this.items.job_title,
        loading: false,
        colPosition: 'right',
      },
      {
        id: 'employment_status.id',
        label: 'Employment Status',
        name: 'employment_status',
        type: 'select',
        items: this.items.employment_status,
        loading: false,
        colPosition: 'right',
      },
      {
        id: 'location.id',
        label: 'Location',
        name: 'location',
        type: 'select',
        items: this.items.location,
        loading: false,
        colPosition: 'right',
      },
      {
        id: 'grade.id',
        label: 'Grade',
        name: 'grade',
        type: 'select',
        items: this.items.grade,
        loading: false,
        colPosition: 'right',
      },
      {
        id: 'department.id',
        label: 'Department',
        name: 'department',
        type: 'select',
        items: this.items.department,
        loading: false,
        colPosition: 'right',
      },
      {
        id: 'division.id',
        label: 'Division',
        name: 'division',
        type: 'select',
        items: this.items.division,
        loading: false,
        colPosition: 'right',
      },
      {
        id: 'driving_license.id',
        label: 'Driving License',
        name: 'driving_license',
        type: 'text',
        colPosition: 'right',
      },
      {
        id: 'driving_license_expired.id',
        label: 'Driving License Expired Range',
        name: 'driving_license_expired',
        type: 'daterange',
        colPosition: 'right',
      },
      {
        id: 'a.is_graded',
        label: 'Nonactive Grade',
        name: 'is_graded',
        type: 'horizontalSwitch',
        is_inline: true,
        options: [{ id: 'active', label: '', value: 1 }],
        colPosition: 'right',
        class: 'd-flex align-items-center',
      },
    ],
    customers: [
      _.clone(this.inputs.name),
      {
        id: 'a.phone',
        label: 'Phone',
        name: 'phone',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.email',
        label: 'Email',
        name: 'email',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.address_shipping',
        label: 'Address',
        name: 'address',
        type: 'text',
        colPosition: 'right',
      },
      {
        id: 'parent.id',
        label: 'Branch Of',
        name: 'customer',
        type: 'select',
        items: this.items.pool,
        loading: false,
        colPosition: 'right',
      },
    ],
    vendors: [
      _.clone(this.inputs.name),
      {
        id: 'a.phone',
        label: 'Phone',
        name: 'phone',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.email',
        label: 'Email',
        name: 'email',
        type: 'text',
        colPosition: 'left',
      },
      {
        id: 'a.address_shipping',
        label: 'Address',
        name: 'address',
        type: 'text',
        colPosition: 'right',
      },
      {
        id: 'parent.id',
        label: 'Branch Of',
        name: 'vendor',
        type: 'select',
        items: this.items.pool,
        loading: false,
        colPosition: 'right',
      },
    ],
    'stocks/entry': [
      _.clone(this.inputs.code),
      _.clone(this.inputs.dateRange),
      {
        id: 'a.type',
        label: 'Type',
        name: 'type',
        type: 'select',
        items: [
          { id: 'issue', name: 'Issue' },
          { id: 'receipt', name: 'Receipt' },
          { id: 'transfer', name: 'Transfer' },
          { id: 'opening', name: 'Opening' },
          { id: 'opname', name: 'Opname' },
        ],
        colPosition: 'left',
      },
      {
        id: 'a.status',
        label: 'Status',
        name: 'status',
        type: 'select',
        items: [
          { id: 'draft', name: 'Draft' },
          { id: 'submitted', name: 'Submitted' },
          { id: 'cancelled', name: 'Cancelled' },
        ],
        colPosition: 'left',
      },
    ],
    'purchase-requests': [
      {
        id: 'a.date',
        label: 'Date',
        name: 'date',
        type: 'daterange',
        colPosition: 'left',
      },
      {
        id: 'employee.id',
        label: 'Requester',
        name: 'employee',
        type: 'select',
        placeholder: 'Select Requester',
        bindLabel: 'fullname',
        items: this.items.employee,
        loading: false,
        colPosition: 'right',
      },
      {
        id: 'a.requirement_date',
        label: 'Requirement Date',
        name: 'requirement_date',
        type: 'daterange',
        colPosition: 'left',
      },
      {
        id: 'a.status',
        label: 'Status',
        name: 'status',
        type: 'select',
        items: [
          { id: 'draft', name: 'Draft' },
          { id: 'request', name: 'Request' },
          { id: 'approved', name: 'Approved' },
          { id: 'rejected', name: 'Rejected' },
        ],
        colPosition: 'left',
      },
    ],
  };
  public form = this.fb.group({
    category: this.search.category,
  });
  public formSubmitted = false;

  private _unsubscribeAll: Subject<any>;
  constructor(
    private fb: FormBuilder,
    private socketApi: SocketApi,
    private _searchService: SearchService,
    private _modalService: NgbModal,
    private _apiService: ApiService,
    private _shareService: ShareService
  ) {
    this._unsubscribeAll = new Subject<void>();
  }

  ngOnInit(): void {
    this.socketApi.connect();
    this.socketApi.on('delivery-notes', (data) => {
      console.log(data);
      this._shareService.setNotif(data);
    });

    this._searchService.search
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((search) => {
        this.search = search;
        this.form.controls['category'].setValue(search.category);
      });
    this._searchService.onSearchAdvanced
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(async (open) => {
        if (open) {
          await this.onAdvancedSearch();
        }
      });
  }

  ngOnDestroy(): void {
    console.log('on-destroy');
    this._unsubscribeAll.next(void 0);
    this._unsubscribeAll.complete();
  }

  onSearch(e) {
    if (!e.target.value) return;
    this._searchService.setTerm({
      type: 'single',
      criteria: e.target.value,
    });
    this.inputSearchEl.nativeElement.value = '';
  }

  onSearchMultiple() {
    this.formSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    if (this.formTermGroups[this.search.category].invalid) {
      return;
    }
    const criteria = [];
    for (const key in this.formTermGroups[this.search.category].controls) {
      const value =
        this.formTermGroups[this.search.category].controls[key].value;
      if (value) {
        const input = this.formTermsInputs[this.search.category].find((e) => {
          return e.name === key;
        });
        const criteriaValue = {
          key: input.id,
          name: key,
          type: input.type,
          label: input.label,
          value: value,
          valueLabel: value,
        };
        if (input.type === 'daterange') {
          criteriaValue.value = {
            from: moment.tz(value[0], 'Asia/Jakarta').format('YYYY-MM-DD'),
            to: moment
              .tz(value[1] !== null ? value[1] : value[0], 'Asia/Jakarta')
              .format('YYYY-MM-DD'),
          };
        }
        if (input.type === 'select') {
          const itemSelected = input.items.find((e) => {
            return e.id === value;
          });
          criteriaValue.valueLabel = input['bindLabel']
            ? itemSelected[input['bindLabel']]
            : itemSelected.name;
        }
        if (key === 'is_graded') {
          //kebalikan nilai nya
          criteriaValue.value = value ? '0' : '1';
          criteriaValue.valueLabel = value ? 'Yes' : 'No';
        }
        criteria.push(criteriaValue);
      }
    }
    this._searchService.setTerm({
      type: 'advanced',
      criteria: criteria,
    });
    this._resetForm();
    this._modalService.dismissAll();
  }

  setCategory(e, isAdvanced = false) {
    let key;
    if (isAdvanced) {
      key = e.key;
    } else {
      key = e;
      this.inputSearchEl.nativeElement.focus();
    }
    this.search.category = key;
    this.form.controls['category'].setValue(key);
    this._searchService.setCategory(key);
  }

  getPlaceholder(): string {
    return (
      'Search in ' +
      this.searchCategories.find((e) => e.key === this.search.category).label
    );
  }

  async onAdvancedSearch() {
    if (this.search.term) {
      if (this.search.term['type'] === 'advanced') {
        this.search.term['criteria'].forEach((criteria) => {
          if (criteria.type === 'daterange') {
            this.formTermGroups[this.search.category].controls[
              criteria.name
            ].setValue(criteria.valueLabel);
          } else {
            this.formTermGroups[this.search.category].controls[
              criteria.name
            ].setValue(criteria.value);
          }
        });
      }
    }
    this._modalService.open(this.modalAdvancedSearch, {
      backdrop: 'static',
      size: 'lg',
      modalDialogClass: 'mt-0',
    });
  }

  onCancel() {
    this._resetForm();
    this._modalService.dismissAll();
  }

  private _resetForm() {
    Object.entries(this.formTermGroups).forEach(([key, value], index) => {
      value.reset();
    });
  }

  private async _getSelectItems(component) {
    return await this._apiService
      .get('components', {
        data: JSON.stringify([component]),
        filter: JSON.stringify({}),
      })
      .toPromise()
      .then(
        (res) => {
          return res.result[component];
        },
        (err) => {
          console.log(err);
        }
      );
  }
}

<ngb-alert
  [type]="'success'"
  *ngIf="searchResult.term"
  class="mb-0 bg-lighten-2"
  (closed)="dismissSearch()"
>
  <h4 class="alert-heading font-italic text-warning-light-2">
    Search Criteria
  </h4>
  <div class="alert-body">
    <ng-container
      *ngIf="searchResult.term.type === 'single'; else resultsMultiple"
    >
      <div>
        {{
          'search.result.' +
            searchResult.term.type +
            '.' +
            searchResult.category | translate
        }}
        <span
          [innerHTML]="
            'search.result.haveMatch'
              | translate: { value: searchResult.term.criteria }
          "
        ></span>
      </div>
    </ng-container>
  </div>
</ngb-alert>
<ng-template #resultsMultiple>
  <ul class="pl-1">
    <li
      *ngFor="let criteria of searchResult.term.criteria"
      [innerHTML]="
        'search.result.' + criteria.type
          | translate: { criteria: this.criteria }
      "
    ></li>
  </ul>
</ng-template>

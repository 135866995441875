import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ComponentsModule } from 'app/components/components.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreCommonModule } from '@core/common.module';
import { CoreTouchspinModule } from '@core/components/core-touchspin/core-touchspin.module';

import { NavbarComponent } from 'app/layout/components/navbar/navbar.component';
import { NavbarBookmarkComponent } from 'app/layout/components/navbar/navbar-bookmark/navbar-bookmark.component';
import { NavbarSearchComponent } from 'app/layout/components/navbar/navbar-search/navbar-search.component';
import { NgxPermissionsModule } from 'ngx-permissions';

import { NavbarNotificationComponent } from 'app/layout/components/navbar/navbar-notification/navbar-notification.component';
import { DialogModule } from 'primeng/dialog';
import { SocketIoModule } from 'ngx-socket-io';
import { NgScrollbarModule } from 'ngx-scrollbar';

@NgModule({
  declarations: [
    NavbarComponent,
    NavbarSearchComponent,
    NavbarBookmarkComponent,
    NavbarNotificationComponent,
  ],
  imports: [
    RouterModule,
    NgbModule,
    CoreCommonModule,
    NgScrollbarModule,
    CoreTouchspinModule,
    NgSelectModule,
    ComponentsModule,
    DialogModule,
    NgxPermissionsModule.forChild(),
    SocketIoModule,
  ],
  // providers: [
  //   {
  //     provide: PERFECT_SCROLLBAR_CONFIG,
  //     useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
  //   },
  // ],
  exports: [NavbarComponent],
})
export class NavbarModule {}

<div class="content-wrapper container-xxl">
  <div class="content-header row justify-content-between">
    <div class="col col-md-3">
      <h2 class="content-header-title float-left mb-0">Closing</h2>
    </div>
  </div>
  <div class="content-body">
    <div class="card shadow-none">
      <div class="card-header rounded-0">
        <div class="col-12 my-1">
          <div class="row">
            <div class="col-lg-2">
              <!-- <label class="font-weight-bold mb-1">Closing By</label>
              <div class="d-flex mt-50 align-items-center">
                <h6 class="mr-1 mb-0">Plan</h6>
                <div class="custom-control custom-switch">
                  <input
                    type="checkbox"
                    id="priceSwitch"
                    name="priceSwitch"
                    class="custom-control-input"
                  />
                  <label for="priceSwitch" class="custom-control-label"></label>
                </div>
                <h6 class="ml-50 mb-0">Actual</h6>
              </div> -->
              <label class="font-weight-bold mb-1">Closing By </label>
              <div class="form-group">
                <div
                  class="btn-group btn-group-toggle"
                  ngbRadioGroup
                  [(ngModel)]="closingBy"
                >
                  <label
                    *ngFor="let option of types"
                    ngbButtonLabel
                    class="btn-outline-primary"
                    rippleEffect
                    ><input ngbButton type="radio" [value]="option.value" />{{
                      option.label
                    }}
                  </label>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <label class="font-weight-bold mb-1">Customer</label>
              <div class="form-group">
                <ng-select
                  [loading]="customer.loading"
                  [selectOnTab]="true"
                  [items]="customer.items"
                  (open)="getCustomer()"
                  [(ngModel)]="customer.id"
                  [class.is-invalid]="showed && !customer.id"
                  bindLabel="name"
                  bindValue="id"
                >
                  <ng-template ng-loadingspinner-tmp>
                    <div
                      class="spinner-border text-primary spinner-border-sm"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                  </ng-template>
                </ng-select>
                <div *ngIf="showed && !customer.id" class="invalid-feedback">
                  <div>Customer is required</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div *ngIf="closingBy == 'plan'">
                <label class="font-weight-bold mb-1">Select File</label>
                <div class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    type="file"
                    ng2FileSelect
                    id="file-upload-order"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    [uploader]="uploader"
                    (change)="selectFile($event)"
                  />
                  <label class="custom-file-label">{{ fileLabel }}</label>
                </div>
                <div>
                  <a
                    (click)="downloadTemplate()"
                    class="text-primary font-small-3 d-block font-weight-bold mt-50 text-underline"
                    href="javascript:void(0)"
                    >Template Import</a
                  >
                </div>
              </div>
              <div *ngIf="closingBy == 'actual'">
                <label class="font-weight-bold mb-1">Periode</label>
                <div class="form-group">
                  <p-calendar
                    [(ngModel)]="rangeDates"
                    selectionMode="range"
                    [readonlyInput]="true"
                    [style]="{ width: '100%' }"
                    (onSelect)="selectDate()"
                    [inputStyle]="{ width: '100%', borderRight: 0 }"
                    [showIcon]="true"
                    dateFormat="dd/mm/yy"
                  >
                    <ng-template pTemplate="footer">
                      <div *ngIf="showAlert">
                        <ngb-alert
                          class="mb-0"
                          [type]="'warning'"
                          (closed)="showAlert = false"
                        >
                          <div class="alert-body">
                            <span
                              [data-feather]="'info'"
                              [class]="'mr-50 align-middle text-warning-darken'"
                            ></span>
                            <strong class="text-warning-darken font-small-3"
                              >Maximum Date Range is 7 Days</strong
                            >
                          </div>
                        </ngb-alert>
                      </div>
                    </ng-template>
                  </p-calendar>
                </div>
              </div>
            </div>
            <div class="col-lg-3 d-flex mt-50 align-items-center">
              <button
                type="button"
                class="btn btn-secondary float-right"
                [disabled]="
                  loading ||
                  (closingBy == 'plan' && !orderNumbers.length) ||
                  !customer.id
                "
                rippleEffect
                (click)="showData()"
              >
                <span *ngIf="!loading">Show Data</span>
                <span *ngIf="loading">
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span class="sr-only">Loading...</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body px-0 pt-0 pb-5">
        <app-loader *ngIf="loading"></app-loader>
        <div *ngIf="!loading && proceed" class="p-5 text-center">
          <i
            class="fa-solid mb-2 text-success-darken fa-circle-check font-large-5"
          ></i>
          <h4>Delivery was successfully closed!</h4>
        </div>
        <div
          class="mb-5 pb-5"
          *ngIf="!loading && !isInit"
          style="overflow-x: scroll"
        >
          <p-table
            #pTable
            [value]="data"
            [customSort]="false"
            [scrollable]="true"
            scrollHeight="63vh"
            [autoLayout]="false"
            [paginator]="false"
            [tableStyle]="{ marginBottom: '100px' }"
            [(selection)]="checkedRows"
            [selectAll]="checkedAll"
            (onRowSelect)="onCheckedRow($event)"
            (onRowUnselect)="onCheckedRow($event)"
            (selectAllChange)="onCheckedAll($event)"
            [selectAll]="checkedAll"
          >
            <ng-template pTemplate="header">
              <tr>
                <th style="min-width: 10%">Order Number</th>
                <th style="min-width: 10%">CheckSheet</th>
                <th style="min-width: 10%">Date</th>
                <th style="min-width: 10%">Route</th>
                <th style="min-width: 10%">Status</th>
                <th style="min-width: 10%">Vehicle</th>
                <th style="min-width: 15%">Driver</th>
                <th style="min-width: 8%">Incentive</th>
                <th style="min-width: 8%">Mel</th>
                <th style="min-width: 8%">Toll</th>
                <th style="min-width: 8%">Fuel</th>
                <th style="min-width: 5%">Note</th>
                <th style="min-width: 10%">Closed in Incentive</th>
                <th
                  style="min-width: 52px; width: 52px"
                  alignFrozen="right"
                  pFrozenColumn
                >
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row let-index="rowIndex">
              <tr class="p-selectable-row">
                <td style="min-width: 10%">
                  <p
                    (click)="
                      curRow = row;
                      curIndex = index;
                      note = row.note;
                      openSidebar(row.delivery)
                    "
                    class="font-medium-1 font-weight-bold m-0 cursor-pointer"
                    placement="bottom"
                    ngbTooltip="Click for detail"
                  >
                    <strong>{{ row.order.order_number }}</strong>
                  </p>
                </td>
                <td style="min-width: 10%">
                  <p class="font-medium-1 font-weight-bold m-0 cursor-pointer">
                    <strong>{{ row.delivery.code }}</strong>
                  </p>
                </td>
                <td style="min-width: 10%">
                  <p class="font-medium-1 font-weight-bold m-0">
                    {{ row.delivery.date | date : 'dd/MM/Y' }}
                  </p>
                </td>
                <td style="min-width: 10%">
                  <div class="cell-line-height">
                    <p class="font-weight-bolder line-height-1 mb-25">
                      {{ row.route.code }}
                    </p>
                    <span class="text-secondary font-small-3">
                      {{ row.route.name }}
                    </span>
                  </div>
                </td>
                <td style="min-width: 10%">
                  <span
                    class="text-status font-weight-bold text-uppercase font-medium-1 {{
                      row.is_closed ? 'closed' : 'open'
                    }}"
                  >
                    {{ row.is_closed ? 'CLOSED' : 'OPEN' }}
                  </span>
                </td>
                <td class="align-center" style="min-width: 10%">
                  <div class="cell-line-height">
                    <p
                      class="font-medium-1 font-weight-bolder line-height-1 mb-25"
                    >
                      {{ row.delivery.vehicle?.number }}
                    </p>
                    <span class="text-secondary font-small-4 font-weight-bold">
                      {{ row.delivery.vehicle?.license_plate_number }}
                    </span>
                  </div>
                </td>
                <td style="min-width: 15%">
                  <div class="cell-line-height">
                    <p
                      class="font-medium-1 font-weight-bold line-height-1 mb-25"
                    >
                      {{ row.delivery.driver?.fullname }}
                    </p>
                    <span
                      *ngIf="
                        row.delivery.driver?.id !== 800 ||
                        row.delivery.driver?.id !== 600
                      "
                      class="text-secondary-darken font-small-4 font-weight-bold"
                    >
                      {{ row.delivery.driver?.employee_id }}
                    </span>
                  </div>
                </td>
                <td style="min-width: 8%" class="d-flex align-items-start">
                  <div class="font-medium-1 font-weight-bold">
                    {{
                      row.delivery.incentive.incentive +
                        row.delivery.incentive.incentive_overtime_delivery
                        | localCurrency
                    }}
                  </div>
                </td>
                <td style="min-width: 8%" class="d-flex align-items-start">
                  <div class="font-medium-1 font-weight-bold">
                    {{ row.delivery.mel ?? 0 | localCurrency }}
                  </div>
                </td>
                <td style="min-width: 8%" class="d-flex align-items-start">
                  <div class="font-medium-1 font-weight-bold">
                    {{ row.delivery.toll ?? 0 | localCurrency }}
                  </div>
                </td>
                <td
                  style="min-width: 8%"
                  class="d-block text-right align-top font-medium-1 font-weight-bold"
                >
                  {{
                    (row.delivery.fuel_price ?? 0) *
                      row.delivery.fuel_consumption | localCurrency
                  }}
                  <span
                    class="text-warning-darken font-weight-bolder d-block font-small-4"
                    *ngIf="row.delivery.fuel_consumption"
                  >
                    {{ row.delivery.fuel_consumption }}L
                  </span>
                </td>
                <td style="min-width: 5%">
                  <button
                    (click)="
                      visibleNotes = true;
                      getNotes(row.delivery.id);
                      curRow = row;
                      curIndex = index;
                      note = row.note
                    "
                    type="button"
                    class="btn btn-icon btn-icon rounded-circle btn-flat-secondary"
                    rippleEffect
                  >
                    <i class="fa-regular fa-message-lines"></i>
                  </button>
                </td>
                <td
                  style="min-width: 10%"
                  class="text-secondary-darken font-small-5 font-weight-bold"
                >
                  {{
                    row.delivery.incentive.is_closed ? 'CLOSED' : 'NOT CLOSED'
                  }}
                </td>
                <td
                  style="min-width: 52px; width: 52px"
                  alignFrozen="right"
                  pFrozenColumn
                >
                  <p-tableCheckbox
                    *ngIf="!row.is_closed"
                    [disabled]="row.delivery.status == 'closed'"
                    [value]="row"
                  ></p-tableCheckbox>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="13">
                  <div class="text-center mx-auto align-items-center mt-5">
                    <i
                      data-feather="alert-circle"
                      size="35"
                      class="mb-3"
                      class="not-found-icon"
                    ></i>
                    <h5 class="text-muted mt-1">Data not found.</h5>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
      <div
        class="card-footer fixed-actions d-flex align-items-center justify-content-between"
      >
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="formLoading || !checkedRows.length"
          rippleEffect
          (click)="onProcess()"
        >
          <span *ngIf="!formLoading">Process</span>
          <span *ngIf="formLoading">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only">Loading...</span>
          </span>
        </button>
        <div class="d-flex mr-5" style="width: 30%">
          <div class="mr-1">
            <h4
              class="font-weight-bolder text-secondary-darken d-inline font-medium-5"
            >
              {{ open }}
            </h4>
            <span class="font-weight-bold text-secondary-darken font-medium-1"
              >Open</span
            >
          </div>
          <div class="mr-1">
            <h4
              class="font-weight-bolder text-secondary-darken d-inline font-medium-5"
            >
              {{ partiallyClosed }}
            </h4>
            <span class="font-weight-bold text-secondary-darken font-medium-1"
              >Partially Closed</span
            >
          </div>
          <div>
            <h4
              class="font-weight-bolder text-secondary-darken d-inline font-medium-5"
            >
              {{ closed }}
            </h4>
            <span class="font-weight-bold text-secondary-darken font-medium-1"
              >Closed</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<core-sidebar
  name="detail-delivery-sidebar"
  [style.width]="sidebarOpen ? '500px' : '400px'"
  class="customizer d-none d-md-block"
>
  <div class="customizer-content">
    <div class="customizer-header p-2 position-relative border-bottom">
      <h4>Delivery</h4>
      <a class="customizer-close" (click)="closeSidebar(); sidebarOpen = false"
        ><span [data-feather]="'x'"></span
      ></a>
    </div>
    <div class="customizer-body p-1">
      <ng-container *ngIf="sidebarOpen">
        <app-checksheet-sidebar [detail]="detail"></app-checksheet-sidebar>
        <div class="mt-3 mb-2">
          <h5 class="font-weight-bold">Notes</h5>

          <div *ngIf="chatLoading" class="w-100 h-100 text-center">
            <div
              class="spinner-border mx-auto my-5"
              style="width: 3rem; height: 3rem"
              role="status"
            >
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div
            *ngIf="!chats.length && !chatLoading"
            class="p-3 text-center border rounded"
          >
            <i
              class="fa-regular fa-message-lines font-large-3 mb-1 text-secondary-darken"
            ></i>
            <h6 class="text-secondary-light font-small-5">Notes is empty</h6>
          </div>
          <app-delivery-notes
            *ngIf="chats.length && !chatLoading"
            [chats]="chats"
            [fromDialog]="false"
          ></app-delivery-notes>
        </div>
      </ng-container>
    </div>
    <div class="costumizer-footer">
      <div *ngIf="sidebarOpen" class="d-flex align-items-start p-1">
        <div class="d-flex">
          <button
            type="button"
            class="btn btn-icon btn-icon rounded-circle btn-flat-secondary m-0"
            (click)="toggled = !toggled"
            rippleEffect
          >
            <span [data-feather]="'smile'"></span>
          </button>
        </div>
        <div class="d-flex w-100">
          <div class="mx-1 w-100">
            <textarea
              #inputNotes
              placeholder="Type your message"
              class="form-control message"
              rows="1"
              id="chat-message-input"
              textareaAutoresize
              [(ngModel)]="data[curIndex].note"
              (click)="onInputChange($event)"
              (keydown.ArrowRight)="onInputChange($event)"
              (keydown.ArrowLeft)="onInputChange($event)"
              (keydown.Space)="onInputChange($event)"
              (keydown.backspace)="onInputChange($event)"
            ></textarea>
          </div>
          <div>
            <button
              type="button"
              rippleeffect
              (click)="sendNotes()"
              [disabled]="noteLoading"
              class="m-0 btn btn-icon btn-primary waves-effect waves-float waves-light"
            >
              <span *ngIf="!noteLoading">
                <i data-feather="send"></i>
              </span>
              <span *ngIf="noteLoading">
                <span
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Loading...</span>
              </span>
            </button>
          </div>
        </div>
        <div *ngIf="toggled" style="position: absolute; bottom: 4rem">
          <emoji-mart
            [showPreview]="false"
            (emojiClick)="handleEmoji($event, inputNotes)"
          ></emoji-mart>
        </div>
      </div>
    </div>
  </div>
</core-sidebar>

<p-dialog
  header="Header"
  [(visible)]="visibleNotes"
  [style]="{ width: '30vw' }"
  (onHide)="onHideDialog()"
>
  <ng-template pTemplate="header">
    <div class="d-flex align-items-center">
      <div class="avatar mr-2">
        <img
          alt="Avatar"
          width="34"
          height="34"
          [src]="user.photo_id | img : 50 : 50"
        />
        <span class="avatar-status-online"></span>
      </div>
      <div>
        <h5 class="mb-0">{{ user.fullname }}</h5>
        <small class="text-secondary-light font-small-5">{{
          user.jobs.job_title.name
        }}</small>
      </div>
    </div>
  </ng-template>
  <div *ngIf="chatLoading" class="w-100 h-100 text-center">
    <div
      class="spinner-border mx-auto my-5"
      style="width: 3rem; height: 3rem"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <app-delivery-notes
    *ngIf="!chatLoading"
    [chats]="chats"
    [fromDialog]="true"
  ></app-delivery-notes>
  <ng-template pTemplate="footer">
    <div class="d-flex align-items-start">
      <div class="d-flex">
        <button
          type="button"
          class="btn btn-icon btn-icon rounded-circle btn-flat-secondary m-0"
          rippleEffect
          (click)="toggled = !toggled"
        >
          <span [data-feather]="'smile'"></span>
        </button>
      </div>
      <div class="d-flex w-100">
        <div class="mx-1 w-100">
          <textarea
            #inputNotes
            placeholder="Type your message"
            class="form-control message"
            rows="1"
            id="chat-message-input"
            textareaAutoresize
            [(ngModel)]="data[curIndex].note"
            (click)="onInputChange($event)"
            (keydown.ArrowRight)="onInputChange($event)"
            (keydown.ArrowLeft)="onInputChange($event)"
            (keydown.Space)="onInputChange($event)"
            (keydown.backspace)="onInputChange($event)"
          ></textarea>
        </div>
        <div>
          <button
            type="button"
            rippleeffect
            (click)="sendNotes()"
            [disabled]="noteLoading"
            class="m-0 btn btn-icon btn-primary waves-effect waves-float waves-light"
          >
            <span *ngIf="!noteLoading">
              <i data-feather="send"></i>
            </span>
            <span *ngIf="noteLoading">
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Loading...</span>
            </span>
          </button>
        </div>
      </div>
      <div *ngIf="toggled" style="position: absolute; bottom: 4rem">
        <emoji-mart
          [showPreview]="false"
          (emojiClick)="handleEmoji($event, inputNotes)"
        ></emoji-mart>
      </div>
    </div>
  </ng-template>
</p-dialog>

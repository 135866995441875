<ng-template #modal>
  <form [formGroup]="group" (ngSubmit)="hasSubmitted()">
    <div class="modal-header">
      <h5 class="modal-title">{{ modalConfig.modalTitle }}</h5>
      <button type="button" class="close" (click)="close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <ng-content></ng-content>
    </div>
    <div class="modal-footer">
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="loading"
        rippleEffect
      >
        <span *ngIf="!loading">{{ modalConfig.buttonPrimaryLabel }}</span>
        <span *ngIf="loading">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="sr-only">Loading...</span>
        </span>
      </button>
      <button
        type="button"
        class="btn btn-grey"
        [disabled]="loading"
        (click)="close()"
        rippleEffect
      >
        Cancel
      </button>
    </div>
  </form>
</ng-template>

import { CoreMenu } from '@core/types';

export const menu: CoreMenu[] = [
  {
    id: 'delivery',
    title: 'Delivery',
    type: 'collapsible',
    icon: 'globe',
    role: [
      'checkSheet.view',
      'deliveryOrder.view',
      'closing.view',
      'customer.view',
      'route.view',
      'incident.view',
      'deliveryManpower.view',
    ],
    children: [
      {
        id: 'check-sheets',
        title: 'CheckSheets',
        type: 'item',
        url: 'check-sheets',
        role: ['checkSheet.view'],
      },
      {
        id: 'delivery-orders',
        title: 'Delivery Orders',
        type: 'item',
        url: 'delivery-orders',
        role: ['deliveryOrder.view'],
      },
      {
        id: 'closing',
        title: 'Closing',
        type: 'item',
        url: 'closing',
        role: ['closing.view'],
      },
      {
        id: 'customers',
        title: 'Customers',
        type: 'item',
        url: 'customers',
        role: ['customer.view'],
      },
      {
        id: 'routes',
        title: 'Routes',
        type: 'item',
        url: 'routes',
        role: ['route.view'],
      },
      {
        id: 'incidents',
        title: 'Delivery Incidents',
        type: 'item',
        url: 'delivery-incidents',
        role: ['incident.view'],
      },
      {
        id: 'delivery-manpower',
        title: 'Delivery Manpower',
        type: 'item',
        url: 'delivery-manpowers',
        role: ['deliveryManpower.view'],
      },
    ],
  },
  {
    id: 'e-tolls',
    title: 'E-Tolls',
    type: 'item',
    icon: 'credit-card',
    url: 'e-tolls',
    role: ['eToll.transaction.view', 'eToll.topUp.view', 'eToll.balance.view'],
  },
  {
    id: 'section-2',
    title: '',
    type: 'section',
    role: [
      'employee.view',
      'employeeDocument.view',
      'birthday.view',
      'attendance.view',
      'schedule.view',
      'attendanceTimeline.view',
      'requestLeave.view',
      'requestGeneral.view',
      'leaveBalance.view',
      'overtime.reporting.request',
      'payslip.view',
      'payrollData.view',
      'closing.incentive.view',
      'driverTrainings.view',
      'tanoko.view',
    ],
  },
  {
    id: 'employee',
    title: 'Employee',
    type: 'collapsible',
    icon: 'users',
    role: [
      'employee.view',
      'employeeDocument.view',
      'birthday.view',
      'healthCheck.view',
      'sickMonitoring.view',
    ],
    children: [
      {
        id: 'employees',
        title: 'Employee List',
        type: 'item',
        url: 'employees',
        role: ['employee.view'],
      },
      {
        id: 'employee-documents',
        title: 'Documents',
        type: 'item',
        url: 'employee-documents',
        role: ['employeeDocument.view'],
      },
      {
        id: 'birthdays',
        title: 'Birthdays',
        type: 'item',
        url: 'birthdays',
        role: ['birthday.view'],
      },
      {
        id: 'health-check',
        title: 'Medical Records',
        type: 'item',
        url: 'medical-records',
        role: ['healthCheck.view'],
      },
      {
        id: 'sick-monitoring',
        title: 'Sick Monitoring',
        type: 'item',
        url: 'sick-monitoring',
        role: ['sickMonitoring.view'],
      },
    ],
  },
  {
    id: 'attendance',
    title: 'Attendance',
    type: 'collapsible',
    icon: 'clock',
    role: ['attendance.view', 'schedule.view', 'attendanceTimeline.view'],
    children: [
      {
        id: 'schedules',
        title: 'Schedules',
        type: 'item',
        url: 'schedules',
        role: ['schedule.view'],
      },
      {
        id: 'attendances',
        title: 'Attendance List',
        type: 'item',
        url: 'attendances',
        role: ['attendance.view'],
      },
      {
        id: 'timeline',
        title: 'Timeline',
        type: 'item',
        url: 'attendance-timeline',
        role: ['attendanceTimeline.view'],
      },
    ],
  },
  {
    id: 'leave-and-request',
    title: 'Leave & Request',
    type: 'collapsible',
    icon: 'clipboard',
    role: [
      'requestLeave.view',
      'requestGeneral.view',
      'leaveBalance.view',
      'overtime.reporting.request',
    ],
    children: [
      {
        id: 'leave-requests',
        title: 'Leave Requests',
        type: 'item',
        url: 'requests/leave',
        role: ['requestLeave.view'],
      },
      {
        id: 'leave-balances',
        title: 'Leave Balances',
        type: 'item',
        url: 'leave-balances',
        role: ['leaveBalance.view'],
      },
      {
        id: 'general-requests',
        title: 'General Requests',
        type: 'item',
        url: 'requests/general',
        role: ['requestGeneral.view'],
      },
      {
        id: 'overtime-reporting',
        title: 'Overtime Reporting',
        type: 'item',
        url: 'overtime',
        role: ['overtime.reporting.request', 'overtime.reporting.approval'],
      },
    ],
  },
  {
    id: 'payroll',
    title: 'Payroll',
    type: 'collapsible',
    icon: 'dollar-sign',
    role: ['payslip.view', 'payrollData.view'],
    children: [
      {
        id: 'payslips',
        title: 'Payslips',
        type: 'item',
        url: 'payslips',
        role: ['payslip.view'],
      },
      {
        id: 'payroll-data',
        title: 'Payroll Data',
        type: 'item',
        url: 'payroll-data',
        role: ['payrollData.view'],
      },
      {
        id: 'closing-incentive',
        title: 'Closing Incentive',
        type: 'item',
        url: 'closing-incentive',
        role: ['closing.incentive.view'],
      },
    ],
  },
  {
    id: 'driver-performance',
    title: 'Driver Performance',
    type: 'collapsible',
    icon: 'check-circle',
    role: ['driverTraining.view', 'driverAssessment.view', 'tanoko.view'],
    children: [
      {
        id: 'training-assessment',
        title: 'Training & Assessment',
        type: 'item',
        url: 'driver-performance',
        role: ['driverTraining.view', 'driverAssessment.view'],
      },
      {
        id: 'tanoko',
        title: 'Tanoko',
        type: 'item',
        url: 'tanoko',
        role: ['tanoko.view'],
      },
    ],
  },

  {
    id: 'section-3',
    title: '',
    type: 'section',
    role: [
      'vehicle.view',
      'vehicleDocument.view',
      'maintenanceSchedule.view',
      'maintenanceJob.view',
      'maintenanceOrder.view',
      'maintenanceFollowUp.view',
      'inspections.view',
      'purchaseRequest.view',
    ],
  },
  {
    id: 'purchase',
    type: 'collapsible',
    title: 'Purchase',
    icon: 'shopping-cart',
    role: ['vendor.view', 'purchaseRequest.view'],
    children: [
      {
        id: 'vendors',
        title: 'Vendors',
        type: 'item',
        url: 'vendors',
        role: ['vendor.view'],
      },
      {
        id: 'purchase-request',
        title: 'Purchase Request',
        type: 'item',
        url: 'purchase-request',
        role: ['purchaseRequest.view'],
      },
    ],
  },
  {
    id: 'stock',
    title: 'Stock',
    type: 'collapsible',
    icon: 'box',
    role: [
      'item.view',
      'stockBalance.view',
      'stockEntry.view',
      'stockReconciliation.view',
    ],
    children: [
      {
        id: 'item',
        title: 'Items',
        type: 'item',
        url: 'items',
        role: ['item.view'],
      },
      {
        id: 'stock-balance',
        title: 'Stock Balance',
        type: 'item',
        url: 'stocks/balances',
        role: ['stockBalance.view'],
      },
      {
        id: 'stock-entry',
        title: 'Stock Entry',
        type: 'item',
        url: 'stocks/entry',
        role: ['stockEntry.view'],
      },
      {
        id: 'stock-reconciliation',
        title: 'Stock Reconciliation',
        type: 'item',
        url: 'stocks/reconciliation',
        role: ['stockReconciliation.view'],
      },
    ],
  },
  {
    id: 'section-4',
    title: '',
    type: 'section',
    role: ['report.view'],
  },
  {
    id: 'vehicle',
    type: 'collapsible',
    title: 'Vehicle',
    icon: 'truck',
    role: ['vehicle.view', 'vehicleDocument.view'],
    children: [
      {
        id: 'vehicles',
        title: 'Vehicle List',
        type: 'item',
        url: 'vehicles',
        role: ['vehicle.view'],
      },
      {
        id: 'vehicle-documents',
        title: 'Documents',
        type: 'item',
        url: 'vehicle-documents',
        role: ['vehicleDocument.view'],
      },
      {
        id: 'inspections',
        title: 'Inspections',
        type: 'item',
        url: 'inspections',
        role: ['inspections.view'],
      },
    ],
  },
  {
    id: 'maintenance',
    title: 'Maintenance',
    type: 'collapsible',
    icon: 'wind',
    role: [
      'maintenanceSchedule.view',
      'maintenanceJob.view',
      'maintenanceOrder.view',
      'maintenanceFollowUp.view',
      'periodicService.view',
    ],
    children: [
      // {
      //   id: 'maintenance-schedules',
      //   title: 'Schedule',
      //   type: 'item',
      //   url: 'maintenance/schedules',
      //   role: ['maintenanceSchedule.view'],
      // },
      {
        id: 'maintenance-orders',
        title: 'Maintenance Orders',
        type: 'item',
        url: 'maintenance/orders',
        role: ['maintenanceOrder.view'],
      },
      {
        id: 'follow-up-maintenance',
        title: 'Follow Up Maintenance',
        type: 'item',
        url: 'maintenance/follow-up-maintenance',
        role: ['maintenanceFollowUp.view'],
      },
      {
        id: 'periodic-service',
        title: 'Periodic Service',
        type: 'item',
        url: 'maintenance/periodic-service',
        role: ['periodicService.view'],
      },
      // {
      //   id: 'periodic-service',
      //   title: 'Periodic Service Migration',
      //   type: 'item',
      //   url: 'maintenance/periodic-service-migration',
      //   role: ['periodicService.view'],
      // },
    ],
  },
  {
    id: 'section-5',
    title: '',
    type: 'section',
    role: ['report.view'],
  },
  {
    id: 'report',
    title: 'Report',
    type: 'item',
    icon: 'bar-chart',
    url: 'reports',
    role: ['report.view'],
  },
  // {
  //   id: 'section-1',
  //   title: 'Dashboard',
  //   type: 'section',
  //   role: ['dashboard'],
  // },
  // {
  //   id: 'dashboard-attendance',
  //   title: 'Attendance',
  //   type: 'collapsible',
  //   icon: 'clock',
  //   role: ['dashboard.attendance'],
  //   children: [
  //     {
  //       id: 'dashboard-attendance-summary',
  //       title: 'Summary',
  //       type: 'item',
  //       url: 'dashboard/attendance/summary',
  //       role: ['dashboard.attendance.summary'],
  //     },
  //     {
  //       id: 'dashboard-attendance-today',
  //       title: 'Today',
  //       type: 'item',
  //       url: 'dashboard/attendance/today',
  //       role: ['dashboard.attendance.today'],
  //     },
  //   ],
  // },
  // {
  //   id: 'dashboard-inspections',
  //   title: 'Inspections',
  //   type: 'item',
  //   icon: 'check-square',
  //   url: 'dashboard/inspection',
  //   role: ['dashboard.inspection'],
  // },
];

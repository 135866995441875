<li class="nav-item d-none d-lg-block">
  <label class="mb-0 mr-2">
    <div class="input-group">
      <div ngbDropdown class="input-group-prepend">
        <span
          class="input-group-text cursor-pointer"
          ngbDropdownToggle
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i data-feather="search"></i>
        </span>
        <div ngbDropdownMenu>
          <ng-scrollbar [ngStyle]="{ height: '30rem', width: '100%' }">
            <a
              ngbDropdownItem
              href="javascript:void(0)"
              class="d-flex"
              *ngFor="let category of searchCategories"
              (click)="setCategory(category.key)"
            >
              <div class="mr-50">
                {{ category.label }}
              </div>
              <div *ngIf="search.category === category.key">
                <i class="fa-regular fa-check"></i>
              </div>
            </a>
            <div class="dropdown-divider"></div>
            <a
              ngbDropdownItem
              href="javascript:void(0)"
              class="text-primary d-flex"
              (click)="onAdvancedSearch()"
            >
              <div class="mr-50">Advanced Search</div>
              <div>
                <i class="fa-regular fa-magnifying-glass"></i>
              </div>
            </a>
          </ng-scrollbar>
        </div>
      </div>
      <input
        #inputSearch
        type="text"
        placeholder="{{ getPlaceholder() }}"
        class="form-control"
        (keyup.enter)="onSearch($event)"
      />
    </div>
  </label>
</li>
<ng-template #modalAdvancedSearch let-modal>
  <form
    [formGroup]="form"
    (ngSubmit)="onSearchMultiple()"
    class="form form-horizontal"
  >
    <div class="modal-header row ml-0 mr-0">
      <div class="col-6 pl-0">
        <div class="form-group row">
          <div class="col-sm-4 col-form-label">
            <label>Search</label>
          </div>
          <div class="col-sm-8">
            <ng-select
              formControlName="category"
              [items]="searchCategories"
              bindLabel="label"
              bindValue="key"
              [clearable]="false"
              (change)="setCategory($event, true)"
            >
            </ng-select>
          </div>
        </div>
      </div>
      <div class="col-6">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="onCancel()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-6">
          <div
            class="form-group row"
            *ngFor="
              let input of formTermsInputs[search.category]
                | filterArray : { colPosition: 'left' }
            "
          >
            <div class="col-sm-4 col-form-label">
              <label>{{ input.label }}</label>
            </div>
            <div class="col-sm-8">
              <app-input
                [form]="input"
                [group]="formTermGroups[search.category]"
                [type]="input.type"
                [submitted]="formSubmitted"
                [controls]="formTermGroups[search.category].controls"
              ></app-input>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div
            class="form-group row"
            *ngFor="
              let input of formTermsInputs[search.category]
                | filterArray : { colPosition: 'right' }
            "
          >
            <div class="col-sm-4 col-form-label">
              <label>{{ input.label }}</label>
            </div>
            <div class="col-sm-8 {{ input?.class || '' }}">
              <app-input
                [form]="input"
                [group]="formTermGroups[search.category]"
                [type]="input.type"
                [submitted]="formSubmitted"
                [controls]="formTermGroups[search.category].controls"
              ></app-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer justify-content-center">
      <button type="submit" class="btn btn-primary" rippleEffect>Search</button>
      <button
        type="button"
        class="btn btn-grey"
        rippleEffect
        (click)="onCancel()"
      >
        Cancel
      </button>
    </div>
  </form>
</ng-template>

import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'findData',
})
export class FindDataPipe implements PipeTransform {
  transform(inputs: any[], filter: Object): Object {
    return _.find(inputs, filter);
  }
}

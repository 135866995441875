import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from 'app/auth/service';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule } from '@core/components';

import { coreConfig } from 'app/app-config';
import { AuthGuard } from 'app/auth/helpers/auth.guards';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ApiService } from '../@core/services/api.service';
import { jwtInterceptorProviders } from './auth/helpers';
import { CloudinaryModule } from '@cloudinary/ng';
import { Socket, SocketIoModule } from 'ngx-socket-io';
import { environment } from '../environments/environment';
import { TableModule } from 'primeng/table';
import { MenuModule } from './layout/components/menu/menu.module';
import { NgxImageCompressService } from 'ngx-image-compress';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./main/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/delivery/delivery.module').then((m) => m.DeliveryModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/attendance/attendances.module').then(
        (m) => m.AttendancesModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('app/main/vehicle/vehicle.module').then((m) => m.VehicleModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/request/request.module').then((m) => m.RequestModule),
  },
  {
    path: 'employees',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/employee/employees/employees.module').then(
        (m) => m.EmployeesModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/payrolls/payrolls.module').then((m) => m.PayrollsModule),
  },
  // {
  //   path: '',
  //   canActivate: [AuthGuard],
  //   loadChildren: () =>
  //     import('./main/stock/stock.module').then((m) => m.StockModule),
  // },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/purchase/purchase.module').then((m) => m.PurchaseModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/stock/stock.module').then((m) => m.StockModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/employee/employee.module').then((m) => m.EmployeeModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/driver-performance/driver-performance.module').then(
        (m) => m.DriverPerformanceModule
      ),
  },
  {
    path: 'pages',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'maintenance',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule
      ),
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/report/report.module').then((m) => m.ReportsModule),
  },
  {
    path: 'maintenance',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./main/maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule
      ),
  },
  // {
  //   path: '',
  //   redirectTo: '/dashboard/welcome',
  //   pathMatch: 'full',
  // },
  // {
  //   path: '**',
  //   redirectTo: '/pages/miscellaneous/error', //Error 404 - Page not found
  // },
];

// const socketConfig: SocketIoConfig = {
//   url: environment.apiUrl,
//   options: {
//     // transports: ['websocket', 'polling'],
//     path: (environment.production ? '/api' : '') + '/socket.io',
//   },
// };

@Injectable()
export class SocketApi extends Socket {
  constructor() {
    super({
      url: environment.socketUrl,
      options: {
        path: '/socket.io',
        transports: ['websocket'],
      },
    });
  }
}

export function permissionsFactory(
  _authService: AuthService,
  _permissionsService: NgxPermissionsService
) {
  return () => {
    return _authService
      .loadPermissions()
      .then((perm) => _permissionsService.loadPermissions(perm));
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TableModule,
    MenuModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
    }),
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    NgxPermissionsModule.forRoot(),

    // App modules
    LayoutModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    // CloudinaryModule,
    SweetAlert2Module.forRoot(),
    SocketIoModule,
  ],
  providers: [
    ApiService,
    jwtInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: permissionsFactory,
      deps: [AuthService, NgxPermissionsService],
      multi: true,
    },
    SocketApi,
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { DeliveryNotesService } from './delivery-notes.service';
import { takeUntil, Subject } from 'rxjs';
import { NgScrollbar } from 'ngx-scrollbar';

@Component({
  selector: 'app-delivery-notes',
  templateUrl: './delivery-notes.component.html',
  styleUrls: ['./delivery-notes.component.scss'],
})
export class DeliveryNotesComponent implements OnInit, OnDestroy {
  @Input() chats: any;
  @Input() fromDialog: boolean;

  @ViewChild(NgScrollbar) scrollbar: NgScrollbar;

  public user = JSON.parse(localStorage.getItem('alsuser')).employee;
  private _unsubscribeAll: Subject<any>;

  constructor(private _deliveryNoteService: DeliveryNotesService) {
    this._unsubscribeAll = new Subject<void>();
    this._deliveryNoteService.isReadyScroll
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((ready) => {
        if (ready) this.scrollToBottom();
      });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._unsubscribeAll.next(void 0);
    this._unsubscribeAll.complete();
  }

  scrollToBottom() {
    this.scrollbar.scrollTo({bottom: 0, end: 0, duration: 300});
    this._deliveryNoteService.setAfterScroll();
  }
}

<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span *ngIf="data.unread" class="badge badge-pill badge-danger badge-up">{{
      data.unread
    }}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary" *ngIf="data.unread">
          {{ data.unread }} New
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list">
      <ng-scrollbar>
        <a
          class="d-flex"
          (click)="openNotif(notif.delivery, i)"
          href="javascript:void(0)"
          *ngFor="let notif of data.notifications; let i = index"
        >
          <div class="media d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-start justify-content-between">
              <div class="media-left">
                <div class="avatar">
                  <img
                    [src]="notif.employee.photo_id | img : 32 : 32"
                    alt="avatar"
                    width="32"
                    height="32"
                  />
                </div>
              </div>
              <div class="media-body">
                <p
                  class="media-heading"
                  [innerHTML]="
                    notif.employee.fullname + ' add new delivery note.'
                  "
                ></p>
                <small class="notification-text"
                  >Note For Delivery {{ notif.delivery.code }}</small
                >
              </div>
            </div>
            <div *ngIf="!notif.read">
              <span
                style="
                  display: block;
                  width: 10px;
                  height: 10px;
                  border-radius: 100%;
                  background-color: var(--warning);
                "
              ></span>
            </div></div
        ></a>
        <div
          *ngIf="!data.notifications.length"
          class="p-3 text-center mx-auto rounded"
        >
          <i
            class="fa-solid fa-bell-slash font-large-2 mb-1 text-secondary-light"
          ></i>
          <h6 class="text-secondary-light font-small-5">
            New Notifications Empty
          </h6>
        </div>
      </ng-scrollbar>
    </li>
    <!--/ Notifications content -->
  </ul>
</li>

<app-modal-delivery-notes
  *ngIf="showDialog"
  [visible]="showDialog"
  [loading]="loading"
  [deliveryId]="delivery.id"
  [deliveryCode]="delivery.code"
  (onClose)="closeDialog($event)"
></app-modal-delivery-notes>

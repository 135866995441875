// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  socketUrl: 'https://staging-api.armas.id/',
  apiUrl: 'https://staging-api.armas.id/als/v4/api/',
  cmsUrl: 'https://staging-cms.armas.id/als/v4/',
  serviceUrl: 'http://localhost:3020/',
  s3Url: 'https://s3.armas.id/',
  imgUrl: 'https://ik.imagekit.io/jiwalu/als/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { CoreMenu } from '@core/types';

export const settingsMenu: CoreMenu[] = [
  {
    id: 'back',
    title: '← Back',
    type: 'item',
    url: 'dashboard/welcome',
  },
  {
    id: 'delivery',
    title: 'Delivery',
    type: 'collapsible',
    icon: 'truck',
    role: [
      'setting.logisticPoint.view',
      'setting.pool.view',
      'setting.eToll.view',
      'setting.incidentType.view',
    ],
    children: [
      {
        id: 'logistic-point',
        title: 'Logistic Point',
        type: 'item',
        url: 'settings/delivery/logistic-points',
        role: ['setting.logisticPoint.view'],
      },
      {
        id: 'pool',
        title: 'Pool',
        type: 'item',
        url: 'settings/delivery/pools',
        role: ['setting.pool.view'],
      },
      {
        id: 'e-toll',
        title: 'E-Tolls',
        type: 'item',
        url: 'settings/delivery/e-tolls',
        role: ['setting.eToll.view'],
      },
      {
        id: 'incident-types',
        title: 'Incident Types',
        type: 'item',
        url: 'settings/delivery/incident-types',
        role: ['setting.incidentType.view'],
      },
    ],
  },
  {
    id: 'section-1',
    title: '',
    type: 'section',
    role: ['setting.role.view', 'setting.permission.view'],
  },
  {
    id: 'roles-permission',
    title: 'Roles & Permissions',
    type: 'collapsible',
    icon: 'lock',
    role: ['setting.role.view', 'setting.permission.view'],
    children: [
      {
        id: 'roles',
        title: 'Roles',
        type: 'item',
        url: 'settings/auth/roles',
        role: ['setting.role.view'],
      },
      {
        id: 'permissions',
        title: 'Permissions',
        type: 'item',
        url: 'settings/auth/permissions',
        role: ['setting.permission.view'],
      },
    ],
  },
  {
    id: 'section-2',
    title: '',
    type: 'section',
    role: [
      'setting.payrollType.view',
      'setting.payrollComponent.view',
      'setting.payrollVariable.view',
      'setting.division.view',
      'setting.employmentStatus.view',
      'setting.grade.view',
      'setting.organization.view',
      'setting.skill.view',
      'setting.education.view',
      'setting.license.view',
      'setting.training.view',
      'setting.workSchedule.view',
      'setting.fingerprint.view',
      'setting.office.view',
      'setting.healthTypes.view',
      'setting.healthComponents.view',
    ],
  },
  {
    id: 'payroll',
    title: 'Payroll',
    type: 'collapsible',
    icon: 'dollar-sign',
    role: [
      'setting.payrollType.view',
      'setting.payrollComponent.view',
      'setting.payrollVariable.view',
    ],
    children: [
      {
        id: 'payment-types',
        title: 'Payment Types',
        type: 'item',
        url: 'settings/payroll/payment-types',
        role: ['setting.payrollType.view'],
      },
      {
        id: 'components',
        title: 'Components',
        type: 'item',
        url: 'settings/payroll/components',
        role: ['setting.payrollComponent.view', 'setting.payrollVariable.view'],
      },
    ],
  },
  {
    id: 'job',
    title: 'Job',
    type: 'collapsible',
    icon: 'layers',
    role: [
      'setting.division.view',
      'setting.employmentStatus.view',
      'setting.grade.view',
      'setting.organization.view',
    ],
    children: [
      {
        id: 'divisions',
        title: 'Divisions',
        type: 'item',
        url: 'settings/job/divisions',
        role: ['setting.division.view'],
      },
      {
        id: 'employment-status',
        title: 'Employment Status',
        type: 'item',
        url: 'settings/job/employment-status',
        role: ['setting.employmentStatus.view'],
      },
      {
        id: 'grades',
        title: 'Grades',
        type: 'item',
        url: 'settings/job/grades',
        role: ['setting.grade.view'],
      },
      {
        id: 'organization-structures',
        title: 'Organizations',
        type: 'item',
        url: 'settings/job/organizations',
        role: ['setting.organization.view'],
      },
    ],
  },
  {
    id: 'qualifications',
    title: 'Qualifications',
    type: 'collapsible',
    icon: 'user-check',
    role: [
      'setting.skill.view',
      'setting.education.view',
      'setting.license.view',
      'setting.training.view',
    ],
    children: [
      {
        id: 'skills',
        title: 'Skills',
        type: 'item',
        url: 'settings/qualifications/skills',
        role: ['setting.skill.view'],
      },
      {
        id: 'educations',
        title: 'Educations',
        type: 'item',
        url: 'settings/qualifications/educations',
        role: ['setting.education.view'],
      },
      {
        id: 'licenses',
        title: 'Licenses',
        type: 'item',
        url: 'settings/qualifications/licenses',
        role: ['setting.license.view'],
      },
      {
        id: 'trainings',
        title: 'Trainings',
        type: 'item',
        url: 'settings/qualifications/trainings',
        role: ['setting.training.view'],
      },
    ],
  },
  {
    id: 'attendance',
    title: 'Attendance',
    type: 'collapsible',
    icon: 'calendar',
    role: [
      'setting.workSchedule.view',
      'setting.fingerprint.view',
      'setting.office.view',
    ],
    children: [
      {
        id: 'work-schedules',
        title: 'Work Schedules',
        type: 'item',
        url: 'settings/attendance/work-schedules',
        role: ['setting.workSchedule.view'],
      },
      {
        id: 'fingerprints',
        title: 'Fingerprints',
        type: 'item',
        url: 'settings/attendance/fingerprints',
        role: ['setting.fingerprint.view'],
      },
      {
        id: 'offices',
        title: 'Offices',
        type: 'item',
        url: 'settings/attendance/offices',
        role: ['setting.office.view'],
      },
    ],
  },
  {
    id: 'medical-record',
    title: 'Medical Record',
    type: 'collapsible',
    icon: 'activity',
    role: ['setting.healthTypes.view', 'setting.healthComponents.view'],
    children: [
      {
        id: 'health-types',
        title: 'Medical Types',
        type: 'item',
        url: 'settings/medical/types',
        role: ['setting.healthTypes.view'],
      },
      {
        id: 'health-components',
        title: 'Medical Components',
        type: 'item',
        url: 'settings/medical/components',
        role: ['setting.healthComponents.view'],
      },
    ],
  },
  {
    id: 'section-3',
    title: '',
    type: 'section',
    role: [
      'setting.warehouse.view',
      'setting.unit.view',
      'setting.inspectionTypes.view',
      'setting.inspectionComponents.view',
      'setting.driverTrainingTypes.view',
      'setting.driverTrainingComponents.view',
      'setting.performanceTypes.view',
      'setting.performanceComponents.view',
    ],
  },
  {
    id: 'stock',
    title: 'Stock',
    type: 'collapsible',
    icon: 'package',
    role: [
      'setting.warehouse.view',
      'setting.unit.view',
      'item.group.view',
      'setting.manufacturer.view',
      'setting.brand.view',
      'setting.rack.view',
    ],
    children: [
      {
        id: 'warehouses',
        title: 'Warehouses',
        type: 'item',
        url: 'settings/stocks/warehouses',
        role: ['setting.warehouse.view'],
      },
      {
        id: 'units',
        title: 'UoM',
        type: 'item',
        url: 'settings/stocks/units',
        role: ['setting.unit.view'],
      },
      {
        id: 'groups',
        title: 'Item Groups',
        type: 'item',
        url: 'settings/stocks/groups',
        role: ['item.group.view'],
      },
      {
        id: 'manufacturers',
        title: 'Manufacturers',
        type: 'item',
        url: 'settings/stocks/manufacturers',
        role: ['setting.manufacturer.view'],
      },
      {
        id: 'brands',
        title: 'Brands',
        type: 'item',
        url: 'settings/stocks/brands',
        role: ['setting.brand.view'],
      },
      {
        id: 'racks',
        title: 'Racks',
        type: 'item',
        url: 'settings/stocks/racks',
        role: ['setting.rack.view'],
      },
    ],
  },
  {
    id: 'inspection',
    title: 'Inspection',
    type: 'collapsible',
    icon: 'clipboard',
    role: ['setting.inspectionTypes.view', 'setting.inspectionComponents.view'],
    children: [
      {
        id: 'inspection-types',
        title: 'Inspection Types',
        type: 'item',
        url: 'settings/inspection/types',
        role: ['setting.inspectionTypes.view'],
      },
      {
        id: 'inspection-components',
        title: 'Inspection Components',
        type: 'item',
        url: 'settings/inspection/components',
        role: ['setting.inspectionComponents.view'],
      },
    ],
  },
  {
    id: 'driver-training',
    title: 'Driver Training',
    type: 'collapsible',
    icon: 'award',
    role: [
      'setting.driverTrainingTypes.view',
      'setting.driverTrainingComponents.view',
    ],
    children: [
      {
        id: 'driver-training-types',
        title: 'Driver Training Types',
        type: 'item',
        url: 'settings/driver-training/types',
        role: ['setting.driverTrainingTypes.view'],
      },
      {
        id: 'driver-training-components',
        title: 'Driver Training Components',
        type: 'item',
        url: 'settings/driver-training/components',
        role: ['setting.driverTrainingComponents.view'],
      },
    ],
  },
  {
    id: 'driver-performance',
    title: 'Driver Performance',
    type: 'collapsible',
    icon: 'check',
    role: [
      'setting.performanceTypes.view',
      'setting.performanceComponents.view',
    ],
    children: [
      {
        id: 'performance-types',
        title: 'Performance Types',
        type: 'item',
        url: 'settings/performance/types',
        role: ['setting.performanceTypes.view'],
      },
      {
        id: 'performance-components',
        title: 'Performance Components',
        type: 'item',
        url: 'settings/performance/components',
        role: ['setting.performanceComponents.view'],
      },
    ],
  },

  {
    id: 'section-4',
    title: '',
    type: 'section',
    role: ['setting.log.view'],
  },
  {
    id: 'user-log',
    title: 'User Log',
    type: 'item',
    icon: 'list',
    url: 'settings/logs',
    role: ['setting.log.view'],
  },
  {
    id: 'section-5',
    title: '',
    type: 'section',
    role: ['setting.uploadAccurate.view'],
  },
  {
    id: 'upload-accurate',
    title: 'Upload Accurate',
    type: 'item',
    icon: 'upload',
    url: 'settings/upload-accurate',
    role: ['setting.uploadAccurate.view'],
  },
];

import {
  Component,
  EventEmitter,
  Injectable,
  Input,
  Output,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ModalConfig } from './modal.config';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
@Injectable()
export class ModalComponent implements OnInit {
  @Input() public modalConfig: ModalConfig;
  @Input() public loading: true;
  @Input() public group: any;
  @Output() afterSubmitted = new EventEmitter<any>();

  @ViewChild('modal') private modalContent: TemplateRef<ModalComponent>;
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {}

  async open() {
    this.modalRef = this.modalService.open(this.modalContent);
    this.modalRef.result.then();
  }

  close() {
    this.modalRef.close();
  }

  dismiss() {
    this.modalRef.dismiss();
  }

  hasSubmitted() {
    this.afterSubmitted.emit();
  }
}

<ng-container
  [ngTemplateOutlet]="selectedInput"
  [ngTemplateOutletContext]="{ form: form }"
></ng-container>

<ng-template #inputText let-form="form">
  <div [formGroup]="group">
    <input
      type="text"
      class="form-control"
      formControlName="{{ form.name }}"
      [attr.maxLength]="form.isCustom && form.maxLength ? form.maxLength : ''"
      [ngClass]="{ 'is-invalid': submitted && controls[form.name].errors }"
      placeholder="{{ form?.placeholder }}"
      [readonly]="form?.disabled ? true : null"
    />
    <p *ngIf="form?.helperText">
      <small class="text-muted">{{ form?.helperText }}</small>
    </p>
    <div
      *ngIf="submitted && controls[form.name].errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
      <div *ngIf="controls[form.name].errors?.['minlength']">
        Minimum length is
        {{ controls[form.name].errors?.['minlength']?.['requiredLength']}}
        Digit / Character
      </div>
    </div>
  </div>
</ng-template>

<ng-template #inputNumber let-form="form">
  <div [formGroup]="group">
    <input
      type="number"
      class="form-control"
      [attr.maxLength]="form.isCustom && form.maxLength ? form.maxLength : ''"
      formControlName="{{ form.name }}"
      [ngClass]="{ 'is-invalid': submitted && controls[form.name].errors }"
      [attr.disabled]="form?.disabled"
    />
    <div
      *ngIf="submitted && controls[form.name].errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
      <div *ngIf="controls[form.name].errors?.['min']">
        Minimum value is {{ controls[form.name].errors?.['min']?.['min']}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #inputPassword let-form="form">
  <div [formGroup]="group">
    <div class="input-group input-group-merge form-password-toggle mb-2">
      <input
        [type]="showPassword ? 'text' : 'password'"
        class="form-control"
        formControlName="{{ form.name }}"
        autocomplete="new-password"
        id="basic-default-password1"
      />
      <div class="input-group-append" (click)="showPassword = !showPassword">
        <span class="input-group-text cursor-pointer"
          ><i
            class="feather"
            [ngClass]="{
              'icon-eye-off': showPassword,
              'icon-eye': !showPassword,
              'is-invalid': submitted && controls[form.name].errors
            }"
          ></i
        ></span>
      </div>
    </div>
    <div
      *ngIf="submitted && controls[form.name].errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
    </div>
  </div>
</ng-template>

<ng-template #textarea let-form="form">
  <div [formGroup]="group">
    <textarea
      class="form-control"
      cols="{{ form.cols }}"
      rows="{{ form.rows }}"
      formControlName="{{ form.name }}"
      [ngClass]="{ 'is-invalid': submitted && controls[form.name].errors }"
    ></textarea>
    <div
      *ngIf="submitted && controls[form.name].errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
    </div>
  </div>
</ng-template>

<ng-template #select let-form="form">
  <div [formGroup]="group">
    <ng-select
      [loading]="form?.loading"
      [selectOnTab]="true"
      [items]="form.items"
      (open)="getItems(form)"
      bindLabel="{{ form?.bindLabel ?? 'name' }}"
      bindValue="{{ form?.bindValue ?? 'id' }}"
      formControlName="{{ form.name }}"
      [ngClass]="{
        'is-invalid': submitted && controls[form.name].errors
      }"
      [clearable]="form?.with_allow_clear ? form?.is_allow_clear : true"
      placeholder="{{ form?.placeholder }}"
      (change)="setChange($event, form)"
      [readonly]="form?.disabled"
    >
      <ng-template ng-loadingspinner-tmp>
        <div
          class="spinner-border text-primary spinner-border-sm"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </ng-template>
    </ng-select>
    <p class="mb-0" *ngIf="form?.helperText">
      <small class="text-muted">{{ form?.helperText }}</small>
    </p>
    <div
      *ngIf="submitted && controls[form.name].errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
    </div>
  </div>
</ng-template>

<ng-template #multiselect let-form="form">
  <div *ngIf="groupName" [formGroup]="group">
    <ng-container [formGroupName]="groupName ?? undefined">
      <ng-select
        [loading]="form?.loading"
        [items]="form.items"
        [multiple]="true"
        bindLabel="{{ form?.bindLabel ?? 'name' }}"
        bindValue="{{ form?.bindValue ?? 'id' }}"
        [selectableGroup]="true"
        [selectableGroupAsModel]="false"
        [closeOnSelect]="false"
        formControlName="{{ form.name }}"
        (open)="getItems(form)"
        [ngClass]="{
          'is-invalid': submitted && controls[form.name].errors
        }"
      >
        <ng-template ng-loadingspinner-tmp>
          <div
            class="spinner-border text-primary spinner-border-sm"
            role="status"
          >
            <span class="sr-only">Loading...</span>
          </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items">
          <span class="badge badge-light-primary">
            {{ items.length }} selected
          </span>
        </ng-template>
        <ng-template
          ng-option-tmp
          let-item="item"
          let-item$="item$"
          let-index="index"
        >
          <input
            id="item-{{ index }}"
            value="{{ item.id }}"
            type="checkbox"
            [checked]="item$.selected"
          />
          {{ item[form.bindLabel] }}
        </ng-template>
      </ng-select>
      <p class="mb-0" *ngIf="form?.helperText">
        <small class="text-muted">{{ form?.helperText }}</small>
      </p>
      <div
        *ngIf="submitted && controls[form.name].errors"
        class="invalid-feedback"
      >
        <div *ngIf="controls[form.name].errors?.['required']">
          {{ form.label }} is required
        </div>
      </div>
    </ng-container>
  </div>

  <div *ngIf="!groupName" [formGroup]="group">
    <ng-select
      [loading]="form?.loading"
      [items]="form.items"
      [multiple]="true"
      bindLabel="{{ form?.bindLabel ?? 'name' }}"
      bindValue="{{ form?.bindValue ?? 'id' }}"
      [selectableGroup]="true"
      [selectableGroupAsModel]="false"
      [closeOnSelect]="false"
      formControlName="{{ form.name }}"
      (open)="getItems(form)"
      [ngClass]="{
        'is-invalid': submitted && controls[form.name].errors
      }"
    >
      <ng-template ng-loadingspinner-tmp>
        <div
          class="spinner-border text-primary spinner-border-sm"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items">
        <span class="badge badge-light-primary">
          {{ items.length }} selected
        </span>
      </ng-template>
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          id="item-{{ index }}"
          value="{{ item.id }}"
          type="checkbox"
          [checked]="item$.selected"
        />
        {{ item[form.bindLabel] }}
      </ng-template>
    </ng-select>
    <p class="mb-0" *ngIf="form?.helperText">
      <small class="text-muted">{{ form?.helperText }}</small>
    </p>
    <div
      *ngIf="submitted && controls[form.name].errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
    </div>
  </div>
</ng-template>

<ng-template #multiCheckAll let-form="form">
  <div [formGroup]="group">
    <ng-select
      #multiselectCheckAll
      [loading]="form?.loading"
      [items]="form.items"
      [multiple]="true"
      [searchable]="false"
      bindLabel="{{ form?.bindLabel ?? 'name' }}"
      bindValue="{{ form?.bindValue ?? 'id' }}"
      [selectableGroup]="true"
      [selectableGroupAsModel]="true"
      [closeOnSelect]="false"
      [clearSearchOnAdd]="false"
      [(ngModel)]="form.model"
      [ngModelOptions]="{ standalone: true }"
      (search)="onSearch($event)"
      (open)="getItems(form)"
      (clear)="checkedAll = false"
      (change)="onSelect(form.name)"
      [disabled]="disabled ?? false"
    >
      <ng-template ng-loadingspinner-tmp>
        <div
          class="spinner-border text-primary spinner-border-sm"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items">
        <span class="badge badge-light-primary">
          {{ items.length }} selected
        </span>
      </ng-template>
      <ng-template ng-header-tmp *ngIf="!loading && form.items.length">
        <div class="d-flex align-items-center">
          <div class="custom-control custom-checkbox" style="margin-right: 5px">
            <input
              type="checkbox"
              class="custom-control-input"
              id="check-all"
              [disabled]="disableCheck"
              [checked]="form.items.length === form.model.length || checkedAll"
              (click)="selectAll($event)"
            />
            <label class="custom-control-label" for="check-all"></label>
          </div>
          <input
            style="width: 100%"
            type="text"
            class="form-control"
            placeholder="Search"
            (input)="multiselectCheckAll.filter($event.target.value)"
          />
        </div>
      </ng-template>
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          id="item-{{ index }}"
          type="checkbox"
          [ngModel]="item$.selected"
          [ngModelOptions]="{ standalone: true }"
        />
        {{ item[form.bindLabel] }}
      </ng-template>
    </ng-select>
    <div
      *ngIf="submitted && controls[form.name]?.errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
    </div>
  </div>
</ng-template>

<ng-template #selectTime let-form="form">
  <div [formGroup]="group" class="timepicker-select cursor-pointer">
    <div class="d-flex align-items-center">
      <ng-select
        [items]="timeTypes"
        class="time-type"
        bindLabel="name"
        bindValue="id"
        formControlName="type"
        [clearable]="false"
      >
      </ng-select>
      <ng-select
        [items]="timeValue"
        class="timepicker"
        bindLabel="name"
        bindValue="id"
        formControlName="time"
      >
        <ng-template ng-label-tmp let-item="item">
          {{ formatTime(item.name) }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          {{ formatTime(item.name) }}
        </ng-template>
      </ng-select>
    </div>
  </div>
</ng-template>

<ng-template #bsDatepicker let-form="form">
  <div class="form-group">
    <div
      [formGroup]="group"
      class="input-group input-group-merge mb-0"
      [ngClass]="{ 'is-invalid': submitted && controls[form.name].errors }"
    >
      <input
        type="text"
        formControlName="{{ form.name }}"
        class="form-control"
        placement="bottom left"
        [bsConfig]="form.config"
        [ngClass]="{ 'is-invalid': submitted && controls[form.name].errors }"
        (onShown)="onOpenCalendar($event)"
        bsDatepicker
      />
      <div class="input-group-append">
        <span class="input-group-text"><i data-feather="calendar"></i></span>
      </div>
    </div>
    <div
      *ngIf="submitted && controls[form.name].errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
    </div>
  </div>
</ng-template>

<ng-template #rating let-form="form">
  <div class="form-group">
    <div class="rating" [formGroup]="group">
      <ngb-rating
        [max]="form.max"
        [(rate)]="form.rate"
        class="outline-none"
        (rateChange)="setReturn(form.rate)"
      ></ngb-rating>
    </div>
    <div
      *ngIf="submitted && controls[form.name].errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
    </div>
  </div>
</ng-template>

<ng-template #checkbox let-form="form">
  <div [formGroup]="group">
    <div [class.demo-inline-spacing]="form.is_inline">
      <div
        *ngFor="let option of form.options"
        class="custom-control custom-checkbox"
      >
        <input
          type="checkbox"
          formControlName="{{ form.name }}"
          class="custom-control-input"
          id="checkbox-{{ option.id }}"
          value="{{ option.value }}"
          [checked]="{ checked: option.checked }"
        />
        <label class="custom-control-label" for="checkbox-{{ option.id }}">{{
          option.label
        }}</label>
      </div>
    </div>
    <div class="mt-50" *ngIf="form?.helperText">
      <small class="text-muted">{{ form?.helperText }}</small>
    </div>
    <div
      *ngIf="submitted && controls[form.name].errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
    </div>
  </div>
</ng-template>

<ng-template #radio let-form="form">
  <div [formGroup]="group">
    <div
      class="demo-inline-spacing"
      [ngClass]="{
        'is-invalid': submitted && controls[form.name].errors
      }"
      [ngStyle]="{ marginTop: form?.isCustom ? form?.customStyle.val : '' }"
    >
      <div
        *ngFor="let option of form.options"
        class="custom-control custom-radio mt-0"
      >
        <input
          formControlName="{{ form.name }}"
          type="radio"
          id="customRadio-{{ option.id }}"
          name="{{ form.name }}"
          class="custom-control-input"
          value="{{ option.value }}"
          checked
        />
        <label class="custom-control-label" for="customRadio-{{ option.id }}">{{
          option.label
        }}</label>
      </div>
    </div>
    <div
      *ngIf="submitted && controls[form.name].errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
    </div>
  </div>
</ng-template>

<ng-template #pCalendar let-form="form">
  <div [formGroup]="group">
    <p-calendar
      dateFormat="{{ form?.format ?? 'dd/mm/yy' }}"
      [showTime]="form.isShowTime"
      [style]="{ width: '100%' }"
      [inputStyle]="{
        width: '100%',
        'border-color': submitted && controls[form.name].errors ? '#ea5455' : ''
      }"
      formControlName="{{ form.name }}"
      [showIcon]="form.showIcon ?? false"
      [readonlyInput]="form.readOnly ?? false"
      view="{{ form?.view ?? 'date' }}"
      [ngClass]="{ 'is-invalid': submitted && controls[form.name].errors }"
      [inline]="form?.inline ?? false"
      selectionMode="{{ form?.mode ?? 'single' }}"
      [numberOfMonths]="form?.numberMonth ?? 1"
    ></p-calendar>
    <div
      *ngIf="submitted && controls[form.name].errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
    </div>
  </div>
</ng-template>

<ng-template #pRangeDatepicker let-form="form">
  <div
    [formGroup]="group"
    [ngClass]="{ 'is-invalid': submitted && controls[form.name].errors }"
  >
    <p-calendar
      dateFormat="{{ form.format }}"
      [numberOfMonths]="2"
      selectionMode="range"
      [style]="{ width: '100%' }"
      [inputStyle]="{
        width: '100%',
        'border-color': submitted && controls[form.name].errors ? '#ea5455' : ''
      }"
      formControlName="{{ form.name }}"
      view="{{ form?.view ?? 'date' }}"
      [showIcon]="form.showIcon ?? false"
      [readonlyInput]="form.readOnly ?? false"
    ></p-calendar>
  </div>
  <div *ngIf="submitted && controls[form.name].errors" class="invalid-feedback">
    <div *ngIf="controls[form.name].errors?.['required']">
      {{ form.label }} is required
    </div>
    <div *ngIf="controls[form.name].errors?.['pattern']">
      Please enter a valid date range
    </div>
  </div>
</ng-template>

<ng-template #horizontalSwitch let-form="form">
  <div [formGroup]="group">
    <div class="custom-control custom-control-primary custom-switch col-7">
      <input
        type="checkbox"
        checked
        class="custom-control-input"
        id="{{ form.id }}"
        value="1"
        formControlName="{{ form.name }}"
      />
      <label class="custom-control-label" for="{{ form.id }}"></label>
    </div>
  </div>
</ng-template>

<ng-template #currency let-form="form">
  <div [formGroup]="group">
    <input
      type="text"
      class="form-control"
      mask="separator"
      thousandSeparator="."
      formControlName="{{ form.name }}"
      [ngClass]="{ 'is-invalid': submitted && controls[form.name].errors }"
    />
    <p *ngIf="form?.helperText">
      <small class="text-muted">{{ form?.helperText }}</small>
    </p>
    <div
      *ngIf="submitted && controls[form.name].errors"
      class="invalid-feedback"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
    </div>
  </div>
</ng-template>

<ng-template #listBox let-form="form">
  <div [formGroup]="group">
    <p-listbox
      [options]="form.options"
      formControlName="{{ form.name }}"
      [checkbox]="true"
      [filter]="true"
      [multiple]="true"
      [listStyle]="{ 'max-height': '250px' }"
      optionLabel="{{ form?.bindLabel ?? 'name' }}"
      optionValue="{{ form?.bindValue ?? 'id' }}"
    >
    </p-listbox>
    <small
      *ngIf="submitted && controls[form.name].errors"
      class="text-danger d-block mt-50"
    >
      <div *ngIf="controls[form.name].errors?.['required']">
        {{ form.label }} is required
      </div>
    </small>
  </div>
</ng-template>

<ng-template #pickList let-form="form">
  <div
    [formGroup]="group"
    [ngClass]="{ 'is-invalid': submitted && controls[form.name].errors }"
  >
    <p-pickList
      class="{{ 'pickList-' + form.name }}"
      [source]="form.sources"
      [target]="form.selected"
      [sourceStyle]="{ height: '20rem' }"
      [targetStyle]="{ height: '20rem' }"
      [sourceHeader]="
        (form?.sourceHeader ?? 'Available') +
        ' ' +
        form.sources.length.toString() +
        ' Data'
      "
      [targetHeader]="
        (form?.targetHeader ?? 'Selected') +
        ' ' +
        form.selected.length.toString() +
        ' Data'
      "
      [filterBy]="form.bindLabel"
      [dragdrop]="form?.dragDrop ?? false"
      [showSourceControls]="false"
      [showTargetControls]="false"
      (onMoveToSource)="onPickListMove()"
      (onMoveAllToSource)="onPickListMove()"
      (onMoveToTarget)="onPickListMove()"
      (onMoveAllToTarget)="onPickListMove()"
    >
      <ng-template let-row pTemplate="item">
        <div *ngIf="!form?.isCustom">
          <div>{{ row[form.bindLabel] }}</div>
        </div>
        <div *ngIf="form?.isCustom">
          <ng-container
            *ngTemplateOutlet="customTemplate; context: { row }"
          ></ng-container>
        </div>
      </ng-template>
    </p-pickList>
    <p *ngIf="form?.helperText">
      <small class="text-muted mt-25">{{ form?.helperText }}</small>
    </p>
    <small
      *ngIf="submitted && controls[form.name].errors && !form.selected.length"
      class="text-danger d-block mt-50"
    >
      {{ form.label }} is required
    </small>
  </div>
</ng-template>

<ng-template #colorPallete let-form="form">
  <div
    *ngIf="groupName"
    [formGroup]="group"
    class="input-group input-group-merge"
    [class.is-invalid]="submitted && controls[form.name].errors"
  >
    <ng-container [formGroupName]="groupName ?? undefined">
      <div class="input-group-prepend">
        <span class="input-group-text" style="padding: 0 5px">
          <ngx-colors
            ngx-colors-trigger
            [formControlName]="form.name"
            [format]="'hex'"
          ></ngx-colors>
        </span>
      </div>
      <input
        type="text"
        class="form-control"
        placeholder="Select Color"
        [formControlName]="form.name"
        [value]="controls[form.name].value"
        [placeholder]="form?.placeholder"
        [class.is-invalid]="submitted && controls[form.name].errors"
      />
      <div
        *ngIf="controls[form.name].value"
        class="input-group-append"
        (click)="controls[form.name].setValue(null)"
      >
        <span class="input-group-text cursor-pointer"
          ><i class="fa-regular fa-trash-can text-danger"></i
        ></span>
      </div>
    </ng-container>
  </div>
  <div
    *ngIf="!groupName"
    [formGroup]="group"
    class="input-group input-group-merge"
    [class.is-invalid]="submitted && controls[form.name].errors"
  >
    <div class="input-group-prepend">
      <span class="input-group-text" style="padding: 0 5px">
        <ngx-colors
          ngx-colors-trigger
          [formControlName]="form.name"
          [format]="'hex'"
        ></ngx-colors>
      </span>
    </div>
    <input
      type="text"
      class="form-control"
      placeholder="Select Color"
      [formControlName]="form.name"
      [value]="controls[form.name].value"
      [placeholder]="form?.placeholder"
      [class.is-invalid]="submitted && controls[form.name].errors"
    />
    <div
      *ngIf="controls[form.name].value"
      class="input-group-append"
      (click)="controls[form.name].setValue(null)"
    >
      <span class="input-group-text cursor-pointer"
        ><i class="fa-regular fa-trash-can text-danger"></i
      ></span>
    </div>
  </div>
  <div *ngIf="submitted && controls[form.name].errors" class="invalid-feedback">
    <div *ngIf="controls[form.name].errors?.['required']">
      {{ form.label }} is required
    </div>
  </div>
</ng-template>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { takeUntil, Subject } from 'rxjs';
import { NotificationsService } from './notifications.service';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html',
  providers: [ApiService, NotificationsService],
})
export class NavbarNotificationComponent implements OnInit, OnDestroy {
  public data: any;
  public showDialog = false;
  public loading = false;
  public delivery: any;

  private _unsubscribeAll: Subject<any>;

  constructor(private _notificationsService: NotificationsService) {
    this._unsubscribeAll = new Subject<void>();

    if (!localStorage.getItem('notification-date')) {
      localStorage.setItem(
        'notification-date',
        moment().tz('Asia/Jakarta').format('YYYY-MM-DD')
      );
    } else {
      const notifDate = localStorage.getItem('notification-date');
      const today = moment().tz('Asia/Jakarta').format('YYYY-MM-DD');

      if (notifDate !== today) {
        localStorage.removeItem('notifications');
      }
    }

    if (!localStorage.getItem('notifications')) {
      localStorage.setItem(
        'notifications',
        JSON.stringify({
          unread: 0,
          notifications: [],
        })
      );
    }
    this.data = JSON.parse(localStorage.getItem('notifications'));
  }

  ngOnInit(): void {
    this._notificationsService.notif
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        if (Object.keys(res).length > 0) {
          this.data = res;
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(void 0);
    this._unsubscribeAll.complete();
  }

  openNotif(delivery, index) {
    this._notificationsService.open(index);
    this.showDialog = true;
    this.loading = true;
    this.delivery = delivery;
  }

  closeDialog(val) {
    this.showDialog = val.showDialog;
    this.loading = val.loading;
  }
}

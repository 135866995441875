import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import * as _ from 'lodash';

export const searchConfig = {
  category: 'check-sheets',
  term: {},
  reused: false,
  from: 'init',
};
export const searchCategories = [
  { key: 'check-sheets', label: 'CheckSheets' },
  { key: 'routes', label: 'Routes' },
  { key: 'templates', label: 'Templates' },
  { key: 'delivery-orders', label: 'Delivery Orders' },
  { key: 'vehicles', label: 'Vehicles' },
  { key: 'employees', label: 'Employees' },
  { key: 'customers', label: 'Customers' },
  { key: 'vendors', label: 'Vendors' },
  { key: 'employee-documents', label: 'Employee Documents' },
  { key: 'health-check', label: 'Medical Records' },
  { key: 'e-tolls/topup', label: 'E-toll Topup' },
  { key: 'e-tolls/transactions', label: 'E-toll Transactions' },
  { key: 'stocks/entry', label: 'Stock' },
  { key: 'purchase-requests', label: 'Purchase Request' },
  { key: 'settings/payroll-variables', label: 'Payroll Variables' },
  { key: 'settings/payroll-components', label: 'Payroll Components' },
  { key: 'settings/auth/permissions', label: 'Permissions' },
  { key: 'settings/delivery/logistic-points', label: 'Logistic Points' },
  { key: 'settings/delivery/e-tolls', label: 'E-Tolls' },
  { key: 'settings/qualifications/skills', label: 'Skills' },
  { key: 'settings/qualifications/trainings', label: 'Trainings' },
  { key: 'settings/attendance/work-schedules', label: 'Work Schedules' },
  { key: 'periodic-service', label: 'Periodic Service' },
  { key: 'settings/health-types', label: 'Health Types' },
  { key: 'settings/healths', label: 'Health Components' },
  { key: 'employee-sicks', label: 'Sick Monitoring' },
  { key: 'schedules', label: 'Schedules' },
  { key: 'attendances', label: 'Attendances' },
  { key: 'attendances/timeline', label: 'Timeline' },
  { key: 'employee-birthday', label: 'Birthdays' },
  { key: 'delivery-incidents', label: 'Delivery Incidents' },
  { key: 'delivery-driver-performances', label: 'Delivery Manpower' },
  { key: 'payroll-generates', label: 'Payslips' },
  { key: 'settings/payroll-types', label: 'Payment Types' },
];
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private searchCategories = _.cloneDeep(searchCategories);
  private searchTerm = _.cloneDeep(searchConfig);
  private currentPath = '';
  public onSearchChanged: BehaviorSubject<any>;
  public onSearchAdvancedTrigger: BehaviorSubject<any>;

  constructor(private _router: Router) {
    this.onSearchChanged = new BehaviorSubject(this.searchTerm);
    this.onSearchAdvancedTrigger = new BehaviorSubject(false);
  }

  resolve(route: ActivatedRouteSnapshot) {
    if (route.data.path !== this.searchTerm.category) {
      this.searchTerm.term = {};
    }
    if (this.searchCategories.some((e) => e.key === route.data.path)) {
      this.searchTerm.category = route.data.path;
    }
    this.currentPath = route.data.path;
    this.searchTerm.from = 'resolve';
    this.onSearchChanged.next(this.searchTerm);
  }

  public setCategory(key) {
    this.searchTerm.category = key;
  }

  public setTerm(term) {
    this.searchTerm.term = term;
    if (this.searchTerm.category !== this.currentPath) {
      this._router.navigate([this.searchTerm.category]);
      return;
    }
    this.searchTerm.from = 'navbar';
    this.onSearchChanged.next(this.searchTerm);
  }

  get search(): any | Observable<any> {
    return this.onSearchChanged.asObservable();
  }

  public openSearchAdvanced() {
    this.searchTerm.category = this.currentPath;
    this.onSearchChanged.next(this.searchTerm);
    this.onSearchAdvancedTrigger.next(true);
  }

  get onSearchAdvanced(): any | Observable<any> {
    return this.onSearchAdvancedTrigger.asObservable();
  }
}

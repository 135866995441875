import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'filterArray',
})
export class FilterArrayPipe implements PipeTransform {
  transform(
    inputs: any[],
    filter: Object | [],
    key: string = '',
    operator: string = ''
  ): any[] {
    if (!inputs || !filter) return inputs;

    if (_.isArray(filter)) {
      return _.filter(inputs, (d: never) => {
        return operator == '=='
          ? filter.includes(d[key])
          : !filter.includes(d[key]);
      });
    } else {
      return _.filter(inputs, filter);
    }
  }
}
